<template>
  <div id="login-body" class="container d-flex justify-content-center">
    <div id="login-card" class="border-0 card col-md-6">
      <article class="card-body">
        <h3 class="card-title text-center mb-4 mt-1"><img class="ml-1 pb-1" :src="getClientLogo()" ></h3>
        <hr>
        <template v-if="loaded">
          <p class="alert alert-danger text-center" role="alert" v-if="!validParams">
            Invalid activation link. Please <a href="mailto:support@draftrs.com">Contact Support</a>
          </p>
          <div v-else>
            <h5 class="text-center">Set a password to activate your account</h5>

            <div class="card text-center mt-3">
              <h6 class="mt-3">Password Requirements</h6>
              <ul>
                  <li :class="hasSpecial ? 'text-success' : 'text-danger'">Contain at least 1 special character</li>
                  <li :class="hasLower ? 'text-success' : 'text-danger'">Contain at least 1 lowercase letter</li>
                  <li :class="hasUpper ? 'text-success' : 'text-danger'">Contain at least 1 uppercase letter</li>
                  <li :class="hasNumber ? 'text-success' : 'text-danger'">Contain at least 1 number</li>
                  <li :class="has8Plus ? 'text-success' : 'text-danger'">At least 8 characters</li>
                  <li :class="passMatch ? 'text-success' : 'text-danger'">Passwords must match</li>
              </ul>
            </div>

            <div class="form-group">
              <div class="ml-0 input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                </div>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  placeholder="Password"
                  @input="validatePassword()"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="ml-0 input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                </div>
                <input
                  v-model="password2"
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  @input="validatePassword()"
                />
              </div>
            </div>

            <div class="form-group">
              <button class=" btn btn-primary btn-block" v-bind:class="{ disabled: !passwordValid }" v-bind:disabled="!passwordValid" @click="activateAccount()">Activate</button>
            </div>
          </div>
        </template>
      </article>
    </div>
  </div>
</template>

<script>
import { activate, cognitoAuth, loginUser } from '../Utils/auth.js';
import { getClientLogo, } from "../Utils/themer.js";
import axios from 'axios'
import { Buffer } from 'buffer/';
export default {
  name: 'activate',
  data: function () {
    return {
      client: 'draftrs',
      email: null,
      code: null,
      validParams: false,
      loaded: false,
      password: null,
      password2: null,
      passwordValid: false,
      hasSpecial: false,
      passMatch: false,
      hasNumber: false,
      hasLower: false,
      hasUpper: false,
      has8Plus: false,
      cognitoUser: null
    }
  },
  async mounted() {
    //decode because of azure's stupid (no period in urls)
    this.email = Buffer.from(this.$route.params.email.replace('-xpx-', '.'), 'base64').toString('ascii');
    this.code = this.$route.params.code;
    this.cognitoUser = await this.validateParams();
    this.validParams = (false !== this.cognitoUser);
    this.loaded = true;    
  },
  methods: {
    validateParams: async function() {
      const user = await cognitoAuth(this.email, this.code);
      return ('NEW_PASSWORD_REQUIRED' == user.challengeName) ? user : false;
    },
    activateAccount: async function() {
      const user = await activate(this.cognitoUser, this.password, this.cognitoUser.challengeParam.userAttributes);
      await axios.post("/api/login", { email: this.email, password: user.signInUserSession.accessToken.jwtToken, client: this.client }).then(res => {
        const dat = res.data.data;
        const jwt = res.data.token;
        
        if (jwt && dat) {
          loginUser(dat.client, this.email, jwt);
          this.$router.push(this.$route.query.redirect || '/');
        }
      })
    },
    getClientLogo(mob) {
      return getClientLogo(mob);
    },
    validatePassword: function () {
        this.hasSpecial = /[!@#$%^&*)(+=._-]/.test(this.password);
        this.passMatch = this.password === this.password2;
        this.hasNumber = /\d/.test(this.password);
        this.hasLower = /[a-z]/.test(this.password);
        this.hasUpper = /[A-Z]/.test(this.password);
        this.has8Plus = 8 <= this.password.length;

        this.passwordValid = (this.hasSpecial && this.passMatch && this.hasNumber
          && this.hasLower && this.hasUpper && this.has8Plus);
    }
  }
}
</script>

<style lang="scss">


</style>