<template>
    <div>
        <StandardNav/>
        <header class="section-fluid bg-purple">
			<div class="container">
				<div class="row">
					<div class="col-md-5 d-flex flex-column justify-content-center align-items-md-start py-5">
						<h1 class=" text-white font-weight-bold">Architects and Engineers Insurance</h1>
						<p class="text-white">
							Professional liability, sometimes also referred to as errors & omissions (E&O) insurance, can protect your business in the event a client or other third party makes a claim against you for alleged errors in your work.   
						</p>
						<p class="text-white">
							Draftrs offers professional liability E&O insurance to solo and small firm design professionals.  Our policy is underwritten by Markel Insurance Company, an AM Best “A” rated carrier.  
						</p>
						<a href="/getaquote" class="mx-auto btn btn-secondary mt-3"
							>Get a quote</a
						>
					</div>
					<div class="col-md-6 offset-md-1">
						<div class="half-width-img">
							<img
								src="/images/architect-at-desk-min.jpg"
								alt="Young male architect at desk with schematics"
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main>
			<section class="container py-5">
				<div class="row">
					<div class="col-md-9 fs-5 mb-5">
						<h2 class="font-weight-bold">Architects</h2>
						<p>
							If you're like most architects, you invest a lot of time and
							effort into making sure each project is as perfect as possible.
							But even with your best efforts, mistakes can happen—and when they
							do, professional liability insurance may help protect the business
							you've worked so hard to build. <router-link class="font-weight-bold" to="professional-liability-insurance-architects">Read More</router-link>.
						</p>						
					</div>
				</div>
				<div class="row">
					<div class="col-md-9 fs-5 mb-5">
						<h2 class="font-weight-bold">Engineers</h2>
						<p>
							As an engineer, you know that details matter. You put so much
							thought and effort into every single aspect of what you do, and
							with good reason: your work is critical to preserving the
							integrity of structures and keeping people safe. But even the most
							careful engineer can find themselves in a risky situation—one that
							could cost them financially or professionally. <router-link class="font-weight-bold" to="professional-liability-insurance-engineers">Read More</router-link>.
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-9 fs-5 mb-5">
						<h2 class="font-weight-bold">Interior Designers</h2>
						<p>
							We know that interior designers are always looking for more ways
							to protect themselves. It's why we're so excited to introduce our
							new insurance options, designed specifically to meet the needs of
							interior designers like you. We understand that in your industry,
							no two projects are alike, and you deserve a policy that can be
							tailored just as uniquely as your eye for design. <router-link class="font-weight-bold" to="professional-liability-insurance-interior-designers">Read More</router-link>.
						</p>
						<p>
							Whether it is an error, something you missed or someone getting
							hurt on the job, having insurance is a great way to protect
							yourself from challenges.
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-9 fs-5 mb-5">
						<h2 class="font-weight-bold">Construction Managers</h2>
						<p>
							As a construction manager, project manager, or owner's
							representative, you're faced with many challenges on a daily
							basis. From managing expenses to navigating the bureaucracy of
							permits and more, your job is hard enough as it is. <router-link class="font-weight-bold" to="professional-liability-insurance-construction-managers">Read More</router-link>.
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-9 fs-5 mb-5">
						<h2 class="font-weight-bold">Land Surveyors</h2>
						<p>
							As a land surveyor, it's your job to make precise measurements to
							determine property boundaries and update boundary lines. You're a
							licensed professional, and you work hard to provide your clients
							with the best service possible. But even though you do everything
							in your power to provide high-quality services, various issues can
							occur when working with clients who feel damaged by your services
							or who have another issue with them. <router-link class="font-weight-bold" to="professional-liability-insurance-land-surveyors">Read More</router-link>.
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-9 fs-5 mb-5">
						<h2 class="font-weight-bold">Technical Design Consultants</h2>
						<p>
							Professional liability (E&O) insurance protects you and your business from claims against you arising out of a range of technical design consulting services including: CAD/BIM Drafting, environmental, acoustical, audio visual and other technical construction design related consulting services. <router-link class="font-weight-bold" to="professional-liability-insurance-technical-design-consultants">Read More</router-link>.
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-md-9 fs-5 mb-5">
						<h2 class="font-weight-bold">Building Designers</h2>
						<p>
							Whether you are creating code compliant custom plans or you are just selling stock plans online, professional liability insurance can protect a building designer from claims and demands arising out of errors or alleged errors in your work. <router-link class="font-weight-bold" to="professional-liability-insurance-building-designers">Read More</router-link>.
						</p>
					</div>
				</div>
			</section>
			<!-- Final CTA -->
			<section class="container-fluid bg-purple">
				<div class="container">
					<div class="row">
						<div
							class="col-md-6 d-flex flex-column justify-content-center align-items-start"
						>
							<img
								src="/images/markel_logo-white.png"
								alt=""
								class="img-fluid mb-4 mt-5"
								width="100px"
							/>
							<h2 class="display-4 text-white font-weight-bold mb-3">
								<span class="text-primary">Top A Rated Insurance</span> for
								small firms
							</h2>
							<p class="text-white mb-4">
								We offer admitted policies underwritten by Markel Insurance
								Company (10275 West Higgins Road, Suite 750, Rosemont, IL 60018
								NAIC # 38970), a specialist insurer with over 40 years of
								experience insuring professional service firms. Rated “A”
								(Excellent) by AM Best
							</p>
							<p class="text-white" style="font-size: 8px">
								* Rating as of September 15, 2021. A.M. Best ratings are under
								continuous review and subject to change, please refer to
								Markel’s website for the most current information. The rating
								represents the overall financial status of Markel Insurance
								Company, and is not a recommendation of the specific policy
								provisions, rates or practices of the issuing insurance company.
								Terms and conditions for rate and coverage may vary by state and
								application. Insurance and coverage are subject to availability
								and qualifications and may not be available in all states.
							</p>
							<a href="/AgentsContactUs" class="btn btn-secondary mb-5"
								>Get started</a
							>
						</div>
						<div class="col-md-5 offset-md-1">
							<img
								src="/images/woman-checking-phone_portrait-min-2.png"
								alt="Young woman holding tablet in office"
								class="img-fluid neg-margin-image"
							/>
						</div>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "design-professionals-insurance",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">


</style>
