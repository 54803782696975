<template>
    <body>
        <div id="wrapper" class="insure-board bg-light ">
            <AgentNav/>

            <nav aria-label="breadcrumb" class=" d-md-block mb-0">
                <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
                    <li class="breadcrumb-item"><a href="/home">Home</a></li>
                    <li class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard" >Dashboard</a></li>
                    <li class="breadcrumb-item" ><a :href="'/policy/' + summary.customerId" >Policies: <span v-if="summary.insured">{{ summary.insured.insured }}</span></a></li>
                    <li class="breadcrumb-item active" aria-current="Policy">Cancel Policy: <span v-if="policy">{{ policy.policyNumber }}</span></li>
                </ol>
            </nav>

            <main id="main" role="main">
                <div class="row justify-content-center p-2">
                    <div class="col-md-8 card p-3 mb-3 shadow card-radius">
                        <h2 class="main-color pl-3">Purchase Extended Reporting Period (ERP)</h2>
                        <h4 class="pl-3 mb-5" v-if="quote">{{ quote.insured }}</h4>
                        <div class="card-body" >
                            <dl class="row" style="font-size: .95rem ">
                                <dd class="col-5">Policy Number:</dd>
                                <dd class="col-6">
                                    <router-link :to="{ name: 'quote', params: { id: policy.quoteId} }">
                                        <span v-if="policy.policyNumber">{{ policy.policyNumber }}</span>
                                        <span class="spinner-border spinner-border-sm ml-5" v-else></span>
                                    </router-link>
                                </dd>

                                <dd class="col-5">Effective: </dd>
                                <dd class="col-6">{{ formatDate(policy.effective) }}</dd>

                                <dd class="col-5">Cancelled: </dd>
                                <dd class="col-6">{{ formatDate(cancelDate) }}</dd>

                                <dd class="col-5">Coverage:</dd>
                                <dd class="col-6">Professional Liability</dd>

                                <dd class="col-5">Premium:</dd>
                                <dd class="col-6">{{ policy.premium | currency }}</dd>
                            </dl>

                            <dl v-show="alreadyHasErp">
                                <dd class="alert alert-warning border border-warning">
                                    <i class="fas fa-exclamation-triangle"></i> Your policy has ERP. Please contact support at support@draftrs.com to help arrange your policy cancellation and ERP effective date.
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div class="col-md-8 card shadow card-radius mt-3 mb-3" v-if="erp == true">
                        <h3 class="card-header bg-transparent px-0">
                            <div class="pl-2 float-left sprite bg-book"></div>
                            <div class="ml-2 pt-3 float-left">
                                Extended Reporting
                                <i
                                    v-b-tooltip.hover 
                                    title="Option for the Insured to purchase a one, two or three year extended reporting period to report claims on the policy when a policy is cancelled. 
                                    Typically purchased when a firm is acquired and/or is no longer in business." 
                                    class="far fa-question-circle main-color">
                                </i>
                            </div>
                        </h3>
                        <div class="card-body">
                            <div class="px-0 form-group mb-3">
                                <dl class="row px-0" >
                                    <dd class="col-md-12">
                                        <label>ERP is effective {{ getCancelDt() }} the date of policy cancellation.<br/>You have up to 30 days after policy cancellation to purchase ERP.</label>
                                    </dd>
                                </dl>
                                <div v-if="erp">
                                    <div class="form-group mb-0 pl-0 col-md-12">
                                        <label class="mb-0"><b>How many months extended reporting do you require?</b></label>
                                        <select class="form-control p-1 mt-2 " v-model="erpMonths" required>
                                            <option value="">Select a Timeframe</option>
                                            <option value="12">12 Months</option>
                                            <option value="24">24 Months</option>
                                            <option value="36">36 Months</option>
                                            <option v-if="['WY', 'CT'].includes(quote.state)" value="100">Unlimited</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 card shadow card-radius mt-3 mb-3" v-show="cancelDate && (erp == 0 || erpMonths > 0)">
                        <dl class="row p-4" >

                            <dt class="col-6 mt-0 p-2 " v-if="erpMonths > 0">Extended Reporting Premium ({{ erpMonths == 100 ? 'Unlimited' : erpMonths + ' months' }}):</dt>
                            <dt class="col-4 mt-0 p-2 " v-if="erpMonths > 0">
                                <span v-if="erpPremium" >
                                    {{ erpPremium | currency }}
                                    <i
                                        v-b-tooltip.hover 
                                        title="Additional cost for adding Extended Reporting" 
                                        class="ml-1 far fa-question-circle main-color">
                                    </i>
                                </span>
                                <span class="spinner-border spinner-border-sm ml-3" v-else></span>
                            </dt>
                        </dl>
                    </div>

                    <div class="col-md-8 card shadow card-radius mt-3 mb-3 p-3" v-show="canPurchase() && prorate > 0">
                        <div class="border border-dark bg-white rounded p-3">
                            <StripePayment v-if="quote && erpMonths && cancelDate" :quoteData="quote" :issueAction="'erp'" :erpMonths="erpMonths" :cancelDate="cancelDate" :payHeader="'Purchase ERP With'" @onPaymentComplete="onPaymentComplete" @onERPPremium="onERPPremium" />
                        </div>
                        <!-- <div class="p-4" >
                            <div class="info-form">
                                <h3 class="main-color mb-3">Credit / Debit Card</h3>
                                <form id="payment-form">
                                    <div id="card-element"></div>
                                    <p id="card-error" class="text-danger mt-3 text-center" role="alert"></p>
                                </form>
                            </div>
                            <div>
                                <div class="extra-margin text-center mt-4">
                                    <h5>{{ 'Purchase ERP $' + prorate }}</h5>
                                    <button id="stripe-pay-btn" @click.prevent="pay()" class="btn btn-primary btn-lg" v-if="!transacting">Purchase ERP</button>
                                    <button class="btn btn-primary btn-lg" type="button" disabled v-if="transacting">
                                        <span class="spinner-border spinner-border-md mr-2 " role="status" aria-hidden="true"></span>
                                        Processing
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </main>
            <b-modal 
                centered
                ref="erp-status-modal" 
                ok-only    
                size="xl"
                hide-header-close
                @ok="redirectToPolicy"
            >
                <h6 class="alert p-2" :class="purchased ? 'alert-success' : 'alert-warning'" >{{ msgTitle }} </h6>
                <div class="row">
                    <div class="offset-2 col-md-8">
                        <p class="my-4" v-html="msg"></p>
                    </div>
                </div>
            </b-modal>
            <StandardFooter/>
        </div>
    </body>
</template>

<script>
import AgentNav from "../components/AgentNav.vue";
import StripePayment from "../components/StripePayment.vue";
import StandardFooter from "../components/StandardFooter.vue";
import axios from 'axios';
import moment from 'moment';

export default {
  name: "PurchaseERP",
  components: {
    AgentNav,
    StripePayment,
    StandardFooter
  },
  data: function() {
    return {
        customerId: null,
        quoteId: null,
        quote: null,
        summary: {},
        policy: {},
        prorate: null,
        cancelDate: null,
        minDate: null,
        maxDate: null,
        purchased: false,
        msgTitle: 'There was an issue purchasing ERP for your policy.',
        msg: 'Please contact your agent or try again later.',
        submitPurchase: false,
        erp: null,
        erpMonths: '',
        erpPremium: null,
        totalRefund: null,
        transacting: false,
        cs: null,
        alreadyHasErp: false,
        addlData: null
    };
  },
  watch: {
    erp: function() {
        this.erpMonths = this.erp == 0 ? '' : this.erpMonths;
    },
    erpMonths: function() {
    },
  },
  mounted() {
    window.scrollTo(0,0);
    this.customerId = this.$route.params.customerid; // url order: /quoteId/customerId
    this.quoteId = this.$route.params.id;

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.minDate = new Date(today)

    this.getCustomerData();
  },
  methods: {
    onERPPremium: async function(result) {
        if (result.erpPremium)
            this.prorate =  result.payAmount;
            this.erpPremium = result.erpPremium;
    },
    onPaymentComplete: async function(result) {
        this.issuing = true;

        if (false !== result) {
            let params = null;

            result.billing.erpMonths = this.erpMonths;
            result.billing.erpPremium = this.erpPremium;

            this.quote.addlExtendedReportingPeriod = 1;
            this.quote.extendedReportingPeriodMonths = this.erpMonths;
            this.quote.extendedReportingDate = this.cancelDate;
            this.quote.rating.breakdowns.extendedReporting = this.erpPremium;

            params = { quoteId: this.quoteId, quote: this.quote, action: 'erp', billing: result.billing, effective: this.cancelDate };

            await axios.post('/api/policy', params).then(() => {
                this.purchased = true;
                this.msgTitle = "ERP purchased for policy "+this.policy.policyNumber+".";
                this.msg = "You purchased ERP for policy "+this.policy.policyNumber+".";
                this.msg += "<br/>The Extended Reporting period of " + this.erpMonths + " months has been purchased for an additional $"  + this.prorate + ".";

                window.scroll(0,0);
                this.transacting = false;
                this.$refs['erp-status-modal'].show();
            });
        } else {
            this.transacting = false;
            this.purchased = false;
            this.cancelMsgTitle = "Ooops!! Something happened.";
            this.cancelMsg = "So sorry something happened. We were not able to cancel your policy "+this.policy.policyNumber+".<br/> Please contact us at support@draftrs.com for help.";
        }

    },
    getCustomerData: async function() {

        let summary = {};
        await axios.get('/api/policy/' + this.customerId).then((res) => {
            summary = res.data;
        });

        this.summary = summary;
        this.policy = this.summary.policies[this.summary.policies.findIndex(p => p.quoteId == this.quoteId)];
        this.maxDate = this.policy.expires;

        await axios.get('/api/quote/' + this.policy.quoteId).then((res) => {
            this.quote = res.data;
        });

        // check if already has erp
        if (this.quote.addlExtendedReportingPeriod && this.quote.extendedReportingPeriodMonths) {
            this.alreadyHasErp = true;
            this.erp = false;
            this.redirectToPolicy();
        } else
            this.erp = true;

        this.summary.transactions.forEach( trans => this.cancelDate = trans.action == 'cancel' ? trans.effective : '');

    },
    formatDate: function(dt, fmt) {
        if (!fmt) {
            fmt = 'MM/DD/YYYY';
        }
        return moment(dt).format(fmt);
    },
    getExpDt: function(eff, term) {
        return moment(eff).add(term, 'months').format('MMMM Do YYYY');
    },
    getCancelDt: function() {
        return moment(this.cancelDate).format('MMMM Do YYYY');
    },
    redirectToPolicy: function() {
        this.$router.push({name: 'policy', params: { customerid: this.customerId}});
    },
    canPurchase: function() {

        if (this.cancelDate && this.prorate != null) {
            if (this.erp == 1 && this.erpMonths > 0)
                return true;
            else if (this.erp == 0)
                return true;
        }

        return false;
    },
}
};
</script>

<style lang="scss">
#payment-form {
    border: 1px solid var(--main-color); 
    padding: 10px;
}
</style>