<template>
    <div id="wrapper">
        <StandardNav/>
        <main id="main" role="main" class="">
            <section class="m-5">
                <div class="text-center">
                <h3 class="main-color p-2"><i class="fas fa-clipboard-list pr-1"></i> Draftrs Terms of Service </h3>
                </div>
                <div class="row mt-5">
                    <div class="col-md-1"></div>
                    <div class="px-3 col-md-10">
                        <p >The following Terms of Service (&ldquo;Terms&rdquo;) shall govern the use and access
                                of all customers, partners and visitors (collectively, &ldquo;User(s)&rdquo;) to our website(s) (the
                                &ldquo;Site&rdquo;), our &ldquo;drop-in code&rdquo; linking to our Site, and any other related service we
                                offer (collectively, the &ldquo;Services&rdquo;). &nbsp;As a User of our Services, these Terms are a legally
                                binding contract, and you should read them carefully.</p>
                        <ol class="pl-0"  start="1">
                            <li class="main-color"><b>1.</b> Changes To Terms</li>
                        </ol>
                        <p >Draftrs may revise and update these Terms from time to time in its sole discretion.
                                Unless stated otherwise, all changes are effective upon notice and apply to all access to and use of the
                                Services. Your continued use of the Services following notice and posting of any revised Terms means that
                                you accept and agree to the changes. </p>
                        <ol class="pl-0"  start="2">
                            <li class="main-color"><b>2.</b> Services</li>
                        </ol>
                        <p >Draftrs provides a variety of insurance products and services through its Site,
                                &ldquo;drop-in code&rdquo; and other means. &nbsp;As a condition of using the Services, you affirm that you
                                are either over 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and
                                are fully able and competent to enter into the terms, conditions, obligations, affirmations, waivers,
                                representations and warranties set forth in the Terms, and to abide by and comply with the Terms. You
                                further affirm that you are not under the age of 13, as the Services are not intended to be offered to
                                children under that age. If you are under 13 years of age, then please do not use the Services.</p>
                        <p >By using or accessing the Services, you represent and warrant that you are not
                                located in a country subject to a U.S. Government embargo, or that has been designated by the U.S.
                                Government as a &ldquo;terrorist supporting&rdquo; country, and that you are not listed on any U.S.
                                Government list of prohibited or restricted parties.</p>
                        <p >Whether or not identified on the Site, Draftrs&rsquo;s products and services may not
                                be available in all areas of the United States. You also may not be eligible for Draftrs&rsquo;s products
                                and services. We reserve the sole right and discretion to determine eligibility. Certain products or
                                services may be subject to specific terms, conditions, representations or agreements as identified on the
                                Site or elsewhere by Draftrs. </p>
                        <ol class="pl-0"  start="3">
                            <li class="main-color"><b>3.</b> Insurance</li>
                        </ol>
                        <p >We may make certain insurance products and services available to purchase for a fee
                                through the Services. If you purchase an insurance product via the Services, charges will be disclosed to
                                you on the Services before you complete the purchase. If you purchase an insurance product or service via
                                the Services, your account with Draftrs may include information you provide to us during the insurance
                                application or claims process. You hereby represent and warrant that you will ensure that this information
                                is kept accurate and up-to-date at all times.</p>
                        <p >All quotes generated by Draftrs are based upon the information you provided and are
                                not a contract, binder, or agreement to extend insurance coverage. Any coverage descriptions provided on the
                                Services are general descriptions of available coverages and are not a statement of insurance contract. To
                                obtain coverage you must submit an application to Draftrs in the manner we permit. All insurance
                                applications are subject to underwriting approval. Coverages and availability may vary by state; and
                                additional minimum coverage limits may be required in your state.</p>
                        <p >If we accept your insurance application, we may accept payment via credit card or
                                other electronic payment service. We or our third-party payment processing partner will bill you based on
                                the information you provide and we accept. All currency references are in U.S. dollars. If the credit card
                                or other payment information you provide is invalid for any reason, or if you charge back your credit card
                                or other payment mechanism for fees due, we may suspend or terminate any or all of the services and products
                                we provide to you. We may automatically charge the card or other payment mechanism for recurring premiums or
                                other recurring fees, the amount of which may change depending on policy changes you initiate and other
                                factors approved by insurance regulatory authorities. Your policy is for the term specified in your
                                insurance contract.</p>
                        <p >By purchasing an insurance product from us through the Services, you agree to accept
                                all records and documents, including the insurance policy, provided to you in electronic form. This consent,
                                unless withdrawn, applies to all transactions between you and Draftrs. However, you have the right to
                                receive communications from us, including the insurance policy, in paper form if you wish by withdrawing
                                this consent. If you withdraw the consent to provide you with records in electronic form, you will then be
                                provided with records in paper form. To withdraw your consent, you must email us at 
                                <a  href="mailto:support@draftrs.com">support@draftrs.com</a>
                                &nbsp;with the following subject line &ldquo;WITHDRAW ELECTRONIC CONSENT.&rdquo; &nbsp;The body
                                of the email must include your name, policy number, effective and expiration dates of the policy, the
                                effective date of your withdrawal and whether you want (a) all communications to be in paper form or (b)
                                your insurance policy to be sent to you in paper form, or both.</p>
                        <p >You may elect to report an insurance claim via the Services, where available. Any
                                such reporting is subject to the applicable insurance policy. If you have any questions concerning the
                                coverage afforded by your policy, please visit the Draftrs support page on the Services or use the contact
                                information provided at the beginning of the Terms.</p>
                        <ol class="pl-0"  start="4">
                            <li class="main-color"><b>4.</b> Content</li>
                        </ol>
                        <p >The content made available through the Services (the &ldquo;Content&rdquo;) is owned
                                by Draftrs or its respective licensors. Any insurance purchasing conclusions and decisions such as coverage
                                amounts, limits and deductibles are completely and solely the responsibility of the insured and its agent,
                                if applicable. The Services and Content should not be construed as rendering tax, legal, investment, or
                                accounting, or insurance advice. The posting of any prospectus or any other information is not a
                                recommendation or opinion for you to buy or sell any product or participate in any transaction involving
                                Draftrs or any other party. At the time of a claim, coverage will be determined solely in accordance with
                                the terms and conditions of the insurance policy, and not the Terms or Content, which shall not affect any
                                coverage determination or interpretation of any provision of the insurance policy. Accordingly, you are
                                encouraged to review and understand your actual policy documentation prior to making any purchase
                                decision.</p>
                        <ol class="pl-0"  start="5">
                            <li class="main-color"><b>5.</b> Account Information</li>
                        </ol>
                        <p >You may be required to register with Draftrs in order to access the Services.
                                &nbsp;It is your responsibility to maintain the security and privacy of your account information, including
                                your username, password, and email identification. &nbsp;You are responsible for any activity that occurs on
                                your account for any reason. &nbsp;If you have reason to believe that your account has been accessed without
                                your authorization, you must notify us immediately at the contact information provided at the beginning of
                                these Terms. &nbsp;For your protection, if we believe that any unauthorized access may occur or has
                                occurred, we may terminate such access without prior notice to you. You also agree that we are permitted to
                                act upon any instructions received using your username and password and to consider such instructions as
                                authorized by you.</p>
                        <ol class="pl-0"  start="6">
                            <li class="main-color"><b>6.</b> Mobile Device Access</li>
                        </ol>
                        <p >To the extent you access the Services through a mobile device, your wireless service
                                carrier&rsquo;s standard charges, data rates and other fees may apply. In addition, downloading, installing,
                                or using certain mobile services or applications may be prohibited or restricted by your carrier, and not
                                all mobile services or applications may work with all carriers or devices. &nbsp;Draftrs may use certain
                                functionality facilitated by your mobile device to improve your experience with our Services. &nbsp;You may
                                disable any aspect of your mobile device &ndash; including GPS functionality, push notifications, and other
                                data features and settings &ndash; at any time. &nbsp;Disabling certain functions may impair Draftrs&rsquo;s
                                ability to provide certain aspects of the Services, and Draftrs is not responsible for any limitation
                                imposed on the Services by your mobile device or its settings.</p>
                        <ol class="pl-0"  start="7">
                            <li class="main-color"><b>7.</b> User Content</li>
                        </ol>
                        <p >You understand that content (including any text, graphic, audio, video, transcripts,
                                log files, or other content) made available or transmitted via the Site or the Services by a User or
                                generated in connection with your use of or access to the Services (&ldquo;User Content&rdquo;), whether
                                privately transmitted or made publicly available, is the sole responsibility of the User who generated such
                                User Content. You, not Draftrs, are responsible for all User Content that you transmit via, make available
                                on, or is generated in connection with your use of or access to the Services. In no event will we be liable
                                in any way for User Content.</p>
                        <p >User Content generated by other users is provided to you AS IS. You may access and
                                use such User Content solely as intended through the provided functionality of the Services and as permitted
                                under the Terms. Unless otherwise permitted under these Terms, you shall not copy, reproduce, distribute,
                                transmit, broadcast, display, sell, license, or otherwise exploit any User Content generated by another User
                                for any other purposes without the prior written consent of Draftrs and the respective owners and licensors
                                of the User Content.</p>
                        <p >With respect to User Content you make available or transmit via the Services, to the
                                extent permitted by law and consistent with our Privacy Policy, you grant us an irrevocable, non-exclusive,
                                perpetual, royalty-free, worldwide license to use, display, copy, distribute, modify, make derivative works
                                of, sell, and import the User Content in connection with the products and services owned, licensed or
                                developed by Draftrs and our licensors.</p>
                        <ol class="pl-0"  start="8">
                            <li class="main-color"><b>8.</b> Advertising</li>
                        </ol>
                        <p >The Services may contain advertisements. &nbsp;In consideration for Draftrs letting
                                you access and use the Services, you agree that we and our third party partners may place advertising on the
                                Services. &nbsp;Because the Services contain User Content, advertising may sometimes appear on your content
                                or other Users&rsquo; Content.</p>
                        <ol class="pl-0"  start="9">
                            <li class="main-color"><b>9.</b> Technical Problems</li>
                        </ol>
                        <p >Draftrs is not responsible for any technical issues with your use of the Services
                                related to unavailability or interruptions of the Services for any reason. &nbsp;You may report technical
                                problems by emailing us at the contact information provided at the beginning of these Terms. &nbsp;Draftrs
                                is not responsible for fixing any technical issue submitted or discovered.</p>
                        <ol class="pl-0"  start="10">
                            <li class="main-color"><b>10.</b> Relationship</li>
                        </ol>
                        <p >These Terms do not create a partnership or agency relationship between Draftrs and
                                any User. Users do not have authority to enter into written or oral (whether implied or express) contracts
                                on behalf of Draftrs. &nbsp;Even where a User links to Draftrs&rsquo;s Services or promotes Draftrs&rsquo;s
                                Services on its platform(s), such activities shall not confer any authority to bind or otherwise solicit
                                contracts on behalf of Draftrs; nor shall any User be entitled to any compensation or remuneration for
                                engaging in such activities.</p>
                        <ol class="pl-0"  start="11">
                            <li class="main-color"><b>11.</b> Links To Other Sites</li>
                        </ol>
                        <p >The Services may link to or may be accessed in connection with external sites, and
                                may connect you with third party services or content. These links or the ability to access other external
                                sites from the Service are provided for your convenience only and do not constitute an endorsement by
                                Draftrs of such sites or their content, products, services or other materials. &nbsp;You access such
                                external sites at your own risk. It is your responsibility to evaluate the content and usefulness of the
                                information obtained from external sites. Draftrs has no control over the content of any external sites and
                                accepts no responsibility for them or for any loss or damage that may arise from your use of them &ndash;
                                this includes any content on external sites that make representations or warranties about Draftrs&rsquo;s
                                Services, to which Draftrs shall not be bound. If you decide to access any external sites linked to or
                                otherwise accessible from the Services, you do so entirely at your own risk and subject to the terms and
                                conditions of use and privacy policies for such external sites. You agree to hold Draftrs harmless from any
                                loss or liability that may result from the use of any links or access to external sites that may appear on
                                the Service.</p>
                        <ol class="pl-0"  start="12">
                            <li class="main-color"><b>12.</b> Restrictions</li>
                        </ol>
                        <p >In accessing and using the Services, you agree to abide by the following rules,
                                restrictions and limitations. &nbsp;You are solely responsible for your conduct and for your communications
                                and interactions with other Users and with Draftrs. &nbsp;Draftrs is not responsible for any dispute that
                                may arise between Users while using the Services.</p>
                        <ol start="1">
                            <li class="my-2"><b>a.</b> You shall not access or use the Services for any unlawful
                                    purpose, or for any use or purpose not intended by Draftrs;</li>
                            <li class="my-2"><b>b.</b> You will not use the Services in any way that could interfere
                                    with, disrupt, negatively affect, or inhibit other Users from using the Services, or that could damage,
                                    disable, overburden or impair the functioning of the Services;</li>
                            <li class="my-2"><b>c.</b> You will not use the Services in violation of any law, including
                                    traffic laws;</li>
                            <li class="my-2"><b>d.</b> You will not abuse, harass, impersonate, intimidate or threaten
                                    any User or any Draftrs employee, agent, or vendor;</li>
                            <li class="my-2"><b>e.</b> You will not use the Services to contact, advertise to, solicit,
                                    or sell any product or service to any User without express consent from Draftrs and any such
                                    User;</li>
                            <li class="my-2"><b>f.</b> You will not post or transmit any content of any kind using the
                                    Services that links to marketing schemes or solicitation of other products or services without
                                    Draftrs&rsquo;s express consent;</li>
                            <li class="my-2"><b>g.</b> You will not use any data mining software, robots, spiders or
                                    similar data gathering and extraction tools to access, acquire, copy, monitor, retrieve, index,
                                    &ldquo;scrape,&rdquo; &ldquo;data mine,&rdquo; or in any way gather content or data from the Services or
                                    otherwise circumvent the navigational structure or presentation of the Services;</li>
                            <li class="my-2"><b>h.</b> You will not disseminate or transmit viruses, worms, Trojan
                                    horses, keyboard loggers, time bombs, spyware, adware, cancelbots, passive collection mechanisms, or any
                                    other malicious or invasive code or program or upload or transmit (or attempt to upload or to transmit)
                                    any material that acts as a passive or active information collection or transmission mechanism,
                                    including, without limitation, clear GIFs, single pixel GIFs, pixel tags, web beacons, action tags, web
                                    bugs, cookies, or other similar devices;</li>
                            <li class="my-2"><b>i.</b> You will not attempt to decipher, decompile, disassemble, or
                                    reverse-engineer any of the software comprising or in any way making up a part of the Services;
                            </li>
                            <li class="my-2"><b>j.</b> You will not interfere with or circumvent any security feature or
                                    any feature that restricts or enforces limitations on the use of, or access to, the Services;
                            </li>
                            <li class="my-2"><b>k.</b> You will not probe, scan, or test the vulnerability of any system
                                    or network or breach or circumvent any security or authentication measures of the Services;</li>
                            <li class="my-2"><b>l.</b> You will not remove, change or obscure any copyright, trademark
                                    notice, trademark, hyperlink or other proprietary rights notices contained within the Services;
                                    and</li>
                            <li class="my-2"><b>m.</b> You will not encourage or promote any activity that violates
                                    these Terms.</li>
                        </ol>
                        <p ></p>
                        <ol class="pl-0"  start="13">
                            <li class="main-color"><b>13.</b> Termination</li>
                        </ol>
                        <p >Draftrs reserves the right to terminate your use of the Services at any time, for any
                                reason, in its sole discretion, and without notice. &nbsp;Draftrs also reserves the right to demand the
                                removal of any marks or other intellectual property, including drop-in code or other approved link, from
                                your website or other platform(s). &nbsp;In addition to suspension and termination of your use of the
                                Services, nothing in these Terms shall preclude or prohibit Draftrs from pursuing any other remedy available
                                at law or equity in connection with your use of the Services. &nbsp; </p>
                        <ol class="pl-0"  start="14">
                            <li class="main-color"><b>14.</b> Disclosure Notice</li>
                        </ol>
                        <p >Draftrs reserves the right to access, read, preserve, and disclose any information it
                                reasonably believe is necessary to do any of the following: (i) satisfy any applicable law, regulation,
                                legal process, subpoena or governmental request; (ii) enforce these Terms, including investigation of
                                potential violations of it; (iii) detect, prevent, or otherwise address fraud, security or technical issues;
                                (iv) cooperate with law enforcement authorities; (v) respond to user support requests; or (vi) protect our,
                                our users&rsquo; or the public&rsquo;s rights, property or safety. </p>
                        <ol class="pl-0"  start="15">
                            <li class="main-color"><b>15.</b> Intellectual Property</li>
                        </ol>
                        <p ></p>
                        <ol start="1">
                            <li><b>a.</b> You hereby grant to Draftrs a non-exclusive, royalty-free, fully
                                    paid-up, right and license to use your data for the purposes of providing you with the Services. You
                                    represent and warrant that you are the exclusive owner of your data or have all rights and licenses
                                    necessary to grant the rights to your data that you have granted to Draftrs in these Terms without the
                                    need to obtain any third-party consents or permissions.</li>
                            <li><b>b.</b> The Services (including the underlying technology, code, systems
                                    and software comprising the Site) are protected by copyright, trademark, patent, and other proprietary
                                    rights and laws. Subject to your compliance with these Terms, and solely for so long as you are
                                    permitted by Draftrs to access and use the Services, Draftrs grants to you a limited, revocable,
                                    non-exclusive, non-transferable, and non-sublicenseable right to access and use the Services for
                                    private, personal, non-commercial use. &nbsp;Unless expressly granted to you in these Terms, Draftrs and
                                    its licensors reserve and retain all right, title and interest (including all intellectual property
                                    rights) in and to: (i) the Services; and (ii) the technology, code, systems and software used to provide
                                    the Services. &nbsp;This includes software that we may provide you to integrate into your website or
                                    platform, or other software we make available to download, including updates and new features added to
                                    the software. &nbsp;You shall not circumvent or disable any content protection system or digital rights
                                    management technology used in the Services or take any other action in contravention of any intellectual
                                    property right of Draftrs with respect to the Services and any other products, services, marks,
                                    copyrights, patents or trade secrets of Draftrs. &nbsp;Notwithstanding anything in these Terms, Draftrs
                                    at all times and in all forms retains full, exclusive and complete ownership of the Services.
                            </li>
                            <li><b>c.</b> Certain software code incorporated into or distributed with the
                                    Services may be licensed by third parties under various &ldquo;open-source&rdquo; or
                                    &ldquo;public-source&rdquo; software licenses, and such software is not licensed under these Terms and
                                    is instead separately licensed pursuant to the terms and conditions of their respective open-source
                                    software licenses. &nbsp;You agree to comply with all such license agreements. &nbsp;</li>
                            <li><b>d.</b> You further understand and agree that Draftrs owns and has the
                                    right to collect, extract, compile, synthesize, analyze and use aggregate data and usage data, without a
                                    duty of accounting to you or any User, consistent with our Privacy Policy. </li>
                        </ol>
                        <p ></p>
                        <ol class="pl-0"  start="16">
                            <li class="main-color"><b>16.</b> Copyright Policy</li>
                        </ol>
                        <p ></p>
                        <ol  start="1">
                            <li><b>a.</b> Copyright Policy. Draftrs takes claims of copyright
                                    infringement seriously and will respond to notices of alleged copyright infringement that comply with
                                    applicable law. If you believe any materials accessible on or from the Draftrs Site or App infringe your
                                    copyright, you may request removal of those materials (or access to them) by submitting written notice
                                    to our Copyright Agent designated below.</li>
                            <li><b>b.</b> Notice to Draftrs. In accordance with the Online Copyright
                                    Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. &sect; 512)
                                    (&ldquo;DMCA&rdquo;), the written notice (the &ldquo;DMCA Notice&rdquo;) must include substantially the
                                    following: (i) your physical or electronic signature; (ii) identification of the copyrighted work you
                                    believe to have been infringed or, if the claim involves multiple works on the Site or App, a
                                    representative list of such works; (iii) identification of the material you believe to be infringing in
                                    a sufficiently precise manner to allow us to locate that material; (iv) adequate information by which we
                                    can contact you (including your name, postal address, telephone number, and, if available, email
                                    address); (v) a statement that you have a good faith belief that use of the copyrighted material is not
                                    authorized by the copyright owner, its agent, or the law; a statement that the information in the DMCA
                                    Notice is accurate; and (vi) a statement, under penalty of perjury, that you are authorized to act on
                                    behalf of the copyright owner. If you fail to comply with all of the requirements of Section 512(c)(3)
                                    of the DMCA, your DMCA Notice may not be effective. Please be aware that if you knowingly materially
                                    misrepresent that material or activity on the Site is infringing your copyright, you may be held liable
                                    for damages (including costs and attorneys&rsquo; fees) under Section 512(f) of the DMCA.</li>
                        </ol>
                        <ol class="pl-0"  start="17">
                            <li class="main-color"><b>17.</b> Disclaimers</li>
                        </ol>
                        <p >DRAFTRS&rsquo;S SERVICES ARE PROVIDED TO YOU ON AN &ldquo;AS-IS&rdquo; AND &ldquo;AS
                                AVAILABLE&rdquo; BASIS AND THE USE THEREOF IS AT YOUR SOLE RISK. DRAFTRS MAKES NO, AND HEREBY DISCLAIMS, ANY
                                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE
                                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NONINFRINGEMENT, AND
                                TITLE WITH RESPECT TO THE SERVICES, TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW. DRAFTRS DOES NOT
                                WARRANT THAT THE USE OF YOUR SERVICES IS LAWFUL IN ANY PARTICULAR JURISDICTION, AND DRAFTRS EXPRESSLY AND
                                SPECIFICALLY DISCLAIMS ANY SUCH WARRANTIES. &nbsp;DRAFTRS FURTHER DOES NOT WARRANT THAT: (A) THE SERVICES
                                (OR THE RESULTS OBTAINED FROM THE USE THEREOF) WILL BE TIMELY, ERROR-FREE, SECURE OR UNINTERRUPTED; (B) THE
                                SERVICES WILL MEET YOUR REQUIREMENTS; OR (C) ANY ERRORS OR MALFUNCTIONS IN THE SITE OR APP WILL BE
                                CORRECTED. DRAFTRS IS NOT LIABLE IN THE EVENT OF ANY UNAUTHORIZED ACCESS TO OR USE OF THE SITE, ANY
                                INTERRUPTION, SUSPENSION OR CESSATION OF ACCESS TO THE SITE, OR ANY BUGS, VIRUSES, TROJAN HORSES, OR OTHER
                                HARMFUL OR MALICIOUS CODE WHICH MAY BE TRANSMITTED THROUGH THE SITE BY ANY THIRD PARTY. DRAFTRS MAKES NO
                                REPRESENTATIONS, WARRANTIES OR GUARANTEES, EXPRESS OR IMPLIED, REGARDING THE ACCURACY, RELIABILITY OR
                                COMPLETENESS OF THE CONTENT MADE AVAILABLE ON ITS SITE.</p>
                        <p >DRAFTRS TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY CONTENT THAT YOU,
                                ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH THE SITE.
                                YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR
                                OTHERWISE INAPPROPRIATE, NONE OF WHICH DRAFTRS WILL BE RESPONSIBLE FOR.</p>
                        <p >ALL THIRD-PARTY MATERIAL AND THIRD-PARTY SERVICES ARE PROVIDED AS-IS WITHOUT
                                WARRANTIES OF ANY KIND. &nbsp;DRAFTRS MAKES NO WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, RELATING TO ANY
                                PRESENT OR FUTURE METHODOLOGY EMPLOYED IN OUR GATHERING OR REPRODUCING OF ANY THIRD-PARTY MATERIAL OR
                                THIRD-PARTY SERVICES, OR AS TO THE ACCURACY, CURRENCY, OR COMPREHENSIVENESS OF THE SAME.</p>
                        <ol class="pl-0"  start="18">
                            <li class="main-color"><b>18.</b> Waiver, Release, And Limitation of Liability</li>
                        </ol>
                        <p >Waiver, Release, And Limitation of Liability</p>
                        <ol  start="1">
                            <li><b>a.</b> DRAFTS IS IN NO EVENT RESPONSIBLE OR LIABLE TO YOU OR TO ANY
                                    THIRD PARTY, WHETHER UNDER CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, INDEMNITY
                                    OR OTHER THEORY, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, LIQUIDATED OR PUNITIVE
                                    DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFIT, REVENUE OR BUSINESS, COST OF SUBSTITUTE
                                    PROCUREMENT, ARISING IN WHOLE OR IN PART FROM YOUR USE OF (OR INABILITY TO USE) THE SERVICES OR ANY
                                    PORTION THEREOF, EVEN IF DRAFTRS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
                                    ANYTHING TO THE CONTRARY IN THESE TERMS, DRAFTRS&rsquo;S AGGREGATE LIABILITY TO YOU FOR ANY CAUSE
                                    WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL BE LIMITED TO ONE HUNDRED DOLLARS
                                    (USD$100.00).</li>
                            <li><b>b.</b> WAIVER OF TRIAL BY JURY. BY ENTERING INTO THIS AGREEMENT, YOU
                                    WAIVE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY RIGHT YOU MAY HAVE TO A TRIAL BY JURY IN
                                    ANY LEGAL ACTION, PROCEEDING, CAUSE OF ACTION, OR COUNTERCLAIM ARISING OUT OF OR RELATING TO THESE
                                    TERMS. YOU CERTIFY AND ACKNOWLEDGE THAT BY ACCESSING AND USING THE SITE THAT YOU MAKE THIS WAIVER
                                    KNOWINGLY AND VOLUNTARILY. IF YOU DO NOT AGREE TO SUCH WAIVER, YOU SHOULD NOT USE THE SERVICES.
                                    &nbsp;</li>
                            <li><b>c.</b> California Residents. If you are now, or at any time during
                                    your use of the Site, a California resident, you hereby waive California Civil Code Section 1542, which
                                    says: &ldquo;A general release does not extend to claims which the creditor does not know or suspect to
                                    exist in his or her favor at the time of executing the release, which if known by him or her must have
                                    materially affected his or her settlement with the debtor.&rdquo;</li>
                        </ol>
                        <p ></p>
                        <ol class="pl-0"  start="19">
                            <li class="main-color"><b>19.</b> Indemnification</li>
                        </ol>
                        <p >To the extent permitted by law, you will indemnify, defend, and hold harmless
                                Draftrs, its parents, subsidiaries, affiliates, and their respective directors, officers, employees,
                                representatives, and agents (each an &ldquo;Indemnified Party&rdquo;) from any and all liabilities or losses
                                of any kind arising from or relating to any claim, suit, proceeding, demand, or action brought by you or a
                                third party or other user against an Indemnified Party relating to: (a) your use of the Services, including
                                any payment obligations incurred through receipt of any Services; (b) failure to comply with these Terms by
                                you or your agents; (c) failure to comply with applicable law by you or your agents; (d) negligence, willful
                                misconduct, or fraud by you or your agents; or (e) violation of privacy rights or infringement of
                                intellectual property rights or allegations thereof to the extent caused by you or your agents.</p>
                        <ol class="pl-0"  start="20">
                            <li class="main-color"><b>20.</b> Governing Law</li>
                        </ol>
                        <p >Our Services are hosted in the United States. If you are using or accessing the
                                Services from outside the United States or any other region with laws or regulations governing personal data
                                collection, use and disclosure that differ from United States laws, please be advised that through your use
                                of the Services, which are governed by United States law, you are transferring information to and from the
                                United States and you consent to that transfer.</p>
                        <p >The laws of the State of Texas and applicable United States law govern these Terms
                                and matters arising out of or relating to the Services, without giving effect to such state&rsquo;s
                                conflicts of law principles or rules of construction concerning the drafter. You hereby irrevocably and
                                unconditionally submit to the jurisdiction of the federal and state courts venued in Dallas County, Texas.
                                for the purpose of any suit, action, or other proceeding arising out of or based upon this Policy, your
                                access to or your use of the Services, which courts are the exclusive forum for any such suit, action, or
                                other proceeding.</p>
                        <ol class="pl-0"  start="21">
                            <li class="main-color"> <b>21.</b> Other Agreements</li>
                        </ol>
                        <p >These Terms are not intended to change or affect any separate agreement or contract
                                that you may have with Draftrs. &nbsp;However, with respect to the Services, these Terms constitute the
                                entire agreement between us with respect to anything related to the Services and your use of the Services.
                                &nbsp;No failure to act on any right by Draftrs shall constitute a further continuing waiver of the same.
                                &nbsp;You have no right to assign any rights under these Terms, but Draftrs may transfer or assign any
                                rights under these Terms without limitation and without notice or consent from you.</p>
                        <p >The invalidity, illegality or unenforceability of any term or provision of these
                                Terms shall in no way affect the validity, legality or enforceability of any other term or provision of
                                these Terms. These Terms will be binding on and will inure to the benefit of the legal representatives,
                                successors and assigns of the parties hereto. If a court or arbitrator decides that any term or provision of
                                these Terms is invalid or unenforceable, the parties agree to replace such term or provision with a term or
                                provision that is valid and enforceable and that comes closest to achieving the purpose of the invalid or
                                unenforceable term or provision, and the parties agree that these Terms shall be enforceable as so
                                modified.</p>
                        <ol class="pl-0"  start="22">
                            <li class="main-color"><b>22.</b> Acceptance of Terms</li>
                        </ol>
                        <p >By using or accessing the Services, you acknowledge that you have reviewed,
                                understood, and accept these Terms of Service, including those aspects of the Terms of Service related to
                                the availability of our products and services, eligibility for such products and services, collection of
                                information, use of information, security of information, and privacy of information as identified in the
                                Terms of Service and in our Privacy Policy, which is incorporated by reference.</p>
                    </div>
                </div>
            </section>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "Terms",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">


</style>
