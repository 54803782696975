<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1 my-1">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							Press Releases
						</h1>
						<h5 class="text-white">
							Draftrs Partners With The American Institute of Building Design To Deliver Professional Liability Insurance To Building Designers  
						</h5>
					</div>
				</div>
			</div>
		</header>
		<main class="container-fluid bg-grey">
            <div class="container py-5" >
				<div class="col-md-10 offset-md-1">
                    <p class="pb-3">
                        <b><center>Draftrs Partners With The American Institute of Building Design To Deliver Professional Liability Insurance To Building Designers</center></b>
                    </p>
                    <p class="pb-3">
                        <b>Dallas, Texas – June 20, 2023 –</b> Draftrs, an insurtech company focused on modernizing small business professional liability insurance, today announced a partnership with The American Institute of Building Design (AIBD), a national association of design professionals specializing in residential design, to provide instant access to comprehensive professional liability insurance products to AIBD members.    
                    </p>
                    <p class="pb-3">
                        The Draftrs embedded platform provides instant quote-to-bind professional liability insurance and allows insureds to issue certificates of insurance and manage their policy online without the need to speak to an underwriter.  Draftrs professional liability insurance is underwritten by Markel Insurtech Underwriters and is available in 50 states.  
                    </p>
                    <p class="pb-3">
                        “We are very excited to partner with AIBD,” said Audrey Lau, co-founder and CEO of Draftrs.  “AIBD provides incredible value to design professionals, from industry-recognized certifications and quality continuing education to access to businesses that are critical for the design and construction industry.  AIBD members can now also get instant quotes for affordable and comprehensive professional liability coverage and manage their policies online through Draftrs.”
                    </p>
                    <p class="pb-3">
                        Draftrs professional liability insurance through the AIBD is available at <a href="https://aibd.org/insurance" >https://aibd.org</a>. 
                    </p>
                    <p class="pb-3">
                        <b>About Draftrs, Inc. (Draftrs)</b><br/>
                        Draftrs, Inc. (Draftrs) is a technology-enabled, managing general agent (MGA) that is streamlining, automating, and digitizing 
                        the insurance underwriting and distribution process for carrier and broker partners to create a more efficient way to deliver 
                        professional liability insurance to the small commercial market. Draftrs provides instant, direct access and enables distribution 
                        partners to become a digital agency without having to make any costly tech upgrades or investments.  For more information 
                        please visit <a href="https://draftrs.com" >https://draftrs.com</a>.
                    </p>
				</div>
                <div class="col-md-10 offset-md-1">
                    <a href="/Press">View more press releases</a>
                </div>
			</div>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import StandardNav from "../../components/StandardNav.vue";
import StandardFooter from "../../components/StandardFooter.vue";
export default {
  name: "PressReleases",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
    },
    watch: {
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
    },
};
</script>

<style lang="scss">
</style>
