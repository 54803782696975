<template>
  <div id="wrapper">
      <main id="main" role="main">
          <EmptyNav/>
          <div class="container-fluid mt-5" v-show="resolving">
              <div class="container h-100">
                  <div class="row h-100 justify-content-center align-items-center">
                      <div class="spinner-border text-secondary" style="width: 3rem; height: 3rem;" role="status">
                          <span class="sr-only">Resolving...</span>
                      </div>
                  </div>
              </div>
          </div>
          <div v-if="!resolving">
              
              <div class="m-5 alert alert-danger" role="alert" v-if="invalid">
                <i class=" text-warning fas fa-exclamation-triangle"></i> You've reached an invalid link. Please <a href="mailto:support@draftrs.com">contact us</a> for assistance.
              </div>
              <div class="m-5 alert alert-primary" role="alert" v-if="expired">
                <i class=" text-warning fas fa-exclamation-triangle"></i> Oops it looks like you clicked an expired access link.<br/><br/>
                We just sent you a new email to verify your identity. It contains a new link to securely access your policy.
              </div>
          </div>
      </main>
    </div>
</template>

<script>
import axios from 'axios';
import { loginUser } from '../Utils/auth.js';
import EmptyNav from "../components/EmptyNav.vue";

export default {
  name: 'linkRouter',
  components: {
    EmptyNav
  },
  data: function () {
    return {
      token: null,
      invalid: false,
      expired: false,
      resolving: true,
    }
  },
  async mounted() {
    //decode because of azure's stupid (no period in urls)
    this.token = this.$route.params.token;
    this.tokenRoute();
  },
  methods: {
    tokenRoute: async function() {
      let invalid = false;
      await axios.get("/api/link/" + this.token).then(res => {
        if (res.data.url) {
          loginUser(res.data.client, res.data.email, res.data.jwt);
          return this.$router.push(res.data.url);
        }

        if (res.data.email) {
          this.expired = true;
        }
      }).catch(function () {
        invalid = true;
      });

      this.invalid = invalid;
      this.resolving = false;
    }
  }
}
</script>

<style lang="scss"></style>