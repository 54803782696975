// I needed to change broker_configs.json to broker_configs.js so I could break up the json as variables and also add comments for documentation.
// It should also make the json easier to edit by removing overly repetitious content.

const pl = [{
    "industry" : {"key" : "Architects and Engineers", "value" : "Architects and Engineers"},
    "workTypes" : [
        {"key" : "Architect", "value" : "Architect"},
        {"key" : "Architectural Technologist/Building Designer", "value" : "Architectural Technologist/Building Designer"},
        {"key" : "Acoustical Consultant", "value" : "Acoustical Consultant"},
        {"key" : "Aerial Surveyor", "value" : "Aerial Surveyor"},
        {"key" : "Audio Visual, Drafting and CAD/BIM", "value" : "Audio Visual Consultant"},
        {"key" : "Civil Engineer", "value" : "Civil Engineer"},
        {"key" : "Construction Manager", "value" : "Construction Manager"},
        {"key" : "Electrical Engineer", "value" : "Electrical Engineer"},
        {"key" : "Environmental Consultant", "value" : "Environmental Consultant"},
        {"key" : "Forensic, Expert Witness and Engineering Consultants", "value" : "Forensic and Engineering Consultants"},
        {"key" : "Interior Design", "value" : "Interior Design"},
        {"key" : "Land Surveyor", "value" : "Land Surveyor"},
        {"key" : "Landscape Architect", "value" : "Landscape Architect"},
        {"key" : "Lighting Consultant", "value" : "Lighting Consultant"},
        {"key" : "Mechanical Engineer", "value" : "Mechanical Engineer"},
        {"key" : "Structural Engineer", "value" : "Structural Engineer"},
        {"key" : "Geotechnical Engineer", "value" : "Geotechnical Engineer"},
        {"key" : "Owners/Developers Representative", "value" : "Owners/Developers Representative"}
    ]
},
{
    "industry" : {"key" : "Construction Design and Consulting", "value" : "Construction Design and Consulting"},
    "workTypes" : [
        {"key" : "Architectural Technologist/Building Designer", "value" : "Architectural Technologist/Building Designer"},
        {"key" : "Acoustical Consultant", "value" : "Acoustical Consultant"},
        {"key" : "Aerial Surveyor", "value" : "Aerial Surveyor"},
        {"key" : "Audio Visual, Drafting and CAD/BIM", "value" : "Audio Visual Consultant"},
        {"key" : "Construction Manager", "value" : "Construction Manager"},
        {"key" : "Environmental Consultant", "value" : "Environmental Consultant"},
        {"key" : "Forensic, Expert Witness and Engineering Consultants", "value" : "Forensic and Engineering Consultants"},
        {"key" : "Interior Design", "value" : "Interior Design"},
        {"key" : "Land Surveyor", "value" : "Land Surveyor"},
        {"key" : "Lighting Consultant", "value" : "Lighting Consultant"},
        {"key" : "Owners/Developers Representative", "value" : "Owners/Developers Representative"}
    ]
}];

const mpl = [{
    "industry" : { "key" : "Business Consultants", "value": "Business Consultants" },
    "workTypes": [
        {"key" : "Human Resources Consultant", "value": "Human Resources Consultant"},
        {"key" : "Management Consultant", "value": "Management Consultant"},
        {"key" : "Marketing Consultant", "value": "Marketing Consultant"},
        // {"key" : "Sales Marketing PR", "value": "Sales Marketing PR"},
        //{"key" : "Operations Consultant", "value": "Operations Consultant"},
        //{"key" : "Technology Consultant", "value": "Technology Consultant"},
        // {"key" : "Finance", "value": "Finance"},
        // {"key" : "Data Analytics Consultant", "value": "Data Analytics Consultant"},
        {"key" : "Organization Behavior Consultant", "value": "Organization Behavior Consultant"},
        {"key" : "Public Relations Consultant", "value": "Public Relations Consultant"},
        
    ],
    "hideAddlServices": false // allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Other Consultants", "value": "Other Consultants" },
    "workTypes": [
        {"key" : "Education Consultant", "value": "Education Consultant"},
        {"key" : "Energy Efficiency/Weatherization Consultant", "value": "Energy Efficiency/Weatherization Consultant"},
        {"key" : "Nonprofit Fundraising Consultant", "value": "Nonprofit Fundraising Consultant"},
        {"key" : "Telecommunications Consultant", "value": "Telecommunications Consultant"},
        {"key" : "Training Consultant", "value": "Training Consultant"}
    ],
    "hideAddlServices": true // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Administrative and Call Services", "value": "Administrative and Call Services"},
    "workTypes": [
        {"key" : "Administrative Assistant", "value": "Administrative Assistant"},
        {"key" : "Answering Service Non-Emergency", "value": "Answering Service Non-Emergency"},
        {"key" : "Call Centers", "value": "Call Centers"},
        //{"key" : "Data Processor", "value": "Data Processor"},
        {"key" : "Personal Assistant", "value": "Personal Assistant"},
        {"key" : "Typing Service", "value": "Typing Service"},
    ],
    "hideAddlServices": false // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Advertising, Creatives and Content", "value": "Advertising, Creatives and Content"},
    "workTypes": [
        {"key" : "Advertising Agency", "value": "Advertising Agency"},
        {"key" : "Digital Marketing", "value": "Digital Marketing"},
        {"key" : "Graphic Designer", "value": "Graphic Designer"},
        {"key" : "Marketing Agency", "value": "Marketing Agency"},
        {"key" : "Photographer", "value": "Photographer"},
    ],
    "hideAddlServices": false // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Beauticians and Grooming", "value": "Beauticians and Grooming"},
    "workTypes": [
        {"key" : "Barber", "value": "Barber"},
        {"key" : "Beautician", "value": "Beautician"},
        {"key" : "Cosmetologist", "value": "Cosmetologist"},
        {"key" : "Manicurist", "value": "Manicurist"},
    ],
    "hideAddlServices": false // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Bookkeepers and Tax Preparers", "value": "Bookkeepers and Tax Preparers"},
    "workTypes": [
        {"key" : "Bookkeepers", "value": "Bookkeepers"},
        {"key" : "Tax Prep (Non CPA)", "value": "Tax Prep (Non CPA)"},
    ],
    "hideAddlServices": false // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Events and Meetings Services", "value": "Events and Meetings Services"},
    "workTypes": [
        // {"key" : "Convention Managers", "value": "Convention Managers"},
        {"key" : "Event Planner", "value": "Event Planner"},
        // {"key" : "Exhibit Designer", "value": "Exhibit Designer"},
        {"key" : "Meeting Planner", "value": "Meeting Planner"},
    ],
    "hideAddlServices": true // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Legal Support Services", "value": "Legal Support Services"},
    "workTypes": [
        {"key" : "Arbitrator", "value": "Arbitrator"},
        {"key" : "Court Reporter", "value": "Court Reporter"},
        {"key" : "Mediator", "value": "Mediator"},
    ],
    "hideAddlServices": true // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Other Professions and Services", "value": "Other Professions and Services"},
    "workTypes": [
        {"key" : "Auctioneer", "value": "Auctioneer"},
        {"key" : "Claims Adjuster", "value": "Claims Adjuster"},
        {"key" : "Grant Writing", "value": "Grant Writing"},
        {"key" : "Interpreter/Translator", "value": "Interpreter/Translator"},
        {"key" : "Market Researcher", "value": "Market Researcher"},
        {"key" : "Notary", "value": "Notary"},
        {"key" : "Travel Agent", "value": "Travel Agent"},
        // {"key" : "Process Server", "value": "Process Server"},
        // {"key" : "Coin/gem/art dealer", "value": "Coin/gem/art dealer"},
        // {"key" : "Relocation Agent", "value": "Relocation Agent"},
    ],
    "hideAddlServices": true // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Pet Grooming and Training", "value": "Pet Grooming and Training"},
    "workTypes": [
        {"key" : "Pet Groomer", "value": "Pet Groomer"},
        {"key" : "Pet Instructor/Trainer", "value": "Pet Instructor/Trainer"},
    ],
    "hideAddlServices": false // don't allow multiple additional services in quoteflow
},
// {
//     "industry" : { "key" : "Real Estate Professionals", "value": "Real Estate Professionals"},
//     "workTypes": [
//         {"key" : "Leasing agent/broker", "value": "Leasing agent/broker"},
//         {"key" : "Property Manager Residential/Commercial", "value": "Property Manager Residential/Commercial"},
//         {"key" : "Real Estate Agent/Broker Residential", "value": "Real Estate Agent/Broker Residential"},
//     ],
//     "hideAddlServices": true // don't allow multiple additional services in quoteflow
// },
{
    "industry" : { "key" : "Recruiters and Employment", "value": "Recruiters and Employment"},
    "workTypes": [
        {"key" : "Employee Screener", "value": "Employee Screener"},
        {"key" : "Employment Agent Temporary/Permanent Placement", "value": "Employment Agent Temporary/Permanent Placement"},
        {"key" : "Executive Recruiter", "value": "Executive Recruiter"},
        {"key" : "IT Staffing Firms", "value": "IT Staffing Firms"},
        {"key" : "Resume Service", "value": "Resume Service"},
    ],
    "hideAddlServices": true // don't allow multiple additional services in quoteflow
},
// {
//     "industry" : { "key" : "Technology and IT Services", "value": "Technology and IT Services"},
//     "workTypes": [
//         {"key" : "Technology Consulting/IT Project Management", "value": "Technology Consulting/IT Project Management"},
//         {"key" : "Computer and Mobile Device Repair", "value": "Computer and Mobile Device Repair"},
//         {"key" : "Computer Training", "value": "Computer Training"},
//         {"key" : "Custom Software Development", "value": "Custom Software Development"},
//         {"key" : "Information Technology Independent Contractors", "value": "Information Technology Independent Contractors"},
//         {"key" : "Off The Shelf Software Development", "value": "Off The Shelf Software Development"},
//     ],
//     "hideAddlServices": true // don't allow multiple additional services in quoteflow
// },
{
    "industry" : {"key" : "Educational Services", "value": "Educational Services"},
    "workTypes": [
        {"key" : "Charm School", "value": "Charm School"},
        {"key" : "Computer Training", "value": "Computer Training"},
        {"key" : "Test Administrator", "value": "Test Administrator"},
        {"key" : "Tutor", "value": "Tutor"},
    ],
    "hideAddlServices": true // don't allow multiple additional services in quoteflow
},
{
    "industry" : { "key" : "Grant Writing", "value": "Grant Writing"},
    "workTypes": [
        {"key" : "Grant Writing", "value": "Grant Writing"},
    ],
    "hideAddlServices": false // don't allow multiple additional services in quoteflow
},
];

const broker_configs = {
    // states: will be an additional filter after states.json/states_prod.json. leave it as null if not used.
    "default" : {
        "mplAssociationQuestion": [
            'ieca'
        ],
        "mplNoGLEnhancement": [ // no GL enhancements for these mpl work types
            'Barber', 'Beautician', 'Cosmetologist', 'Manicurist', 'Auctioneer', 'Management Consultant', 
            'Training Consultant', 'Sales Marketing PR', 'Exhibit Designer', 'Charm School', 'Test Administrator', 'Tutor', 
            'Event Planner', 'Meeting Planner', 'Pet Groomer', 'Pet Instructor/Trainer', 'Travel Agent'
        ],
        "hasRetailBrokers" : ['limit', 'semsee', 'accessone80', 'founderspro'], // GH #681 certain brokers will have agents that we cc email too
        "mplOnlyBrokers" : "catalant, nacm", // brokers that are allowed to do MPL only
        "plOnlyBrokers" : "rpsins, acec, greyling",
        "mplAndPlBrokers" : "for now this is not needed",
        "no6MonthPolicyTerms" : false, // disable 6 month term option
        "agencyBill" : false, // for now only rpsins is doing agency bill // for testing local you need to change the client variable in themer.js
        "states" : null, // null == all 50 states are included
        "industryTypes" : [
            {"key" : "Design Professionals AEC", "value" : "Design Professionals AEC", "product" : "pl", "header": 'header-only'}, // just a placeholder
            {"key" : "Architects and Engineers", "value" : "Architects and Engineers", "product" : "pl"},
            {"key" : "Construction Design and Consulting", "value" : "Construction Design and Consulting", "product" : "pl"},
            
            {"key" : "Advertising, Creatives and Content", "value": "Advertising, Creatives and Content", "product" : "mpl", "header": 'header'},
            
            {"key" : "Bookkeepers and Tax Preparers", "value": "Bookkeepers and Tax Preparers", "product" : "mpl", "header": 'header'},
            
            {"key" : "Consultants", "value" : "Consultants", "product" : "mpl", "header": 'header-only'}, // just a placeholder
            {"key" : "Business Consultants", "value": "Business Consultants", "product" : "mpl"},
            {"key" : "Other Consultants", "value": "Other Consultants", "product" : "mpl"},

            {"key" : "Legal Support Services", "value": "Legal Support Services", "product" : "mpl", "header": 'header'},

            {"key" : "Recruiters and Employment", "value": "Recruiters and Employment", "product" : "mpl", "header": 'header'},
            
            {"key" : "Other Professional Services", "value" : "Other Professional Services", "product" : "mpl", "header": 'header-only'}, // just a placeholder
            {"key" : "Administrative and Call Services", "value": "Administrative and Call Services", "product" : "mpl"},
            {"key" : "Beauticians and Grooming", "value": "Beauticians and Grooming", "product" : "mpl"},
            
            //{"key" : "Claims Adjuster", "value": "Claims Adjuster", "product" : "mpl"},
            {"key" : "Educational Services", "value": "Educational Services", "product" : "mpl"},
            {"key" : "Events and Meetings Services", "value": "Events and Meetings Services", "product" : "mpl"},
            {"key" : "Grant Writing", "value": "Grant Writing", "product" : "mpl"},
            
            {"key" : "Other Professions and Services", "value": "Other Professions and Services", "product" : "mpl"},
            {"key" : "Pet Grooming and Training", "value": "Pet Grooming and Training", "product" : "mpl"},
            // {"key" : "Real Estate Professionals", "value": "Real Estate Professionals", "product" : "mpl"},
            
            // {"key" : "Technology and IT Services", "value": "Technology and IT Services", "product" : "mpl"},

            //{"key" : "Travel Agent", "value": "Travel Agent", "product" : "mpl"},
        ],
        "appetites" : { // this is the appetites modal
            "Architects and Engineers" : "Under $1M in annual revenue, 10 employees or less, No structural or geotechnical engineers and no building or materials inspections, Startup firms OK but firm professionals must have an average 8 years of experience, Up to 2 reported claims and no more than $15K total incurred",
            "Construction Design and Consulting" : "Under $1M in revenues, 10 employees or less, No structural or geotechnical engineers and no building or materials inspections, Startup firms OK but firm professionals must have an average 8 years of experience, Up to 2 reported claims and no more than $15K total incurred",
            "Business Consultants" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but consultants must have at least 5 years of professional experience, No architects - engineers - lawyers - certified public accountants - investment advisors - real estate agents - healthcare professionals - interim CEO/CFO - software/systems developers, No services related to products design manufacturing sales or testing - audit or attest - mergers and acquisitions - debt or equity financing - trustees or fiduciaries",
            "Other Consultants" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but consultants must have at least 5 years of professional experience, No architects - engineers - lawyers - certified public accountants - investment advisors - real estate agents - healthcare professionals - interim CEO/CFO - software/systems developers, No services related to products design manufacturing sales or testing - audit or attest - mergers and acquisitions - debt or equity financing - trustees or fiduciaries",
            "Advertising, Creatives and Content" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but must have at least 5 years of professional experience, No services for video games - cartoons shows and movies - politicized material - pornographic material, No work for clients in alcohol - firearms - tobacco - gaming - pharmaceuticals - cannabis - mass  media entertainment",
            "Legal Support Services" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but must have at least 5 years of professional experience, No attorney services or legal advice",
            "Administrative and Call Services" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but must have at least 5 years of professional experience, No legal or medical advice - emergency dispatch - crisis prevention hotlines - debt collection - political campaigns and donations, No healthcare - adult care - childcare/nanny services - social work - financial or invesment advice",
            "Recruiters and Employment" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but must have at least 5 years of professional experience, No professional employer organizations, No placement of healthcare professionals or nanny/childcare professionals or architects or attorneys or accountants. No oversight of placed employees",
            "Bookkeepers and Tax Preparers" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but must have at least 5 years of professional experience, No CPAs, No audit and attest - financial and investment advice - tax avoidance strategy - M&A consulting - preparation and certification of financial statements - legal advice and opinions",
            "Pet Grooming and Training" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but must have at least 5 years of professional experience, No handling or training of exotic animals - contraband animals - animal breeding - animal testing - kenneling - highly trained service animals",
            "Beauticians and Grooming" : "Under $500K in annual revenue - $250K in CA and WA, Startups OK but must have at least 5 years of professional experience, No treatments requiring supervision of medical doctors - surgical hair removal or transplants - cosmetic surgery - botox or lasers - permanent makeup or tattos, No sale of beauty products under Insureds own name"
        },
        pl,
        mpl,
    },
    "aibd" : {
        "no6MonthPolicyTerms" : true
    },
    "grantprofessionals" : {
        "no6MonthPolicyTerms" : true
    },
    "shingleit" : {
        "states" : null,
        "industryTypes" : [
            {"key" : "Architects and Engineers", "value" : "Architects and Engineers", "product" : "pl"},
            {"key" : "Construction Design and Consulting", "value" : "Construction Design and Consulting", "product" : "pl"},
            {"key" : "Business Consultants", "value": "Business Consultants", "product" : "mpl"}
        ],
        "appetites" : null // no appetites modal
    },
    "professionalliabilitypro" : {
        "states" : "AL, AZ, CA, CO, FL, GA, ID, IL, LA, NJ, NV, NY, OK, OR, PA, TX, UT, WA"
    },
    "ironridgeus" : {
        "states" : "FL"
    },
    "zippydi" : {
        "states" : "FL"
    },
    "rpsins" : {
        "agencyBill" : true // for testing local you need to change the client variable in themer.js
    },
    "catalant" : {
    },
    "nacm" : {
        "states" : null,
        "industryTypes" : [
            {"key" : "Legal Support Services", "value": "Legal Support Services", "product" : "mpl"}
        ],
        "mpl": [
            {
            "industry" : { "key" : "Legal Support Services", "value": "Legal Support Services"},
            "workTypes": [
                {"key" : "Arbitrator", "value": "Arbitrator"},
                {"key" : "Mediator", "value": "Mediator"}
            ],
            "hideAddlServices": true, // don't allow multiple additional services in quoteflow
            "appetites" : null // no appetites modal
        }
        ]
    },
    "ieca" : {
        "states" : null,
        "industryTypes" : [
            {"key" : "Other Consultants", "value": "Other Consultants", "product" : "mpl"}
        ],
        "mpl": [
            {
            "industry" : { "key" : "Other Consultants", "value": "Other Consultants"},
            "workTypes": [
                {"key" : "Education Consultant", "value": "Education Consultant"}
            ],
            "hideAddlServices": true, // allow multiple additional services in quoteflow
            "appetites" : null // no appetites modal
            }
        ]
    },
    "oatc" : {
        "states" : null,
        "industryTypes" : [
            {"key" : "Bookkeepers and Tax Preparers", "value": "Bookkeepers and Tax Preparers", "product" : "mpl"}
        ],
        "mpl": [
            {
                "industry" : { "key" : "Bookkeepers and Tax Preparers", "value": "Bookkeepers and Tax Preparers"},
                "workTypes": [
                    {"key" : "Bookkeepers", "value": "Bookkeepers"},
                    {"key" : "Tax Prep (Non CPA)", "value": "Tax Prep (Non CPA)"},
                ],
                "hideAddlServices": true // don't allow multiple additional services in quoteflow
            },
        ]

    },
}

module.exports = {broker_configs};
