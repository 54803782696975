const timelineTemplate = document.createElement("template");
timelineTemplate.innerHTML =
	/* html */
	`
	<style>
		.timeline-section {
			background: linear-gradient(
				to bottom,
				#f7f7fc 0%,
				#f7f7fc 50%,
				#fff 50%,
				#fff 100%
			);
			min-height: 60vh;
		}

		.timeline-btn {
			border: none;
			background-color: var(--bs-secondary);
			height: 50px;
			width: 50px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.timeline-btn .carousel-control-prev-icon,
		.timeline-btn .carousel-control-next-icon  {
			height: 1.5rem;
		}


		.events-container {
			position: relative;
			height: 350px;
		}

		.event-card {
			min-height: 250px;
			background: #fff;
			width:33%;
			flex-shrink: 0;
			position: absolute;
			transition: .5s all ease-in-out;
			
		}

		.year-tag-container {
			direction: rtl;
		}

		.year-tag-container span {
			background-color: #f7f7fc;
			border-radius: 30px;
			padding: 10px 18px;
			color: #000;
			margin-right: 16px;
			font-size: 12px;
			font-weight: bold;
			transition: 1s all ease;
		}

		.year-tag-container span.float-start {
			background-color: var(--bs-secondary);
			color: #fff;
		}

		@media screen and (max-width: 768px) {
			.event-card {
				width: 100%;
			}
		}
		
	</style>
    <section class="container-fluid timeline-section py-5">
		<div class="container py-5">
			<div class="row my-5">
				<div class="col-12 d-flex justify-content-between align-items-center">
					<h2 class="display-4 font-weight-bold">Our Journey</h2>
					<div class=" d-flex" style="gap: 20px">
						<button class="timeline-prev timeline-btn">
							<span class="carousel-control-prev-icon"></span>
						</button>
						<button class="timeline-next timeline-btn">
							<span class="carousel-control-next-icon"></span>
						</button>
					</div>
				</div>
			</div>
			<div class="row overflow-hidden">
				<div class="col-12 events-container">
					<div class="event-card p-4 shadow" data-year="2022">
						<p class="text-black text-muted">Q1-Q2 (2022)</p>
						<h4 class="font-weight-bold">Launch of professional liability for architects</h4>
						<p class="text-black text-muted">Professional liability insurance costs California architects about $140 per month, versus the national median of about $145 a month.</p>
					</div>
					<div class="event-card p-4 shadow"  data-year="2022">
						<p class="text-black text-muted">Q1-Q2 (2022)</p>
						<h4 class="font-weight-bold">Launch of professional liability for architects</h4>
						<p class="text-black text-muted">Professional liability insurance costs California architects about $140 per month, versus the national median of about $145 a month.</p>
					</div>
					<div class="event-card p-4 shadow"  data-year="2021">
						<p class="text-black text-muted">Q1-Q2 (2022)</p>
						<h4 class="font-weight-bold">Launch of professional liability for architects</h4>
						<p class="text-black text-muted">Professional liability insurance costs California architects about $140 per month, versus the national median of about $145 a month.</p>
					</div>
					<div class="event-card p-4 shadow"  data-year="2021">
						<p class="text-black text-muted">Q1-Q2 (2022)</p>
						<h4 class="font-weight-bold">Launch of professional liability for architects</h4>
						<p class="text-black text-muted">Professional liability insurance costs California architects about $140 per month, versus the national median of about $145 a month.</p>
					</div>
					<div class="event-card p-4 shadow"  data-year="2020">
						<p class="text-black text-muted">Q1-Q2 (2022)</p>
						<h4 class="font-weight-bold">Launch of professional liability for architects</h4>
						<p class="text-black text-muted">Professional liability insurance costs California architects about $140 per month, versus the national median of about $145 a month.</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12  year-tag-container">
					<!-- Generated from initYearTags -->
				</div>
			</div>
		</div>
	</section>
    `;

class Timeline extends HTMLElement {
	constructor() {
		super();
		this.contents = new DocumentFragment();
		this.contents.appendChild(timelineTemplate.content.cloneNode(true));
	}

	connectedCallback() {
		this.appendChild(this.contents);

		const prevBtn = document.querySelector(".timeline-prev");
		const nextBtn = document.querySelector(".timeline-next");
		const events = document.querySelectorAll(".event-card");
		const yearContainer = document.querySelector(".year-tag-container");
		let currentSlide = 0;
		let mobile = window.innerWidth < 768;

		const checkWindowWidth = () => {
			if (window.innerWidth < 768) {
				mobile = true;
				initCarousel();
			} else if (window.innerWidth > 768) {
				mobile = false;
				initCarousel();
			}
		};

		const initCarousel = () => {
			events.forEach((event, index) => {
				event.style.left = `calc(${index * (mobile ? 100 : 33)}% + ${
					index * (mobile ? 0 : 30)
				}px)`;
			});
			const spans = yearContainer.querySelectorAll("span");
			spans.forEach((span) => {
				span.classList.add("mr-3");
				if (span.textContent >= events[currentSlide].dataset.year)
					span.classList.add("float-start");
			});
		};

		const initYearTags = () => {
			let yearArray = [];
			events.forEach((event) => {
				if (!yearArray.includes(event.dataset.year))
					yearArray.push(event.dataset.year);
			});
			yearArray.sort();
			yearArray.reverse();
			yearArray.forEach((year) => {
				const span = document.createElement(`span`);
				span.textContent = `${year}`;
				yearContainer.insertAdjacentElement("beforeend", span);
			});
		};

		const carousel = (next) => {
			const spans = yearContainer.querySelectorAll("span");
			if (next && currentSlide > events.length - 2) {
				currentSlide = -1;
			}
			if (!next && currentSlide < 1) currentSlide = events.length;
			next ? currentSlide++ : currentSlide--;
			events.forEach((event) => {
				event.style.transform = `translateX(-${currentSlide * 100}%)`;
			});

			spans.forEach((span) => {
				span.textContent >= events[currentSlide].dataset.year
					? span.classList.add("float-start")
					: span.classList.remove("float-start");
			});
		};

		initYearTags();
		initCarousel();

		prevBtn.addEventListener("click", () => carousel(false));
		nextBtn.addEventListener("click", () => carousel(true));
		window.addEventListener("resize", () => checkWindowWidth());
	}
}

window.customElements.define("time-line", Timeline);
