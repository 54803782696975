<script setup>
import {computed, ref} from "vue"

let props = defineProps({
    context: {
        type: Object,
        required: true
    },
    value: Number
})
let saved = ref(true)
let save = function () {
    emit('saveData')
    saved.value = true
}
let emit = defineEmits(['input', 'saveData'])
const setToReferral = function () {
    model.value = 0
    emit('saveData')
}
let model = computed(
        {
            get() {
                return props.context.model ?? props.value
            },
            set(answer) {
                // eslint-disable-next-line
                props.context.model = answer
                emit('input', answer)
            }
        }
)
</script>

<template>
    <div class="col-md-12">
        <div class="fs-policy-area w-100" v-if="['ROLE_SUPER', 'ROLE_UNDERWRITER'].includes(getRole())">
            <h5 class="main-color mt-3">AGENT QUOTE PREMIUM ENTRY</h5>
            <div>
                <p class="text-left">Enter a Premium</p>
                <!-- <div v-if="quote.premium > 0 && quote.status !== 'referral'"><small>Enter 0 and click the save button if you want to put this quote back into referral status. This will also allow users to edit their policy again.</small></div> -->
                <div class="text-70">Enter a premium and click "Save". This will prevent anyone from editing application. To unlock and edit the form click
                    the "Unlock Form & set to Referral" button.
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="number" v-model="model" class="form-control" @input="saved = false">
                    <div class="input-group-append">
                        <span class="input-group-text">.00</span>
                    </div>
                </div>
                <button class="btn btn-sm btn-success m-2" :disabled="saved" @click.prevent="save">
                    {{ saved ? 'Saved' : 'Save' }}
                </button>
                <button class="btn btn-sm btn-outline-secondary m-2" :disabled="model === 0" @click="setToReferral()">Unlock Form &amp; set to Referral</button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>