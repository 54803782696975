<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1 my-1">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							Press Releases
						</h1>
						<h5 class="text-white">
							Draftrs Now Automating 100% Of Professional Liability Insurance Underwriting, Binding and Servicing 
						</h5>
					</div>
				</div>
			</div>
		</header>
		<main class="container-fluid bg-grey">
            <div class="container py-5" >
				<div class="col-md-10 offset-md-1">
                    <h5 class="main-color pb-3">
                        <b>Draftrs Now Automating 100% Of Professional Liability Insurance Underwriting, Binding and Servicing</b>
                    </h5>
                    <p class="pb-3">
                        <b>Dallas, Texas – March 27, 2023 –</b> Draftrs, an insurtech focused on modernizing small business professional liability insurance, today announced that it had fully automated all aspects of its professional liability insurance offering.
                    </p>
                    <p class="pb-3">
                        Partner agents use the automated Draftrs platform to offer their clients instant quotes, billing and policy binding.  During the policy term, insureds and their agents use the Draftrs platform to make instant updates to their policy, issue endorsements and download certificates of insurance.  
                    </p>
                    <p class="pb-3">
                        “Professional liability insurance is the most important insurance for professionals,” said Audrey Lau, co-founder and CEO of Draftrs.  “However, the market for this insurance has lagged in terms of innovation and automation.  We are excited to have not only automated the quoting process, but also critical aspects of the policy lifecycle such as policy binding and instant endorsements.  With these features, we believe that we are the fastest and most efficient market for professional liability insurance.” 
                    </p>
                    <p class="pb-3">
                        The instant quotes, binding and servicing available on the Draftrs platform give insureds the instant access they expect and save agents time so they can focus on customer relationships.  The company estimates that agents using the Draftrs platform are seeing a reduction of more than 80% in total administrative servicing time per client compared to the main professional liability carriers in the market today.  
                    </p>
                    <p class="pb-3">
                        “We set out to build a comprehensive policy management engine so that professional liability insurance specialists have the tools to service their clients,” said Curtis Corliss, co-founder and CTO of Draftrs.  “Our partner agents are using these tools today to reduce administrative time and provide better service to the insured.”  
                    </p>
                    <p class="pb-3">
                        Draftrs offers professional liability insurance for design professionals, including architects and engineers.  The product is available in 49 states and is underwritten by an A rated carrier.  The company expects to launch additional professional liability insurance products throughout 2023.  
                    </p>
                    <p class="pb-3">
                        <b>About Draftrs, Inc. (Draftrs)</b><br/>
                        Draftrs, Inc. (Draftrs) is a technology-enabled, managing general agent (MGA) that is streamlining, automating, and digitizing the insurance underwriting and distribution process for carrier and broker partners to create a more efficient way to deliver professional liability insurance to the small commercial market.  Draftrs provides instant, direct access and enables distribution partners to become digital agencies without having to make any costly tech upgrades or investments.  For more information, please visit <a href="https://draftrs.com" >https://draftrs.com</a>.
                    </p>
				</div>
                <div class="col-md-10 offset-md-1">
                    <a href="/Press">View more press releases</a>
                </div>
			</div>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import StandardNav from "../../components/StandardNav.vue";
import StandardFooter from "../../components/StandardFooter.vue";

export default {
  name: "PressReleases",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
    },
    watch: {
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
    },
};
</script>

<style lang="scss">

</style>
