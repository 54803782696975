import { render, staticRenderFns } from "./OpenAPI.vue?vue&type=template&id=28fe689c"
import script from "./OpenAPI.vue?vue&type=script&lang=js"
export * from "./OpenAPI.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports