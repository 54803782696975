<template>
    <div id="wrapper">
        <StandardNav/>
        <main id="main" role="main" class="">
            <section class="m-5" >
                <div class="text-center">
                    <h3 class="main-color p-2"><i class="fas fa-clipboard-list pr-1"></i> Privacy Policy </h3>
                </div>
                <div class="row mt-5">
                    <div class="col-md-1"></div>
                    <div class="px-3 col-md-10">
                            <p >Draftrs Data Privacy Policy</p>
                            <p >Draftrs, Inc. (&ldquo;Draftrs&rdquo;, &rdquo;we&rdquo;, &rdquo;us&rdquo;,
                                    &rdquo;our&rdquo;), inclusive of its parents, subsidiaries and affiliates, has published this &ldquo;Privacy
                                    Policy&rdquo; (or &ldquo;Policy&rdquo;) to detail our policies and procedures regarding the collection, use,
                                    and disclosure of personally identifiable information received in the course of our users and customers
                                    visiting and using our website(s) (the &ldquo;Site&rdquo;) and any other related services we offer. Within
                                    this Policy, we will be referring to the Site and any other service offered by Draftrs collectively as the
                                    &ldquo;Service(s).&rdquo; </p>
                            <p >This Privacy Policy applies to information that is provided to us when you use the
                                    Services. We reserve the right in our sole discretion to modify this Privacy Policy at any time. &nbsp;We
                                    will post the most recent version of this Policy on this page of our Site. We encourage you to periodically
                                    review this page for the latest information on our privacy practices.</p>
                            <p >Feel free to email us, or contact us by mail:</p>
                            Draftrs, Inc.<br/>
                            Attn: Privacy Officer<br/>
                            3839 McKinney Avenue<br/>
                            Suite 155-5062<br/>
                            Dallas, TX 75204-1488<br/>
                            <p ><a href="mailto:info@draftrs.com">info@draftrs.com</a></p>
                            
                            <ol class="pl-0" start="1">
                                <li class="ml-0 main-color"><b>1.</b> Collecting PII and other Information</li>
                            </ol>
                            <p >While using Draftrs&rsquo;s Services, we will ask you to provide us with specific
                                    personal identifying information in order to provide the Services, contact you, and to set up your personal
                                    account (&ldquo;Personal Identifying Information&rdquo; or &ldquo;PII&rdquo;). You can always refuse to
                                    disclose PII, but in doing so, you may limit our ability to successfully deliver the Services. PII may
                                    include, but is not limited to, </p>
                            <ul class="pl-3">
                                <li class="py-2"><b>a.</b> Your name; home and employment addresses; email addresses (both
                                        work and personal); telephone number; date of birth; gender; any online profile you may have; social
                                        security number; employer and employment information; financial data; education background; employment
                                        history; credit history; payment history; credit card number; and/or other billing information.
                                        &nbsp;</li>
                                <li class="py-2"><b>b.</b> Information pertaining to your employment or business, including:
                                        business name (both its legal and &ldquo;DBA&rdquo; name); business ownership structure; length of time
                                        in business; the type of work you do; the number of employees or contractors you employ; the types of
                                        work your subcontractors do; the type of work your employees do; estimated payroll or revenue; business
                                        owners&rsquo; and officers&rsquo; names (state/location, name, date of birth, title, ownership
                                        percentage, duties, class code, payroll); location of workers and employees; employee activities with
                                        higher risks; background checks; safety and hiring; FEIN and other state unemployment identifications
                                        and/or tax identifications; and your company&rsquo;s business and other business revenue metrics.
                                </li>
                                <li class="py-2"><b>c.</b> The amount of time you have worked in your industry. </li>
                                <li class="py-2"><b>d.</b> Information related to any prior insurance information or prior
                                        insurance claims.</li>
                                <li class="py-2"><b>e.</b> Information related to any prior lawsuits or regulatory actions
                                        you have been the subject of.</li>
                                <li class="py-2"><b>f.</b> Information related to whether you are licensed to do certain
                                        types of work.</li>
                                <li class="py-2"><b>g.</b> We may also collect information during your use of our Site,
                                        including operating system, your phone carrier, hardware you use, pages you viewed, your IP address, how
                                        often you use the Services, events that occur within the Site during your use, aggregated usage
                                        information, performance data, the site and location from where you downloaded or otherwise accessed the
                                        Services, your browser type, your Internet service provider, the referring and exit pages, installed
                                        fonts, device IDs, language and time zone settings, and other information relating to your use of or
                                        access to the Services through the use of automatic data collection tools. </li>
                                <li class="py-2"><b>h.</b> In connection with your initial insurance application or renewal
                                        of insurance to the extent permitted by law and with your consent, we may review your credit report or
                                        obtain or use a credit-based insurance score, based on information contained in your credit report. An
                                        insurance score uses information from your credit report to help predict how often you are likely to
                                        file claims and how expensive those claims will be. Typical items from a credit report that could affect
                                        a score include, but are not limited to, the following: payment history, number of revolving accounts,
                                        number of new accounts, the presence of collection accounts, bankruptcies and foreclosures. The
                                        information used to develop the insurance score comes from various credit reporting agencies.
                                </li>
                            </ul>
                            <p ></p>
                            <ol class="pl-0" start="2">
                                <li class="ml-0 main-color"><b>2.</b> Using PII</li>
                            </ol>
                            <p >Draftrs uses PII in a number of ways, including, but not limited to, the following:
                                    (i) to provide and improve aspects of the Services, including the products we offer and the service we
                                    provide in response to claims and other requests you may have; (ii) to improve the experience for all
                                    customers, including identifying, completing and expediting any problems, issues or requests customers may
                                    have related to our Services; (iii) to project, predict and understand customer usage and needs; (iv) to
                                    insurers, reinsurers, advisors, regulators, brokers and claims personnel and others involved in the claims
                                    handing process; (v) to generate insurance quotes prepared by insurance agents or brokers using
                                    Draftrs&rsquo;s Site and applications, and to facilitate the communication of such insurance quotes to
                                    insureds as directed by the insurance brokers for the insureds to review its application for insurance and
                                    provide payment; (vi) to communicate with customers regarding product updates, announcements, changes, and
                                    other offerings from Draftrs; (vii) to facilitate auditing, prevent or investigate fraud, comply with
                                    requests from regulatory and law enforcement authorities, protect our rights, protect your safety, protect
                                    the safety of others, comply with applicable law, respond to subpoena or court order, and otherwise
                                    investigate, establish, exercise, and defend legal rights; and, (viii) to facilitate a merger, acquisition,
                                    bankruptcy, dissolution, reorganization, sale of some or all of our assets, financing, acquisition of all or
                                    a portion of our business, a similar transaction or proceeding, or steps in contemplation of such activities
                                    (e.g. due diligence) and/or to facilitate any other activity in connection with the creation, development,
                                    offering, provision, or servicing of our products and Services. </p>
                            <ol class="pl-0" start="3">
                                <li class="ml-0 main-color"><b>3.</b> Social Media</li>
                            </ol>
                            <p >You may see various social media links on our Site (Facebook, Instagram, Twitter,
                                    LinkedIn, etc.). While we encourage our users to use these features, know that these features may collect
                                    PII, which will subsequently be hosted on these third-party sites or servers. As such, you will need to read
                                    and agree to each site&rsquo;s respective privacy policy. &nbsp;You understand, acknowledge and agree that
                                    Draftrs does not have any responsibility or authority with respect to any third party&rsquo;s collection or
                                    use of PII that is not expressly identified in this Policy.</p>
                            <ol class="pl-0" start="4">
                                <li class="ml-0 main-color"><b>4.</b> Payment Processing</li>
                            </ol>
                            <p >When you purchase our products by using our Services, we will collect personal
                                    information from you in order to process that payment. This information will likely include the items of PII
                                    listed above. While we do not store your payment info (except for the last four (4) digits of your credit
                                    card which we may store for verification), we will provide the PII necessary to process your payment to our
                                    third-party payment processor, and that payment processor will store and process your that PII.</p>
                            <ol class="pl-0" start="5">
                                <li class="ml-0 main-color"><b>5.</b> Email</li>
                            </ol>
                            <p >By using our Services, you have the opportunity to provide us with your contact
                                    information in order to receive periodic updates and newsletters about Draftrs and its Services. We may use
                                    third-party communication software to send these emails and we may make your contact information available
                                    to our referral partners. &nbsp;By using the Services, you agree to this disclosure, but have the ability to
                                    opt out of any such communications. </p>
                            <ol class="pl-0" start="6">
                                <li class="ml-0 main-color"><b>6.</b> Children</li>
                            </ol>
                            <p >As we do not provide our Services to anyone under the age of 13 we similarly do not
                                    collect PII from anyone under the age of 13. &nbsp;If you are under the age of 13, please do not provide any
                                    PII to Draftrs. Regardless of your age, please do not provide Draftrs with any PII of any person under the
                                    age of 13. &nbsp;If you are a parent or a guardian who has discovered that your child under age 13 has
                                    submitted his or her personally identifiable information to us without your permission or consent, we will
                                    make reasonable efforts to remove the information from our database, at your written request. To request the
                                    removal of your child&rsquo;s information, please contact us as provided at the beginning of this Policy and
                                    include in your message your child&rsquo;s name and the email address that your child submitted.</p>
                            <ol class="pl-0" start="7">
                                <li class="ml-0 main-color"><b>7.</b> Security</li>
                            </ol>
                            <p >We take the security of your PII very seriously, but no system is 100% secure. While
                                    we do our best to keep everything secure, we cannot guarantee the absolute security of your information.
                                    However, rest assured that we follow industry best practices, including the provisions of the California
                                    Consumer Privacy Act of 2018, when it comes to the transmission and submission of your data, and every
                                    member of our team who is responsible for handling data follows these practices.</p>
                            <ol class="pl-0" start="8">
                                <li class="ml-0 main-color"><b>8.</b> Anonymous and De-identified Data</li>
                            </ol>
                            <p >We may use third-party advertising services that collect non-personally identifying
                                    information using cookies and the other tools mentioned above. From your web browsing patterns and other
                                    non-specific, non-identifying information, we are better able to create targeted advertising campaigns. You
                                    may opt out of this data collection at any time by contacting us as provided at the beginning of this
                                    Policy, and include your name and email address.</p>
                            <p >Additionally, we may use aggregated, de-identified data to show larger trends in the
                                    use and delivery of our Services. No PII is ever disclosed in the process.</p>
                            <ol class="pl-0" start="9">
                                <li class="ml-0 main-color"><b>9.</b> Analytics and Tracking</li>
                            </ol>
                            <div class="pl-3">
                                <ol class="pl-0" start="1">
                                    <li ><b>1.</b> Cookies and Local Storage</li>
                                </ol>
                                <p >Draftrs actively collects information through the use of &ldquo;cookies&rdquo; and
                                        &ldquo;local storage.&rdquo; We use these to observe usage patterns and traffic on our Site, and
                                        subsequently link the appropriate cookies to your PII and account in order to optimize your customer
                                        experience. When visiting our site, your browser may alert you to our usage of cookies or local storage. You
                                        can always set your browser setting to limit their usage; however, it may limit the features of our
                                        Services.</p>
                                <ol class="pl-0" start="2">
                                    <li ><b>2.</b> Web Beacons</li>
                                </ol>
                                <p >Another tracking technology that we employ is the Web Beacon (a.k.a. web bugs or
                                        clear gifs). These tools help us manage and observe our content, track email campaigns, and other
                                        communications, and optimize the way we present content. Like cookies, these tools can be linked to PII in
                                        order to provide better customization and specificity.</p>
                                <ol class="pl-0" start="3">
                                    <li ><b>3.</b> Log Data</li>
                                </ol>
                                <p >Our servers automatically record certain information about how visitors interact with
                                        our Site &ndash; this is known as &ldquo;Log Data.&rdquo; Log Data is a broad category of data, but it can
                                        include, but is not limited to: (i) browser type; (ii) operating system; (iii) Internet Protocol address;
                                        (iv) pages viewed and duration of viewing; (v) features and functionalities used; and (vi) links clicked.
                                    </p>
                            </div>
                            <ol class="pl-0" start="10">
                                <li class="ml-0 main-color"><b>10.</b> Google</li>
                            </ol>
                            <p >We may use Google Analytics to collect non-personal information about your use of
                                    our Site in order to improve our Site and Services. This tool may collect information about how often you
                                    visit the Site, what pages you frequent, and what websites you visited prior to coming to our Site. It also
                                    collects the IP address assigned to you on the date you visit the site. Google Analytics leaves a cookie on
                                    your web browser to identify you as a unique user the next time you visit the Site. Please visit Google
                                    Analytics Terms of Service (&ldquo;Google A Terms&rdquo;) Google Maps Terms of Service (&ldquo;Google M
                                    Terms&rdquo;), the Google Privacy Policy (&ldquo;Google Privacy&rdquo;), and the Google Partner Privacy
                                    Policy (&ldquo;Google PPP&rdquo;) for more information. The links (as of the effective date of this Privacy
                                    Policy) can be found here: Google A Terms:&nbsp;<a 
                                        href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttps://marketingplatform.google.com/about/analytics/terms/us/%26sa%3DD%26source%3Deditors%26ust%3D1627088991074000%26usg%3DAOvVaw3nDRZLS9COxPg2-urlkNXc&amp;sa=D&amp;source=editors&amp;ust=1629309306658000&amp;usg=AOvVaw10JjtwxFoZy1O5dQMVK4xJ">https://marketingplatform.google.com/about/analytics/terms/us/</a>&nbsp;; Google M Terms:&nbsp;<a 
                                        href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttps://maps.google.com/help/terms_maps/%26sa%3DD%26source%3Deditors%26ust%3D1627088991074000%26usg%3DAOvVaw0GqwTbZfPJkJ67EDwem3jT&amp;sa=D&amp;source=editors&amp;ust=1629309306659000&amp;usg=AOvVaw3ByOM_EyX4n6Rnegq0XuSY">https://maps.google.com/help/terms_maps/</a>
                                    &nbsp;; Google Privacy:&nbsp;<a 
                                        href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttp://www.google.com/policies/privacy%26sa%3DD%26source%3Deditors%26ust%3D1627088991074000%26usg%3DAOvVaw16jYLBYFskDawsSk1CYtt-&amp;sa=D&amp;source=editors&amp;ust=1629309306659000&amp;usg=AOvVaw10qikeQPoqwzPZnxjif1Pq">www.google.com/policies/privacy</a>
                                    &nbsp;; Google PPP:&nbsp;<a 
                                        href="https://www.google.com/url?q=https://www.google.com/url?q%3Dhttp://www.google.com/policies/privacy/partners/%26sa%3DD%26source%3Deditors%26ust%3D1627088991075000%26usg%3DAOvVaw0ICf3HtcNXeXxTeaY9Ui3m&amp;sa=D&amp;source=editors&amp;ust=1629309306659000&amp;usg=AOvVaw0Pj5-nZY09qR_ZbVu12LR5">www.google.com/policies/privacy/partners/</a>
                                    &nbsp;.</p>
                            <ol class="pl-0" start="11">
                                <li class="ml-0 main-color"><b>11.</b> Disclosure of Your Information to Third Parties</li>
                            </ol>
                            <p >Draftrs does not sell any of its users&rsquo; Personal Identifying Information
                                    without express consent. PII will only be disclosed as expressly set forth in this Policy.</p>
                            <ol class="pl-0" start="12">
                                <li class="ml-0 main-color"><b>12.</b> Third-Party Services</li>
                            </ol>
                            <p >Draftrs may use third-party platforms or services in order to deliver, maintain, or
                                    update Draftrs&rsquo;s Services and software. As such, Draftrs may disclose PII to these third-party
                                    providers in order to maintain and continue the operation of our Services. </p>
                            <ol class="pl-0" start="13">
                                <li class="ml-0 main-color"><b>13.</b> Accessing and Managing Your Information</li>
                            </ol>
                            <p >Every Draftrs customer or account holder can request access to their PII. &nbsp;You
                                    may request to review your PII by contacting us at any time as provided at the beginning of this Policy. If
                                    you believe that the information is incomplete or inaccurate, you can request that we correct it. We may
                                    not, however, be able to provide information relating to investigations, claims, litigation, or other
                                    matters. We will be happy to make corrections whenever possible. Draftrs reserves the right to archive or
                                    save data in order to comply with laws and regulations.</p>
                            <ol class="pl-0" start="14">
                                <li class="ml-0 main-color"><b>14.</b> Your California Privacy Rights</li>
                            </ol>
                            <p >We may collect customer information from persons other than the individual or
                                    individuals applying for coverage. Such customer information as well as other personal or privileged
                                    information subsequently collected may, in certain circumstances, be disclosed to third parties without your
                                    authorization as permitted by law. Residents of the State of California, under the California Civil Code,
                                    have the right to request from certain companies conducting business in California a list of all third
                                    parties to which the company has disclosed personal information during the preceding year for direct
                                    marketing purposes and a disclosure of the shared information. To make such a request, contact us as at the
                                    contact information provided at the beginning of this Policy.</p>
                            <p >If you are a California resident under age 18 who has registered an account with us,
                                    you may request the removal of the content you publicly posted on our Site. To request such removal, contact
                                    us at the contact information provided at the beginning of this Policy. Please include with your request
                                    your username and a clear statement that you would like us to remove the content you posted to the Site. We
                                    may ask, and you will provide us with, additional details to remove such content. Your request does not
                                    ensure the complete or comprehensive removal of the content you posted. We are not required to remove
                                    content you posted if you fail to follow our instructions related to such removal or if (i) we are required
                                    by law to maintain the content; (ii)a third party stored, posted, republished, or reposted the content; or,
                                    (iii) we anonymize the content so that you cannot be individually identified by it.</p>
                            <ol class="pl-0" start="15">
                                <li class="ml-0 main-color"><b>15.</b> International Users</li>
                            </ol>
                            <p >Draftrs and the Site are based in the United States. &nbsp;Draftrs only offers
                                    insurance to United States residents. &nbsp;By using the Site, you consent to this collection, transfer,
                                    storage, and processing of information in the United States in accordance with this Privacy Policy.
                                    &nbsp;</p>
                            <ol class="pl-0" start="16">
                                <li class="ml-0 main-color"><b>16.</b> Governing Law, Jurisdiction, Venue</li>
                            </ol>
                            <p >The information collected from our Services is hosted in the United States. If you
                                    are using or accessing the Services from outside the United States or any other region with laws or
                                    regulations governing personal data collection, use and disclosure that differ from the United States laws,
                                    please be advised that through your use of the Services, which are governed by United States law, you are
                                    transferring information to and from the United States and you consent to that transfer.</p>
                            <p >The laws of the State of Texas and applicable United States law govern all matters
                                    arising out of or relating to this Policy, including, without limitation, interpretation, construction,
                                    performance, and enforcement, without giving effect to such state&rsquo;s conflicts of law principles or
                                    rules of construction concerning the drafter hereof. You hereby irrevocably and unconditionally submit to
                                    the jurisdiction of the federal and state courts venued in Dallas County, Texas for the purpose of any suit,
                                    action, or other proceeding arising out of or based upon this Policy, your access to or your use of the
                                    Services, which courts are the exclusive forum for any such suit, action, or other proceeding.</p>
                            <ol class="pl-0" start="17">
                                <li class="ml-0 main-color"><b>17.</b> Assignment</li>
                            </ol>
                            <p >The rights granted under this Policy are non-transferable and non-assignable by you,
                                    and such rights are not enforceable by anyone other than you, pursuant to applicable laws. &nbsp;However,
                                    Draftrs may transfer and/or assign any rights under this Policy without limitation and without notice or
                                    consent from you.</p>
                            <ol class="pl-0" start="18">
                                <li class="ml-0 main-color"><b>18.</b> Acceptance of Policy</li>
                            </ol>
                            <p >By using or accessing the Services, you acknowledge that you have reviewed and
                                    understand this Policy, and consent to the procedures identified in this Policy. &nbsp;You accept and
                                    consent to the collection of PII, the retention of PII, the handling of PII, and potential disclosure of PII
                                    as identified in this Policy.</p>
                    </div>
                </div>
            </section>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "Privacy",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">


</style>
