<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1 my-5">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							Get appointed
						</h1>
						<h5 class="text-white">
							Please complete the form and we will get back to you shortly!
						</h5>
					</div>
				</div>
			</div>
		</header>
		<main class="container-fluid bg-grey">
			<div class="container py-5">
				<div class="col-md-10 offset-md-1">
					<FormulateForm
                        @submit="submitHandler"
                        #default="{ hasErrors }"
                    >
						<div class="row">
							<div class="col-md-6 mb-4">
								<div class="form-group">
                                    <FormulateInput type="text" placeholder="First Name*" name="First Name" input-class="form-control" validation="required" id="fname" v-model="contact.fname" />
									
								</div>
							</div>
							<div class="col-md-6 mb-4">
								<div class="form-group">
                                    <FormulateInput type="text" placeholder="Last Name*" name="Last Name" input-class="form-control" validation="required" id="lname" v-model="contact.lname" />
									
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 mb-4">
								<div class="form-group">
                                    <FormulateInput 
                                        type="text" 
                                        placeholder="Agency / Company Name*" 
                                        name="Company Name" 
                                        input-class="form-control" 
                                        validation="required" 
                                        id="company" 
                                        v-model="contact.company" 
                                    />
								</div>
							</div>
							<div class="col-md-6 mb-4">
								<div class="form-group">
									<FormulateInput
                                        v-model="contact.companyType"
                                        input-class="form-control"
                                        validation="required"
                                        :options="['Agent', 'Aggregator', 'Broker', 'Insurance Company', 'Small Business Service Provider', 'Trade Association', 'Technology Partner', 'Wholesaler', 'Other']"
                                        type="select"
                                        placeholder="Company Type"
                                    />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 mb-4">
								<div class="form-group">
                                    <FormulateInput 
                                        id="phone"
										aria-label="Phone Number"
                                        type="tel"
                                        name="Phone Number" 
                                        placeholder="Phone Number*" 
                                        input-class="form-control" 
                                        validation="required" 
                                        v-model="contact.phone"
                                    />
								</div>
							</div>
							<div class="col-md-6 mb-4">
								<div class="form-group">
                                    <FormulateInput 
                                        id="email"
										aria-label="Email address"
                                        type="email" 
                                        name="Email" 
                                        placeholder="Email*" 
                                        input-class="form-control" 
                                        validation="email"
                                        v-model="contact.email" 
                                    />
									
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 mb-4">
								<div class="form-group">
                                    <FormulateInput
                                        v-model="contact.estimatedAgencyPremiums"
                                        input-class="form-control"
                                        validation="required"
                                        :options="['Up to 1M', '1M - 5M', '5M - 10M', '10M - 25M', '25M - 50M', '50M+']"
                                        type="select"
                                        placeholder="Estimated Total Agency PL Premiums"
                                        id="premiums-pl"
										aria-label="Estimated Total Agency PL Premiums"
                                    />
								</div>
							</div>
							<div class="col-md-6 mb-4">
								<div class="form-group">
                                    <FormulateInput
                                        v-model="contact.estimatedAllAgencyPremiums"
                                        input-class="form-control"
                                        validation="required"
                                        :options="['Up to 1M', '1M - 5M', '5M - 10M', '10M - 25M', '25M - 50M', '50M+']"
                                        type="select"
                                        placeholder="Estimated Total Agency Premiums All Lines"
                                        id="premiums-all-lines"
										aria-label="Estimated Total Agency Premiums All Lines"
                                    />
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 mb-4">
								<div class="form-group">
                                    <FormulateInput 
                                        type="textarea" 
                                        validation="required|max:400,length|min:30,length" 
                                        input-class="form-control" 
                                        id="message" 
                                        v-model="message" 
                                        validation-name="Message"
                                        placeholder="Message"
                                        aria-label="Leave a message here"
                                        rows="6"
                                        :help="`Please keep it under 400 characters. ${400 - message.length} left.`"
                                    />
								</div>
							</div>
						</div>
                        <FormulateInput
                            input-class="btn btn-primary"
                            type="submit"
                            :disabled="hasErrors"
                        />
					</FormulateForm>
				</div>
			</div>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import axios from 'axios';
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "AgentsContactUs",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
        return {
            contact: {agentscontactus: true, fname: '', lname: '', phone: '', email: '', company: '', companyType: '', estimatedAgencyPremiums: '', estimatedAllAgencyPremiums: '', message: ''},
            message: '',
            statusMsg: false,
            statusMsgExtended: ''
        };
    },
    watch: {
        message: function() {
            if (this.message.length > 400) {
                this.message = this.message.substr(0, 400);
            }

        }
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
        submitHandler: async function() {
            this.contact.message = this.message;
            await axios.post('/api/contact', this.contact).then((response) => {
                if (response.data.status) {
                    this.$toast.open({ 
                        message: 'Thanks for sending us a message! Someone should get back to you ASAP.',
                        type: 'success',
                        dismissible: true,
                        duration: 100000,
                        position: 'top-right'
                    });
                } else {
                    this.$toast.open({ 
                        message: 'Oops something happened! Please try contacting us at support@draftrs.com.',
                        type: 'warning',
                        dismissible: true,
                        duration: 100000,
                        position: 'top-right'
                    });
                }
                window.scroll(0,0);
            });
        },
        redirectToHome: function() {
        this.$router.push({name: 'home', params: {}});
    },
    },
};
</script>

<style lang="scss">

</style>
