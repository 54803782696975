<template>
    <router-view/>
</template>

<script>
import { isLoggedIn, } from "./Utils/auth.js";
import { getStyle, getStyles} from "./Utils/themer.js";
import LogRocket from 'logrocket';

if (!['localhost', 'dev.draftrs.com'].includes(location.hostname)) {
  LogRocket.init('uwcevy/draftrs'); // record session when not localhost or dev.draftrs.com
}

export default {
  name: "App",
  props: {},
  mounted() {
    //init custom theme css variables
    const styles = getStyles();
    for (let i in styles) {
      const pc = getStyle(styles[i]);
      if (null !== pc) {
        document.documentElement.style.setProperty('--main-'+styles[i], pc);
      }
    }
  },
  data: function() {
    return {};
  },
  methods: {
    loggedIn: function() {
      return isLoggedIn();
    }
  }
};
</script>


<style lang="scss">

</style>
