<template>
  <div id="wrapper">
    <StandardNav/>
    <main id="main" role="main" class="">
      <section class="mt-5 mx-5">
        <h1>Sessions</h1>
        <div class="text-center">
          <div class="form-group">
            <label>Page {{ page }}</label>
            <input type="range" min="0" v-model="page" class="form-control-range">
          </div>
        </div>

        <div id="seshns">
          <div class="row" v-for="sesh, idx in sessions.sessions" v-bind:key="idx">
            
            <div class="row">
              <small class="text-muted mr-3"><i class="far fa-clock mr-1"></i> {{ moment(sesh.startTs).fromNow() }} <i class="fas fa-hourglass-half ml-4 mr-1"></i> {{ duration(sesh.duration).humanize() }}</small>
              <span v-if="sesh.metadata.owner" class="badge badge-primary ml-2 tag-link"><i class="far fa-building mr-1 mt-1 mb-1"></i> {{ sesh.metadata.owner }}</span>
              <span v-else-if="sesh.metadata.client" class="badge badge-primary ml-2 tag-link"><i class="far fa-building mr-1 mt-1 mb-1"></i> {{ sesh.metadata.client }}</span>
              <span v-if="sesh.metadata.email" class="badge badge-secondary ml-2 tag-link"> <i class="far fa-envelope mr-1 mt-1 mb-1"></i><a :href="'mailto:'+ sesh.metadata.email">{{ sesh.metadata.email }}</a></span>
              <span v-if="sesh.metadata.city" class="badge badge-secondary ml-2" tag-link><i class="fas fa-location-arrow mr-1 mt-1 mb-1"></i> {{ sesh.metadata.city }}</span>
              <span v-if="sesh.metadata.quoteId" class="badge badge-warning ml-2 tag-link"><i class="fas fa-quote-left mr-1 mt-1 mb-1"></i> <a :href="'/quote/'+sesh.metadata.quoteId" target="_blank">{{sesh.metadata.quoteId}}</a></span>
              <span v-if="'ROLE_CONSUMER' == sesh.metadata.role" class="badge badge-success ml-2 tag-link"><i class="fab fa-black-tie mr-1 mt-1 mb-1"></i> Consumer</span>
              <span v-else-if="sesh.metadata.role" class="badge badge-danger ml-2 tag-link"><i class="fab fa-black-tie mr-1 mt-1 mb-1"></i> Agent</span>
            </div>
            <div class="row mt-1">
              <code>{{ sesh }}</code>
            </div>
          </div>
        </div>

        <div class="text-center">
          <div class="form-group">
            <label>Page {{ page }}</label>
            <input type="range" min="0" v-model="page" class="form-control-range">
          </div>
        </div>
      </section>
    </main>
    <StandardFooter/>
  </div>
</template>

<script>

const axios = require('axios');
const moment = require('moment');
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "Sessions",
  components: {
    StandardNav,
    StandardFooter
  },
  mounted () {
    this.loadSessions();
  },
  data: function() {
    return {
      sessions: [],
      page: 1
    };
  },
  watch: {
    page: function() {
        this.loadSessions();
    }
  },
  methods: {
    async loadSessions() {
      let ret = [];
      let url = '/api/sessions';
      url = this.page ? (url + '/' + this.page) : url;
      //url = this.$route.params.quoteId ? (url + '/' + this.$route.params.quoteId) : url;
      await axios.get(url)
      .then((res) => {
        ret = res.data;
      })
      .catch((e) => { console.log(e); ret = []; });
      this.sessions = ret;

      return ret;
    },
    moment(param) {
      return moment(param);
    },
    duration(param) {
      return moment.duration(param);
    }
  },
};
</script>

<style lang="scss">
.tag-link {
  color: #fff !important;
  text-shadow: #000 0px 0px 2px;
}
.tag-link a {
  color: #fff !important;
  
}
</style>
