<template>
    <nav class="navbar navbar-expand-lg navbar-light py-4">
        <div class="container-fluid">
            <a href="/"><img :src="getClientLogo()" :height="getClientDomain() == 'draftrs' ? '33px' : false" alt=""></a>
            <div v-if="getClientText().phone.length > 0" class="pt-1" :class="getClientText().phone.length < 5 ? 'd-none': ''" style="font-size: 10pt">{{ getClientText().phone }}</div>
            <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div
                    class="collapse navbar-collapse justify-content-end"
                    id="navbarNavDropdown"
            >
                <ul class="navbar-nav mr-3">
                    <li class="nav-item dropdown">
                        <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                        >Our Products</a
                        >
                        <div
                                class="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                        >
                            <a href="/design-professionals-insurance" class="dropdown-item">Architects &amp; Engineers</a>
                            <a href="/advertising-creatives" class="dropdown-item">Advertising &amp; Creative Professionals</a>
                            <a href="/bookkeepers-taxpreparers" class="dropdown-item">Bookkeepers &amp; Taxpreparers</a>
                            <a href="/construction-design-consultants" class="dropdown-item">Construction Design &amp; Consulting</a>
                            <a href="/ConsultantsSummary" class="dropdown-item">Consultants</a>
                            <a href="/LawyersSummary" class="dropdown-item">Mediators &amp; Arbitrators</a>
                            <a href="/recruiters-staffing" class="dropdown-item">Recruiters &amp; Staffing</a>
                            <a href="/other-professions" class="dropdown-item">Other Professions</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                        >About Us</a
                        >
                        <div
                                class="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                        >
                            <a href="/AboutUs" class="dropdown-item">About Draftrs</a>
                            <a href="https://software.draftrs.com" target="_blank" class="dropdown-item">Software</a>
                            <a href="/Press" class="dropdown-item">Press Releases</a>
                            <a href="/FAQ" class="dropdown-item">FAQ</a>
                            <a href="/ContactUs" class="dropdown-item">Contact Us</a>
                            <a href="/FileClaim" class="dropdown-item">File a Claim</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                        >
                            Agents & Partners
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item" href="/AgentsPartners">Why partner with us</a>
                            <a href="/AgentsContactUs" class="dropdown-item">Get Appointed</a>
                            <a class="dropdown-item" href="/dashboard" v-if="!loggedIn()">Login</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                        >Resources</a
                        >
                        <div
                                class="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                        >
                            <a href="/Professional-Liability-Insurance" class="dropdown-item">Professional Liability</a>
                            <a href="/General-Liability-Insurance" class="dropdown-item">General Liability</a>
                            <a href="/Insurance-Frequently-Used-Terms-Definitions" class="dropdown-item">Insurance Glossary</a>
                            <!-- <router-link to="OtherCoverages" class="dropdown-item">Other Coverages</router-link> -->
                            <a href="/Blog" class="dropdown-item">Blog</a>
                        </div>
                    </li>
                    <li class="nav-item" v-if="loggedIn()">
                        <a href="/dashboard" title="Agent Dashboard" class="nav-link"><i class="fas fa-lg fa-sliders-h main-color px-2"></i></a>
                    </li>
                </ul>
                <router-link class="btn btn-outline-primary mr-2 mt-3 mt-md-0" to="dashboard" v-if="!loggedIn()">Login</router-link>
                <a href="/logout" class="btn btn-outline-primary mr-2 mt-3 mt-md-0" v-else>Logout</a>
                <a v-if="!loggedIn()" href="/AgentsContactUs" class="btn btn-secondary mt-3 mt-md-0">Get Appointed</a>
            </div>
        </div>
    </nav>
</template>

<script>

// import '../../public/js/scripts.js';
import {isLoggedIn} from "../Utils/auth.js";
import {getClientLogo, getClientDomain, getClientText} from "../Utils/themer.js";
import 'bootstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../node_modules/bootstrap-vue/dist/bootstrap-vue.min.css';
import '../../public/css/style.css';

export default {
    name: "StandardNav",
    components: {},
    props: {},
    data: function () {
        return {};
    },
    methods: {
        loggedIn: function () {
            return isLoggedIn();
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientText() {
            return getClientText();
        },
        getClientDomain() {
            return getClientDomain();
        }
    }
};
</script>

<style lang="scss">

</style>

