<template>
  <div id="wrapper">
      <code>{{ version }}</code>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'version',
  data: function () {
    return {
      version: null
    }
  },
  async mounted() {
    await axios.get("/api/version").then(res => {
      this.version = res.data;
    });
  },
  methods: {
  }
}
</script>

<style lang="scss"></style>