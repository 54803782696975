<template>
    <header id="header" class="nav-header">
        <nav :class="($route.name === 'endorse' || $route.name === 'quote' ? '' : 'fixed-top') + ' container-fluid navbar navbar-expand-lg navbar-inverse bg-inverse justify-content-between bg-white shadow-sm'">
            <div class="navbar-brand text-center mr-0 py-0">
                <a href="/dashboard"><img :src="getClientLogo()" alt=""></a>
                <!-- <div v-if="getClientText().phone.length > 0" class="pt-1" :class="getClientText().phone.length < 5 ? 'd-none': ''" style="font-size: 10pt">{{ getClientText().phone }}</div> -->
            </div>
            <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
            >
                <i class="pt-2 fas fa-solid fa-bars"></i>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item pt-2">
                        <span class="navbar-text pr-3">Welcome Back <b v-b-tooltip.hover="user.email">{{ user.firstName }} {{ user.lastName }}</b></span>
                    </li>
                    <li v-if="getRole() !== 'ROLE_CONSUMER'" class="nav-item dropdown pt-2">
                        <div class="dropdown">
                            <a class="nav-link dropdown-toggle text-dark" href="#" id="accountDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Manage
                            </a>
                            <div class="py-4 rounded-lg dropdown-menu dropdown-menu-left" aria-labelledby="accountDropdown">
                                <a v-if="getRole() === 'ROLE_SUPER' && !isCarrierAdmin()" class="dropdown-item" href="/dashboard"><i class="mr-1 fas fa-sliders-h"></i>Agent Dashboard</a>
                                <a v-if="getRole() === 'ROLE_SUPER' && !isCarrierAdmin()" class="dropdown-item" href="/admin/users" target="_BLANK"><i
                                        class="mr-1 fas fa-user-friends"></i>Users</a>
                                <a v-if="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole()) && !isCarrierAdmin()" class="dropdown-item" href="/admin/accounting" target="_BLANK"><i
                                        class="ml-1 mr-2 fas fa-file-invoice-dollar"></i>Accounting</a>
                                <a v-if="['ROLE_SUPER',].includes(getRole()) && !isCarrierAdmin()" class="dropdown-item" href="/admin/mail" target="_BLANK"><i
                                        class="ml-1 mr-2 fas fa-envelope"></i>Mail</a>
                                <a v-if="['ROLE_SUPER',].includes(getRole()) && !isCarrierAdmin()" class="dropdown-item" href="/admin/sessions" target="_BLANK"><i
                                        class="fab fa-buromobelexperte ml-1 mr-2"></i>Sessions</a>
                                <a href="/userguide" class="dropdown-item"><i class="mx-1 fas fa-book-open"></i>User Guide</a>
                                <a href="/openapi" target="_BLANK" class="dropdown-item"><i class="mx-1 fas fa-project-diagram"></i>Open API</a>
                            </div>
                        </div>
                    </li>
                    <li v-if="getRole() !== 'ROLE_CONSUMER'" class="nav-item pt-2  mr-3">
                        <div class="dropdown">

                            <a class="nav-link dropdown-toggle text-dark" href="#" id="accountDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Agent Resources
                            </a>

                            <div class="py-4 dropdown-menu dropdown-menu-left" aria-labelledby="accountDropdown">
                                <a href="/userguide" class="dropdown-item"><i class="mx-1 fas fa-book-open"></i> User Guide</a>
                                <a href="/pdf/draftrs-ae-coverage-summary.pdf" target="_blank" class="dropdown-item"><i class="mt-3 mx-1 fas fa-file-pdf"></i> A&amp;E Appetite &amp; Coverage
                                    Summary</a>
                                <a href="/pdf/markel-draftrs-ae-specimen-policy-form.pdf" target="_blank" class="dropdown-item"><i class="mx-1 fas fa-file-pdf"></i> Specimen A&amp;E Policy
                                    Form</a>

                                <a href="/pdf/draftrs-mpl-coverage.pdf" target="_blank" class="dropdown-item"><i class="mt-3 mx-1 fas fa-file-pdf"></i> MPL Appetite &amp; Coverage Summary</a>
                                <a href="/pdf/draftrs-mpl-specimen-policy-form.pdf" target="_blank" class="dropdown-item"><i class="mx-1 fas fa-file-pdf"></i> Specimen MPL Policy Form</a>

                                <a href="/pdf/draftrs-all-pl-summary.pdf" target="_blank" class="dropdown-item"><i class="mt-3 mx-1 fas fa-file-pdf"></i> Draftrs All Available Classes</a>
                            </div>

                        </div>
                    </li>
                    <li v-if="getRole() === 'ROLE_CONSUMER'" class="nav-item pt-2 mr-3">
                        <div class="dropdown">

                            <a class="nav-link dropdown-toggle text-dark" href="#" id="accountDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Risk Management Resources
                            </a>

                            <div class="py-4 dropdown-menu dropdown-menu-left" aria-labelledby="accountDropdown">
                                <h6 class="dropdown-header">A&E Resources</h6>
                                <a href="/pdf/draftrs-ae-risk-management-guide-2024.pdf" target="_blank" v-b-tooltip.hover.left="disclaimer" class="dropdown-item"><i
                                        class="mx-1 fas fa-file-pdf"></i> A&E Risk Management Guide</a>
                                <a href="/pdf/draftrs-ae-contract-risk-management.pdf" target="_blank" v-b-tooltip.hover.left="disclaimer" class="dropdown-item"><i
                                        class="mx-1 fas fa-file-pdf"></i> A&E Webinar Slides - Contracts</a>
                                <a href="/pdf/draftrs-understanding-the-pl-policy.pdf" target="_blank" v-b-tooltip.hover.left="disclaimer" class="dropdown-item"><i
                                        class="mx-1 fas fa-file-pdf"></i> A&E Webinar Slides - Understanding the PL Policy</a>
                            </div>

                        </div>
                    </li>
                    <li class="nav-item pt-2">
                        <a href="/logout" class="border rounded-pill nav-link text-dark py-2 px-4 ">Logout</a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>


<script>

import {isLoggedIn, getUserData} from "../Utils/auth.js";
import {getClientDomain, getClientLogo, getClientText} from "../Utils/themer.js";
import Vue from "vue"

export default {
    name: "AgentNav",
    components: {},
    props: {},
    data: function () {
        return {
            user: getUserData(),
            disclaimer: 'All information is intended for informational purposes only. Draftrs, Inc. does not provide financial, legal or tax advice. You should consult a professional advisor regarding risk management. Your continued use of this website is subject to our Terms of Service'
        };
    },
    computed: {
        loading() {
            return Vue.prototype.loading.value
        }
    },
    methods: {
        loggedIn: function () {
            return isLoggedIn();
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientText() {
            return getClientText();
        },
        getRole() {
            return getUserData().role;
        },
        getClientDomain() {
            return getClientDomain();
        }
    }
};
</script>

<style lang="scss">
</style>
