<template>
<body>
    <div id="wrapper">
      <AgentNav/>

      <nav aria-label="breadcrumb" class=" d-md-block mb-0">
          <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard" >Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="Users">Users</li>
          </ol>
      </nav>

        <div class="card card-radius shadow mt-2 mb-5 mx-3">
          <div class="card-body">
            <button type="button" class="btn btn-primary my-2" data-toggle="modal" data-target="#user-modal" @click="newUser=true; selectedUser={ enabled:true };">Add User</button>
            <table class="table table-responsive table-md" id="users-tbl">
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Role</th>
                  <th scope="col">Owner</th>
                  <th scope="col">Company</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Status</th>
                  <th scope="col">Enabled</th>
                  <th scope="col">License</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="u, k in users" :key="k" @click="selectUser(u)" data-toggle="modal" data-target="#user-modal">
                  <td>{{ u.email }}</td>
                  <td>{{ u.firstName }}</td>
                  <td>{{ u.lastName }}</td>
                  <td>{{ u.role }}</td>
                  <td>{{ u.owner }}</td>
                  <td>{{ u.company }}</td>
                  <td>{{ u.phoneNumber }}</td>
                  <td>{{ u.status }}</td>
                  <td>{{ u.enabled }}</td>
                  <td>{{ u.licenseNumber }}</td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
    <div id="user-modal" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" v-if="selectedUser">
          <div class="modal-header">
            <h5 class="modal-title" v-if="newUser">Create User</h5>
            <h5 class="modal-title" v-else>Edit User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="userModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="!newUser">
              <div class="col-md-3">
                Created
                <p><span class="badge badge-secondary">{{ selectedUser.created }}</span></p>
              </div>
              <div class="col-md-3">
                Updated
                <p><span class="badge badge-secondary">{{ selectedUser.updated }}</span></p>
              </div>
              <div class="col-md-3">
                Status
                <p><span class="badge badge-secondary">{{ selectedUser.status }}</span></p>
              </div>
              <div class="col-md-3">
                  Enabled
                  <p>
                    <span class="badge badge-secondary mr-3">{{ selectedUser.enabled }}</span>
                    <button class="btn btn-xs btn-danger" v-if="true === selectedUser.enabled" @click="disableUser();" >Disable</button>
                    <button class="btn btn-xs btn-success" v-if="false === selectedUser.enabled" @click="enableUser();" >Enable</button>
                  </p>
              </div>
            </div>
            <div class="form-group row" v-for="field, k in getUserEditAttrs()" :key="k">
              <label class="col-sm-2 col-form-label">{{ field }}</label>
              <div class="col-sm-10">
                <input v-if="'notifyQuoted' === k" type="checkbox" class="form-control" v-bind:class="{ disabled: !selectedUser.enabled }" :disabled="!selectedUser.enabled" v-model="selectedUser[k]">
                <select v-else-if="'role' === k" v-model="selectedUser[k]" class="form-control" v-bind:class="{ disabled: !selectedUser.enabled }" :disabled="!selectedUser.enabled">
                    <option v-for="rd, rv in roleMap()" :key="rv" :value="rv">{{ rd }}</option>
                </select>
                <input v-else type="text" class="form-control" v-bind:class="{ disabled: !selectedUser.enabled }" :disabled="!selectedUser.enabled" v-model.trim="selectedUser[k]">
              </div>
          </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" v-if="newUser" @click="createUser()">Create User</button>
            <button type="button" class="btn btn-primary" v-if="!newUser && selectedUser.enabled" @click="updateUser()">Save changes</button>
            <button type="button" class="btn btn-danger" v-if="!newUser && !selectedUser.enabled" @click="deleteUser()">Delete User</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="selectedUser=null;">Cancel</button>
          </div>
        </div>    
      </div>
    </div>
  </div>
</body>
</template>

<script>
import { getUserData } from '../Utils/auth.js';
import AgentNav from "../components/AgentNav.vue";
import axios from 'axios'

export default {
  name: 'users',
  props: {
  },
  components: {
    AgentNav
  },
  data: function () {
    return {
      users: [],
      user: {},
      selectedUser: null,
      newUser: false
    }
  },
  async mounted() {
    // prevent carriers and observers from seeing any user data
    if (this.isCarrierAdmin() || this.isObserver())
      this.$router.push({name: 'dashboard', params: {}});

    this.users = await this.listUsers();
    this.user = getUserData();

  },
  watch: {
  },
  methods: {
    listUsers: async function() {
      let ret;
        await axios.get('/api/users')
        .then((res) => {
          ret = res.data;
          ret.sort(function(a, b){ if(a.firstName < b.firstName) { return -1; } if(a.firstName > b.firstName) { return 1; } return 0; });
        })
        .catch((e) => { console.log(e); ret = false; });

        return ret;
    },
    selectUser: function(user) {
      this.newUser = false;
      this.selectedUser = user;
    },
    getUserEditAttrs: function() {
      return {'email': 'Email', 'firstName': 'First Name', 'lastName': 'Last Name', 'role': 'Role', 'owner': 'Owner', 'company': 'Company', 'licenseNumber': 'License Number', 'phoneNumber': 'Phone Number', 'notifyQuoted': 'Notify Quoted' };
    },
    roleMap: function(val) {
      const map = {'ROLE_CONSUMER': 'Consumer', 'ROLE_OBSERVER': 'Observer (only sees dashboard)', 'ROLE_USER': 'User', 'ROLE_AGENT': 'Agent', 'ROLE_UNDERWRITER': 'Underwriter', 'ROLE_ADMIN': 'Admin', 'ROLE_SUPER': 'Super Admin (only for Draftrs users)',};
      return (val) ? map[val] : map;
    },
    createUser: async function() {
      let ret;
      this.selectedUser.owner = this.selectedUser.owner.toLowerCase();
      this.selectedUser.company = this.selectedUser.company.toLowerCase();
      await axios.post('/api/users', this.selectedUser)
      .then((res) => {
        ret = res.data;
      })
      .catch((e) => { console.log(e); ret = false; });

      if (false !== ret) {
        this.newUser = false;
        this.selectedUser = ret;
        this.users = await this.listUsers();
      }

      return ret;
    },
    updateUser: async function() {
      let ret;
      this.selectedUser.owner = this.selectedUser.owner.toLowerCase();
      this.selectedUser.company = this.selectedUser.company.toLowerCase();
      await axios.put('/api/users/' + this.selectedUser.email, this.selectedUser)
      .then(() => { ret = true; })
      .catch((e) => { console.log(e); ret = false; });

      if (false !== ret) {
        this.newUser = false;
        this.users = await this.listUsers();
      }

      return ret;
    },
    enableUser: async function() {
      this.selectedUser.enabled = true;
      let ret;
      await axios.put('/api/users/' + this.selectedUser.email + '/enable', {})
      .then(() => {
        ret = true;
      })
      .catch(() => { ret = false; });
      
      return ret;
    },
    disableUser: async function() {
      this.selectedUser.enabled = false;
      let ret;
      await axios.put('/api/users/' + this.selectedUser.email + '/disable', {})
      .then(() => {
        ret = true;
      })
      .catch(() => { ret = false; });
      
      return ret;
    },
    deleteUser: async function() {
      let ret;
      await axios.delete('/api/users/' + this.selectedUser.email, {})
      .then(() => {
        ret = true;
      })
      .catch(() => { ret = false; });
      this.users = await this.listUsers();
      
      return ret;
    }
  }
}
</script>

<style lang="scss">
  #users-tbl {
    cursor: pointer;
  }
</style>