<template>
    <div>
        <h3 v-if="!agencyBill" class="main-color text-center pb-3">{{ payHeader }} <span v-if="quote.state === 'MI'">ACH</span></h3>
        <div class="info-form text-center pb-3">
            <!-- <div v-if="agencyBill" class="form-check form-check-inline mx-3">
                <input class="big-radio" type="radio" v-model="payMethod" value="agencyBill" name="payMethod" id="pay-method-agencyBill" @click="prepStripe('agencyBill')">
                <label class="main-color form-check-label cursor-pointer text-120 text-left" for="pay-method-agencyBill">
                    Agency Bill
                    <div class="text-muted text-60 pt-1">
                        (payment via agency)
                    </div>
                </label>
            </div> -->
            <div v-if="!agencyBill" class="form-check form-check-inline mx-3" :class="quote.state === 'MI' ? 'd-none' : ''">
                <input :disabled="payError" class="big-radio" type="radio" v-model="payMethod" value="cc" name="payMethod" id="pay-method-cc" @click="prepStripe('cc')">
                <label class="main-color form-check-label cursor-pointer text-120 text-left" for="pay-method-cc">
                    Credit Card
                    <div class="text-muted text-60 pt-1">
                        (additional {{ ccSurcharge }}% charge)
                    </div>
                </label>
            </div>
            <div v-if="!agencyBill" class="form-check form-check-inline mx-3">
                <input :disabled="payError" class="big-radio" type="radio" v-model="payMethod" value="ach" name="payMethod" id="pay-method-ach" @click="prepStripe('ach')">
                <label class="main-color form-check-label cursor-pointer text-120 text-left" for="pay-method-ach">
                    Bank ACH 
                    <div v-if="quote.state !== 'MI'" class="text-muted text-60 pt-1">
                        (no additional charge)
                    </div>
                </label>
            </div>
        </div>

        <div class="alert alert-danger border border-danger" v-if="payError">
            <i class="fas fa-exclamation-triangle"></i> Something happened with payment processing, please verfiy your card details and try again.
        </div>
        
            <div v-if="payMethod === 'cc'" class="info-form px-3" >
                
                <h4 class="py-4 text-center" >
                    Total Due 
                    <span v-if="payAmount" >{{ payAmount | currency }}</span>
                    <span v-else ><i class="ml-3 fas fa-spinner fa-pulse mr-1"></i></span>
                    <i v-if="payAmount" v-b-tooltip.html :title="getPayTooltip()" class="ml-1 far fa-question-circle text-muted"></i>
                </h4>

                <h5 class="main-color mb-3 ml-4 text-center">Enter your credit card details.</h5>
                <form class="payment-form">
                    <div id="card-element"><!--Stripe.js injects the Card Element--></div>
                    <p id="card-error" class="text-danger mt-3 text-center" role="alert"></p>
                </form>
            </div>
            <div v-if="payMethod === 'ach'" class="info-form" >
                <h4 class="py-4 text-center" >
                    Total Due
                    <span v-if="payAmount" >{{ payAmount | currency }}</span>
                    <span v-else ><i class="ml-3 fas fa-spinner fa-pulse mr-1"></i></span>
                    <i v-if="payAmount" v-b-tooltip.html :title="getPayTooltip()" class="ml-1 far fa-question-circle text-muted"></i>
                </h4>
                <h6 class="main-color mb-3 ml-4">Enter your name, then click "Add Bank Details".</h6>
                <form class="payment-form">
                    <div id="ach-element">
                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <input type="text" :class="nameOnBank ? 'is-valid' : 'is-invalid'" v-model="nameOnBank" class="form-control" required>
                                <small class="form-text text-muted">Name On Bank Account</small>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <input type="text" :class="quote.email ? 'is-valid' : 'is-blank'" v-model="quote.email" class="form-control" required>
                                <small class="form-text text-muted">Email</small>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12 mb-3 text-center">
                                <button :disabled="!payAmount" id="stripe-pay-btn" @click.prevent="collectACH()" :class="canPay() ? 'btn-light border' : 'btn-primary'" class="btn">Add Bank Details</button>
                                
                            </div>
                        </div>
                    </div>
                    
                </form>
            </div>

        <div v-if="canPay()" class="ml-5 mt-5" >
            <div class="main-color pl-0 pb-2">
                <h5>Please read and check to acknowledge your consent to provide an e-signature on all policy applications / documents and to bind coverage.</h5>
                <p id="consent-error" class="text-120 text-danger" role="alert"></p>
            </div>
            

            <div v-if="inRenewGracePeriod()" class="purp-form-check text-left mb-4">
                <input id="check-consent" v-model="consentRenewalGracePeriod" value="true" @click="consent('renewalGracePeriod')" class="purp-check-input" type="checkbox" required>
                <label for="check-consent" class="cursor-pointer pt-2 ml-3">
                    <small >
                        <b>This policy has already expired but is in the renewal grace period.</b> By checking this box you are further warranting that you do not have knowledge of any claims or potential circumstances that could lead to a claim under this policy
                    </small>
                </label>
            </div>

            <div class="purp-form-check text-left">
                <input id="check-consent" v-model="quote.consent" @click="consent('warrant')" class="purp-check-input" type="checkbox" required>
                <label for="check-consent" class="cursor-pointer pt-2 ml-3">
                    <small>
                        By checking this box, you warrant that you are an authorized representative of &quot;{{ insured }}&quot; and that the information provided in this 
                        <a href="#a" :disabled="appSpinner" style="width: 140px" title="Open PDF in new tab" @click.prevent="openAppDoc('application')">
                            <i tabindex="0"
                                v-b-tooltip.hover 
                                title="Open your quote application as a pdf in tab/window"
                            >
                            <span class="text-nowrap"><i v-if="appSpinner" class="fas fa-spinner fa-pulse"></i> Application</span>
                            </i>
                        </a>
                        is true to the best of the applicants knowledge.
                    </small>
                </label>
            </div>
            <div class="purp-form-check text-left mt-4">
                <input id="check-consent-carrier" v-model="quote.consentCarrier" @click="consent('disclosures')" class="purp-check-input" type="checkbox" required>
                <label for="check-consent-carrier" class="cursor-pointer pt-2 ml-3">
                    <small v-if="quote.lob === 'mpl'">
                        By checking this box you are signing the insurance 
                        <a href="#a" :disabled="appSpinner" style="width: 140px" title="Open PDF in new tab" @click.prevent="openAppDoc('application')">
                            <i tabindex="0"
                                v-b-tooltip.hover 
                                title="Open your quote application as a pdf in tab/window"
                            >
                            <span class="text-nowrap"><i v-if="appSpinner" class="fas fa-spinner fa-pulse"></i> Application</span>
                            </i>
                        </a> and the insurance carrier&quot;s  
                        <a href="#a" :disabled="appSpinner" style="width: 140px" title="Open PDF in new tab" @click.prevent="openAppDoc('application-mpl-supplement')">
                            <i tabindex="0"
                                v-b-tooltip.hover 
                                title="Open your quote application as a pdf in tab/window"
                            >
                            <span class="text-nowrap"><i v-if="appSpinner" class="fas fa-spinner fa-pulse"></i> Representation Statement</span>
                            </i>
                        </a>.
                    </small>
                    <small v-else>
                        By checking this box you are acknowledging the insurance carriers
                        <ul class="fa-ul pl-4 ml-0 mt-3" >
                            <li>
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a href="/legal/Markel_EDSC%20and%20Fraud.docx" target="_blank">State Fraud Statements and Electronic Delivery and Signature Consent Disclosure</a>.
                            </li>
                            <li v-if="quote.state === 'AR'" class="mt-2">
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a  href="/legal/MPAE.6200-AR.08.21.pdf" target="_blank">PolicyHolder Notice</a>
                            </li>
                            <li v-if="quote.state === 'CA'" class="mt-2">
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a  href="/legal/MPIL.1151-CA.08.21.pdf" target="_blank">Policyholder Notice - CA Premium Refund Notice</a>
                            </li>
                            <li v-else-if="quote.state === 'MN'" class="mt-2">
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a href="/legal/MAE.4228-MN.08.21.pdf" target="_blank">PolicyHolder Notice</a>
                            </li>
                            <li v-else-if="quote.state === 'ND'" class="mt-2">
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a  href="/legal/MPAE.6200-ND.01.22.pdf" target="_blank">PolicyHolder Notice</a>
                            </li>
                            <li v-else-if="quote.state === 'NY'" class="mt-2">
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a href="/legal/MPIL.1106-NY.11.16.pdf" target="_blank">PolicyHolder Notice</a>
                            </li>
                            <li v-else-if="quote.state === 'RI'" class="mt-2">
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a href="/legal/MPAE.6200-RI.08.21.pdf" target="_blank">PolicyHolder Notice</a>
                            </li>
                            <li v-if="quote.state === 'CA'" class="mt-2">
                                <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                                <a  href="/legal/MPIL.1157-CA.05.23.PHN.pdf" target="_blank">Policyholder Notice - CA Fraud Warning</a>
                            </li>
                        </ul>
                    </small>
                </label>
            </div>
            <div v-if="payMethod === 'ach' && stripeStatus === 'requires_confirmation'" class="purp-form-check text-left mt-4">
                <input v-model="quote.consentACH" id="check-ach-payment" @click="consent('disclosures')" class="purp-check-input" type="checkbox" required>
                <label for="check-ach-payment" class="cursor-pointer pt-2 ml-3 text-muted">
                    <small>
                        <span class="text-70">
                        By checking this box, 
                        you authorize Draftrs to debit the bank account specified above for any amount owed for charges arising from your use of Draftrs’ services and/or purchase of products from Draftrs, 
                        pursuant to Draftrs’ website and terms, until this authorization is revoked. 
                        You may amend or cancel this authorization at any time by providing notice to Draftrs with 30 (thirty) days notice.
                        </span>
                    </small>
                </label>
            </div>

            <div v-if="hasBrokerOverride() && quote.manualBrokerFee > 0" class="purp-form-check text-left mt-4 mb-4">
                <input id="check-broker" v-model="consentBrokerFee" value="true" @click="consent('brokerFee')" class="purp-check-input" type="checkbox" required>
                <label for="check-broker" class="cursor-pointer pt-2 ml-3">
                    <small >
                        By checking this box you acknowledge and agree to the ${{ quote.manualBrokerFee >= 0 ? quote.manualBrokerFee : Math.abs(quote.rating.breakdowns.brokerFee) }} {{ getClientText(quote.owners[0]).casual }} agent fees. These fees are non-refundable and charged by the agent for their risk management services. These fees are not policy premiums and are not required by law. You further agree to appoint {{ getClientText(quote.owners[0]).casual }} as your broker of record to represent you competently and honestly.
                    </small>
                </label>
            </div>
        </div>
        <div>
            <div class="extra-margin text-center mt-4 pb-3" id="paybutton" v-if="!editingDisabled" >
                <button :disabled="!canPay() || !payAmount" id="stripe-pay-btn" @click.prevent="purchase()" :class="!canPay() ? 'invisible': 'cursor-pointer'" class="shadow-lg btn btn-success btn-lg" v-if="!issuing">
                    {{ agencyBill && payMethod == 'agencyBill' ? 'Submit Bind Request' : 'Complete Purchase' }}
                </button>
                <button class="btn btn-primary btn-lg" type="button" disabled v-if="issuing">
                    <span class="spinner-border spinner-border-sm mr-2 " role="status" aria-hidden="true"></span>
                    Processing. Please wait... 
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import {loadStripe} from '@stripe/stripe-js';
import VueScrollTo from 'vue-scrollto';
import { getClientText } from "@/Utils/themer";
import { getUserData } from "@/Utils/auth";
export default {
    name: "StripePayment",
    props: {
    payHeader: {
        type: String,
        default() {
        return 'Pay With';
        }
    },
    issueAction: {
        type: String,
        default() {
        return 'issue';
        }
    },
    erpMonths: {
        type: String,
        default() {
        return '';
        }
    },
    cancelDate: {
        type: String,
        default() {
        return '';
        }
    },
    agencyBill: {
        type: Boolean,
        default() {
            return false;
        }
    },
    quoteData: {
        type: Object,
        default() {
        return {};
        }
    },
    editingDisabled: {
        type: Boolean,
        default() {
        return false;
        }
    },
    token: {
        type: String,
        default() {
        return null;
        }
    }
    },
    data: function() {
    return {
        purchased: false,
        firstLoad: false,
        issuing: false,
        appSpinner: false,
        insured: 'Insured',
        clientSecret: null,
        stripeStatus: 'none',
        scrollPosition: null,
        pay: '',
        ccSurcharge: 1.7,
        payAmount: 0,
        payMethod: '',
        nameOnBank: '',
        consentRenewalGracePeriod: '',
        consentBrokerFee: '',
        brokerFee: 0,
        totalDeduct: 0,
        ccFee: 0,
        snapshotId: null,
        payError: false,
        quote: {},
        expirationGracePeriod: 7
    };
    },
    mounted() {
        this.quote = this.quoteData
        if (this.agencyBill) {
            this.payMethod = 'agencyBill';
        } else if (!Object.prototype.hasOwnProperty.call(this.quote, 'payMethod')) {
            this.payMethod = 'cc'
        } else {
            this.payMethod = this.quote.payMethod;
        }

        if (Object.prototype.hasOwnProperty.call(this.quote, 'nameOnBank')) {
            this.nameOnBank = this.quote.nameOnBank;
        }

        window.addEventListener('scroll', this.updateScroll);
        this.prepStripe(this.payMethod);
    },
    watch: {
        erpMonths: function() {
            this.prepStripe();
        },
        issuAction: function() {
            this.issuing = this.issueAction;
        },
        'quote.insured': function() {
            this.insured = this.quote.insured ? this.quote.insured : 'Insured';
        }
    },
    methods: {
        getClientText(broker = '') {
            return getClientText(broker);
        },
        getRole() {
            return getUserData().role;
        },
        hasBrokerOverride() {
            return (['issue', 'renew'].includes(this.issueAction) && this.brokerFee > 0 && this.quote.rating && this.quote.rating.breakdowns.defaultBrokerFee && this.payMethod !== 'agencyBill');
        },
        prepStripe: async function(type) {
            
            if (type==='agencyBill')
                this.payMethod = 'agencyBill';
            else if (type==='cc') {
                this.payMethod = 'cc'
            } else if (type==='ach') {
                this.payMethod = 'ach'
            }
            let stripePubKey = false;
            await axios.get('/api/config').then((res) => {
                stripePubKey = res.data.stripe_pk;
            });

            if (this.payMethod !== 'agencyBill')
                this.stripe = await loadStripe(stripePubKey);

            await this.paymentInit();

            
            // Stripe injects an iframe into the DOM for CC
            if (this.payMethod === 'cc') {
                var elements = this.stripe.elements();
                var style = {
                    base: {
                        color: "#32325d",
                        fontFamily: 'Arial, sans-serif',
                        fontSmoothing: "antialiased",
                        fontSize: "18px",
                        "::placeholder": {
                        color: "#32325d"
                        }
                    },
                    invalid: {
                        fontFamily: 'Arial, sans-serif',
                        color: "#fa755a",
                        iconColor: "#fa755a"
                    }
                };
                this.card = elements.create("card", { style: style });
                this.card.mount("#card-element");
                this.card.on("change", function (event) {
                    document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
                });
            } else if (this.payMethod === 'ach') {
                this.stripeStatus = 'none';
            }
        },
        collectACH: function() {
            if (!this.nameOnBank || !this.quote.email.length)
                return
            this.stripe.collectBankAccountForPayment(
            {
                clientSecret: this.clientSecret,
                params: {
                    payment_method_type: 'us_bank_account',
                    payment_method_data: {
                        billing_details: {name: this.nameOnBank, email: this.quote.email},
                    },
                },
            }).then((res) => { 
            // Handle result.error or result.paymentIntent
                this.stripeStatus = res.paymentIntent.status;
                if (this.canPay()) {
                    VueScrollTo.scrollTo('#paybutton', 2500, {easing: 'ease', offset: -35, cancelable: false})
                } else {
                    this.stripStatus = 'none';
                }
            
            });
        },
        purchase: async function() { 
            let els;
            let formEl = document.getElementById('form5');
            let payError = document.getElementById('pay-error');
            if (formEl) {
                els = formEl.getElementsByClassName('is-blank');
                Array.from(els).forEach(element => element.classList.add('is-invalid'));
            }
            if (payError) {
                if (els && els.length) {
                    this.formErrors = true;
                payError.classList.add('show')
                payError.classList.remove('collapse')
                            VueScrollTo.scrollTo('#pay-error', 2500, {easing: 'ease', offset: -35, cancelable: false})
                    return false;
                } else {
                    payError.classList.remove('show')
                    payError.classList.add('collapse')
                }
            }
            if (!this.quote.consent || !this.quote.consentCarrier || (this.payMethod === 'ach' && !this.quote.consentACH || (this.hasBrokerOverride() && !this.consentBrokerFee && this.quote.rating.breakdowns.brokerFee >= 0))) {
                document.getElementById('consent-error').innerHTML = 'You must consent to all below';
                return false;
            } else {
                document.getElementById('consent-error').innerHTML = '';
            }
            this.issuing = true;
            let billing = null;

            if (this.payMethod === 'agencyBill') {
                billing = this.payWithAgencyBill();
            } else if (this.payMethod === 'cc') {
                billing = await this.payWithCard(this.card);
            } else if (this.payMethod === 'ach') { // ach
                billing = await this.payWithACH();
            }

                
            if (billing && ['succeeded', 'processing', 'requires_action'].includes(billing.status)) {
                this.payError = false;
                if (this.payMethod === 'cc' && billing.id && billing.status === 'succeeded') {
                    this.$emit("onPaymentComplete", { success: true, billing: billing, payMethod: 'cc', nameOnBank: ''});
                } else if (this.payMethod === 'ach' && billing.id) {
                    if (['processing'].includes(billing.status)) {
                        this.$emit("onPaymentComplete", { success: true, billing: billing, payMethod: 'ach', nameOnBank: this.nameOnBank});
                    } else if (['requires_action'].includes(billing.status) && this.hasObject(billing, 'link')) { // ach micro deposit with link
                        this.$emit("onPaymentComplete", { success: true, billing: billing, payMethod: 'ach', nameOnBank: this.nameOnBank});
                    }
                } else if (this.payMethod === 'agencyBill') {
                    this.$emit("onPaymentComplete", { success: true, billing: billing, payMethod: 'agencyBill', nameOnBank: ''});
                }
            } else {
                this.payError = true;
                this.$emit("onPaymentComplete", { success: false, payMethod: this.payMethod, nameOnBank: (this.payMethod === 'ach' ? this.nameOnBank : '')});
                this.issuing = false;
            }

        },
        consent: async function(id) {
            await axios.get('/api/consent/'+id).then((res) => {
                if (!this.quote.consents) {
                    this.quote.consents = [];
                }
                let repl = false;
                for (let i in this.quote.consents) {
                    if (id === this.quote.consents[i].id) {
                        this.quote.consents[i] = res.data;
                        repl = true;
                        break;
                    }
                }
                if (false === repl) {
                    this.quote.consents.push(res.data);
                }
            });
        },
        getPayTooltip() {
            let pm = this.payMethod;
            let output = '';

            if (['issue', 'renew'].includes(this.issueAction)) {
                let brokerFee = this.quote.manualBrokerFee;
                let totalDeduct = this.totalDeduct;
                let taxes = this.quote.rating.breakdowns.taxFee ? this.quote.rating.breakdowns.taxFee : 0;
                let basePrem = (this.quote.premium ? this.quote.premium : this.quote.manualPremium) - taxes;

                if (pm === 'cc') {
                    output = this.formatCurrency(basePrem) + ' Policy Premium<br/>';
                    output += taxes ? this.formatCurrency(taxes) + ' Taxes<br/>' : '';
                    output += this.ccFee ? this.formatCurrency(this.ccFee) + ' Credit Card Fee<br/>' : '';
                    output += brokerFee ? this.formatCurrency(brokerFee) + ' Agents Fee' : '';
                    output += totalDeduct ? '-' + this.formatCurrency(totalDeduct) + ' Agent Commission' : '';
                } else if (pm === 'ach') {
                    output = 'no additional charge';
                    output = (brokerFee || taxes || totalDeduct) ? this.formatCurrency(basePrem) + ' Premium<br/>' : '';
                    output += taxes ? this.formatCurrency(taxes) + ' Taxes<br/>' : '';
                    output += brokerFee ? this.formatCurrency(brokerFee) + ' Agents Fee' : '';
                    output += totalDeduct ? '-' + this.formatCurrency(totalDeduct) + ' Agent Commission' : '';
                }
            } else if (this.issueAction === 'endorse') {
                if (pm === 'cc') {
                    let ccFee = this.payAmount * this.ccSurcharge/100;
                    let basePrem = this.payAmount - ccFee;
                    output =  this.formatCurrency(basePrem) + ' Premium <br/>';
                    output +=  this.formatCurrency(ccFee) + ' Credit Card Fee';
                } else if (pm === 'ach') {
                    output = 'no additional fees';
                }
            }

            return output;
        },
        formatCurrency: function(value) {
            var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD',});
            return formatter.format(value); // $2,500.00
        },
        paymentInit: async function(brokerFee = undefined) {

            if (brokerFee >= 0 ) {
                this.brokerFee = brokerFee;
                this.quote.manualBrokerFee = brokerFee;
            }

            this.snapshotId = this.$route.params.snapshotId;

            if (!this.quote.premium)
                return
                
            this.payAmount = 0;
            let cs = false;
            let eff = this.quote.endorseEffective;
            if (!eff) {
                eff = this.quote.effective;
            }
            if (!eff) {
                eff = moment().format("YYYY-MM-DD");
            }
            if ('renew' === this.issueAction) {
                eff = this.quote.effective;
            }
            let opts = {};
            if (this.token) {
                opts.headers = { "authentication": "Bearer " + this.token};
            }

            if (this.issueAction === 'renew' && !this.snapshotId)
                return;

            let rq = null;
            const _id = this.issueAction === 'renew' ? this.snapshotId : this.quote.id;
            if (this.issueAction === 'erp' && this.cancelDate && this.erpMonths)
                rq = {quoteId: _id, premium: this.quote.premium, ratingId: this.quote.ratingId, action: this.issueAction, effective: this.cancelDate , getPaymentIntent: true, addlData: {payMethod: this.payMethod, erpMonths: this.erpMonths, manualBrokerFee: this.quote.manualBrokerFee}};
            else
                rq = {quoteId: _id, premium: this.quote.premium, ratingId: this.quote.ratingId, action: this.issueAction, effective: eff, getPaymentIntent: true, addlData: {payMethod: this.payMethod, manualBrokerFee: this.quote.manualBrokerFee}};

            await axios.post('/api/payment', rq, opts).then((res) => {
                this.payAmount = res.data.amount / 100;
                this.brokerFee = res.data.brokerFee;
                this.totalDeduct = res.data && res.data.totalDeduct ? res.data.totalDeduct : 0;
                this.ccFee = res.data.ccFee ? res.data.ccFee : 0;

                if (this.issueAction === 'erp')
                    this.$emit("onERPPremium", {payAmount: this.payAmount, erpPremium: res.data.erp.premium})

                if (true === res.data.client_secret) {
                    cs = res.data;
                } else {
                    cs = res.data.client_secret;
                }
            });
            this.clientSecret = cs;
        },
        payWithAgencyBill: function() {
            var resp = false;

            if (this.payMethod === 'agencyBill') {
                resp = {       
                    id: 'n/a',
                    payment_type: 'agencyBill',
                    payment_method: 'agencyBill',
                    amount: this.payAmount * 100,
                    status: 'succeeded'
                }
            }

            return resp;
        },
        payWithCard: async function(card) {
            var resp = false;
            if (true === this.clientSecret.client_secret) {
                return this.clientSecret;
            }
            if (this.payMethod === 'cc') {
                await this.stripe.confirmCardPayment(this.clientSecret, {
                    payment_method: {
                        card: card
                    }
                }).then(function(result) {
                    if (result.error) {
                        var errorMsg = document.querySelector("#card-error");
                        errorMsg.textContent = result.error.message;
                        setTimeout(function() { errorMsg.textContent = "";}, 4000);
                        resp = false;
                    } else {
                        const base = result.paymentIntent;
                        
                        resp = {       
                            id: base.id,
                            payment_type: 'cc',
                            payment_method: base.payment_method,
                            amount: base.amount,
                            status: base.status
                        }
                    }
                });
            }

            return resp;
        },
        payWithACH: async function() {
            var resp = false;
            if (true === this.clientSecret.client_secret) {
                return this.clientSecret;
            }
            if (this.payMethod === 'ach') {
                await this.stripe.confirmUsBankAccountPayment(this.clientSecret).then(function(result) {
                    if (result.error) {
                        resp = false;
                    } else {
                        const base = result.paymentIntent;
                        resp = {       
                            id: base.id,
                            payment_type: 'ach',
                            payment_method: base.payment_method,
                            amount: base.amount,
                            status: base.status
                        }

                        if (base.status === 'requires_action' && base.next_action.type === 'verify_with_microdeposits') {
                            resp.link = base.next_action.verify_with_microdeposits.hosted_verification_url;
                        }

                    }
                });
            }
            return resp;
        },
        openAppDoc: async function(file) {
            let opts = {};
            if (this.token) {
                opts = { 'authentication': "Bearer " + this.token};
            }
            file = 'application-mpl-supplement' === file ? 'application-mpl-supplement' : 'application';
            let url = '/api/application/'+ file +'/'+this.quote.id;
            this.appSpinner = true;
            await axios({
                url: url, 
                method: 'GET',
                responseType: 'arraybuffer', // important
                headers: opts
            }).then((response) => {
                var file = new Blob([response.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.appSpinner = false;
            });
        },
        canPay: function() {

            if (this.quote.ofacData && this.quote.ofacData.pass) {
                if ((this.payMethod === 'ach' && this.stripeStatus === 'requires_confirmation') || this.payMethod === 'cc' || this.payMethod === 'agencyBill') {
                    if (this.issueAction === 'renew') {
                        return this.canRenew();
                    }

                    return true;
                }

            }
            return false;
        },
        canRenew: function() {
            if (this.issueAction === 'renew') {

                const expireGracePeriod = moment.utc(new Date(this.quote.renewalExpiration)).add(this.expirationGracePeriod, 'days');
                const now = moment.utc();
                return now.isBefore(expireGracePeriod);
            }

            return false;
        }, 
        inRenewGracePeriod: function() {
            if (this.issueAction === 'renew') {
                const expiredDate =  moment.utc(new Date(this.quote.renewalExpiration));
                const expireGracePeriod = moment.utc(new Date(this.quote.renewalExpiration)).add(this.expirationGracePeriod, 'days');
                const now = moment.utc();
                return now.isAfter(expiredDate) && now.isBefore(expireGracePeriod);
            }

            return false;
        }
    }
};
</script>

<style lang="scss">
#card-error {
    font-size: 24px;
}
.payment-form {
    border: 1px solid var(--main-color); 
    padding: 10px;
}
.purp-form-check {
  padding: 0;
}
.purp-check-input {
    height: 30px !important;
}
</style>
