import axios from "axios"
import {getUserData, loginUser} from "./auth"
import router from "../router"

export function convertStringsToIntegers(object) {
    let converted = {}
    for (let attr in object) {
        if (Object.hasOwn(object, attr) &&
            object[attr] !== null &&
            !isNaN(object[attr]) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(object[attr]))) {
            converted[attr] = parseFloat(object[attr])
        } else if (object[attr] === "") { // convert empty strings to null
            converted[attr] = null
        } else {
            converted[attr] = object[attr]
        }
    }
    return {...converted}
}

export async function saveQuote(data) {
    const userData = getUserData();
    let response = await axios.post('/api/quote', data)
    let quote = response.data
    if (!data.id) {
        for (let i in data) {
            quote[i] = data[i];
        }
        //update consumer token on first save
        if ('ROLE_CONSUMER' === userData.role) { // this method adds values to the user token
            await axios.post("/api/consumer/token", {client: userData.client, quoteId: quote.id, email: quote.email}).then(response => {
                if (response.data.token) {
                    loginUser(userData.client, quote.email, quote.token)
                }
            });
        }
    }
    quote = convertStringsToIntegers(quote)
    if (quote.id !== data.id) {
        await router.push('/quote/' + quote.id + '?lob=' + quote.lob)
    }
    return quote
}

export async function getQuoteData(id) {
    let response = await axios.get('/api/quote/' + id)
    return response.data
}

export async function getPremium(quote) {
    let rates = {};
    let res = await axios.post('/api/rating', {quote}).catch((err) => {
        console.error(err);
        return {error: err}
    });
    if (!res?.data) return;
    rates = {...res.data};
    if (!rates?.summary) {
        delete quote.rating;
        delete quote.ratingId;

        return quote.premium = 0;
    }
    quote.ratingId = rates.summary.id;
    quote.premium = rates.summary.premium;
    quote.rating = rates.summary;

    return {quote, rates: rates};
}