import Vue from 'vue';
import VueRouter from 'vue-router';
import Quote from '../views/Quote.vue';
import Quoteletter from '../views/Quoteletter.vue';
import PreviewPolicy from '../views/PreviewPolicy.vue';
// import Endorse from '../views/Endorse.vue';
// import Renew from '../views/Renew.vue';
import Login from '../views/Login.vue';
import SendAccess from '../views/SendAccess.vue';
import SendPayment from '../views/SendPayment.vue';
import Activate from '../views/Activate.vue';
import linkRouter from '../views/Link.vue';
import Users from '../views/Users.vue';
import Mail from '../views/Mail.vue';
import Sessions from '../views/Sessions.vue';
import Index from '../views/Index.vue';
import Accounting from '../views/Accounting.vue';
import Renewals from '../views/Renewals.vue';
// import CoveragePL from '../views/CoveragePL.vue';
// import CoverageGL from '../views/CoverageGL.vue';
import Assign from '../views/Assign.vue';
// import CoveragePLvsGL from '../views/CoveragePLvsGL.vue';
// import TermsDefinitions from '../views/TermsDefinitions.vue';
// import CoverageWhyPL from '../views/CoverageWhyPL.vue';
import ProfessionalLiabilityInsurance from '../views/ProfessionalLiabilityInsurance.vue';
import GeneralLiabilityInsurance from '../views/GeneralLiabilityInsurance.vue';
import DesignProfessionalsArchitectsInsurance from '../views/DesignProfessionalsArchitects.vue';
import DesignProfessionalsEngineersInsurance from '../views/DesignProfessionalsEngineers.vue';
import DesignProfessionalsInteriorDesignersInsurance from '../views/DesignProfessionalsInteriorDesigners.vue';
import DesignProfessionalsConstructionManagersInsurance from '../views/DesignProfessionalsConstructionManagers.vue';
import DesignProfessionalsLandSurveyorsInsurance from '../views/DesignProfessionalsLandSurveyors.vue';
import DesignProfessionalsTechnicalDesignConsultantsInsurance from '../views/DesignProfessionalsTechnicalDesignConsultants.vue';
import BuildingDesigners from '../views/BuildingDesigners.vue';

// MPL PAGES
import MPLConstructionDesignConsultants from '../views/MPLConstructionDesignConsultants.vue';
import MPLAdvertisingCreatives from '../views/MPLAdvertisingCreatives.vue';
import MPLRecruitersStaffing from '../views/MPLRecruitersStaffing.vue';
import MPLOtherProfessions from '../views/MPLOtherProfessions.vue';
import MPLBookkeepersTaxpreparers from '../views/MPLBookkeepersTaxpreparers.vue';

import PLTermsDefinitions from '../views/FrequentlyUsedTermsDefinitions.vue';

import DesignProfessionalsInsurance from '../views/DesignProfessionalsInsurance.vue';
import AccountantsSummary from '../views/AccountantsSummary.vue';
import LawyersSummary from '../views/LawyersSummary.vue';
import ConsultantsSummary from '../views/ConsultantsSummary.vue';
import Payment from '../views/Payment.vue';
import FAQ from '../views/FAQ.vue';
import WeInsureArchitects from '../views/WeInsureArchitects.vue';
import WeInsureEngineers from '../views/WeInsureEngineers.vue';
import WeInsureConstructionManagers from '../views/WeInsureConstructionManagers.vue';
import WeInsureLandSurveyors from '../views/WeInsureLandSurveyors.vue';
import WeInsureInteriorDesigners from '../views/WeInsureInteriorDesigners.vue';
import WeInsureTechnicalConsultants from '../views/WeInsureTechnicalConsultants.vue';
import AboutUs from '../views/AboutUs.vue';
import TermsOfService from '../views/Terms.vue';
import PrivacyPolicy from '../views/Privacy.vue';
import ContactUs from '../views/ContactUs.vue';
import Rating from '../views/Rating.vue';
import Rater from '../views/Rater.vue';
import AgentsPartners from '../views/AgentsPartners.vue';
import AgentsContactUs from '../views/AgentsContactUs.vue';
import FileClaim from '../views/FileClaim.vue';
import Dashboard from '../views/Dashboard.vue';
import Recover from '../views/Recover.vue';
import Policy from '../views/Policy.vue';
import Cancel from '../views/Cancel.vue';
import PurchaseERP from '../views/PurchaseERP.vue';
import Version from '../views/Version.vue';
import Documents from '../views/Documents.vue';
import StartQuote from '../views/StartQuote.vue';
import UserGuide from '../views/UserGuide.vue';
import Press from '../views/Press.vue';
import Sso from '../views/Sso.vue';
import draftrsandshinglepartner from '../views/press/draftrs-and-shingle-partner.vue';
import draftrslaunches48states from '../views/press/draftrs-launches-48-states.vue';
import draftrslaunchesnewdigitalprofessionalliabilityinsuranceplatform from '../views/press/draftrs-launches-new-digital-professional-liability-insurance-platform.vue';
import draftrs_automating_professional_liability_insurance_underwriting_binding_servicing from '../views/press/draftrs-automating-professional-liability-insurance-underwriting-binding-servicing.vue';
import aibd from '../views/press/draftrs-partners-with-the-american-institute-of-building-design.vue';
import nacm from '../views/press/draftrs-partners-with-the-national-association-of-certified-mediators.vue';
import Blog from '../views/Blog.vue';
import OpenAPI from '../views/OpenAPI.vue';
import {getUserData, isLoggedIn, logoutUser} from '../Utils/auth.js';
import PolicyEdit from "@/views/PolicyEdit.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: "/home",
        name: "home",
        component: Index,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "",
        name: "index",
        component: Index,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance",
        name: "professional-liability-insurance",
        component: ProfessionalLiabilityInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/general-liability-insurance",
        name: "general-liability-insurance",
        component: GeneralLiabilityInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/design-professionals-insurance",
        name: "design-professionals-insurance",
        component: DesignProfessionalsInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance-architects",
        name: "professional-liability-insurance-architects",
        component: DesignProfessionalsArchitectsInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance-engineers",
        name: "professional-liability-insurance-engineers",
        component: DesignProfessionalsEngineersInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance-interior-designers",
        name: "professional-liability-insurance-interior-designers",
        component: DesignProfessionalsInteriorDesignersInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance-construction-managers",
        name: "professional-liability-insurance-construction-managers",
        component: DesignProfessionalsConstructionManagersInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance-land-surveyors",
        name: "professional-liability-insurance-land-surveyors",
        component: DesignProfessionalsLandSurveyorsInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance-technical-design-consultants",
        name: "professional-liability-insurance-technical-design-consultants",
        component: DesignProfessionalsTechnicalDesignConsultantsInsurance,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/professional-liability-insurance-building-designers",
        name: "professional-liability-insurance-building-designers",
        component: BuildingDesigners,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/Insurance-Frequently-Used-Terms-Definitions",
        name: "Insurance-Frequently-Used-Terms-Definitions",
        component: PLTermsDefinitions,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/accountantssummary",
        name: "accountantssummary",
        component: AccountantsSummary,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/lawyerssummary",
        name: "lawyerssummary",
        component: LawyersSummary,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/consultantssummary",
        name: "consultantssummary",
        component: ConsultantsSummary,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/weinsurearchitects",
        name: "weinsurearchitects",
        component: WeInsureArchitects,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/weinsureengineers",
        name: "weinsureengineers",
        component: WeInsureEngineers,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/weinsureconstructionmanagers",
        name: "weinsureconstructionmanagers",
        component: WeInsureConstructionManagers,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/weinsurelandsurveyors",
        name: "weinsurelandsurveyors",
        component: WeInsureLandSurveyors,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/weinsureinteriordesigners",
        name: "weinsureinteriordesigners",
        component: WeInsureInteriorDesigners,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/weinsuretechnicalconsultants",
        name: "weinsuretechnicalconsultants",
        component: WeInsureTechnicalConsultants,
        meta: {
            allowAnonymous: true
        }
    },

    // MPL PAGES
    {
        path: "/construction-design-consultants",
        name: "construction-design-consultants",
        component: MPLConstructionDesignConsultants,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/advertising-creatives",
        name: "advertising-creatives",
        component: MPLAdvertisingCreatives,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/recruiters-staffing",
        name: "recruiters-staffing",
        component: MPLRecruitersStaffing,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/bookkeepers-taxpreparers",
        name: "bookkeepers-taxpreparers",
        component: MPLBookkeepersTaxpreparers,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/other-professions",
        name: "other-professions",
        component: MPLOtherProfessions,
        meta: {
            allowAnonymous: true
        }
    },

    {
        path: "/faq",
        name: "faq",
        component: FAQ,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/payment/:token",
        name: "payment",
        component: Payment,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/aboutus",
        name: "aboutus",
        component: AboutUs,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/termsofservice",
        name: "termsofservice",
        component: TermsOfService,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/privacypolicy",
        name: "privacypolicy",
        component: PrivacyPolicy,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/contactus",
        name: "contactus",
        component: ContactUs,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/press",
        name: "press",
        component: Press,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/press/draftrs-and-shingle-partner",
        name: "draftrs-and-shingle-partner",
        component: draftrsandshinglepartner,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/press/draftrs-launches-48-states",
        name: "draftrs-launches-48-states",
        component: draftrslaunches48states,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/press/draftrs-launches-new-digital-professional-liability-insurance-platform",
        name: "draftrs-launches-new-digital-professional-liability-insurance-platform",
        component: draftrslaunchesnewdigitalprofessionalliabilityinsuranceplatform,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/press/draftrs-automating-professional-liability-insurance-underwriting-binding-servicing",
        name: "draftrs-automating-professional-liability-insurance-underwriting-binding-servicing",
        component: draftrs_automating_professional_liability_insurance_underwriting_binding_servicing,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/press/draftrs-partners-with-the-american-institute-of-building-design",
        name: "draftrs-partners-with-the-american-institute-of-building-design",
        component: aibd,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/press/draftrs-partners-with-the-national-association-of-certified-mediators",
        name: "draftrs-partners-with-the-national-association-of-certified-mediators",
        component: nacm,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/blog/:titleUrl?",
        name: "blog",
        component: Blog,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/openapi",
        name: "openapi",
        component: OpenAPI,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/getaquote", // using getaquote as an easily toggaleable link between contactus or quote when it changes
        name: "getaquote",
        component: StartQuote,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/agentspartners",
        name: "agentspartners",
        component: AgentsPartners,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/agentscontactus",
        name: "agentscontactus",
        component: AgentsContactUs,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/fileclaim/:customerId?",
        name: "fileclaim",
        component: FileClaim,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/recover",
        name: "recover",
        component: Recover,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/version",
        name: "version",
        component: Version,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/sso",
        name: "sso",
        component: Sso,
        meta: {
            allowAnonymous: true
        }
    },
    {
      path: "/admin/rating/:quoteId?",
      name: "rating",
      component: Rating,
      meta: {
          allowAnonymous: true
      }
    },
    {
      path: "/admin/rater",
      name: "rater",
      component: Rater,
      meta: {
          allowAnonymous: true
      }
    },
    { // this is for a consumer redirect draftrs.com/quote to start a new quote for a consumer
        path: "/quote",
        name: "quote",
        component: Quote,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/quote/:id?/:policyId?/:customerId?",
        name: "quote",
        component: Quote
    },
    {
        path: "/endorse/:id?/:snapshotId?",
        name: "endorse",
        component: Quote
    },
    {
        path: "/renew/:id?/:snapshotId?",
        name: "renew",
        component: Quote
    },
    {
        path: "/qtltr/:id?",
        name: "quoteletter",
        component: Quoteletter,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/prvplc/:id?",
        name: "previewPolicy",
        component: PreviewPolicy,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/sendAccess/:quoteId?",
        name: "sendAccess",
        component: SendAccess
    },
    {
        path: "/sendPayment/:quoteId?",
        name: "sendPayment",
        component: SendPayment
    },
    {
        path: "/start",
        name: "start",
        component: StartQuote,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/userguide",
        name: "userguide",
        component: UserGuide,
        meta: {
            role: 'ROLE_SUPER'
        }
    },
    {
        path: "/insured/:customerid/:id",
        name: "insured",
        component: PolicyEdit
    },
    {
        path: "/policy/:customerid?",
        name: "policy",
        component: Policy
    },
    {
        path: "/cancel/:customerid/:id",
        name: "cancel",
        component: Cancel
    },
    {
        path: "/purchaseerp/:customerid/:id",
        name: "purchaseerp",
        component: PurchaseERP
    },
    {
        path: "/documents/:id/:customerid",
        name: "documents",
        component: Documents
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard
    },
    {
        path: "/assign/:quoteId",
        name: "assign",
        component: Assign
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/activate/:code/:email",
        name: "activate",
        component: Activate,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/link/:token",
        name: "linkRouter",
        component: linkRouter,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/admin/users",
        name: "users",
        component: Users,
        meta: {
            role: 'ROLE_SUPER'
        }
    },
    {
        path: "/admin/mail",
        name: "mail",
        component: Mail,
        meta: {
            role: 'ROLE_SUPER'
        }
    },
    {
        path: "/admin/sessions",
        name: "sessions",
        component: Sessions,
        meta: {
            role: 'ROLE_SUPER'
        }
    },
    {
        path: "/admin/accounting",
        name: "accounting",
        component: Accounting,
        meta: {
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: "/admin/renewals",
        name: "renewals",
        component: Renewals,
        meta: {
            role: 'ROLE_ADMIN'
        }
    },
    {
        path: "/logout",
        name: "logout",
        component: {
            beforeRouteEnter(to, from, next) {
                logoutUser();
                next({path: ''});
            }
        }
    },
    {
        path: "/consumertoagent",
        name: "consumertoagent",
        component: {
            beforeRouteEnter(to, from, next) {
                logoutUser();
                next({path: '/login'});
            }
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name == '' && isLoggedIn()) {
        next({path: '/login'})
    } else if (to.name == 'login' && isLoggedIn()) {
        next({path: '/'})
    } else if (to.meta.role && ['ROLE_SUPER', 'ROLE_ADMIN'].includes(to.meta.role) && isLoggedIn()) { // ACL FOR ROLE_SUPER & ROLE_ADMIN
        const userRole = getUserData().role;

        if (to.meta.role == 'ROLE_SUPER' && userRole == 'ROLE_SUPER')
            next()
        else if (to.meta.role == 'ROLE_ADMIN' && ['ROLE_SUPER', 'ROLE_ADMIN'].includes(userRole))
            next()
        else {
            next({
                path: '/dashboard',
                query: {redirect: to.fullPath}
            })
        }
    } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
        next({
            path: '/login',
            query: {redirect: to.fullPath}
        })
    } else {
        next()
    }
})

export default router