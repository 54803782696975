<script setup>
import {ref, watch} from "vue"
import {useElementVisibility} from "@vueuse/core"

let props = defineProps({
            context: {
                type: Object,
                required: true
            }
        })
let premiumRoot = ref(null)
let isVisible = useElementVisibility(premiumRoot)
watch(isVisible, (visible) => {
    if(visible) props.context.rootEmit('action', 'getPremium')
})
</script>

<template>
    <div ref="premiumRoot" class="col-md-12">{{ context.model }}</div>
</template>
