<script setup>
import {computed} from "vue"

let props = defineProps({
    context: {
        type: Object
    },
    value: {
        type: [String, Number, null],
        default: null
    },
    label: {
        type: [String, null],
        default: null
    },
    sublabel: {
        type: [String, null],
        default: null
    },
    tooltip: {
        type: [String, null],
        default: null
    },
    optionsDisabled: {
        type: [Object, Boolean],
        default: () => {
            return null
        }
    },
    list: {
        type: Array,
        default: () => {
            return []
        }
    },
    schema: Object
})
let emit = defineEmits('input')
let model = computed({
    get: () => {
        return props.context?.model ?? (props.value === 1 ? true : props.value === 0 ? false : null)
    },
    set: (answer) => {
        // eslint-disable-next-line
        if (props.context) props.context.model = answer
        emit('input', answer === true ? 1 : 0)
    }
})
let sublabel_ = computed(() => {
    if (props.sublabel) return props.sublabel
    return props.schema?.sublabel || null
})
let tooltip_ = computed(() => {
    if (props.tooltip) return props.tooltip
    return props.schema?.tooltip || null
})
let optionsDisabled_ = computed(() => {
    if (props.optionsDisabled) return props.optionsDisabled
    return props.schema?.optionsDisabled || null
})
let list_ = computed(() => {
    if (props.list) return props.list
    return props.schema?.list || null
})
</script>

<template>
    <div class="boolean-question">
        <label v-if="label" class="text-left" :class="model === null ? 'is-blank' : ''">{{ label }}</label>
        <p v-if="sublabel_" class="mb-3">{{ sublabel }}</p>
        <i v-if="tooltip_"
           tabindex="0"
           v-b-tooltip.hover
           :title="tooltip_"
           class="far fa-question-circle main-color">
        </i>
        <div v-if="list_.length" class="row py-2">
            <div v-for="(row, idx) in list" :class="'col-md-' + (Math.floor(12 / list.length))" :key="idx">
                <ul class="fa-ul">
                    <li v-for="item in row" class="pb-2" :key="item"><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>{{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="choice-area">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <label v-for="(val, label) in {'Yes': true, 'No': false}" class="btn btn-secondary" :key="val"
                       :class="val === model ? 'active' : (model === null ? 'is-blank' : '')"
                >
                    <input
                            type="radio"
                            v-model="model"
                            name="options"
                            :value="val"
                            :disabled="optionsDisabled_"
                    >
                    <i class="fas fa-check-circle main-color mr-1"></i>{{ label }}
                </label>
            </div>
        </div>
    </div>
</template>
