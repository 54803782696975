<script setup>
import {getClientLogo, getClientDomain, getClientText, getStyle} from "@/Utils/themer.js"

defineProps({
    purchased: Boolean,
    quote: Object,
    idx: Number,
    issueAction: String,
    lock: Boolean
})
const emit = defineEmits(['navigate'])
const sideBarChangeTab = (tab) => {
    emit('navigate', tab)
}
</script>

<template>
    <div class=" d-none d-md-block bg-light rounded m-2 h-100 shadow">
        <div class="text-center" v-if="getRole() === 'ROLE_CONSUMER'">
            <a v-if="getClientDomain() !== 'draftrs'" class="navbar-brand mr-0" href="/dashboard"><img :src="getClientLogo()" alt=""></a>
            <div v-if="getClientText().phone.length > 0" class="pt-1" :class="getClientText().phone.length < 5 ? 'd-none': ''" style="font-size: 10pt">
                {{ getClientText().phone }}
            </div>
        </div>
        <div class="fs-nav" v-if="!purchased">
            <ul>
                <li v-if="quote.status === 'issued'"><a href="javascript:close()" class="main-color"><i class="fas fa-chevron-left mr-2"></i>Back To My Policy</a></li>
                <li><a href="#" @click="sideBarChangeTab(0)" :class="{ active: 0 === idx }"><span>1</span>Start</a></li>
                <li><a href="#" @click="sideBarChangeTab(1)" :class="{ active: 1 === idx }"><span>2</span>Company</a></li>
                <li><a href="#" @click="sideBarChangeTab(2)" :class="{ active: 2 === idx }"><span>3</span>Details</a></li>
                <li><a href="#" @click="sideBarChangeTab(3)" :class="{ active: 3 === idx }"><span>4</span>Quote</a></li>
                <li v-if="!lock && quote.premium">
                    <a href="#" @click="sideBarChangeTab(4)" :class="{ active: 4 === idx }"><span>5</span>{{ issueAction === 'renew' ? 'Renew Policy' : 'Purchase' }}</a>
                </li>
            </ul>
        </div>
        <div class="fs-nav" v-else>
            <ul>
                <li v-if="issueAction === 'issued'"><a href="#" class="active">Quote Purchased</a></li>
                <li v-if="issueAction === 'endorse'"><a href="#" class="active">Policy Endorsed</a></li>
            </ul>
        </div>
        <div>
            <div class="mt-3 pt-5 pl-5 text-90">
                <h5>Have questions?</h5>
                <p>Ask one of our insurance advisors.</p>
                Email: <a href="mailto:submissions@draftrs.com">submissions@draftrs.com</a>
            </div>
            <div class="pt-1 pl-5 text-90">
                Call: +1 214 210 3476
            </div>
        </div>
        <div class="text-center text-muted" v-if="getClientDomain() === 'grange'">
            <div class="mt-3 pt-5 text-90">
                <b>Agency Name</b><br/>
                {{ getStyle('agencyName') }}
            </div>
            <div class="pt-4 text-90">
                <b>Agency Code</b><br/>
                {{ getStyle('agencyCode') }}
            </div>
        </div>
    </div>
</template>