<template>
  <div class="text-md-start" v-if="!sent">
    <button
      class="text-button text-white pt-2 mb-3 pb-4"
      @click="showForm = showForm ? 0 : 1"
    >
      Have an existing policy?
    </button>

    <form @submit.prevent="findAccount" id="existing-policy-form" :class="showForm ? 'visible' : 'invisible'">
      <div class="text-white pb-1"
        >We will email you a login link to access your policy.</div
      >
      <div class="input-group mt-1">
        <input
          type="email"
          class="form-control border-radius-30"
          placeholder="Enter your email address"
          v-model="email"
        />

        <button
          class="btn btn-secondary position-absolute"
          type="submit"
          id="existing-policy-submit-btn"
        >
          <i v-if="spinner" class="fas fa-spinner fa-pulse mr-1"></i> {{ spinner ? 'Sending' : 'Submit' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  const axios = require('axios');
  import { getClientDomain } from "../Utils/themer.js";

  export default {
    name: "RecoverComponent",
    mounted () {
    },
    data: function() {
      return {
        email: null,
        spinner: false,
        sent: false,
        showForm: 0
      };
    },
    methods: {
      async findAccount(e) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.email.toLowerCase().match(regex)) {
          e.preventDefault();
          this.spinner = true;
          
          await axios.post('/api/recover', { email: this.email, client: getClientDomain() })
          .then((res) => {
            this.spinner = false;

            if (res.data.success == true) {
              this.$toast.open({ 
                message: 'An email has been sent to <strong>'+ this.email +'</strong> with instructions to access your policy.',
                type: 'success',
                dismissible: true,
                duration: 100000,
                position: 'top-right'
              });
            } else {
              this.$toast.open({ 
                message: 'Oops, we could not find your email '+ this.email +'. Please contact your broker for assistance.',
                type: 'warning',
                dismissible: true,
                duration: 100000,
                position: 'top-right'
              });
            }

            setTimeout(() => { this.email = ''; this.sent = true; }, 3000);
          })
          .catch(error => {
            this.spinner = false;
            this.$toast.open({ 
              message: 'We are having a problem verifying your email address. Please check that it is correct.',
              type: 'warning',
              dismissible: true,
              duration: 100000,
              position: 'top-right'
            });
            console.log(error)
          })
          
        }
      }
    },
  };
</script>

<style lang="scss">
</style>
