<script setup>

import * as XLSX from 'xlsx';
import {ref} from "vue"
let props = defineProps({
    context: {
        type: Object,
        required: true
    },
    repetition: {
        type: String,
        default: ''
    }
})
let emit = defineEmits(['action'])
let sheet = ref(1)
let onChange = function (event) {
    let files = event.target.files, f = files[0]
    let reader = new FileReader()
    reader.onload = function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, {type: 'array'});
        let sheetName = workbook.SheetNames[sheet.value - 1]
        let worksheet = workbook.Sheets[sheetName]
        let json = XLSX.utils.sheet_to_json(worksheet)
        emit('action', 'imported', {repetition: props.repetition, json})
    }
    reader.readAsArrayBuffer(f);
}
</script>

<template>
    <div :class="`formulate-input-element formulate-input-element--${context.class} align-top`" :data-type="context.type">
<!--        <label for="sheet-number">Sheet Number</label>-->
<!--        <input type="number" v-model="sheet" class="fixed-width-50 align-top" id="sheet-number">-->
        <input type="file" @change="onChange" />
    </div>
</template>