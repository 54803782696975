<template>
    <div id="wrapper">
        <StandardNav/>
        <main id="main" role="main">
            <section class="lab-area">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="upper-text">
                                <h1>Architects Professional<br> 
                                    Liability Insurance</h1>
                                <p>
                                    Architects pofessional liability insurance for claims<br> against you arising out of your professional<br> services.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="img-arae">
                                <img src="/images/wo.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="block">
                                <h2>Claims Scenarios</h2>
                                <p>A building inspector finds multiple code violations including inadequate disabled accessibility in an office building. The building owner sues the architect for additional construction costs to build to code and for loss of rental income as a result of delayed building opening.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="block">
                                <h2>Coverage Highlights</h2>
                                <ul>
                                    <li>Defense costs, policy provides defense if you get sued</li>
                                    <li>Covers claims for bodily injury, property damage and financial loss</li>
                                    <li>Additional expense reimbursements for regulatory and administrative actions and professional licensing and disciplinary hearings</li>
                                    <li>Pre-claims reporting and subpeona assistance</li>
                                </ul>
                            </div>
                            <div class="btn-box">
                                <a :href="loggedIn() ? '/Quote' : '/ContactUs'" class="btn btn-get">Get a Quote Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>
import { isLoggedIn, } from "../Utils/auth.js";
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "Index",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {};
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
    loggedIn: function() {
      return isLoggedIn();
    },
  },
};
</script>

<style lang="scss">

</style>
