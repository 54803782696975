<template>
<body>
    <div id="wrapper">
      <AgentNav/>

      <nav aria-label="breadcrumb" class=" d-md-block mb-0">
          <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard" >Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="Accounting">Accounting</li>
          </ol>
      </nav>
        <div class="card card-radius shadow mt-2 mb-5 mx-3">
          <div class="card-body">
            <div v-if="spinners.data" style="height: 0px; z-index: 200; position: fixed; top: 35%; left: 47.5%" >
                <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>


            <!-- filters -->
            <div class="text-muted row" id="accountingSummary">
              <div class="col">
                <div class="form-inline mb-2">
                  <label>From </label>
                  <b-form-datepicker
                      no-flip
                      today-button
                      reset-button
                      close-button
                      reset-value=""
                      no-close-on-select
                      v-model="fromDt"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      type="text"
                      placeholder="MM/DD/YYYY"
                      class="ml-1 col-3"
                  >
                  </b-form-datepicker>
                  <input v-model="owner" v-b-tooltip.hover title="owner like: draftrs or rpsins, riskprous" class="form-control col-md-1 ml-2 mr-2" placeholder="all"/>
                  <a class="btn btn-outline-secondary btn-sm" @click="updateTransactionList()">Filter</a>
                  <h4 class="pt-2 ml-5 text-success font-weight-bold">{{ transactions.length }}  results</h4>
                   
                </div>
              </div>
              <div class="float-right pr-4">
                    <input v-if="getOwner() == 'draftrs'" type="checkbox" value="true" id="checkbox" v-model="raw">  <label for="checkbox"><b>Show Raw Data</b></label>
                  </div>
            </div>
            <div class="pl-5">
              <a class="btn btn-primary btn-md my-3" :disabled="spinners.bordereau" @click="downloadBordereau()" v-if="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole()) && getOwner() == 'draftrs'">
                <i v-if="spinners.bordereau" class="fas fa-spinner fa-pulse"></i>
                <i v-else class="fas fa-solid fa-file-excel"></i>
                PL Bordereau
              </a>
              <a class="btn btn-primary btn-md my-3 mx-3" :disabled="spinners.bordereau" @click="downloadBordereau('mpl')" v-if="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole()) && getOwner() == 'draftrs'">
                <i v-if="spinners.bordereau" class="fas fa-spinner fa-pulse"></i>
                <i v-else class="fas fa-solid fa-file-excel"></i>
                MPL Bordereau
              </a>
              <a class="btn btn-primary btn-md my-3 mx-3" :disabled="spinners.bordereau" @click="downloadBordereau('wk_mpl')" v-if="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole()) && getOwner() == 'draftrs'">
                <i v-if="spinners.bordereau" class="fas fa-spinner fa-pulse"></i>
                <i v-else class="fas fa-solid fa-file-excel"></i>
                Prior Week MPL Bdx
              </a>
              <a class="ml-3 btn btn-primary btn-md my-3" :disabled="spinners.accounting" @click="downloadAccounting()" v-if="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole())">
                <i v-if="spinners.accounting" class="fas fa-spinner fa-pulse"></i>
                <i v-else class="fas fa-solid fa-file-excel"></i>
                Agent Report
              </a>
            </div>
            <table class="table table-sm table-bordered table-responsive" style="min-height: 600px" id="transactions-tbl">
              <thead>
                <tr>
                  <th scope="col">Accounting Period</th>
                  <th scope="col">Insurance Paper Name</th>
                  <th scope="col">Policy Number</th>
                  <th scope="col">Policy Name</th>
                  <th scope="col">Policy Effective Date</th>
                  <th scope="col">Policy Expiry Date</th>
                  <th scope="col">Transaction Effective Date</th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Total Paid</th>
                  <th scope="col">Policy Premium</th>
                  <th scope="col">Risk State</th>
                  <th scope="col">Pay Method</th>
                  <th scope="col">Owner</th>
                  <th scope="col">Total Stripe Fees</th>
                  <th scope="col">Fees Paid by Insured</th>
                  <th v-if="getOwner() == 'draftrs'" scope="col">Carrier Payment</th>
                  <th scope="col">Taxes</th>
                  <th scope="col">Broker Fee</th>
                  <th v-if="getOwner() == 'draftrs'" scope="col">Broker Payment</th>
                  <th v-if="getOwner() == 'draftrs' && showRaw()" scope="col">RAW</th>
                </tr>
              </thead>
              <tbody class="text-right">
                <tr v-for="t, k in transactions" :key="k">
                  <td title="accounting period" >{{ getMoment(t.ts).startOf('month').format('MM/DD/YYYY') + ' - ' + getMoment(t.ts).endOf('month').format('MM/DD/YYYY') }}</td>
                  <td>Markel Insurance Company</td>
                  <td title="policy #" >{{ t.policyNumber }}</td>
                  <td title="policy name" ><a :href="'../policy/' + t.customerId" target="_blank" >{{ t.insured.insured }} <i class="fas fa-external-link-alt"></i></a><br/> <b>ts: {{ formatDate(t.ts) }}</b></td>
                  <td title="effective date" >{{ formatDate(t.policy.effective) }}</td>
                  <td title="expiration date" >{{ formatDate(t.policy.expires) }}</td>
                  <td title="transaction date" >{{ t.ts ? formatDate(t.ts) : '' }}</td>
                  <td title="transaction">{{ t.action }}</td>
                  <td title="Total Paid" >{{ t.billing.amount / 100 }}</td>
                  <td title="Policy Premium">{{ getPremium(t) }}</td>
                  <td title="policy state">{{ t.policy.state }}</td>
                  <td title="payment method">
                    <span v-if="'ach' == t.billing.payment_type" class="badge badge-success badge-pill ml-2">ACH</span>
                    <span v-else class="badge badge-danger badge-pill ml-2">Credit</span>
                  </td>
                  <td title="broker">{{ t.owner }}</td>
                  <td title="Total Stripe Fees"><span v-if="valuesFromIMS">{{ stripeFees(t) }}</span> {{ totalStripeFees(t) }}</td>
                  <td title="Fees Paid by Insured" ><span v-if="valuesFromIMS">{{ Math.round((t.calcs.txConsumer ? t.calcs.txConsumer : 0) * 100) / 100 }}</span>  {{ feePaidByInsured(t) }}</td>
                  <td title="Carrier Payment" v-if="getOwner() == 'draftrs'" ><span v-if="valuesFromIMS">{{ Math.round((t.calcs.premium + t.calcs.txFee) * .75 * 100) / 100 }}</span>  {{ carrierPayment(t) }}</td>
                  <td title="Taxes" >{{ Math.round(t.calcs.taxFees * 100) / 100 }}</td>
                  <td title="Broker Fee">{{ t.calcs.brokerFee ? t.calcs.brokerFee : '' }}</td>
                  <td v-if="getOwner() == 'draftrs'" title="Broker Payment" >{{ brokerPayment(t) }}</td>
                  <td v-if="getOwner() == 'draftrs' && showRaw()" title="Raw Data" v-html="getRaw(t)"></td>
                </tr>
                <tr id="acc-totals">
                  <td :colspan="12">All</td>
                  <td title="total Stripe transaction fee" >${{ Math.round((totals.txFee) * 100) / 100 }}</td>
                  <td title="total Insured transaction fee" >${{ Math.round(totals.consumerTxFee * 100) / 100 }}</td>
                  <td title="total Draftrs transaction fee" >${{ Math.round(totals.draftrsTxFee * 100) / 100 }}</td>
                  <td title="total carrier payment" v-if="getOwner() == 'draftrs'">${{ Math.round(totals.carrier * 100) / 100 }}</td>
                  <td title="total taxes and fees">${{ Math.round(totals.passthrough * 100) / 100 }}</td>
                  <td title="total broker fees">${{ totals.brokerFee }}</td>
                  <td v-if="getOwner() == 'draftrs'" title="total broker payment" >${{ Math.round(totals.broker * 100) / 100 }}</td>
                  <td v-if="getOwner() == 'draftrs' && showRaw()"></td>
                  
                </tr>
                <tr v-if="getOwner() == 'draftrs'">
                  <td colspan="18" ></td>
                  <td colspan="2" class="bg-success" >
                    Profit: <b>${{ Math.round(totals.draftrs * 100) / 100 }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  </div>
</body>
</template>

<script>
import AgentNav from "../components/AgentNav.vue";
import { getUserData } from "../Utils/auth.js";
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'accounting',
  props: {
  },
  components: {
    AgentNav
  },
  data: function () {
    return {
      transactions: [],
      totals: [],
      owner: null,
      fromDt: moment().subtract(2, 'months').toISOString(),
      spinners: {"bordereau": false,"accounting": false, "data": true},
      raw: false,
      valuesFromIMS: false  // this is for debugging/comparing calculations from IMS vs calculated after the fact
    }
  },
  async mounted() {
    // prevent carriers and observers from seeing any accounting data
    if (this.isCarrierAdmin() || this.isObserver())
      this.$router.push({name: 'dashboard', params: {}});

    this.totals = {
      txFee: 0,
      consumerTxFee: 0,
      brokerTxFee: 0,
      draftrsTxFee: 0,
      carrier: 0,
      passthrough: 0,
      broker: 0,
      draftrs: 0
    };

    //this.owner = this.getOwner();
    await this.updateTransactionList();
  },
  methods: {
    showRaw() {
      return this.raw;
    },
    getRaw(t) {
      // let output = 'quote id: ' + t.id + '<br/>';
      // output += 'action: ' + t.action + '<br/>';
      // output += 'billing amt: ' + t.billing.amount/100 + '<br/>';
      // output += 'id: ' + t.billing.id + '<br/>';
      // output += JSON.stringify(t.calcs).replaceAll('"', '').replaceAll(',', '</br>').replace('{', '').replace('}', '').replaceAll(':', ': ');
      let output = JSON.stringify(t).replaceAll('"', '').replaceAll(',', '</br>').replace('{', '').replace('}', '').replaceAll(':', ': ');

      return output;

    },
    stripeFees: function(t) {
      let txConsumer = Object.prototype.hasOwnProperty.call(t.calcs, 'txConsumer') ? t.calcs.txConsumer : 0;
      let txDraftrs = Object.prototype.hasOwnProperty.call(t.calcs, 'txDraftrs') ? t.calcs.txDraftrs : 0;
      let txBroker = Object.prototype.hasOwnProperty.call(t.calcs, 'txBroker') ? t.calcs.txBroker : 0;

      return Math.round((txConsumer + txDraftrs + txBroker) * 100) / 100;
    },
    brokerPayment: function(t) {
        return t.calcs.commissionPct ? this.formatMoney(this.getPremium(t) * t.calcs.commissionPct) : 0;
    },
    carrierPayment: function(t) { 
      return this.formatMoney(this.getPremium(t) * .75);
    },
    feePaidByDraftrs: function(t) { 
      return this.formatMoney(Math.abs(this.totalStripeFees(t) - this.feePaidByInsured(t)));
    },
    feePaidByInsured: function(t) { 
      const brokerFee = t.calcs.brokerFee ? t.calcs.brokerFee : 0;

      if (t.billing.payment_type == 'ach') {
        return 0;
      } else { // cc
        if (['issue', 'renew'].includes(t.action))
          return this.formatMoney(t.billing.amount/100 - t.policy.premium - brokerFee);
        else // endorsements & cancellations
          return this.formatMoney((t.billing.amount/100) * .017);
      }
    },
    totalStripeFees: function(t) { 
      if (t.billing.payment_type == 'ach') {
        return this.formatMoney(t.billing.amount/100 * .012);
      } else { // cc
        return this.formatMoney(t.billing.amount/100 * .029 + .30);
      }
    },
    getPremium: function(t) {
      const brokerFee = t.calcs.brokerFee ? t.calcs.brokerFee : 0;
      const surcharge = Math.round(t.calcs.taxFees * 100) / 100;

      if ( ['issue', 'renew'].includes(t.action)) {
        return Math.round(t.policy.premium - surcharge);
      } else if ( 'endorse' == t.action ) {
        return t.policy.premium ? Math.round(t.policy.premium - surcharge) : 0;
      } else {
        return Math.round(t.calcs.premium - brokerFee - surcharge);
      }
    },
    updateTransactionList: async function() {
      var dt = moment(this.fromDt).toISOString();
      this.transactions = await this.getTransactions({ fromDt: dt, owner: this.owner });
    },
    downloadBordereau: async function(lob = '') {
      this.spinners.bordereau = true;
      const dt = moment(this.fromDt).toISOString();
      let data = { fromDt: dt, owner: this.owner };
      if (lob == 'wk_mpl')
        data.wkly = true;

      lob = lob ? 'MPL' : '';
      axios({
            url: '/api/bordereau'+lob, 
            method: 'POST',
            data: data,
            responseType: 'blob', // important
        }).then((response) => {
          this.spinners.bordereau = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'bordereau' + lob + '.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    downloadAccounting: async function() {
      this.spinners.accounting = true;
      const dt = moment(this.fromDt).toISOString();
      axios({
            url: '/api/accounting', 
            method: 'POST',
            data: { fromDt: dt, owner: this.owner },
            responseType: 'blob', // important
        }).then((response) => {
          this.spinners.accounting = false;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'accounting.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    },
    getTransactions: async function(transPostParams) {
      let ret;
        await axios.post('/api/transactions', transPostParams)
        .then((res) => {
          this.spinners.data = false;
          ret = res.data.filter( d => d.action != 'changeinfo'); // remove all transactions with changeinfo $0 premium
          ret = ret.filter( d => d.action != 'endorse' && d.billing && d.billing != 0); // remove $0 endorsements
          ret = ret.filter( d => Object.prototype.hasOwnProperty.call(d, 'policy')); 
          //ret = ret.filter( d => Object.prototype.hasOwnProperty.call(d.policy, 'effective')); 

          this.totals['txFee'] = this.totals['draftrs'] = this.totals['brokerFee'] = this.totals['passthrough'] = this.totals['carrier'] = this.totals['draftrsTxFee'] = this.totals['brokerTxFee'] = this.totals['consumerTxFee'] = this.totals['brokerFee'] = 0;
          for (let i in ret) {
            this.totals['txFee'] += Object.prototype.hasOwnProperty.call(ret[i].calcs, 'txFee') ? this.totalStripeFees(ret[i]) : 0;
            this.totals['consumerTxFee'] += Object.prototype.hasOwnProperty.call(ret[i].calcs, 'txConsumer') ? this.feePaidByInsured(ret[i]) : 0;
            this.totals['brokerTxFee'] += ret[i].calcs.txBroker;
            this.totals['draftrsTxFee'] += this.feePaidByDraftrs(ret[i]); //ret[i].calcs.txDraftrs;
            this.totals['carrier'] += this.carrierPayment(ret[i]); //ret[i].calcs.carrier;
            this.totals['passthrough'] += ret[i].calcs.taxFees;
            this.totals['broker'] += ret[i].calcs.commission;
            this.totals['brokerFee'] += Object.prototype.hasOwnProperty.call(ret[i].calcs, 'brokerFee') ? ret[i].calcs.brokerFee : 0;
            this.totals['draftrs'] += ret[i].calcs.profit;
          }
        })
        .catch((e) => { console.log(e); ret = false; });

        return ret;
    },
    getRole() {
      return getUserData().role;
    },
    getOwner() {
      return getUserData().owner;
    },
    formatDate: function(dt, format = 'MM/DD/YYYY') {
        return moment(dt).format(format);
    },
    getMoment: function(dt) {
      return moment.utc(dt)

    }
  }
}
</script>

<style lang="scss">
#transactions-tbl {
  font-size: 12px;
}
#acc-totals {
  font-weight: bold;
}
#accountingSummary {
  font-size: .85em;
}
#accountingSummary input.form-control {
  font-size: .85em;
}
</style>