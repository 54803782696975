

// IF YOU ARE ADDING A NEW BLOG POST PLEASE READ BELOW FIRST 

// Put the most recent blog posts 1st in the list, If you are adding a new post that is older make sure to place it accordingly in order by the date.
// We use markdown syntax for the body. The indention of the body text as shown below is very important to follow. 
// Empty lines means spacing between paragraphs. The dashes will format to bulleted lists.
// titleUrl: remove all single quotes, periods, question marks, and add dashes for spaces. See the existing titleUrls below for guidance.
// If any additional markdown syntax doesn't work let me know. I might need to add more css into styles.scss under the .markdown-it class

const blogPosts = [
    {
        date : 'May 7, 2024',
        topic : 'General Insurance',
        title : 'Why Do My Professional Liability Insurance Premiums Keep Increasing? What Can I Do To Reduce My Premiums?',
        titleUrl : 'Why-Do-My-Insurance-Premiums-Keep-Increasing',
        body : 
`
This is a common question we get.  In this post we will share some of the most common reasons for professional liability insurance premium increases and offer a couple suggestions on how to reduce your premiums!

Your Firm Has Grown

A premium increase on your professional liability renewal is often the result of your firm growing and taking on more work.  Most professional liability policies are rated based on revenues over the last full year and the number of employees.  As your revenues grow from year to year, so will your professional liability premiums, because the increase in work also means an increase in the likelihood of mistakes and claims.  If your firm is growing, consider the following to try to reduce your premium:

1. Tip: Deduct your direct reimburseables.  You should check with your agent or underwriter first, but most carriers will allow you to deduct direct reimburseables from reported revenues.  Direct reimburseables are direct pass-through fees you charge your client for things like travel expenses.

Automatic Underwriting Rating Adjustments

Another common cause of premium increases is rating adjustments on your retroactive coverage.  When you first purchased professional liability insurance, you probably did not have any retroactive coverage and got a pretty competitive rate because the carrier was not providing any coverage for your past work.  But every year that passes means the carrier is providing coverage for all the work that has occurred and they automatically charge for each additional year of retroactive coverage they provide.  This charge usually maxes out at 5 years for most carriers.  If these automatic coverage and rating adjustments are causing the increases:

2. Tip: Make sure you are also getting all available underwriting credits.  Many carriers offer credits based on how long you have been in business, how much professional experience you have, contract risk management practices, etc.  These credits vary from carrier to carrier, so double check with your agent or underwriter to make sure you get the credit you deserve! 

Make Sure You Are Insured By A Carrier Suited For Your Firm

This one is a bit outside of your direct control, but carriers may impose rate increases due to excessive loss and claim activity they are experiencing in their program.  Many professional liability carriers cover a wide range of firms, from sole proprietors to firms with thousands of employees.  Historically, it has been the largest firms that have attracted the severe claims that carriers have been trying to manage.  Insurance carriers may raise rates for small or mid-sized businesses to compensate for claims on large accounts. 

3. Tip: Try to choose a carrier with a program that focuses on you.  If you are a small or mid-sized firm, it can be best to stick with a program that was specifically designed for firms of that size.  This may give you the best pricing and reduce the likelihood of unexpected premium swings because there will be no large firm losses to adversely impact your rates and premiums.
`
    },
    {
        date : 'September 4, 2023',
        topic : 'General and Design Professionals',
        title : 'Why Do Design Professional’s Need Professional Liability Insurance?',
        titleUrl : 'Why-Do-Design-Professionals-Need-Professional-Liability-Insurance',
        body : 
`
Professional liability insurance is not mandatory for many design professionals from a licensing perspective.  However, many purchase the insurance due to client contract requirements and/or for financial protection and risk management as disputes are very common in the design and construction industry.

Professional liability insurance covers design professionals for alleged errors or mistakes in their work.  These claims, demands or suits are most likely going to come from clients though they could also come from other third parties who might suffer property damage, injuries and/or financial loss as a result of the alleged errors in a design professional’s work.

Mistakes could arise from errors in load calculations or a building code oversight that contributes to project delays and rework costs.  Or, it could be a matter that the design professional had nothing to do with except that they were just dragged into the claim along with the contractor and other project designers.  Professional liability insurance will provide legal defense and pay on your behalf any awarded amounts you become legally liable for (subject to the terms and conditions of the policy).

Highlights:
- Professional liability insurance covers you for alleged errors or mistakes in your work
- A claim or demand could come from a client due to a code oversight in your designs that contributed to project delays and rework costs
- Or your work might be perfect but you end up getting drawn into a claim along with the contractor and other designers, fairly common in design and construction disputes
- Professional liability insurance can provide you with legal defense in these matters and pay, on your behalf, any awarded amounts you become legally liable for 
`
    },
    {
        date : 'August 14, 2023',
        topic : 'General Insurance',
        title : 'Can I purchase professional liability insurance on a daily or short term basis?',
        titleUrl : 'Can-I-purchase-professional-liability-insurance-on-a-daily-or-short-term-basis',
        body :
`
Draftrs does not offer policies on a short term basis nor do we recommend you purchase professional liability insurance in this way.  Professional liability insurance is almost always written on a claims made basis with a retroactive date trigger (for more on retroactive dates click 👉 <a target="_Blank" href="./If-I-Purchase-a-Policy-Today-Will-It-Cover-Any-of-My-Past-Work">here</a> and 👉 <a target="_Blank" href="../Insurance-Frequently-Used-Terms-Definitions">here</a> and you will end up paying for a policy that offers no coverage.

First, professional errors are most often discovered after the work has been completed and in some professions, months or years after the work has been completed.  Purchasing a professional liability policy over the period while you are working on a job will not provide you with any coverage if a claim is made 2 months later after you complete the job.

Second, purchasing a short term professional policy means that you will not be able to carry over forward a retroactive date for prior acts coverage.  Coverage under a professional policy is triggered by a retroactive date which establishes how far back you policy will cover you for your past work.  This date is established the first time you purchase professional liability insurance and carries over if you maintain consecutive coverage.  Buying short term policies off and on and/or on demand means that you will lose your retroactive date and the short term policy you buy this month will not cover any of the work you performed last month.
`
    },
    {
        date : 'July 10, 2023',
        topic : 'General Insurance',
        title : 'If I Purchase a Policy Today, Will It Cover Any of My Past Work? (retroactive dates)',
        titleUrl : 'If-I-Purchase-a-Policy-Today-Will-It-Cover-Any-of-My-Past-Work',
        body : 
`
If this is your first time buying professional liability and/or you do not currently have a professional liability policy in place then the first policy you purchase will NOT provide any coverage for your past work.  It will only cover the work you perform from the policy effective date going forward.  Any claims arising from work performed prior to this date will NOT be covered.

All professional liability policies are written on a claims made basis with a retroactive date trigger. The retroactive date is established as the first year you purchase professional liability insurance.  This date carries forward as your renew your policy.  The retroactive determines how far back your current policy will cover you for work you performed in the past.

For example, you purchase your first professional policy ever today.  The retroactive date would then be established as today’s date.  All work you perform on or after this date will be covered.  Work performed prior to this date is NOT covered.  You continue renewing your policy for the next couple of years and in year three, you receive a demand from an old client saying you owe them compensation for errors in your work.  If the work you performed for this client occurred prior to the retroactive date, it would NOT be covered.  If the work was performed on or after the retroactive date, then this could potentially be covered and you should notify the carrier.

If you had prior knowledge of an error or issue and are purchasing insurance in case you end up getting sued – this would also NOT be covered.  Due to a prior knowledge exclusion on the policy.
`
    },
    {
        date : 'June 26, 2023',
        topic : 'General Insurance',
        title : 'Should I Use An Insurance Broker?',
        titleUrl : 'Should-I-Use-An-Insurance-Broker',
        body :
`
As a small business professional, you are used to doing things on your own and Draftrs can provide small firm professionals direct online access to comprehensive professional liability insurance products and policy management tools like instant online mid-term endorsements.   But there are good reasons you may also want to consider working with an agent: 

- Other insurance products.  While professional liability insurance is the most important insurance product for small business professionals, it may not be the only relevant product.  Other products like cyber insurance, D&O insurance and disability insurance may be relevant for you.  Sifting through different types of insurance can be complicated and an agent can help you make sense of the different products. 
- Expert advice.  Many agents specialize in professional liability insurance.  These agents can help you understand what coverages are relevant to your business operation and can obtain quotes from additional carriers.  

If you would like to work with agent, just let us know at support@draftrs.com and we will introduce you to one of our partner agents who specializes in professional liability.  Get advice from a specialist agent while still benefiting from the instant online access Draftrs offers for quotes, certificates and mid-term endorsements. 
`
    },
    {
        date : 'June 5, 2023',
        topic : 'Design Professionals',
        title : 'Do Interior Designers Need Professional Liability?',
        titleUrl : 'Do-Interior-Designers-Need-Professional-Liability',
        body : 
`
As an interior designer, the projects you take on, and your responsibilities, are always changing.  You may be working on residential projects one month and commercial projects the next.  Your responsibilities may include traveling to locations, developing plans with owners, sourcing materials, working with vendors and contractors, and managing to a budget.  Even for the most diligent design professionals, mistakes can happen.  

When a mistake costs your client money, your interior design firm can be sued for professional negligence.  Examples may include: 

- Failure to complete projects on time
- Costs exceeding the agreed budget
- Making a mistake in the suitability of materials for the project 
- Making a mistake in measuring or installing materials 

💡Let’s take a concrete example.  Suppose you are working on installing high end wallpaper for a project.  Due to excess humidity, the wallpaper starts to bubble and peel.  The client seeks compensation from the interior designer to remove and replace the wallpaper. 

This is where professional liability insurance steps in.  Professional liability insurance, also known as errors and omissions insurance, covers professional negligence, and can cover defense costs, loss of earnings and damages awarded by a court.   A single mistake or lawsuit can be devasting, and professional liability insurance can give you the peace of mind that you are covered.  
`
    },
    {
        date : 'April 26, 2022',
        topic : 'Other',
        title : 'Draftrs.com launches',
        titleUrl : 'draftrs-com-launches',
        body : 
`
Draftrs, an insurtech company focused on modernizing small business professional liability insurance, today announced the launch of a new digital insurance platform that enables instant underwriting and policy management for small firm professionals and agents.
`
    },
];

module.exports = {blogPosts};
