<script setup>
import {computed, onMounted, ref} from "vue"
import BreadCrumb from "@/components/BreadCrumb.vue"
import LeftSidebar from "@/components/Quote/LeftSidebar.vue"
import {getPremium, getQuoteData, saveQuote} from "@/Utils/quote.js"
import {VStepper, VDivider, VStepperStep, VStepperItems, VStepperHeader, VStepperContent, VOverlay, VProgressCircular} from 'vuetify/lib'
import {useRoute} from "vue-router/composables"
import Vue from "vue"

const props = defineProps({
    issueAction: [String, null],
    schema: {
        type: [Array],
        required: true
    },
    layout: String,
    child: {
        type: Boolean,
        default: false
    },
    repetition: {
        type: Boolean,
        default: false
    }
})
let purchased = ref(false)
let lockEdit = ref(false)
let currentTab = ref(0)
let schema_ = ref(props.schema)
let data = ref({lob: 'cherub'})
onMounted(async () => {
    let route = useRoute()
    if (route.params.id) {
        data.value = await getQuoteData(route.params.id)
    }
})
let validation = ref(null)
const sideBarChangeTab = (newTab) => {
    if (!validation.value.hasErrors || newTab < currentTab.value) {
        step.value = newTab + 1;
    } else {
        form.value[0].showErrors() // why is form ref an array??
    }
}
const saveQuoteData = async function () {
    return await saveQuote(data.value)
}
const form = ref(null)
let handleSubmit = async function () {
    data.value = await saveQuoteData()
    if (validation.value.hasErrors) {
        form.value[0].showErrors()
    } else {
        step.value++
    }
}
const stepper = ref(null)
let step = ref(1)
 let fieldAction = function (action, params) {
     if ('getPremium' === action) {
         getPremium(data.value)
     }
     if ('imported' === action) {
         let reps = []
         for (let row in params.json) {
             let rep = {}
             for (let field in params.json[row]) {
                 if (field === 'dob' || field.indexOf('date') > -1 || field.indexOf('Date') > -1) {
                     rep[field] = excelDateToYYYMMDD(params.json[row][field])
                 } else {
                     rep[field] = params.json[row][field]
                 }
             }
             reps.push(rep)
         }
         Vue.set(data.value, params.repetition, reps)
     }
 }
let loading = computed(() => {
    return Vue.prototype.loading.value
})
function excelDateToYYYMMDD(serial) {
    let utc_days  = Math.floor(serial - 25569)
    let utc_value = utc_days * 86400
    let date_info = new Date(utc_value * 1000)
    let month = date_info.getMonth() + 1
    if (month < 10) month =  '0' + month.toString()
    return date_info.getFullYear().toString() + '-' + month + '-' + date_info.getDate().toString()
}
</script>

<template>
    <div id="wrapper">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="128"></v-progress-circular>
        </v-overlay>
        <BreadCrumb :quote="data" :issueAction="issueAction"/>
        <main id="main" role="main" class="mb-5">
            <section class="row h-100">
                <div class="col-md-3 pr-0">
                    <left-sidebar :purchased="purchased" :quote="data" :idx="currentTab" :issue-action="issueAction" :lock="lockEdit" @navigate="sideBarChangeTab"/>
                </div>
                <div class="col-md-9 px-0">
                    <div class="block">{{ data }}</div>
                    <v-stepper ref="stepper" v-model="step" class="quote-form">
                        <v-stepper-header>
                            <template v-for="(item, i) in schema_">
                                <v-stepper-step
                                        :complete="false"
                                        :step="i + 1"
                                        :key="i"
                                />
                                <v-divider :key="i.toString() + 'divider'"></v-divider>
                            </template>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content v-for="(item, k) in schema_" :step="k + 1" :key="k">
                                <FormulateForm
                                        @validation="validation = $event"
                                        ref="form"
                                        v-model="data"
                                        :schema="item.children"
                                        @saveData="saveQuoteData"
                                        @action="fieldAction"
                                        :persist="true"
                                />
                                <button v-if="(step - 1) > 0" class="btn btn-primary float-left" @click="step--">Back</button>
                                <button v-if="step < schema.length" @click="handleSubmit" class="btn btn-primary float-right" type="button">Next</button>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                    <!--                            <FormulateAddMore add-more="this" context="none"/>-->
                    <!--                                        <Repetition v-if="item.type==='repetition'" v-model="data_[item.schema.name]" :schema="item.schema" @input="updateRep"/>-->
                    <!--                                        <line-of-business v-else-if="item.type === 'grouping'" :schema="item.schema" :data="data_" layout="horizontal" :child="true"/>-->
                    <!--                                        <form-field v-else v-model="data_[item.name]" :schema="item"/>-->
                </div>
            </section>
        </main>
        <!--        </template>-->
    </div>
</template>
