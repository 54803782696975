<template>
    <div>
        <StandardNav/>
        <header class=" pl-0 ml-0 bg-purple">
            <div class="pl-5 py-5">
                <div class="pl-5 my-5">
                    <h1 class="display-4 text-white font-weight-bold mb-4">
                        Blog
                    </h1>
                    <h5 class="text-white">
                        Insights about professional liability insurance for small professional firms and sole practitioners
                    </h5>
                </div>
            </div>
		</header>
		<main class="container-fluid text-90 bg-grey">
			<div class="py-5 px-5 row">
				<div v-if="blogPost" class="col-md-8">  
                    <h5 class="mb-3 ml-2 px-5" v-if="!titleUrl"><b>Latest</b></h5>
                    <div class="ml-2 px-5 mb-5">
                        <h5><b>{{ blogPost.title }}</b></h5>
                        <b>{{ blogPost.date }}</b> 
                        <div class="markdown-it mt-3" style="min-height: 700px" v-html="markdown(blogPost.body)" ></div>
                    </div>
                    <div class="ml-2 px-5">
                        <h5 class="mb-3"><b>More</b></h5>
                        <div class="row mt-1" v-for="bp in blogPosts" :key="bp.title">
                            
                            <!-- <div v-if="bp.titleUrl == blogPost.titleUrl" >{{ bp.date }}</div><div class="col-8">{{ bp.title }}</div> -->
                            <div class="col-3" >
                                {{ bp.date }}
                            </div>
                            <div class="col-9">
                                <span v-if="bp.titleUrl == blogPost.titleUrl">{{ bp.title }}</span>
                                <a v-else :href="'/Blog/'+ bp.titleUrl" > {{ bp.title }}</a>
                            </div>
                            
                        </div>
                    </div>
				</div>
                <div class="border-left px-5 col-md-4">
                    <div class="w-100 text-center">
                        <img width="300" class="pl-5" src="/images/blog-post.jpg">
                    </div>
                    <h5 class="pt-5"><b>Blog Topics</b></h5>
                    <ul class="mt-3" v-for="(topic, idx) in topics" :key="topic.topic">
                        <li :class="idx"><span >{{ topic.topic }}<i class="ml-2 fas fa-caret-down"></i></span>
                        <ul class="ml-5" v-for="title in topic.posts" :key="title">
                            <li class="mb-3">
                                <span v-if="titleUrl == title.titleUrl" >{{ title.title }}</span>
                                <a v-else :href="'/Blog/' + title.titleUrl" >{{title.title }}</a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                </div>
			</div>

		</main>
        <StandardFooter/>
    </div>
</template>

<script>
var md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true
});

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import { blogPosts } from '../Utils/blog_posts.js';

export default {
  name: "Blog",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
        return {
            titleUrl: '',
            blogPosts: blogPosts,
            blogPost: null,
            topics: []
        };
    },
    watch: {
    },
    mounted() {

        this.buildTopics(blogPosts);

        if (this.$route.params.titleUrl) {
            this.titleUrl = this.$route.params.titleUrl;
            this.blogPost = blogPosts.filter( bp => bp.titleUrl == this.titleUrl )[0]
        } else {
            this.blogPost = blogPosts[0];
        }

        window.scrollTo(0,0);
    },
    methods: {
        markdown: function(str) {
            return md.render(str);
        },
        buildTopics: function(posts) {
            posts.forEach(post => {
                let idx = null;
                this.topics.forEach((t, index) => { idx = t.topic == post.topic ? index : null})

                if (this.topics.length && idx !== null )
                    this.topics[idx].posts.push({titleUrl: post.titleUrl, title: post.title})
                else 
                    this.topics.push({topic: post.topic, posts:[{titleUrl: post.titleUrl, title: post.title}]})
            })
        }
    },
};
</script>

<style lang="scss">

</style>
