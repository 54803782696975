<template>

    <!-- START MPL SUMMARY -->
    <div v-if="quote.lob && quote.lob == 'mpl' ">
        <div v-if="quote.showQL == 'mpl'">
            <hr class="text-dark">
            <h5 class="text-primary">
                Professional Liability Insurance {{ ['agencyBillPending', 'issued'].includes(this.quote.status) ? 'Binder' : 'Quote' }}
            </h5>
            <h5 class="text-primary mb-3">
                Miscellaneous Professional Liability Policy
            </h5>

            <h5 class="my-2">Program Overview</h5>
            <div class="mb-4 w-100 text-90">
                <div class="row">
                    <div class="col-3">Carrier:</div>
                    <div class="col-5">Massachusetts Bay Insurance Company/ The Hanover Insurance Group</div>
                </div>
                <div class="row">
                    <div class="col-3">Paper:</div>
                    <div class="col-5">Admitted</div>
                </div>
                <div class="row">
                    <div class="col-3">Best Rating:</div>
                    <div class="col-5">A (Excellent)</div>
                </div>
                <div class="row">
                    <div class="col-3">Best Financial Size:</div>
                    <div class="col-5">XV (Reserves of $2 billion or more) </div>
                </div>
            </div>

            <p class="my-4">
                The Hanover Insurance Group is a Fortune 500 company that has been providing property and 
            casualty insurance for over 160 years.  They are a leading professional liability insurer of small 
            and mid-sized firms.  All claims are handled by their dedicated in-house professional liability 
            claims team.
            </p>
            <p class="my-4">
                Draftrs is the exclusive program administrator for Hanover Insurance’s digitally enabled small 
            business advantage professional liability program, with coverage and pricing developed 
            specifically for small firms.
            </p>

            <h5 class="my-2">Professional Liability {{ ['agencyBillPending', 'issued'].includes(this.quote.status) ? 'Binder' : 'Quote' }} Summary</h5>
            <div class="mb-4 w-100 text-90">
                <div class="row" v-if="['agencyBillPending', 'issued'].includes(this.quote.status)">
                    <div class="col-3">Policy Number:</div>
                    <div class="col-5">{{ quote.policyNumber}}</div>
                </div>
                <div class="row" v-else>
                    <div class="col-3">Quote Number:</div>
                    <div class="col-5">{{ quote.id}}</div>
                </div>

                <div class="row">
                    <div class="col-3">Named Insured:</div>
                    <div class="col-5">{{ quote.insured }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Effective Dates:</div>
                    <div class="col-3">From: {{ formatDate(quote.effective) }}</div>
                    <div class="col-3">To: {{ formatDate(quote.expires) }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Retroactive Date:</div>
                    <div class="col-5">{{ quote.retroDate }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Limits:</div>
                    <div class="col-3">${{ displayLimit(quote.limit,'per') }} per claim</div>
                    <div class="col-6">${{ displayLimit(quote.limit,'agg') }} total policy aggregate</div>
                </div>

                <div class="row">
                    <div class="col-3">Deductible:</div>
                    <div class="col-5">{{ quote.deductible | currency }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Premium:</div>
                    <div class="col-5">{{ quote.premium | currency }}</div>
                </div>

                <div class="row" v-if="quote.rating.breakdowns.brokerCommission">
                    <div class="col-3">Commission:</div>
                    <div class="col-5">{{ quote.rating.breakdowns.brokerCommission * 100 }}%</div>
                </div>
            </div>
            <div style="height: 510px"> </div>
            <div>
                <a href="/home"><img :src="this.getClientLogo(quote.owners[0])" :height="this.getClientStyle(quote.owners[0], 'agent-logo-height')" alt=""></a>
            </div>
        </div>
        <!-- end cover page -->
        
        <h2 class="mb-4 main-color">{{ quoteSummaryHeader }} </h2>
        <div class="row mb-4">
            <div class="col-md-6">
                <div class="border border-dark bg-white rounded p-3 mt-2 text-center">
                    <p><span class="text-muted">Named Insured:</span> <b>{{ quote.insured }}</b></p>
                    <p class="mb-0"><span class="text-muted">Effective Date:</span> <b>{{ formatDate(quote.effective) }}</b></p>
                </div>
            </div>

        <div class="col-md-6 text-center pt-2" >
            <div class="text-muted">
                <span>{{ issueAction=='endorse' ? 'Endorsement Premium' : 'Total Premium' }}</span>
            </div>
            <div class="text-block d-flex justify-content-center">
                <h2 v-if="issueAction=='endorse' && prorate > 0" class="mb-0"><div id="total-premium" class=""> {{ prorate | currency }} </div></h2>
                <h2 v-else-if="['issue', 'renew'].includes(issueAction)" class="mb-0" ><div id="total-premium" class=""> {{ quote.premium | currency }} </div></h2>
                <h2 class="mb-0" v-else><div id="total-premium" class=""> {{ 0 | currency }} </div></h2>

            </div>
            <div class="text-muted" style="font-size: .8em" v-html="displayTax()"></div>
        </div>

        </div>
        <div class="fs-sel-op html2pdf__page-break">
            <div class="total">
                <h2 class="main-color">Professional Liability Quote</h2>
                <div class="img-info">
                    <div class="sprite bg-avada mx-auto"></div>
                </div>
                <div class="text-info">
                    <div class="list-area text-left">
                        <dl class="row">
                            <dt class="col-sm-4 col-6">Policy Term:</dt>
                            <dd class="col-sm-8 col-6">{{ formatDate(quote.effective) }} - {{ getExpDt() }}</dd>
                        
                            <dt class="col-sm-4 col-6">Limits:   </dt>
                            <dd class="col-sm-8 col-6">${{ formatStr(quote.limit) }} per claim and total policy aggregte</dd>
                        
                            <dt class="col-sm-4 col-6">Deductible:</dt>
                            <dd class="col-sm-8 col-6">${{ formatStr(quote.deductible) }} per claim</dd>
                        
                            <dt class="col-sm-4 col-6">Retroactive Date:</dt>
                            <dd class="col-sm-8 col-6">
                                {{ getRetroDt() }}
                                <i tabindex="0"
                                v-b-tooltip.hover 
                                title="The policy provides coverage for your professional services that were performed on or after this date." 
                                class="far fa-question-circle main-color">
                                </i>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>                                       

            <div class="fs-std-cov total" v-if="issueAction != 'endorse'">
                <h2 class="main-color">Optional Coverage Enhancements</h2>
                <div class="img-info">
                    <div class="sprite bg-men_larger mx-auto"></div>
                </div>
                <div class="text-info">
                    <div class="list-area text-left">
                        <dl class="row">
                            <dt class="col-sm-5 col-6 mb-3">Additional Named Insureds</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.hasOwnProperty('addlNamedInsureds') && quote.addlNamedInsureds.length">Included</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>

                            <dt class="col-sm-5 col-6 mb-3">Notice of Cancellation to Third Parties</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlNoticeOfCancel3rdParties">Included</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>

                            <dt v-if="quote.addlExtendedReportingPeriod" class="col-sm-5 col-6 mb-3">Extended Reporting Period</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlExtendedReportingPeriod">Included - {{ quote.rating.breakdowns.extendedReporting | currency }} Added Premium</dd>
                            <!-- <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd> -->

                            <dt class="col-sm-5 col-6 mb-3">General Liability</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlGL">Included - $25 Added Premium</dd>
                            <dd class="col-sm-7 col-6 rig" v-else-if="quote.rating && quote.rating.breakdowns.glMPL">Included - {{ quote.rating.breakdowns.glMPL | currency }} Added Premium</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>

        <div class="fs-std-cov" v-if="issueAction != 'endorse'">
            <h2 v-b-toggle.coverage-details class="main-color"><span v-b-tooltip.hover title="Coverage details" >Standard Coverages Always Included <i class="fas fa-chevron-circle-down"></i></span></h2>
            <b-collapse id="coverage-details" visible accordion="coverage-details" role="tabpanel">
                <ul class="text-left mb-1"  >
                    <li>Financial loss and defense costs<span>Full Limits</span></li>
                    <li>
                        Personal injury coverage 
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Covers claims for invasion of privacy, libel, slander, false arrest, malicious prosecution etc, that arises out of the Insureds professional services." 
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Full Limits</span>
                    </li>
                    <li>
                        Disciplinary and Administrative Expense Coverage
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Supplemental limits, in addition to policy limits reimbursing the Insured for expenses incurred as a result of Disciplinary proceedings. Provides $250 per day in salary and expenses associated with attending proceedings"
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>$10K</span>
                    </li>
                    <li>
                        Pre-Claims Reporting and Asssitance
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Allows the Insured to notify the carrier of potential professional liability matters, before any claim is actually made against the Insured, so that the carrier may investigate the matter at their sole discretion and option."
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Included</span>
                    </li>
                    <li>Punitive Damages - Where Insurable<span>Included</span>
                    </li>
                </ul>
            </b-collapse>
        </div>

        <div class="fs-std-cov" v-if="issueAction != 'endorse'">
            <h2 v-b-toggle.excluded-details class="main-color"><span v-b-tooltip.hover title="Excluded coverage details" >Excluded and Not Covered Under the Policy <i class="fas fa-chevron-circle-down"></i></span></h2>
            <b-collapse id="excluded-details" visible accordion="excluded-details" role="tabpanel">
                <ul class="text-left mb-1" >
                    <li>Bodily injury and property damage (unless purchased and/or endorsed) <span>Excluded</span></li>
                    <li>Pollution matters (unless purchased and/or endorsed)<span>Excluded</span></li>
                    <li>Data breach, social engineering, phishing  <span>Excluded</span></li>
                    <li>Advertising promotions, sweepstakes, redemptions <span>Excluded</span></li>
                    <li>Intellectual property infringement matters (unless purchased and/or endorsed)<span>Excluded</span></li>
                    <li>Specified Professions and Service Exclusions
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Exclusions Include: Actuaries, Architects and Engineers, CPA Accounting, Healthcare and Medicine, Insurance Agents and Brokers, Investment Advisors and Broker/Dealers, Legal Services, Mortage Broker, Property Developer or Builder Construction Manager
                            "
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Excluded</span>
                    </li>
                </ul>
            
                <div v-if="hasProject('Power and Energy and Utilities') || hasWorkType('Construction Manager')">
                    <h2 class="main-color mt-2">Other added Exclusions</h2>
                    <ul class="text-left">
                        <li v-if="hasProject('Power and Energy and Utilities') && quote.limit == 2000000">
                            Oil and Gas Project Sublimit
                            <i tabindex="0"
                                v-b-tooltip.hover 
                                title="The maximum limit under the policy for any claims on any oil and gas and power generation project is $1 miilion."
                                class="far fa-question-circle main-color"
                            >
                            </i>
                        </li>
                        <li v-if="hasProject('Power and Energy and Utilities')">Natural Resources Appraisal and Forecast Exclusion</li>
                        <li v-if="hasWorkType('Construction Manager')">
                            Construction Management Excluded Activities
                            <i tabindex="0"
                                v-b-tooltip.hover 
                                title="The policy covers agency construction management services only.  
                                This endorsement excludes work and responsibilities of a contractor including scheduling of trades, control of budget, job site safety and construction means, methods."
                                class="far fa-question-circle main-color"
                            >
                            </i>
                        </li>
                    </ul>
                </div>
            </b-collapse>
        </div>
            
        <div class="fs-std-cov" v-if="issueAction != 'endorse'">
            <h2 v-b-toggle.mpl-cov-restrictions class="main-color"><span v-b-tooltip.hover title="Consultants coverage details" >Consultants Specific Coverage and/or Restrictions <i class="fas fa-chevron-circle-down"></i></span></h2>
            <b-collapse id="mpl-cov-restrictions" visible accordion="mpl-cov-restrictions" role="tabpanel">
                <ul class="text-left mb-1" >
                    <li>Warranty, guaranty of future value and performance of investments, property or securities<span>Excluded</span></li>
                    <li>Warranty, guaranty of future sales, earnings, profits or economic value<span>Excluded</span></li>
                    <li>Bankruptcy, insolvency or sale of any Employee Benefit Plan  <span>Excluded</span></li>
                    <li>Costs and expenses associated with any product recall, repair, replace <span>Excluded</span></li>
                </ul>
            </b-collapse>
        </div>
    </div>
    <!-- END MPL SUMMARY -->

    <!-- START PL SUMMARY -->
    <div v-else>
        <div v-if="quote.showQL == 'pl'" >
            <hr class="text-dark">

            <h5 class="text-primary">
                Professional Liability Insurance {{ ['agencyBillPending', 'issued'].includes(this.quote.status) ? 'Binder' : 'Quote' }}
            </h5>
            <h5 class="text-primary mb-3">
                Architects, Engineers and Design Professional Liability Policy
            </h5>

            <h5 class="my-2">Program Overview</h5>
            <div class="mb-4 w-100 text-90">
                <div class="row">
                    <div class="col-3">Carrier:</div>
                    <div class="col-5">Markel Insurance Company</div>
                </div>
                <div class="row">
                    <div class="col-3">Paper:</div>
                    <div class="col-5">Admitted</div>
                </div>
                <div class="row">
                    <div class="col-3">Best Rating:</div>
                    <div class="col-5">A (Excellent)</div>
                </div>
                <div class="row">
                    <div class="col-3">Best Financial Size:</div>
                    <div class="col-5">XV (Reserves of $2 billion or more) </div>
                </div>
            </div>

            <p class="my-4">
                Markel corporation is a Fortune 500 company with 70+ years of experience as a commercial specialty insurance carrier. They are a leading professional liability insurer of design firms for over 40 years. All claims are handled by their dedicated in-house professional liability claims team.
            </p>
            <p class="my-4">
                Draftrs is the exclusive program administrator for Markel's digitally enabled design firm professional liability program with coverage and pricing developed specifically for small firms.
            </p>

            <h5 class="my-2">Professional Liability {{ ['agencyBillPending', 'issued'].includes(this.quote.status) ? 'Binder' : 'Quote' }} Summary</h5>
            <div class="mb-4 w-100 text-90">
                <div class="row" v-if="['agencyBillPending', 'issued'].includes(this.quote.status)">
                    <div class="col-3">Policy Number:</div>
                    <div class="col-5">{{ quote.policyNumber}}</div>
                </div>
                <div class="row" v-else>
                    <div class="col-3">Quote Number:</div>
                    <div class="col-5">{{ quote.id}}</div>
                </div>

                <div class="row">
                    <div class="col-3">Named Insured:</div>
                    <div class="col-5">{{ quote.insured }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Effective Dates:</div>
                    <div class="col-3">From: {{ formatDate(quote.effective) }}</div>
                    <div class="col-3">To: {{ formatDate(quote.expires) }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Retroactive Date:</div>
                    <div class="col-5">{{ quote.retroDate }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Limits:</div>
                    <div class="col-3">${{ displayLimit(quote.limit,'per') }} per claim</div>
                    <div class="col-6">${{ displayLimit(quote.limit,'agg') }} total policy aggregate</div>
                </div>

                <div class="row">
                    <div class="col-3">Deductible:</div>
                    <div class="col-5">{{ quote.deductible | currency }}</div>
                </div>

                <div class="row">
                    <div class="col-3">Premium:</div>
                    <div class="col-5">{{ quote.premium | currency }}</div>
                </div>

                <div class="row" v-if="quote.rating.breakdowns.brokerCommission">
                    <div class="col-3">Commission:</div>
                    <div class="col-5">{{ quote.rating.breakdowns.brokerCommission * 100 }}%</div>
                </div>
            </div>
            <div style="height: 510px"> </div>
            <div>
                <a href="/home"><img :src="this.getClientLogo(quote.owners[0])" :height="this.getClientStyle(quote.owners[0], 'agent-logo-height')" alt=""></a>
            </div>
        </div>

        <h2 class="mb-4 main-color">{{ quoteSummaryHeader }} </h2>
        <div class="row mb-4">
            <div class="col-md-6">
                <div class="border border-dark bg-white rounded p-3 mt-2 text-center">
                    <p><span class="text-muted">Named Insured:</span> <b>{{ quote.insured }}</b></p>
                    <p class="mb-0"><span class="text-muted">Effective Date:</span> <b>{{ formatDate(quote.effective) }}</b></p>
                </div>
            </div>
            <div class="col-md-6 text-center">
                <div class="text-muted p-2"> <span>{{ issueAction=='endorse' ? 'Endorsement Premium' : 'Total Premium' }}</span></div>
                <div class="text-block" >
                    <h2 v-if="issueAction=='endorse' && prorate > 0" class="mb-0"><div id="total-premium" class=""> {{ prorate | currency }} </div></h2>
                    <h2 v-else-if="['issue', 'renew'].includes(issueAction)" class="mb-0"><div id="total-premium" class=""> {{ quote.premium | currency }} </div></h2>
                    <h2 class="mb-0" v-else><div id="total-premium" class=""> {{ 0 | currency }} </div></h2>
                </div>
                <div class="text-muted" style="font-size: .8em" v-html="displayTax()"></div>
            </div>
        </div>
        <div class="fs-sel-op html2pdf__page-break">
            <div class="total">
                <h2 class="main-color">Professional Liability {{ ['agencyBillPending', 'issued'].includes(this.quote.status) ? 'Binder' : 'Quote' }}</h2>
                <div class="img-info">
                    <div class="sprite bg-avada mx-auto"></div>
                </div>
                <div class="text-info">
                    <div class="list-area text-left">
                        <dl class="row">
                            <dt class="col-sm-4 col-6">Policy Term:</dt>
                            <dd class="col-sm-8 col-6">{{ formatDate(quote.effective) }} - {{ getExpDt() }}</dd>
                        
                            <dt class="col-sm-4 col-6">Limits:   </dt>
                            <dd class="col-sm-8 col-6">${{ formatStr(displayLimit(quote.limit, 'per')) }} per claim and ${{ formatStr(displayLimit(quote.limit, 'agg')) }} policy aggregte</dd>
                        
                            <dt class="col-sm-4 col-6">Deductible:</dt>
                            <dd class="col-sm-8 col-6">${{ formatStr(quote.deductible) }} per claim</dd>
                        
                            <dt class="col-sm-4 col-6">Retroactive Date:</dt>
                            <dd class="col-sm-8 col-6">
                                {{ getRetroDt() }}
                                <i tabindex="0"
                                v-b-tooltip.hover 
                                title="The policy provides coverage for your professional services that were performed on or after this date." 
                                class="far fa-question-circle main-color">
                                </i>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>                                       

            <div class="total" v-if="issueAction != 'endorse'">
                <h2 class="main-color">Optional Coverage Enhancements</h2>
                <div class="img-info">
                    <div class="sprite bg-men_larger mx-auto"></div>
                </div>
                <div class="text-info">
                    <div class="list-area text-left">
                        <dl class="row">
                            <dt class="col-sm-5 col-6 mb-3">Additional Named Insureds</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.hasOwnProperty('addlNamedInsureds') && quote.addlNamedInsureds.length">Included</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>
                        
                            <dt class="col-sm-5 col-6 mb-3">Additional Insureds - Vicarious</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlVicarious">Included - {{ quote.rating.breakdowns.aiv | currency }} Added Premium</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>
                        
                            <dt class="col-sm-5 col-6 mb-3">First Dollar Defense</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addl1stDefense">Included - {{ quote.rating.breakdowns.firstDollar | currency }} Added Premium</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>

                            <dt class="col-sm-5 col-6 mb-3">Personal Injury Enhancement</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlPersonalInjury">Included - {{ quote.rating.breakdowns.pi | currency }} Added Premium</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>

                            <dt class="col-sm-5 col-6 mb-3">Rectification</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlRectification">Included - {{ quote.rating.breakdowns.rect | currency }} Added Premium</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>

                            <dt class="col-sm-5 col-6 mb-3">Notice of Cancellation to Third Parties</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlNoticeOfCancel3rdParties">Included</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>

                            <dt class="col-sm-5 col-6 mb-3">General Liability</dt>
                            <dd class="col-sm-7 col-6 rig" v-if="quote.addlGL">Included - $25 Added Premium</dd>
                            <dd class="col-sm-7 col-6 rig" v-else>Not Included</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>

        <div class="fs-std-cov" v-if="issueAction != 'endorse'">
            <h2 v-b-toggle.coverage-details class="main-color"><span v-b-tooltip.hover title="Coverage details" >Standard Coverages Always Included <i class="fas fa-chevron-circle-down"></i></span></h2>
            <b-collapse id="coverage-details" visible accordion="coverage-details" role="tabpanel">
                <ul class="text-left mb-1"  >
                    <li>Bodily Injury, Property Damage and Financial Loss<span>Full Limits</span></li>
                    <li>
                        Contingent and Contractors Pollution Liability 
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Covers pollution events arising out of your professional services.  This includes any drilling, excavation, sampling and/or other necessary construction activities to your performance of professional services." 
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Full Limits</span>
                    </li>
                    <li>
                        Joint Venture Participation Coverage
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Covers the Insured’s professional services when performed as a joint venture partner.  Does not provide any coverage for the joint venture itself.  Does not provide coverage for the work of other joint venture parties."
                                class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Full Limits</span>
                    </li>
                    <li>
                        Regulatory, Disciplinary and Administrative Expense Coverage
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Supplemental limits, in addition to policy limits reimbursing the Insured for expenses incurred as a result of regulatory or disciplinary proceedings."
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>$10K</span>
                    </li>
                    <li>Subpeona Assistance<span>$5k</span></li>
                    <li>
                        Pre-Claims Reporting and Asssitance
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Allows the Insured to notify the carrier of potential professional liability matters, before any claim is actually made against the Insured, so that the carrier may investigate the matter at their sole discretion and option."
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Included</span>
                    </li>
                    <li>Punitive Damages - Where Insurable<span>Included</span></li>
                    <li>
                        Waiver of Subrogation - With Prior Written Contract
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="When a carrier makes payment under a policy, the carrier typically retains the Insured's rights to seek recovery from any other responsible third party.  This provision waives this right to the extent the Insured had a prior written contract with the third party to waive these subrogation rights."
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Included</span>
                    </li>
                </ul>
            </b-collapse>
        </div>

        <div class="fs-std-cov" v-if="issueAction != 'endorse'">
            <h2 v-b-toggle.excluded-details class="main-color"><span v-b-tooltip.hover title="Excluded coverage details" >Excluded and Not Covered Under the Policy <i class="fas fa-chevron-circle-down"></i></span></h2>
            <b-collapse id="excluded-details" visible accordion="excluded-details" role="tabpanel">
                <ul class="text-left mb-1" >
                    <li>Employment Related Matters and Claims From Employees <span>Excluded</span></li>
                    <li>Copyright, Trademark, Patent Matters <span>Excluded</span></li>
                    <li>Actual Construction, Installation, Fabrication performed by an Insured <span>Excluded</span></li>
                    <li>Products Design Manufacture, Sale or Distribution <span>Excluded</span></li>
                    <li>Nuclear Energy Related Matters <span>Excluded</span></li>
                    <li>Specified Project Type Exclusions
                        <i tabindex="0"
                            v-b-tooltip.hover 
                            title="Exclusions Include: Condo/Townhomes/Subdivisions/HOA developments with more than 25 units - Custom Homes Greater Than $3M in values - High Rise Buildings taller than 12 stories
                            - Mines/Quarries - Nuclear Energy Projects - Bridges - Blast &amp; Bored Tunnels - Dams, Harbours or Wharves - Amusement/Water Park Rides - Aviation &amp; Naval
                            - Offshore Projects - Professional Sports Stadiums - Prototype or First Technologies - Stand Alone Parking Structures
                            "
                            class="far fa-question-circle main-color"
                        >
                        </i>
                        <span>Excluded</span>
                    </li>
                </ul>
            
                <div v-if="hasProject('Power and Energy and Utilities') || hasWorkType('Construction Manager')">
                    <h2 class="main-color mt-2">Other added Exclusions</h2>
                    <ul class="text-left">
                        <li v-if="hasProject('Power and Energy and Utilities') && quote.limit == 2000000">
                            Oil and Gas Project Sublimit
                            <i tabindex="0"
                                v-b-tooltip.hover 
                                title="The maximum limit under the policy for any claims on any oil and gas and power generation project is $1 miilion."
                                class="far fa-question-circle main-color"
                            >
                            </i>
                        </li>
                        <li v-if="hasProject('Power and Energy and Utilities')">Natural Resources Appraisal and Forecast Exclusion</li>
                        <li v-if="hasWorkType('Construction Manager')">
                            Construction Management Excluded Activities
                            <i tabindex="0"
                                v-b-tooltip.hover 
                                title="The policy covers agency construction management services only.  
                                This endorsement excludes work and responsibilities of a contractor including scheduling of trades, control of budget, job site safety and construction means, methods."
                                class="far fa-question-circle main-color"
                            >
                            </i>
                        </li>
                    </ul>
                </div>
            </b-collapse>
        </div>
    </div>
    <!-- END MPL SUMMARY -->

</template>
<script>

import { getClientDomain, getClientLogo, getClientStyle} from "../Utils/themer.js";
import axios from 'axios';
import moment from 'moment';

export default {
    name: "QuoteSummary",
    props: {
        quote: {
            type: Object,
            default() {
            return {};
            }
        },
        prorate: {
            type: Number,
            default() {
            return 0;
            }
        },
        issueAction: {
            type: String,
            default() {
            return 'issue';
            }
        },
        header: {
            type: String,
            default() {
            return 'Review & Purchase your policy';
            }
        },
    },
    data: function() {
        return {
            downloadSpinner: false,
            quoteSummaryHeader: ''
        };
    },
    mounted() {
    },
    watch: {
        'issueAction': function() {
            if (this.$route.name == 'payment') {
                if (this.issueAction == 'renew')
                    this.quoteSummaryHeader = "👋 Let's Renew Your Policy!";
                else if (this.issueAction=='endorse' || this.quote.status=='issued') 
                    this.quoteSummaryHeader = 'Purchase Your Policy Endorsement';
                else 
                    this.quoteSummaryHeader = "Purchase Your Policy";
            }
        }
    },
    methods: {
        downloadPolicyDoc: function() {
            this.downloadSpinner = true;
            axios({
                url: '/api/doc/'+this.quote.customerId+'/'+this.quote.policyNumber+'/policy', 
                method: 'POST',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'policy.pdf');
                document.body.appendChild(link);
                link.click();
                this.downloadSpinner = false;
            });
        },
        formatDate: function(dt) {
            return moment.utc(dt).startOf('date').format('MM/DD/YYYY');
        },
        getExpDt: function() {
            return moment.utc(this.quote.effective).add(this.quote.policyTerm, 'months').format('MM/DD/YYYY');
        },
        formatStr: function(str) {
            return str ? str.toLocaleString() : '';
        },
        getRetroDt: function() {
            return this.quote.retroDate ? moment.utc(this.quote.retroDate).format('MM/DD/YYYY') : moment.utc(this.quote.effective).format('MM/DD/YYYY');
        },
        hasProject: function(projectName) {
            if (Array.isArray(this.quote.projects) && this.quote.projects.includes(projectName))
                return true;

            return false;
        },
        hasWorkType: function(workType) {
            if (this.quote.workType == workType) {
                return true;
            } else if (Array.isArray(this.quote.addlServicesProvided) && this.quote.addlServicesProvided.includes(workType))
                return true;

            return false;
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientDomain() {
            return getClientDomain();
        },
        getClientStyle(client,style) {
            return getClientStyle(client, style);
        }
    },
};
</script>

<style lang="scss">

</style>
