export default {
    schema: [
        {
            name: 'tab1',
            type: 'tab',
            children: [
                {
                    name: 'insured',
                    label: 'Insured Name',
                    validation: 'required'
                },
                {
                    type: 'date',
                    name: 'effectiveDate',
                    label: 'Effective Date',
                    validation: 'required'
                },
                {
                    type: 'number',
                    name: 'expenseProfitRatio',
                    label: 'Expense + Profit Ratio (ex-comis)',
                    validation: 'between:0,90,value',
                    value: 25
                },
                {
                    type: 'number',
                    name: 'commissionRatio',
                    label: 'Commission Ratio',
                    validation: 'between:0,90,value',
                    value: 5
                },
                {
                    type: 'select',
                    name: 'annualPayment',
                    label: 'Payment Amount - annual',
                    validation: 'required',
                    options: [10000, 20000],
                    value: 10000
                },
                {
                    type: 'number',
                    name: 'investmentReturn',
                    label: 'Investment Return',
                    validation: 'between:0,90,value',
                    value: 5
                }
            ]
        },
        {
            name: 'tab2',
            type: 'tab',
            children: [
                {
                    name: 'importer',
                    type: 'importer',
                    label: 'Employee Importer',
                    '@action': true,
                    repetition: 'employees'
                },
                {
                    type: "group",
                    name: "employees",
                    repeatable: true,
                    label: "Employees",
                    'add-label': "+ Add Employee",
                    validation: "required",
                    children: [
                        {
                            name: 'name',
                            label: 'Name'
                        },
                        {
                            name: 'dob',
                            type: 'date',
                            label: 'Date of Birth'
                        },
                        {
                            name: 'age',
                            type: 'number',
                            label: 'Age'
                        },
                        {
                            name: 'income',
                            label: 'Income',
                            type: 'number'
                        },
                        {
                            name: 'gender',
                            label: 'Gender',
                            type: 'select',
                            options: ['M','F']
                        }
                    ]
                }
            ]
        },
        {
            name: 'tab3',
            type: 'tab',
            children: [
                // {
                //     name: 'premium',
                //     type: 'premium',
                //     label: 'Get Premium',
                //     '@action' : true
                // },
                {
                    name: 'manualPremium',
                    type: 'manualPremium',
                    label: 'Manual Premium',
                    validation: "number"
                }
            ]
        }
    ]
}