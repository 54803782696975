<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1 my-1">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							Press Releases
						</h1>
						<h5 class="text-white">
							Draftrs And Shingle Partner To Deliver Professional Liability Insurance To Freelance and Small Engineering Firms  
						</h5>
					</div>
				</div>
			</div>
		</header>
		<main class="container-fluid bg-grey">
            <div class="container py-5">
				<div class="col-md-10 offset-md-1">   
                    <p class="pb-3">
                        <b><center>Draftrs And Shingle Partner To Deliver Professional Liability Insurance To Freelance and Small Engineering Firms</center></b>
                    </p>
                    <p class="pb-3">
                        <b>Dallas, Texas – February 21, 2023</b> – Draftrs, an insurtech company focused on modernizing small business professional liability insurance, today announced a partnership with Shingle, a technology provider to independent, accredited engineers and the businesses that need their engineering services, to distribute professional liability insurance to Shingle’s network of engineers.      
                    </p>
                    <p class="pb-3">
                        The Draftrs embedded platform provides instant quote-to-bind professional liability insurance and allows insureds to issue certificates of insurance and manage their policy online without the need to speak to an underwriter.  Draftrs' professional liability insurance is underwritten by A-rated carriers and is currently available in 48 states. 
                    </p>
                    <p class="pb-3">
                        “We are very excited to embed our quoting platform with Shingle,” said Audrey Lau, co-founder and CEO of Draftrs.  “Shingle’s network of engineers is growing quickly.  These small businesses need a fast and simple option for comprehensive professional liability coverage.” 
                    </p>
                    <p class="pb-3">
                        “We are thrilled to have discovered Draftrs,” said Aaron Klimisch, co-founder of Shingle.  “Obtaining E&O insurance for engineers can be complex and challenging to navigate.  Draftrs has developed an efficient system to enable individual engineers to acquire E&O insurance quickly and without the typical bureaucratic hassles.”
                    </p>
                    <p class="pb-3">
                        The Shingle network is available at <a href="https://shingleit.com">shingleit.com</a>. 
                    </p>
                    <h5>About Draftrs, Inc. (Draftrs)</h5>
                    <p class="pb-3">
                        Draftrs, Inc. (Draftrs) is a technology-enabled, managing general agent (MGA) that is streamlining, automating, and digitizing the insurance underwriting and distribution process for carrier and broker partners to create a more efficient way to deliver professional liability insurance to the small commercial market.  Draftrs provides instant, direct access and enables distribution partners to become digital agencies without having to make any costly tech upgrades or investments.  For more information, please visit <a href="https://draftrs.com">https://draftrs.com</a>. 
                    </p>
                    <h5>About Shingle Engineering Technologies (Shingle)</h5>
                    <p class="pb-3">
                        Shingle is an online platform designed to empower engineers to showcase their skills and experience and build their personal brand for freelancing opportunities. It provides the tools and resources to rapidly establish and manage business operations, market services, and nurture client and partner relationships as an independent consulting engineer.
                    </p>
				</div>
                <div class="col-md-10 offset-md-1">
                    <a href="/Press">View more press releases</a>
                </div>
			</div>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import StandardNav from "../../components/StandardNav.vue";
import StandardFooter from "../../components/StandardFooter.vue";

export default {
  name: "PressReleases",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
    },
    watch: {
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
    },
};
</script>

<style lang="scss">

</style>
