<template>
    <div v-if="quote">
        <div class="card-body">
            <div class="d-flex justify-content-center mb-5">
                <img :src="this.getClientLogo(quote.owners[0])" :height="this.getClientStyle(quote.owners[0], 'agent-logo-height')" alt=""><br/>
            </div>
            <div class="d-flex justify-content-center">
                <h4>Policy Preview for {{ quote.insured }}</h4>
                <div style="height: 0px; z-index: 200; position: fixed; top: 20%; left: 47.5%" >
                    <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

const axios = require('axios');
import { loginUser, isLoggedIn, getUserData } from '../Utils/auth.js';
import { getClientDomain, getClientLogo, getClientStyle } from "../Utils/themer.js";

export default {
    name: "Payment",
    components: {
    },
    props: {},
    data: function() {
        return {
            quote: {},
            context: {},
            quoteId: null,
            token: null,
        };
    },
    mounted() {
        //decode because of azure's stupid (no period in urls)
        this.token = this.$route.params.token;

        if (!this.loggedIn()) {
            this.tokenRoute();
        } else {
            this.quoteData();
        }

    },
    methods: {
        previewPolicy: function() {
            let url = '/api/doc/1/1/preview/1';
            axios({
                url: url, 
                method: 'POST',
                responseType: 'blob', // important
                data: this.quote
            }).then((response) => {
                var blob = new Blob([response.data], {type: 'application/pdf'});
                var blobURL = URL.createObjectURL(blob);
                window.open(blobURL, "_self");
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'policy-preview-'+ this.quote.insured +'.pdf');
                document.body.appendChild(link);
                link.click();
            });
        },
        quoteData: async function() {
            const quoteId = this.$route.params.id;

            if (quoteId)
                this.quote.id = quoteId ;

            await axios.get('/api/quote/' + this.quote.id).then((res) => {
                this.quote = res.data;

                if (!this.quote.lob || this.quote.lob !== 'mpl' || this.quote.lob == 'pl')
                    this.quote.showQL = 'pl';
                else if (this.quote.lob == 'mpl' )
                    this.quote.showQL = 'mpl';

                this.previewPolicy();
            });
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientDomain() {
            return getClientDomain();
        },
        getClientStyle(client,style) {
            return getClientStyle(client, style);
        },
        tokenRoute: async function() {
            const client = getClientDomain();
            await axios.post("/api/consumer/token", { client: client }).then(res => {
                if (res.data.token) {
                loginUser(client, null, res.data.token);
                    this.quoteData();
                }
            });
        },
        loggedIn: function() {
            return isLoggedIn();
        },
        getRole() {
            return getUserData().role;
        }
    },
};
</script>

<style lang="scss">

</style>
