import { render, staticRenderFns } from "./draftrs-automating-professional-liability-insurance-underwriting-binding-servicing.vue?vue&type=template&id=664440a7"
import script from "./draftrs-automating-professional-liability-insurance-underwriting-binding-servicing.vue?vue&type=script&lang=js"
export * from "./draftrs-automating-professional-liability-insurance-underwriting-binding-servicing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports