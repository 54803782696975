import decode from 'jwt-decode'
import axios from 'axios'
import { isDevServer } from "./themer.js";
import Amplify, { Auth } from 'aws-amplify';
import LogRocket from 'logrocket';
import Vue from 'vue';

Amplify.configure({
    Auth: {
        region: 'us-west-1',
        userPoolId: 'us-west-1_IvGcAPqyd',
        userPoolWebClientId: '7i2abu8vcs9vmq2l434kjqbb38',
        authenticationFlowType: 'USER_SRP_AUTH',
    }
});

const TOKEN = 'token';

export function loginUser(client, email, jwt) {
    LogRocket.identify(email, {
        client: client,
        email: email,
    });

    
    !isDevServer() && Vue.config.openreplay.setUserID(email);
    !isDevServer() && Vue.config.openreplay.setMetadata('client', client);
    !isDevServer() && Vue.config.openreplay.setMetadata('email', email);

    
    setAuthToken(jwt);
    
    return jwt
}

export function logoutUser() {
    clearAuthToken()
}

export function setAuthToken(jwt) {
    localStorage.setItem(TOKEN, jwt);
    setAuthHeaders(jwt);
}

export function setAuthHeaders(jwt) {
    axios.defaults.headers.common['authentication'] = 'Bearer ' + jwt;
}

export function getAuthToken() {
    return localStorage.getItem(TOKEN)    
}

export function clearAuthToken() {
    localStorage.removeItem(TOKEN);
    axios.defaults.headers.common['authentication'] = '';
}

let locDataLoaded = false;
export function isLoggedIn() {
    let authToken = getAuthToken()
    let status =  authToken && !isTokenExpired(authToken)
    if (status) {
        !axios.defaults.headers.common['authentication'] ? setAuthHeaders(authToken) : null // if browser ever is refreshed
        const ud = getUserData();
        if (Vue.config.host !== 'localhost') {
            !isDevServer() && Vue.config.openreplay.setMetadata('email', ud.email);
            !isDevServer() && Vue.config.openreplay.setMetadata('client', ud.client);
            if (ud.firstName) {
                !isDevServer() && Vue.config.openreplay.setMetadata('username', ud.firstName + ' ' + ud.lastName);
            }
            !isDevServer() && Vue.config.openreplay.setMetadata('owner', ud.owner);
            !isDevServer() && Vue.config.openreplay.setMetadata('role', ud.role);

            //location data
            if (!locDataLoaded) {
                axios.get('https://pro.ip-api.com/json?key=RKcmiyjAU02mV1B').then(resp => {
                    const locDat = resp.data;
                    !isDevServer() && Vue.config.openreplay.setMetadata('city', locDat.city);
                    !isDevServer() && Vue.config.openreplay.setMetadata('lat', locDat.lat);
                    !isDevServer() && Vue.config.openreplay.setMetadata('long', locDat.long);
                    !isDevServer() && Vue.config.openreplay.setMetadata('country', locDat.countryCode);
                    !isDevServer() && Vue.config.openreplay.setMetadata('region', locDat.region);
                    !isDevServer() && Vue.config.openreplay.setMetadata('ip', locDat.query);
                });
                locDataLoaded = true;
            }
        }
    }

    return status
}

export function getUserData() {
    return decode(getAuthToken());
}

export async function activate(cognitoUser, newPassword) {
    try {
      const change = await Auth.completeNewPassword(cognitoUser, newPassword, {});
      return change;
    } catch (error) {
        return false;
    }
}

export async function cognitoAuth(username, password) {
    try {
        return await Auth.signIn(username, password);
    } catch (error) {
        return false;
    }
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken);
    if (!token.exp) {
        return null;
    }

    let date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date
}

function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}