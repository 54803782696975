<template>
    <div id="wrapper">
        <AgentNav/>
        <main id="main" role="main" class="">
            <section class="mt-5 mx-5 mb-2">
                <div class="text-center">
                    <h3 class="main-color p-2"><i class="fas fa-book-open"></i> User Guide</h3>
                    <p>
                        Select each topic below to expand the content. You can watch a short video or follow the step by step bulleted instructions.
                    </p>
                </div>
            </section>
                <div class="container">
                    <div class="mx-4 p-2 pb-4">
                        <h5 class="main-color pt-2 ml-3">Agent User Guide</h5>
                        <div class="accordion" id="about-draftrs">
                            <div class="card border-bottom">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left main-color p-0" type="button" data-toggle="collapse" data-target="#adOne" aria-expanded="true" aria-controls="adOne">
                                        Starting a Quote and Submission
                                        </button>
                                    </h2>
                                </div>
                                <div id="adOne" class="collapse" aria-labelledby="headingOne" data-parent="#about-draftrs">
                                    <div class="card-body row">
                                        <div class="offset-1 col-md-10 text-center">
                                            <video width="100%" controls>
                                                <source src="/videos/starting_a_quote_and_submission.mp4" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                            <ul class="fa-ul text-left">
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>To manually create a submission -first log into your agency dashboard</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>One you are logged in, click on the “start a new application” link to access the application and quote pages.</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Fill in the details to complete the application</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Once the application is complete you will instantly be able to select quote options with different limits, deductibles and coverage enhancements</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>You can also send this application directly to the Insured so that they can fill in the application and instantly view their quote options.  You can do this by sending your agency “start” link address.  This start link address should have been provided by your underwriter in your intro email.  It is typically “youragencyname.draftrs.com/start”</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2 border-bottom">
                                <div class="card-header" id="headingThree">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left collapsed main-color p-0" type="button" data-toggle="collapse" data-target="#adThree" aria-expanded="false" aria-controls="adThree">
                                            Viewing Submission Activity and Assigning Accounts
                                        </button>
                                    </h2>
                                </div>
                                <div id="adThree" class="collapse" aria-labelledby="headingThree" data-parent="#about-draftrs">
                                    <div class="card-body row">
                                        <div class="offset-1 col-md-10 text-center">
                                            <video width="100%" controls>
                                                <source src="/videos/viewing_submission_activity_and_assigning.mp4" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                            <ul class="fa-ul text-left">
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Whether the account is manually created by the agency or created directly by the Insured through any agency sites or start links – they will always be added to your agency dashboard for your review and access.</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Manually created submissions will be assigned to the agent user who created the account – viewable under “Mine”</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>External and start link submissions created by an Insured are assigned to your agency default user – viewable under “All”</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Admin users at your agency are able to assign accounts to different users by selecting the “reassign” shortcut on the dashboard</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Users will receive an email notification when any account has been created or assigned to them</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2 border-bottom">
                                <div class="card-header" id="headingFour">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left collapsed main-color p-0" type="button" data-toggle="collapse" data-target="#adFour" aria-expanded="false" aria-controls="adFour">
                                            Sending Quotes, Binders and Payment Links to Insureds
                                        </button>
                                    </h2>
                                </div>
                                <div id="adFour" class="collapse" aria-labelledby="headingFour" data-parent="#about-draftrs">
                                    <div class="card-body row">
                                        <div class="offset-1 col-md-10 text-center">
                                            <video width="100%" controls>
                                                <source src="/videos/sending_quotes_binders_and_payment_links.mp4" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                            <ul class="fa-ul text-left">
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Once an account has been quoted, you can email the quote and payment link to the Insured for binding on the final quote screen.</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>The “quote access” option sends the Insured the entire application, quote and payment pages for review so that they can also edit and make changes</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>The “payment link” option only sends the final quote summary and payment page so that the Insured can pay and bind coverage – but they will not be able to edit the application and quote options</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Once payment is processed, coverage will be bound.  There are no additional binder letters that need to be issued.  Coverage cannot be bound prior to payment processing.</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>For Insureds that are using Draftrs for the first time, please remind them to check their SPAM boxes if they do not see these emails in their inbox.  They will come from the email address support@draftrs.com with your agencies name and contact information in the body of the email.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2 border-bottom">
                                <div class="card-header" id="headingFive">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left collapsed main-color p-0" type="button" data-toggle="collapse" data-target="#adFive" aria-expanded="false" aria-controls="adFive">
                                            Viewing Submission Activity and Assigning Accounts
                                        </button>
                                    </h2>
                                </div>
                                <div id="adFive" class="collapse" aria-labelledby="headingFive" data-parent="#about-draftrs">
                                    <div class="card-body row">
                                        <div class="offset-1 col-md-10 text-center">
                                            <video width="100%" controls>
                                                <source src="/videos/viewing_submission_activity_and_assigning.mp4" type="video/mp4">
                                                Your browser does not support the video tag.
                                            </video>
                                            <ul class="fa-ul text-left">
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>After an Insured pays and binds coverage, they will immediately be directed to a screen where they can download their policy and access their account</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>Agents will be able to access this account through the agency dashboard which will list all of your agencies prospective and existing accounts with Draftrs</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>All bound and issued accounts will be directed to a policy dashboard where you and the Insured can access policy documents, issue insurance certificates, endorse the policy with midterm changes and endorsements, update Insured contact information and cancel the policy</li>
                                                <li><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span>If an Insured makes a request for an Insurance Certificate for example, you could either go in to the policy dashboard to download the certificate and email it to the Insured OR you can send the policy dashboard link to the Insured by selecting the shortcut for “emailing the Insured” on you agency account lists.  An email will be sent to the Insured so that they can directly access the policy dashboard to download docs, issue insurance certs and make policy changes themselves</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>

import AgentNav from "../components/AgentNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "UserGuide",
  components: {
    AgentNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
        workType: ''
    };
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    newQuote: function() {
        this.$router.push({name: 'quote', params: { workType: this.workType}});
    }
  },
};
</script>

<style lang="scss">

</style>
