<template>
    <nav class="navbar navbar-expand-lg navbar-light py-4" v-if="!inFrame()">
        <div class="container-fluid">
            <a href="/"><img :src="getClientLogo()" :height="getClientDomain() === 'draftrs' ? '33px' : false" alt=""></a>
            <div v-if="getClientText().phone.length > 0" class="pt-1" :class="getClientText().phone.length < 5 ? 'd-none' : ''" style="font-size: 10pt">
                {{ getClientText().phone }}
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end mt-3" id="navbarNavDropdown">
                <ul class="navbar-nav mr-3">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                           data-toggle="dropdown" aria-expanded="false">Who We Insure</a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href="/design-professionals-insurance" class="dropdown-item">Design Professionals</a>
                            <a href="/LawyersSummary" class="dropdown-item">Lawyers</a>
                            <a href="/AccountantsSummary" class="dropdown-item">Accountants</a>
                            <a href="/ConsultantsSummary" class="dropdown-item">Consultants</a>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                           data-toggle="dropdown" aria-expanded="false">About us</a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href="/AboutUs" class="dropdown-item">About Draftrs</a>
                            <a href="/Press" class="dropdown-item">Press Releases</a>
                            <a href="/FAQ" class="dropdown-item">FAQ</a>
                            <a href="/ContactUs" class="dropdown-item">Contact Us</a>
                        </div>
                    </li>
                </ul>
                <router-link class="btn btn-outline-primary mr-2 mt-3 mt-md-0" to="dashboard" v-if="!loggedIn()">Agent
                    Login
                </router-link>
                <!-- <router-link class="btn btn-outline-primary mr-2 mt-3 mt-md-0" to="logout" v-else>Logout</router-link> -->
                <a v-if="!loggedIn()" href="/AgentsContactUs" class="btn btn-secondary mt-3 mt-md-0">Get Appointed</a>
            </div>
        </div>
    </nav>
</template>

<script>

import {isLoggedIn} from "../Utils/auth.js";
import {getClientLogo, getClientDomain, getClientText} from "../Utils/themer.js";
import Vue from "vue"

export default {
    name: "ConsumerQuoteNav",
    components: {},
    props: {},
    data: function () {
        return {};
    },
    computed: {
        loading() {
            return Vue.prototype.loading.value
        }
    },
    methods: {
        loggedIn: function () {
            return isLoggedIn();
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientText() {
            return getClientText();
        },
        getClientDomain() {
            return getClientDomain();
        },
        inFrame() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }
    }
};
</script>

<style lang="scss">
</style>
