<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1 my-1">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							Press Releases
						</h1>
					</div>
				</div>
			</div>
		</header>
		<main class="container-fluid bg-grey">
			<div class="container py-5">
                <div class="col-md-10 offset-md-1">
                    <p class="pb-3">
                        <b><center>Draftrs Partners With The National Association of Certified Mediators To Deliver Professional Liability Insurance To Mediators  </center></b>
                    </p>
                    <p class="pb-3">
                        <b>Dallas, Texas – November 29, 2023 –</b>     
                        Draftrs, an insurtech company focused on modernizing small business professional liability insurance, today announced a partnership with the National Association of Certified Mediators (NACM), the largest professional certification association in the world for mediators, to provide instant access to comprehensive professional liability insurance products to NACM members.
                    </p>
                    <p class="pb-3">
                        The Draftrs embedded platform provides instant quote-to-bind professional liability insurance and allows insureds to issue certificates of insurance and manage their policy online without the need to speak to an underwriter.  Draftrs professional liability insurance is underwritten by national insurance carriers rated A (Excellent) by AM Best.  
                    </p>
                    <p class="pb-3">
                        “We are very excited to partner with NACM,” said Audrey Lau, co-founder and CEO of Draftrs.  “NACM provides incredible value to mediators, from training courses and certification examinations to continuing education and industry information.  NACM members can now also get instant quotes for affordable and comprehensive professional liability coverage that is specifically designed for mediators.”  
                    </p>
                    <p class="pb-3">
                        Draftrs professional liability insurance through the NACM is available at <a href="https://www.mediatorcertification.org/mediator-insurance/" >https://www.mediatorcertification.org/mediator-insurance/</a>. 
                    </p>
                    <p class="pb-3">
                        <b>About Draftrs, Inc. (Draftrs)</b><br/>
                        Draftrs, Inc. (Draftrs) is a technology-enabled, managing general agent (MGA) that is streamlining, automating, and digitizing 
                        the insurance underwriting and distribution process for carrier and broker partners to create a more efficient way to deliver 
                        professional liability insurance to the small commercial market. Draftrs provides instant, direct access and enables distribution 
                        partners to become a digital agency without having to make any costly tech upgrades or investments.  For more information 
                        please visit <a href="https://draftrs.com" target="_blank" >https://draftrs.com</a>.
                    </p>
				</div>
                <div class="col-md-10 offset-md-1">
                    <h2 class="pb-3 main-color">More</h2>
                    <ul>
                        <li><a href="/press/draftrs-partners-with-the-national-association-of-certified-mediators">Draftrs Partners With The National Association of Certified Mediators to Deliver Professional Liability Insurance To Mediators</a></li>
                        <li><a href="/press/draftrs-partners-with-the-american-institute-of-building-design">Draftrs Partners With The American Institute of Building Design To Deliver Professional Liability Insurance To Building Designers</a></li>
                        <li><a href="/press/draftrs-automating-professional-liability-insurance-underwriting-binding-servicing">Draftrs Now Automating 100% Of Professional Liability Insurance Underwriting, Binding and Servicing</a></li>
                        <li><a href="/press/draftrs-and-shingle-partner">Draftrs And Shingle Partner To Deliver Professional Liability Insurance To Freelance and Small Engineering Firms</a></li>
                        <li><a href="/press/draftrs-launches-48-states">Draftrs Professional Liability Product For Architects and Engineers is Now Available In 48 States</a></li>
                        <li><a href="/press/draftrs-launches-new-digital-professional-liability-insurance-platform">Draftrs Launches New Digital Professional Liability Insurance Platform</a></li>
                    </ul>
                </div>
			</div>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "PressReleases",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
    },
    watch: {
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
    },
};
</script>

<style lang="scss">

</style>
