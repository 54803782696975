<template>
  <div id="wrapper">
    <StandardNav/>
    <main id="main" role="main" class="">
      <section class="mt-5 mx-5">
        <div class="row mt-5">
          <div class="text-center offset-3 col-md-6">
            <h3 class="main-color p-2"><i class="fas fa-user-circle"></i> Access your Policy</h3>
            <p class="py-2 px-3">
                If you have an existing Insurance Policy, enter your email address.<br/> We will email you a login link to access your policy. 
            </p>
          </div>
        </div>
        <div class="offset-4 col-md-4">
          <div>
            <form @submit.prevent="findAccount">
              <div class="input-group mb-3">
                <input type="email" class="form-control" v-model="email" placeholder="Enter your email address"/>
                <div class="input-group-append">
                    <button class="btn btn-success" type="submit"><i v-if="spinner" class="fas fa-spinner fa-pulse mr-1"></i> {{ spinner ? 'Sending' : 'Submit' }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
    <StandardFooter/>
  </div>
</template>

<script>

const axios = require('axios');
import { getClientDomain } from "../Utils/themer.js";
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "Recover",
  components: {
    StandardNav,
    StandardFooter
  },
  mounted () {
  },
  data: function() {
    return {
      email: null,
      spinner: false,
    };
  },
  methods: {
    async findAccount(e) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email.toLowerCase().match(regex)) {
        e.preventDefault();
        this.spinner = true;
        await axios.post('/api/recover', { email: this.email, client: getClientDomain() }).then(() => {
          this.spinner = false;

          this.$toast.open({ 
            message: 'An email has been sent to <strong>'+ this.email +'</strong> with a login link to access your policy.',
            type: 'success',
            dismissible: true,
            duration: 20000,
            position: 'top-right'
          });
        });
      }
    }
  },
};
</script>

<style lang="scss">
</style>
