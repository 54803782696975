<template>
    <nav class="navbar navbar-expand-lg navbar-light py-4">
        <a href="/home"><img :src="getClientLogo()" :height="getClientDomain() == 'draftrs' ? '33px' : false" alt=""></a>
    </nav>
</template>

<script>

import {isLoggedIn} from "../Utils/auth.js";
import {getClientDomain, getClientLogo} from "../Utils/themer.js";

export default {
    name: "EmptyNav",
    props: {},
    data: function () {
        return {};
    },
    methods: {
        loggedIn: function () {
            return isLoggedIn();
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientDomain() {
            return getClientDomain();
        }
    }
};
</script>

<style lang="scss">
</style>
