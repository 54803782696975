<template>
<body>
    <div id="wrapper">
      <AgentNav/>

      <nav aria-label="breadcrumb" class=" d-md-block mb-0">
          <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard" >Dashboard</a></li>
          </ol>
      </nav>
      <div class="row row-border p-2">
        <div class="col-md-4 text-center pt-2">
          <select v-model="lob" style="width: 100%" class="form-control" @change="fetchAlgorithm()">
            <option name="pl" value="pl" label="pl"></option>
            <option name="mpl" value="mpl" label="mpl"></option>
          </select>
        </div>
        <div class="col-md-8 p-2">
          <a class="btn btn-outline-secondary btn-sm" @click="downloadAlgo()">Download Excel Algorithm</a>
          <a class="btn btn-outline-secondary btn-sm ml-2" @click="showUploadModal=true">Update Excel Algorithm</a>
          <a class="btn btn-outline-secondary btn-sm ml-2" @click="toggleMappings()">View Algorithm Mappings</a>
        </div>
      </div>
      <div class="row row-border">
        <div class="col-md-4 row-border">
          <table class="table">
            <tr v-for="count, name in factors" v-bind:key="name" v-show="algorithm && algorithm.factors.includes(name)">
              <td @click="selectFactor(name)">{{  name }}
                 <span class="badge badge-secondary ml-2">{{ count }}</span>
                 <span class="badge badge-secondary ml-2" @click="downloadFactor(name)">Download</span>
                 <span class="badge badge-secondary ml-2" @click="selectedFactor=name; showFactorModal=true;">Upload</span>
              </td>
            </tr>
          </table>
        </div>
        <div class="col-md-8 row-border">
          <textarea v-model="mappings" class="mapping" v-if="mappings" rows="30"></textarea>

          <table class="table">
            <tr v-for="row, rowId in factorData" v-bind:key="rowId">
              <td v-for="col, colId in row" v-bind:key="colId">
                {{ col }}
              </td>
          </tr>
          </table>
        </div>
      </div>      

      <b-modal
          v-model="showUploadModal"
          size="lg"
          title="Profile Update"
          hide-footer>
          <input type="file" name="file" ref="algorithmUpload"/>
          <textarea v-if="algorithm" v-model="algorithm.logic" class="mapping" rows="30"></textarea>
          <input type="submit" @click="updateAlgorithm()"/>
        </b-modal>

        <b-modal
          v-model="showFactorModal"
          size="lg"
          title="Profile Update"
          hide-footer>
          <input type="file" name="file" ref="factorUpload"/>
          <input type="submit" @click="updateFactor()"/>
        </b-modal>
  </div>
</body>
</template>

<script>
//import { getUserData } from '../Utils/auth.js';
import AgentNav from "../components/AgentNav.vue";
import axios from 'axios';
import { Buffer } from 'buffer';

export default {
  name: 'rating',
  props: {
  },
  components: {
    AgentNav
  },
  data: function () {
    return {
      lob: 'pl',
      factors: [],
      factorData: null,
      algorithm: null,
      mappings: null,
      showUploadModal: false,
      showFactorModal: false,
      selectedFactor: null
    }
  },
  async mounted() {
    let factors;
    await axios.get('/api/rater/factors').then((res) => {
      factors = res.data;
    });
    this.factors = factors;

    await this.fetchAlgorithm();
  },
  watch: {
    
  },
  methods: {
    async selectFactor(id) {
      let factors;
      await axios.get('/api/rater/factors/'+id).then((res) => {
        factors = res.data;
      });
      this.factorData = factors;
    },
    async fetchAlgorithm() {
      let algo;
      await axios.get('/api/rater/algorithm/' + this.lob).then((res) => {
        algo = res.data;
      });
      this.algorithm = algo;
    },
    async downloadAlgo() {
      const url = window.URL.createObjectURL(new Blob([Buffer.from(this.algorithm.excelData, 'base64')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'algo-'+ this.lob +'.xlsx');
      document.body.appendChild(link);
      link.click();
    },
    async downloadFactor(factorId) {
      let fact;
      await axios.get('/api/factorsrc/' + this.lob + '/' + factorId).then((res) => {
        fact = res.data;
      });
      
      const url = window.URL.createObjectURL(new Blob([Buffer.from(fact.src, 'base64')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'factor-'+ this.lob +'-'+factorId+'.xlsx');
      document.body.appendChild(link);
      link.click();
    },
    async toggleMappings() {
      if (this.mappings) {
        this.mappings = null;
      } else {
        this.mappings = this.algorithm.logic;
      }
    },
    async updateFactor() {
      const file = this.$refs.factorUpload.files[0];
      var reader = new FileReader();
      let lob = this.lob;
      let fact = this.selectedFactor;

      reader.readAsArrayBuffer(file);
      reader.onload = async function (evt) {
        let postBdy = { "excel": Buffer.from(evt.target.result).toString('base64')}
          await axios.post('/api/factorsrc/' + lob + '/' + fact, postBdy).then(() => {
            
          });
      }
    },
    async updateAlgorithm() {
      const file = this.$refs.algorithmUpload.files[0];
      const logic = this.algorithm.logic;
      const lob = this.lob;
      var reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = async function (evt) {
        let postBdy = { "excel": Buffer.from(evt.target.result).toString('base64'), "logic": logic}
          await axios.post('/api/rater/algorithm/' + lob, postBdy).then(() => {
            
          });
      }
    }
  }
}
</script>

<style lang="scss">
  #users-tbl {
    cursor: pointer;
  }
  .row-border {
    border: 1px solid black;
  }
  textarea.mapping {
    width: 100%;
    font-size: 10px;
  }
</style>