<template>
    <div id="wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="text-block">
                    <h2 class="main-color"> <i class="far fa-handshake"></i>{{ isAgencyBill() ? 'Your Policy is Bound' : 'Thank you for your business!' }}</h2>
                    <p v-if="issueAction=='issue'" class="my-3" style="line-height: 1.5">
                        <span v-if="isAgencyBill()">
                            A temporary binder has been issued. Your agent will contact you with payment details.<br/> You will have 14 days to remit premium payment to your agent {{ quote.agentName }}.<br/> ☎️ {{ quote.agentPhone }} <br/> 
                            <a :href="'mailto:' + quote.agentEmail +'?subject=Purchase%20success%20Draftrs%20question'">✉️ {{ quote.agentEmail }}</a>
                        </span>
                        <span v-else>
                            Welcome to Draftrs!  We are so happy you joined us! If you have any questions please contact our customer service team at
                            <a href="mailto:support@draftrs.com?subject=Purchase%20success%20Draftrs%20question">support@draftrs.com</a>
                        </span>
                        
                    </p>
                    <p v-else-if="issueAction=='endorse'" class="my-3">We&lsquo;ve made your policy endorsement!<br>  
                        If you have any questions please contact our customer service team at 
                        <a href="mailto:support@draftrs.com?subject=Purchase%20success%20Draftrs%20question">support@draftrs.com</a>
                    </p>
                    <p v-else class="my-3">We&lsquo;ve made your updates!<br>  
                        If you have any questions please contact our customer service team at 
                        <a href="mailto:support@draftrs.com?subject=Purchase%20success%20Draftrs%20question">support@draftrs.com</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="upp-text">
            <h3 class="main-color">Policy Confirmation</h3>
        </div>
        <div class="list-area">
            <dl class="row my-4">
                <dt class="col-6">Policy Number:   </dt>
                <dd class="col-6">
                    <span v-if="hasPolicyNumber()">{{ quote.policyNumber }}</span>
                    <span v-else><i class="fas fa-spinner fa-pulse"></i></span>

                </dd>
            
                <dt class="col-6">Coverage:</dt>
                <dd class="col-6">Professional Liability   </dd>
            
                <dt class="col-6">Named Insured</dt>
                <dd class="col-6">{{ quote.insured }}</dd>

                <dt class="col-6">Effective Date:   </dt>
                <dd class="col-6">{{ formatDate(quote.effective) }} - {{ getExpDt() }}</dd>
            </dl>
        </div>
        <div v-if="!isAgencyBill() && hasPolicyNumber()">
            <button :disabled="downloadSpinner" class="btn btn-primary btn-lg text-center" style="width: 240px"  @click="downloadPolicyDoc()">
                <i v-if="downloadSpinner" class="fas fa-spinner fa-pulse"></i> Download Policy
            </button>
            
            <div>
                <router-link :to="{ name: 'policy', params: { customerid: quote.customerId }}" class="btn btn-primary btn-lg mt-3" style="width: 240px" >Manage Policy</router-link>
            </div>
        </div>
        <div v-else-if="!isAgencyBill()">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="align-middle spinner-border text-secondary" style="width: 5rem; height: 5rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="stripeLink" >
            <h2 class="main-color my-5">
                Wait!!! Theres one more step to complete your purchase.
            </h2>
            <p><i class="fas fa-exclamation-triangle"></i> Click the button below to complete your ACH transaction using Stripe micro deposits. It may take 1 to 2 days for your micro deposits to show up. We also emailed you the link to verify your micro payments.</p>
            <a :href="stripeLink" target="_blank" class="btn btn-primary btn-md text-center" style="width: 400px" >Verify your ACH micro deposit</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: "PurchaseSuccess",
  props: {
    quote: {
      type: Object,
      default() {
        return {};
      }
    },
    issueAction: {
      type: String,
      default() {
        return 'issue';
      }
    },
    stripeLink: {
      type: String,
      default() {
        return null;
      }
    }
  },
  data: function() {
    return {
        downloadSpinner: false
    };
  },
  mounted() {
    
  },
  methods: {
    isAgencyBill: function() {
        return this.quote.payMethod == 'agencyBill' ? true : false;
    },
    hasPolicyNumber: function() {
        return this.hasObject(this.quote, 'policyNumber') ? true : false;
    },
    downloadPolicyDoc: function() {
        this.downloadSpinner = true;
        axios({
            url: '/api/doc/'+this.quote.customerId+'/'+this.quote.policyNumber+'/policy', 
            method: 'POST',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'policy.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadSpinner = false;
        });
    },
    formatDate: function(dt) {
        return moment(dt).format('MM/DD/YYYY');
    },
    getExpDt: function() {
        return moment(this.quote.effective).add(this.quote.policyTerm, 'months').format('MM/DD/YYYY');
    },
  },
  
};
</script>

<style lang="scss">

</style>
