<template>
    <div>
        <!-- <div class="w-100 main-color-bg" style="height: 50px; background-color: purple">
            &nbsp;
        </div> -->
        <footer class="container-lg py-5" v-if="client == 'draftrs'">
            <div class="row mt-5">
                <div class="col-md-6 mb-5 text-center">
                    <a v-if="!loggedIn()" href="/login" class="btn btn-outline-primary mr-3">Login</a>
                    <router-link class="btn btn-outline-primary mr-3 mt-md-0" to="logout" v-else>Logout</router-link>
                    <a href="/AgentsContactUs"  class="btn btn-secondary">Get Appointed</a>

                    <div class="row footer-links">
                        <div class="col-md-7 d-flex flex-column align-items-start gap-2">
                            <p class="text-secondary font-weight-bold mt-5">Coverage</p>
							<a href="/design-professionals-insurance">Architects &amp; Engineers</a>
							<a href="/advertising-creatives">Advertising &amp; Creative Professionals</a>
							<a href="/bookkeepers-taxpreparers">Bookkeepers &amp; Taxpreparers</a>
							<a href="/construction-design-consultants">Construction Design &amp; Consulting</a>
							<a href="/ConsultantsSummary">Consultants</a>
							<a href="/LawyersSummary">Mediators &amp; Arbitrators</a>
							<a href="/recruiters-staffing">Recruiters &amp; Staffing</a>
							<a href="/other-professions">Other Professions</a>
                        </div>
                        <div class="col-md-5 mt-5 d-flex flex-column align-items-start gap-2">
                            <p class="text-secondary font-weight-bold">Agents & Partners</p>
                            <a href="/AgentsPartners">Why partner with us</a>
                            <a href="/AgentsContactUs">Get appointed!</a>
                            <a v-if="!loggedIn()" href="/login">Login</a>
                        </div>
                        <div class="col-md-7 mt-5 d-flex flex-column align-items-start gap-2">
                            <p class="text-secondary font-weight-bold">About</p>
                            <a href="/AboutUs">About Draftrs</a>
                            <a href="https://software.draftrs.com" target="_blank" >Software</a>
                            <a href="/Press" >Press Releases</a>
                            <a href="/FAQ">FAQ</a>
                            <a href="/ContactUs">Contact us</a>
                            <a href="/FileClaim">File a claim</a>
                            
                        </div>
                        <div class="col-md-5 mt-5 d-flex flex-column align-items-start gap-2">
                            <p class="text-secondary font-weight-bold">Resources</p>
                            <a href="/Professional-Liability-Insurance">Professional Liability</a>
                            <a href="/General-Liability-Insurance" >General Liability</a>
                            <router-link to="Insurance-Frequently-Used-Terms-Definitions">Insurance Glossary</router-link>
                            <router-link to="Blog">Blog</router-link>
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-6 d-flex flex-column justify-content-between">
                    <div>
                        <a class="" href="/index.html"
                            ><img src="/images/draftrs-logo-nav.png" alt="Draftrs Logo"
                        /></a>
                        <p class="my-5 text-black text-text-muted">
                            Our mission is to make professional liability insurance easier and
                            simpler for small business professional service firms and the
                            agents that serve them. We provide direct access so that you can
                            get a quote, buy coverage and manage your policies online.
                        </p>
                    </div>
                    <div class="mt-5">
                        <h4 class="my-3 font-weight-bold">Stay up to date with our Newsletter</h4>
                        <form action="" class="position-relative">
                            <input
                                disabled
                                type="email"
                                class="form-control"
                                id="newsletter-email"
                                aria-describedby="email"
                                placeholder="Enter your e-mail"
                                style="padding: 8px 16px;"
                            />
                            <a v-b-tooltip.hover="'Newsletter coming soon!'" id="newsletter-submit-btn" type="submit" class="btn btn-secondary position-absolute"  >Subscribe</a>
                        </form>
                        <div class="pt-2 pl-2 d-flex ms-1 my-3" style="gap: 5px">
                            <a href="mailto:support@draftrs.com" class="main-color" ><i style="padding-top: 2px" class="far fa-envelope fa-2x"></i></a>
                            <a target="_blank" href="https://twitter.com/draftrs_inc" ><i style="padding-top: 3px" class="main-color mt-0 fab fa-twitter fa-2x"></i></a>
                            <a target="_blank" href="https://www.linkedin.com/company/draftrs-inc" class="main-color" ><i class=" fab fa-linkedin-in fa-2x"></i></a>
            
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
            <p class="mt-5 footer-links">Draftrs, Inc. © {{ year }} All rights reserved 
                | 
                <a class="mx-2" target="_BLANK" href="/privacypolicy" >Privacy Policy</a> | 
                <a class="mx-2" target="_BLANK" href="/termsofservice" >Terms of Service</a>
            </p>
            </div>
            
        </footer>
        <footer class="text-center py-4 bg-light" style="min-height: 100vh;" v-else>
        </footer>
    </div>
</template>
<script>

import { isLoggedIn, } from "../Utils/auth.js";
import { getClientDomain, getClientLogo, getClientText } from "../Utils/themer.js";

export default {
    name: "StandardFooter",
    props: {},
    data: function() {
    return {
        client: getClientDomain(),
        year: 2021
    };
    },
    mounted() {
        this.getYear();
    },
    methods: {
        getClientLogo() {
            return getClientLogo();
        },
        getClientText() {
            return getClientText();
        },
        loggedIn: function() {
            return isLoggedIn();
        },
        getYear: function() {
            const date = new Date();
            this.year = date.getFullYear();
        }
    },
};
</script>

<style lang="scss">
</style>
