<template>
    <vue-html2pdf 
        id="vue-html2pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :pdf-quality="2"
        :manual-pagination="true"
        filename="draftrs-quote-binder-letter"
        pdf-format="letter"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <div>
                <main v-if="quote.owners" id="main" role="main">
                    <section class="col-md-12">
                        <div class="card-body">
                            <div>
                                <a href="/home"><img :src="this.getClientLogo(quote.owners[0])" style="max-height:55px" :height="this.getClientStyle(quote.owners[0], 'agent-logo-height')" alt=""></a>
                            </div>
                            <div class="d-flex justify-content-center mb-5">
                                <QuoteSummary :quote="quote" :prorate="prorate" :issueAction="context.action" :header="''" />
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </section>
    </vue-html2pdf>
</template>

<script>

const axios = require('axios');
import VueHtml2pdf from 'vue-html2pdf'
import QuoteSummary from "../components/QuoteSummary.vue";
import { getClientDomain, getClientLogo, getClientStyle} from "../Utils/themer.js";

export default {
name: "Quoteletter",
components: {
    QuoteSummary,
    VueHtml2pdf
},
props: {
    quoteData: {
        type: Object,
        default() {
        return {};
        }
    },
},
data: function() {
    return {
        context: {},
        quoteId: null,
        token: null,
        prorate: null,
        loadingSpinner: false,
        quote: {}
    };
},
mounted() {
    this.quote = this.quoteData;
    this.loadQuote();
},
methods: {
    generateReport () {
        this.$refs.html2Pdf.generatePdf()
        
    },
    loadQuote: async function() {
        
        const quoteId = this.$route.params.id;

        if (quoteId)
            this.quote.id = quoteId;

        if(!Object.prototype.hasOwnProperty.call(this.quote, 'limit')) {
            await axios.get('/api/quote/' + this.quote.id).then((res) => {
                this.quote = res.data;

                if (!this.quote.lob || this.quote.lob !== 'mpl' || this.quote.lob == 'pl')
                    this.quote.showQL = 'pl';
                else if (this.quote.lob == 'mpl' )
                    this.quote.showQL = 'mpl';

                this.generateReport();
            });
        } else {
            if (!this.quote.lob || this.quote.lob !== 'mpl' || this.quote.lob == 'pl')
                this.quote.showQL = 'pl';
            else if (this.quote.lob == 'mpl' )
                this.quote.showQL = 'mpl';

            this.generateReport();
        }

    },
    getClientLogo(mob) {
        return getClientLogo(mob);
    },
    getClientDomain() {
        return getClientDomain();
    },
    getClientStyle(client, style) {
        getClientStyle(client, style)
    }
},
};
</script>

<style lang="scss">

</style>