<template>
    <div>
        <StandardNav/>
		<header class="section-fluid bg-purple">
			<div class="container">
				<div class="row">
					<div
						class="col-md-5 d-flex flex-column justify-content-center align-items-md-start py-5"
					>
						<h1 class="display-3 text-white font-weight-bold">Professional Liability</h1>
						<p class="text-white">
							Professional liability, sometimes also referred to as errors & omissions (E&O) and malpractice insurance, covers a professional in the event a client or other third party sues for damages that arise out errors or omissions in the professional’s works and services.
						</p>
						<a href="/getaquote" class="mx-auto btn btn-secondary mt-3"
							>Get a quote</a
						>
					</div>
					<div class="col-md-6 offset-md-1">
						<div class="half-width-img">
							<img
								src="/images/discussing-blueprint.jpg"
								alt="Young male architect at desk with schematics"
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main>
			<!-- About FAQ -->
			<section class="container py-5">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1">
						<h2 class="font-weight-bold mb-4">Professional Liability FAQs</h2>
						<div class="accordion" id="about-faq">
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutOne"
											aria-expanded="true"
											aria-controls="aboutOne"
										>
											Do I need professional liability?
										</button>
									</h2>
								</div>
								<div id="aboutOne" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											Many clients will contractually require professionals to carry professional liability insurance that covers their work. Depending on the profession, some states may also require the professional to obtain professional liability insurance as part of licensure requirements. Many small firms end up purchasing coverage as a result of these contractual and regulatory requirements but with coverage being so affordable, it is prudent for professionals to purchase the coverage regardless. A professional claim against you can be costly and stressful but a professional liability policy can provide legal assistance and protect you and your business. The policy will take on the claim defense, cover legal costs and pay awarded damages for covered matters
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutTwo"
											aria-expanded="false"
											aria-controls="aboutTwo"
										>
											Claims made and retroactive coverage
										</button>
									</h2>
								</div>
								<div id="aboutTwo" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											Most professional liability policies are written on a "claims made" basis. An important part of the claims made policy is the retroactive date, which is the date that the professional services were actually performed. “Claims made” policies provide coverage for claims made during the policy period related to professional services that were performed on or after the retroactive date.
For example, you buy a professional policy for the first time in 2021 with a 2021 retroactive date. Later in the year a client sues you for work you performed in 2020. That claim would not be covered because the retroactive date only picks up work you performed from 2021 onward.
										</p>
										<ul class="fa-ul">
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> If you are purchasing professional liability for the first time, your retroactive date will match the inception date of the policy. When you renew your coverage you should always make sure you carry over this first retroactive date on your future policies.
											</li>
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> If you have purchased continuous professional liability coverage in the past, it is important that you maintain that first retroactive date. This ensures all of you past work, from that first retroactive date forward, will continue to be covered should you receive a claim later on.
											</li>
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> You should never give up the retroactive date in exchange for a lower premium. You pay the premium for the work you perform but most professional liability claims will come in after you complete the work and in some cases, years after the work is complete.
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutThree"
											aria-expanded="false"
											aria-controls="aboutThree"
										>
											How is professional liability different from general liability?
										</button>
									</h2>
								</div>
								<div id="aboutThree" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											A general liability policy covers third party bodily injury and property damage that occurs on your premises. General liability also includes “covered or completed operations” coverage to cover a firms business operations however, this often does not include professional services.
										</p>
										<p>
											In fact, many general liability policies will have a “professional services” exclusion under this coverage part. That means there would be no coverage under a general liability policy for claims arising out of a professional firms’ services. 
										</p>
										<p>
											By contrast, a professional liability policy is intended to cover third party claims due to errors or omissions in professional services. An unsatisfied client suing the professional for damages is the most common claimant under the policy. All professional liability policies provide coverage for financial loss claims and depending on the profession, some policies will also include coverage for bodily injury and property damage claims.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "FAQ",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>
