<template>
<body>
    <div id="wrapper">
      <AgentNav/>

      <nav aria-label="breadcrumb" class=" d-md-block mb-0">
          <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard" >Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="Mail">Mail</li>
          </ol>
      </nav>

        <div class="card card-radius shadow mt-2 mb-5 mx-3">
          <div class="card-body">
            <table class="table table-responsive table-md small" v-if="records.length">
              <thead>
                <tr>
                  <th v-for="key in keys" :key="key" scope="col">{{ key }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="record, idx in records" :key="idx">
                  <td>{{ record['Event'] }}</td>
                  <td>{{ getMoment(record['Timestamp']) }}</td>
                  <td>{{ record['Subject'] }}</td>
                  <td>{{ record['ToAddress'] }}</td>
                  <td>{{ record['SourceEnv'] }}</td>
                  <td>{{ record['QuoteId'] }}</td>
                  <td>{{ record['User'] }}</td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
  </div>
</body>
</template>

<script>
import moment from 'moment';
import AgentNav from "../components/AgentNav.vue";
import axios from 'axios'

export default {
  name: 'mail',
  props: {
  },
  components: {
    AgentNav
  },
  data: function () {
    return {
      keys: { "Event": "Event", "Timestamp": "Timestamp", "Subject": "Subject", "ToAddress": "Sent To", "SourceEnv": "Environment", "QuoteId": "Quote Id", "User": "User" },
      records: []
    }
  },
  async mounted() {
    this.records = await this.getMailLogs();
  },
  methods: {
    getMoment(time) {
      return moment(time).format('ll h:mm a z') + ' PST';
    },
    getMailLogs: async function() {
      let ret;
      await axios.post('/api/mail/logs', {})
      .then((res) => {
        ret = res.data;
      })
      .catch((e) => { console.log(e); ret = false; });

      return ret;
    }
  }
}
</script>

<style lang="scss"></style>