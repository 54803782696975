<template>
<body>
    <div id="wrapper">
      <AgentNav/>

      <nav aria-label="breadcrumb" class=" d-md-block mb-0">
          <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard" >Dashboard</a></li>
          </ol>
      </nav>
      <div class="row p-4">
        <div class="col">
          <input type="text" placeholder="Quote ID" v-model="quoteId" @change="changeQuoteId()"/>
          <textarea class="col-md-12" placeholder="Quote Data" v-model="quoteData" rows="15"></textarea>
          <a class="btn btn-secondary" @click="rate()">Rate</a>
          <a class="btn btn-outline-dark btn-sm ml-2" v-if="ratingId" @click="download()">Download Rater</a>
        </div>
      </div>
      <div class="row p-4" v-if="ratingId">
        <div class="col">
          <h5>Total Premium: ${{ premium }}</h5>
          <input type="text" placeholder="Rating ID" @change="audit()" v-model="ratingId"/>
          <textarea class="col-md-12" placeholder="Rating Data" v-model="ratingData" rows="15"></textarea>
        </div>
      </div>

      <div class="row p-4">
        <div class="col">
          <h5 v-if="ratingId">Rating Factors</h5>
          <table class="table table-sm">
            <tr v-for="lookup, idx in lookups" v-bind:key="idx">
                <td>{{ lookup.name }}</td>
                <td>{{ lookup.lookups }}</td>
                <td>{{ lookup.results }}</td>
            </tr>
          </table>
        </div>
      </div>
      
      
      
      
  </div>
</body>
</template>

<script>
//import { getUserData } from '../Utils/auth.js';
import AgentNav from "../components/AgentNav.vue";
import axios from 'axios';
import { Buffer } from 'buffer';

export default {
  name: 'rating',
  props: {
  },
  components: {
    AgentNav
  },
  data: function () {
    return {
      quoteId: null,
      quoteData: null,
      ratingId: null,
      ratingData: null,
      lookups: [],
      rater: null,
      premium: null
    }
  },
  async mounted() {
    if (this.$route.params.quoteId) {
      this.quoteId = this.$route.params.quoteId;
    }
  },
  watch: {
    quoteId: function() {
      this.changeQuoteId();
    }
  },
  methods: {
    async getQuoteData (quoteId) {
      let quote;
      await axios.get('/api/quote/' + quoteId).then((res) => {
            quote = res.data;
          });
          
      return JSON.stringify(quote);
    },
    async audit() {
      let quote;
      this.lookups = [];
      await axios.get('/api/audit/'+this.ratingId).then((res) => { quote = res.data; });
      for (let i in quote.lookups) {
        let lookupVals = [];
        for (let o=1; o<=4; o++) {
          let exactInc = 'exact'+o;
          if (quote.lookups[i][exactInc]) {
            lookupVals.push(quote.lookups[i][exactInc].toString());
          }
        }
        this.lookups.push({
          name: quote.lookups[i].factor,
          lookups: lookupVals.join(', '),
          results: quote.lookups[i].results.join(', '),
        });
      }
    },
    async rate () {
      let quote;
      await axios.post('/api/ratingv2/', this.quoteData).then((res) => {
            quote = res.data;
          });
      this.ratingData = JSON.stringify(quote);
      this.ratingId = quote.summary.id;
      this.premium = quote.summary.premium;

      await this.audit();
      return JSON.stringify(quote);
    },
    async changeQuoteId() {
      this.quoteData = await this.getQuoteData(this.quoteId);
    },
    async download() {
      let quote;
      await axios.get('/api/rating/'+this.ratingId).then((res) => {
        quote = res.data; 
        const url = window.URL.createObjectURL(new Blob([Buffer.from(res.data, 'base64')]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'rater-'+ this.ratingId +'.xlsx');
        document.body.appendChild(link);
        link.click();
      });
      return quote;
    }
  }
}
</script>

<style lang="scss">
  #users-tbl {
    cursor: pointer;
  }
</style>