<template>
    <div>
        <StandardNav/>
		<header class="about-header container-fluid d-flex align-items-center">
			<div class="container py-5">
				<div class="row">
					<div class="col-md-5">
						<img
							src="/images/phone-quote-min.png"
							alt="App get a quote instantly screen. Speedy, Easy and Fast."
							class="about-img-fluid"
						/>
					</div>
					<div class="col-md-7 d-flex flex-column justify-content-end">
						<h2 class="fs-3 font-weight-bold text-white">Our mission</h2>
						<h1 class="display-3 font-weight-bold text-white">
							Making Professional Liability Insurance Easier
							and For Everyone
						</h1>
					</div>
				</div>
			</div>
		</header>
		<main>
			<!-- Mission Statement -->
			<section class="container-fluid">
				<div class="container py-5">
					<div class="row my-5">
						<div class="col-md-7 offset-md-5 fs-3">
							<h3>
								Our mission is to make professional liability insurance easier
								and simpler for small business professional service firms and
								the agents that serve them.
							</h3>
							<br />
							<h3>
								We get it. For professional firms, professional liability
								insurance is the most important insurance purchase and that is
								our main focus.
							</h3>
						</div>
					</div>
				</div>
			</section>
			<!-- Features Grid -->
			<section
				class="container-fluid text-banner-section d-flex justify-content-center align-items-center"
			>
				<div class="col-md-1">
					<span class="text-white d-inline text-end" style="font-size: 200px"
						></span
					>
				</div>
				<div class="col-md-6">
					<h2 class="display-1 text-white font-weight-bold d-inline">
						Fast. Easy. Protected.
					</h2>
				</div>
			</section>
			<section class="container-fluid bg-grey py-5">
				<div class="container">
					<div class="row my-5">
						<div class="col-md-5 offset-md-1">
							<h2 class="display-4 font-weight-bold">
								Reliable.<br />
								Secure.
							</h2>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 offset-md-1">
							<img
								class="my-4"
								src="/images/icons/medal-icon.png"
								alt="Medal icon"
							/>
							<h3 class="font-weight-bold">Always-on 24/7 access</h3>
							<p class="text-black text-text-muted mb-5">
								Morning, noon, or night and weekends too!  24/7 access to
								quote, bind and manage your policy.
							</p>
						</div>
						<div class="col-md-4 offset-md-2">
							<img
								class="my-4"
								src="/images/icons/shield-icon.png"
								alt="Shield icon"
							/>
							<h3 class="font-weight-bold">Your data is safe with us</h3>
							<p class="text-black text-text-muted mb-5">
								Keep complete control and retention of your customer data.
							</p>
						</div>
					</div>
				</div>
			</section>
			<!-- Hero image with text -->
			<section class="container-fluid p-0 bg-grey">
				<div class="row">
					<div class="col-md-6 p-0">
						<img
							src="/images/young-workers-standing-laptops-min.jpg"
							alt="Young professionals working on mobile devices"
							class="img-fluid p-0"
							style="height: 100%; object-fit: cover"
						/>
					</div>
					<div
						class="col-md-5 p-5 my-5 ms-md-5 d-flex flex-column justify-content-center align-items-start"
					>
						<h2 class="display-4 text-black font-weight-bold">
							Get ready for easy online insurance, with
							<span class="text-primary"> seamless experiences </span>
						</h2>
						<p class="text-black text-text-muted">
							Draftrs is a small business professional liability insurance
							platform that makes it quick and easy for professional firms and
							their agents to get a quote, bind coverage, and service policies.
						</p>
						<a href="/agentspartners" class="mx-auto btn btn-primary">Learn More</a>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import '../../public/js/timeline.js';
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "AboutUs",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
        workType: ''
    };
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    newQuote: function() {
        this.$router.push({name: 'quote', params: { workType: this.workType}});
    }
  },
};
</script>

<style lang="scss">

</style>
