<template>
    <div id="wrapper">
        <StandardNav/>
        <main id="main" role="main">
            <section class="pro-box m-5">
                <div class="container">
                    <div class="row mb-5">
                        <div class="col-md-6">
                            <div class="text-upper d-md-none">
                                <h2>Construction Managers<br> Professional Liability Insurance</h2>
                                <p>Construction managers pofessional liability<br> insurance for claims against you arising out of your<br> professional services.</p>
                            </div>
                        </div>
                        <div class="col-md-6 pl-1">
                            <div class="img-boxx">
                                <img src="/images/karan.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-6 d-none d-md-block">
                            <div class="text-upper">
                                <h2>Construction Managers<br> Professional Liability Insurance</h2>
                                <p>Construction managers pofessional liability<br> insurance for claims against you arising out of your<br> professional services.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="block">
                                <h2>Claims Scenarios</h2>
                                <p>An owners representative construction manager did not obtain the correct construction permits which delays construction start by 3 months. The owner sues for financial loss as a result of project delays</p>
                                <p>A project experiences substantial delays and cost overruns. The owner sues the contractor for construction errors and breach of contract. The contractor countersues the owner and the construction manager for not providing timely response to information requests and failure to coordinate</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="block">
                                <h2>Coverage Highlights</h2>
                                <ul>
                                    <li>Defense costs, policy provides defense if you get sued</li>
                                    <li>Covers claims for bodily injury, property damage and financial loss</li>
                                    <li>Additional expense reimbursements for regulatory and administrative actions and professional licensing and disciplinary hearings</li>
                                    <li>Pre-claims reporting and subpeona assistance</li>
                                </ul>
                                <h3>
                                    Note: This coverage is not intended for Contractors. The policy excludes construction and workmanship <br>when performed by an Insured
                                </h3>
                            </div>
                            <div class="btn-box">
                                <a :href="loggedIn() ? '/Quote' : '/ContactUs'" class="btn btn-get">Get a Quote Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>
import { isLoggedIn, } from "../Utils/auth.js";
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "Index",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {};
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
    loggedIn: function() {
      return isLoggedIn();
    },
  },
};
</script>

<style lang="scss">

</style>
