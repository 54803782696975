<template>
    <div>
        <LoginNav/>
        <main class="login-header container-fluid">
            <h1 class="display-4 text-white font-weight-bold text-center py-5">
                Login
            </h1>
            <div class="row">
                <div
                        class="col-md-8 offset-md-2 col-sm-10 offset-sm-1 border-radius-30 p-4 form-container mb-5"
                >
                    <div class="row">
                        <div class="col-md-6 pr-4 border-right mb-4 mb-md-0">
                            <h4 class="text-center font-weight-bold mb-3">{{ clientCasual }} Agent login</h4>
                            <div class="d-flex flex-column">
                                <div class="mb-3 form-group">
                                    <input
                                            type="email"
                                            class="form-control"
                                            id="email"
                                            placeholder="Email"
                                            aria-label="Email"
                                            required
                                            v-model="email"
                                            @keyup.enter="login()"
                                    />
                                </div>
                                <div class="mb-3 form-group position-relative">
                                    <i
                                            @click="showInputPassword();"
                                            class="show-password-icon pr-2"
                                    ></i>
                                    <input
                                            type="password"
                                            class="form-control"
                                            id="password"
                                            placeholder="Password"
                                            aria-label="Password"
                                            required
                                            @keyup.enter="login()"
                                            v-model="password"
                                    />
                                </div>
                                <button :disabled="!email || !password" @click.prevent="login()" type="submit" class="btn border btn-primary text-white mt-4">
                                    <i v-if="spinners.login" class="fas fa-spinner fa-pulse"></i> Submit
                                </button>
                                <a v-b-tooltip.hover.bottom="'Please send an email to your managing broker or support@draftrs.com'" class="link-secondary text-center mt-4 mb-3">
                                    Forgot password?</a
                                >
                            </div>
                        </div>

                        <div v-if="isAgentLogin()" class="col-md-6 pl-4">
                            <h4 class="text-center font-weight-bold mb-3">
                                Retail Agent Account Access
                            </h4>

                            <div class="d-flex flex-column justify-content-center">
                                <div class="mb-3 form-group">
                                    <input
                                            type="email"
                                            class="form-control"
                                            id="agentemail"
                                            placeholder="Retail Agent Email"
                                            aria-label="Email"
                                            v-model="agentemail"
                                            required
                                    />
                                </div>
                                <div class="mb-3 form-group">
                                    <input
                                            type="email"
                                            class="form-control"
                                            id="policyemail"
                                            placeholder="Policyholder Email"
                                            aria-label="Email"
                                            v-model="policyemail"
                                            required
                                    />
                                </div>

                                <button :disabled="!policyemail || !agentemail" @click="findAccount" type="submit" class="btn border btn-primary text-white mt-4">
                                    <i v-if="spinners.policy" class="fas fa-spinner fa-pulse"></i> Get policy
                                </button>
                                &nbsp;
                            </div>
                        </div>
                        <div v-else class="col-md-6 pl-4">
                            <h4 class="text-center font-weight-bold mb-3">
                                Policyholder Login
                            </h4>

                            <div class="d-flex flex-column justify-content-center">
                                <div class="mb-3 form-group">
                                    <input
                                            type="email"
                                            class="form-control"
                                            id="policyemail"
                                            placeholder="Policyholder Email"
                                            aria-label="Email"
                                            v-model="policyemail"
                                            required
                                    />
                                </div>
                                <p class="text-center mt-2" style="margin-bottom: 22px">
                                    We will email you a link to access your policy.
                                </p>

                                <button :disabled="!policyemail" @click="findAccount" type="submit" class="btn border btn-primary text-white mt-4">
                                    <i v-if="spinners.policy" class="fas fa-spinner fa-pulse"></i> Get my policy
                                </button>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>

import LoginNav from "../components/LoginNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import {loginUser, cognitoAuth} from '../Utils/auth.js';
import {getClientDomain, getClientLogo, getClientText} from "../Utils/themer.js";
import axios from 'axios';

export default {
    name: 'login',
    props: {
        token: {
            type: String
        }
    },
    components: {
        LoginNav,
        StandardFooter
    },
    data: function () {
        return {
            retailBrokers: ['rpsins'],
            client: getClientDomain(),
            email: '',
            password: '',
            policyemail: '',
            agentemail: '',
            msg: false,
            msgSuccess: false,
            spinners: {login: false, policy: false},
            recover: false,
            logo: null,
            phone: '',
            clientCasual: ''
        }
    },
    mounted() {
        if (this.$route.params.msg) {
            this.msg = this.$route.params.msg
            this.msgSuccess = this.$route.params.success
        }
        this.logo = this.getClientLogo();
        this.phone = this.getClientText().phone;
        this.clientCasual = this.getClientText().casual;

    },
    methods: {
        loginError: function () {
            this.$toast.open({
                message: 'Invalid Email or Password. Please contact your broker for assistance or try again.',
                type: 'error',
                dismissible: true,
                duration: 100000,
                position: 'top-right'
            });
            this.spinners.login = false;
        },
        login: async function () {
            this.client = getClientDomain();
            this.spinners.login = true;
            const user = await cognitoAuth(this.email, this.password);

            if (false === user) {
                this.loginError();
                return;
            }
            await axios.post("/api/login", {email: this.email, password: user.signInUserSession.accessToken.jwtToken, client: this.client}).then(res => {
                const dat = res.data.data;
                const jwt = res.data.token;
                this.msg = this.msgSuccess = false
                this.spinners.login = false;

                if (jwt && dat) {
                    loginUser(dat.client, this.email, jwt)
                    this.$router.push(this.$route.query.redirect || '/dashboard');
                } else {
                    this.loginError();
                }
            }).catch(() => {
                this.loginError();
            });
            return;
        },
        getClientLogo() {
            return getClientLogo();
        },
        getClientText() {
            return getClientText();
        },
        showInputPassword: function () {
            let input = document.getElementById("password");
            input.type === "password" ? (input.type = "text") : (input.type = "password");
        },
        isAgentLogin() {
            return this.retailBrokers.includes(this.client);
        },
        async findAccount(e) {
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.policyemail.toLowerCase().match(regex)) {
                e.preventDefault();
                this.spinners.policy = true;
                let params = this.isAgentLogin() ? {agentEmail: this.agentemail, email: this.policyemail, client: getClientDomain()} : {email: this.policyemail, client: getClientDomain()};

                await axios.post('/api/recover', params)
                        .then((res) => {
                            this.spinners.policy = false;

                            const _email = this.agentemail ? this.agentemail : this.policyemail;
                            if (res.data.success == true) {
                                this.$toast.open({
                                    message: 'An email has been sent to <strong>' + _email + '</strong> with instructions to access your policy.',
                                    type: 'success',
                                    dismissible: true,
                                    duration: 100000,
                                    position: 'top-right'
                                });
                            } else {
                                this.$toast.open({
                                    message: 'Oops, we could not find your email ' + _email + '. Please contact your broker for assistance or try again.',
                                    type: 'warning',
                                    dismissible: true,
                                    duration: 100000,
                                    position: 'top-right'
                                });
                            }

                            setTimeout(() => {
                                this.sent = true;
                            }, 3000);
                        })
                        .catch(error => {
                            this.spinners.policy = false;
                            this.$toast.open({
                                message: 'We are having a problem verifying your email address. Please check that it is correct. Or contact your broker for access.',
                                type: 'warning',
                                dismissible: true,
                                duration: 100000,
                                position: 'top-right'
                            });
                            console.log(error)
                        })

            }
        }
    }
}
</script>

<style lang="scss">
</style>
