<template>
    <div>
        <StandardNav/>
        <header class="hero container-fluid text-center bg-purple overflow-hidden">
			<div class="container">
				<div class="row">
					<div class="col-md-8 ml-0 pl-0 m-t-5 d-flex flex-column justify-content-center align-items-md-start"
					>
						<h1 class="display-4 font-weight-bold text-white mt-5 text-md-left">
							Professional liability coverage for today's
							<span class="text-primary"> professional service firms </span>
						</h1>
						<h5 class="text-white my-4 text-md-left">
							Get your professional liability coverage faster with Draftrs.
							Purchase affordable coverage from a Top A Rated Carrier* in
							minutes, without worrying about paperwork, or overpaying for
							insurance.  
						</h5>

						<div style="min-width: 400px" class="text-sm-left text-md-center">
							<a href="/agentspartners" style="min-width: 160px" class="btn btn-outline-info mx-1 mb-3 mr-2" 
								>For Agents</a
							>
							<a :href="loggedIn() ? '/Quote' : '/getaquote'" style="min-width: 160px" class="btn btn-secondary mx-1 mb-3"
							>Get a Quote</a
							>
							
						</div>
						<RecoverComponent class="pl-2" />
					</div>
					<div class="col-md-4">
						<div class="pt-md-2 pt-sm-0 half-width-img">
							<img
								src="/images/woman-no-bg-min.png"
								alt="Young professional woman"
								style="width: 60%; object-position: top left"
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
            <main>
			<!-- Image Grid -->
			<section class="container-lg py-5">
				<div class="row">
					<div
						class="col-md-5 d-flex flex-column justify-content-center align-items-center align-items-md-baseline text-center text-md-start mb-5 mb-md-0"
					>
						<h2 class="display-4 font-weight-bold">
							Get <span class="text-primary">professional liability </span>
							insurance right
						</h2>
						<p class="my-4 text-black text-text-muted">
							Purchasing professional liability coverage is a great way to
							protect your firm. But understanding different coverages and
							navigating the maze of policy options can be difficult. We take
							the guess work out by offering coverage that is customized for
							small firms with coverage and exclusions that are clear and
							up front.
						</p>
						<a href="/getaquote" class="mx-auto btn btn-primary">Get a Quote</a>
					</div>
					<div class="col-md-7">
						<img
							src="/images/image-grid-min.png"
							alt="Diverse group of professionals smiling"
							class="img-fluid"
						/>
					</div>
				</div>
			</section>
			<!-- Rotator -->
			<section class="container-fluid bg-grey">
				<div class="container-lg py-5">
					<div class="row">
						<div class="col-md-6 d-flex mb-5 mb-md-0 steps-container">
							<div
								class="flex-shrink-0 d-flex flex-column justify-content-center mr-4"
								style="font-size: 12px"
							>
								<i class="step-node active"></i>
								<p class="step active">Get a free quote</p>
								<i class="step-node"></i>
								<p class="step">Make payment</p>
								<i class="step-node"></i>
								<p class="step">Download policy</p>
								<i class="step-node"></i>
								<p class="step">Manage account</p>
							</div>
							<div
								class="position-relative w-100 d-flex flex-column justify-content-center"
							>
								<div class="position-absolute step-content active">
									<h2 class="display-4 font-weight-bold">
										Done!<br />
										Fast & Easy.
									</h2>
									<p class="text-black text-text-muted">
										Complete our short online application and
										<span class="text-primary">instantly get quotes</span>
										with different policy options"
									</p>
									<a href="/getaquote" class="btn btn-primary">Get Started</a>
								</div>
								<div class="position-absolute step-content">
									<h2 class="display-4 font-weight-bold">Make a payment</h2>
									<p class="text-black text-text-muted">
										Pay with your
										<span class="text-primary"> credit card </span>
										to instantly bind coverage
									</p>
									<a href="/getaquote" class="btn btn-primary">Get Started</a>
								</div>
								<div class="position-absolute step-content">
									<h2 class="display-4 font-weight-bold">Download policy</h2>
									<p class="text-black text-text-muted">
										Download policy documents and issue insurance certificates
										<span class="text-primary"> instantly </span>
									</p>
									<a href="/getaquote" class="btn btn-primary">Get Started</a>
								</div>
								<div class="position-absolute step-content">
									<h2 class="display-4 font-weight-bold">Manage account</h2>
									<p class="text-black text-text-muted">
										Update policies and add basic endorsements at
										<span class="text-primary"> no extra charge </span>
									</p>
									<a href="/getaquote" class="btn btn-primary">Get Started</a>
								</div>
							</div>
						</div>
						<div class="col-md-5 offset-md-1 position-relative steps-container">
							<img
								src="/images/quote-app-min.png"
								alt="Get an insurance quote instantly app screen"
								class="img-fluid shadow position-absolute step-img active border-radius-30"
							/>
							<img
								src="/images/credit-card-payment.png"
								alt="Make a credit card payment app screen"
								class="img-fluid shadow position-absolute step-img border-radius-30"
							/>
							<img
								src="/images/download-icon-min.png"
								alt="Download button for documents"
								class="img-fluid shadow position-absolute step-img border-radius-30"
							/>
							<img
								src="/images/invoice-card-min.png"
								alt="Invoice on app dashboard"
								class="img-fluid shadow position-absolute step-img border-radius-30"
							/>
						</div>
					</div>
				</div>
			</section>
			<!-- Carousel Screens -->
			<section class="container-fluid bg-purple">
				<div class="container-lg py-5">
					<h2 class="display-4 text-center text-white font-weight-bold my-5">
						Keep up with
						<span class="text-primary">client insurance requirements</span>
						and manage your policies effortlessly
					</h2>
					<div class="row">
						<div class="col-md-8 offset-md-2">
							<!-- Carousel -->
							<div
								id="carouselExampleIndicators"
								class="carousel slide mb-5"
								data-ride="carousel"
							>
								<ol class="carousel-indicators">
									<li
										data-target="#carouselExampleIndicators"
										data-slide-to="0"
										class="active"
									></li>
									<li
										data-target="#carouselExampleIndicators"
										data-slide-to="1"
									></li>
									<li
										data-target="#carouselExampleIndicators"
										data-slide-to="2"
									></li>
									<li
										data-target="#carouselExampleIndicators"
										data-slide-to="3"
									></li>
								</ol>
								<div class="carousel-inner">
									<div class="carousel-item active">
										<img
											src="/images/get-quote-full.png"
											class="d-block w-100"
											alt="Get online quote easy and instantly on web page"
										/>
									</div>
									<div class="carousel-item">
										<img
											src="/images/account-screenshot-min.png"
											class="d-block w-100"
											alt="Web page of dashbaord to manage account"
										/>
									</div>
									<div class="carousel-item">
										<img
											src="/images/cert.png"
											class="d-block w-100"
											alt="Generate Insurance Certificates"
										/>
									</div>
									<div class="carousel-item">
										<img
											src="/images/review-policy-full.png"
											class="d-block w-100"
											alt="Web page to manage account and view documents"
										/>
									</div>
								</div>
								<button
									class="carousel-control-prev"
									type="button"
									data-target="#carouselExampleIndicators"
									data-slide="prev"
								>
									<span
										class="carousel-control-prev-icon"
										aria-hidden="true"
									></span>
									<span class="sr-only">Previous</span>
								</button>
								<button
									class="carousel-control-next"
									type="button"
									data-target="#carouselExampleIndicators"
									data-slide="next"
								>
									<span
										class="carousel-control-next-icon"
										aria-hidden="true"
									></span>
									<span class="sr-only">Next</span>
								</button>
							</div>
							<div class="row mb-5">
								<p class="text-white text-center mb-5">
									Small firms often struggle with the administrative processes
									associated with quotes, payments, and policy management. Look your best 
									with clients by ensuring you have a process in place 
									to manage your client insurance requirements.
								</p>
								<div class="d-flex m-auto">
									<a
										href="/agentspartners"
										class="btn btn-outline-info mx-1 mb-3"
										>For Agents</a
									>
									<a href="/getaquote" class="btn btn-secondary mx-1 mb-3"
										>Get a Quote</a
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- Trusted Partners -->
			<section class="container py-5">
				<div class="row justify-content-center">
					<div class="text-center col-md-8 my-5">
						<h2 class="display-4 text-black font-weight-bold my-5">
							Partnered with
							<span class="text-primary"> Leading National Agencies</span>
						</h2>
						<p class="text-black text-muted">
							Draftrs is small business professional liability insurance
							platform that makes it quick and easy for agents to get a quote,
							bind coverage, and service policies.
						</p>
						<div class="row justify-content-between my-5" style="gap: 40px">
							<div class="col-md-3">
								<a target="_blank" href="https://acecbit.org/pli-quote/">
									<img
										src="/images/acec-logo.png"
										alt="ACEC logo"
										class="img-fluid"
									/>
								</a>
							</div>
							<div class="col-md-3">
								<a target="_blank" href="https://www.risk-strategies.com/">
									<img
										src="/images/risk-strategy-logo.png"
										alt="Risk Strategies logo"
										class="img-fluid"
									/>
								</a>
							</div>
							<div class="col-md-3">
								<a target="_blank" href="https://greyling.com/">
									<img
										src="/images/greyling-logo.png"
										alt="Greyling logo"
										class="img-fluid"
									/>
								</a>
							</div>
						</div>
						<a href="/agentspartners" class="btn btn-primary"
							>Partner with us</a
						>
					</div>
				</div>
			</section>
			<!-- Coverage -->
			<section class="container-fluid bg-purple">
				<div class="row">
					<div class="col-lg-5 offset-lg-1 col-md-7 offset-md-0 py-5 p-md-5">
						<div>
							<h5 class="font-weight-bold text-white my-3">Who we cover</h5>
							<h2 class="display-3 text-white font-weight-bold mb-4">
								We’ve got you covered
							</h2>
							<div class="row border-top py-3 mx-1 align-items-center">
								<div class="col-4 pl-0">
									<h5 class="text-white font-weight-bold">
										Design Professionals
									</h5>
								</div>
								<div class="col-8">
									<p class="text-white">
										Architects - - Engineers - - 
										Interior Designers - - Construction Managers - -  
										Land Surveyors - - Technical Design Consultants
									</p>
								</div>
							</div>
							<div class="row border-top py-3 mx-1 align-items-center">
								<div class="col-4 pl-0">
									<h5 class="text-white font-weight-bold">Consultants</h5>
								</div>
								<div class="col-8">
									<p class="text-white">
										Business Strategy - - Education - - 
										Human Resources - - Management - -  
										Marketing - - Non-Profit Fundraising
									</p>
								</div>
							</div>
							<div class="row border-top py-3 mx-1 align-items-center">
								<div class="col-4 pl-0">
									<h5 class="text-white font-weight-bold">Other Professionals</h5>
								</div>
								<div class="col-8">
									<p class="text-white">
										Bookkeepers - - Tax Preparers - - 
										Recruiters - - Administrative - -  
										Mediators - - Grant Writers --
										And More!
									</p>										
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-5 offset-lg-1 col-md-5 offset-md-0 pr-md-0">
						<img
							src="images/men-standing-with-tablet.jpg"
							alt="Young professional woman checking phone"
							style="width: 100%; height: 100%; object-fit: cover"
						/>
					</div>
				</div>
			</section>
			<!-- Reviews -->
			<section class="container-fluid py-5 bg-grey">
				<div class="container py-5">
					<div class="row pb-5">
						<div class="col-md-8 offset-md-2">
							<h1 class="display-3 text-black text-center font-weight-bold">
								Loved by architects, engineers, design professionals, and their
								brokers
							</h1>
						</div>
					</div>
					<div class="row mb-5">
						<div class="col-md-6 pe-md-5">
							<h4 class="font-weight-bold my-5">What our agents are saying</h4>
							<div class="d-flex mb-5">
								<div class="mr-3 flex-shrink-0">
									<h4 class="font-weight-bold">
										<span class="display-6 font-weight-bold">5.0</span>/ 5
									</h4>
								</div>
								<div>
									<img src="/images/icons/stars-5-icon.png" alt="" />
									<h5 class="font-weight-bold mt-3">
										Great market option for our clients
									</h5>
									<p class="text-black text-muted">
										Process was remarkably easy and fast. The direct billing is
										great.  Saves us so much time and so many of our clients are
										always asking to pay with credit card.
									</p>
								</div>
							</div>
							<div class="d-flex">
								<div class="mr-3 flex-shrink-0">
									<h4 class="font-weight-bold">
										<span class="display-6 font-weight-bold">4.9</span>/ 5
									</h4>
								</div>
								<div>
									<img src="/images/icons/stars-5-icon.png" alt="" />
									<h5 class="font-weight-bold mt-3">Draftrs will be our go to for small firms!</h5>
									<p class="text-black text-muted">
										We like the self service, direct bill and direct client access. 
										Clients appreciate the faster turnaround times. With the time 
										saved from paperwork we can focus more on building client 
										relationships and helping them with risk management.
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-6 pe-md-5">
							<h4 class="font-weight-bold my-5">
								What our insureds are saying
							</h4>
							<div class="d-flex mb-5">
								<div class="mr-3 flex-shrink-0">
									<h4 class="font-weight-bold">
										<span class="display-6 font-weight-bold">5.0</span>/ 5
									</h4>
								</div>
								<div>
									<img src="/images/icons/stars-5-icon.png" alt="" />
									<h5 class="font-weight-bold mt-3">
										Piece of cake!
									</h5>
									<p class="text-black text-muted">
										Overall, it was very easy to use and very easy to complete the
										purchase. My agent was so helpful. I would definitely recommend
										Draftrs to other engineers who are looking for professional
										liabiliy insurance.
									</p>
								</div>
							</div>
							<div class="d-flex">
								<div class="mr-3 flex-shrink-0">
									<h4 class="font-weight-bold">
										<span class="display-6 font-weight-bold">5.0</span>/ 5
									</h4>
								</div>
								<div>
									<img src="/images/icons/stars-5-icon.png" alt="" />
									<h5 class="font-weight-bold mt-3">Awesome experience!</h5>
									<p class="text-black text-muted">
										The process was simple and straightforward compared to the 
										experience with my previous provider which was horrible! My
										agent was nice and knowledgeable and I am happy she recommended Draftrs.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- Final CTA -->
			<section class="container-fluid bg-purple">
				<div class="container">
					<div class="row">
						<div
							class="col-md-6 d-flex flex-column justify-content-center align-items-start"
						>
							<img
								src="/images/markel-hanover-logo-white.png"
								alt=""
								class="img-fluid mb-4 mt-5"
								width="400px"
							/>
							<h2 class="display-4 text-white font-weight-bold mb-3">
								<span class="text-primary">Top A Rated Insurance</span> for
								small firms
							</h2>
							<p class="text-white mb-4">
								We offer admitted policies underwritten by Markel Insurance Company and Hanover Insurance Company.
                            </p>
							<p class="text-white mb-4">
								Markel Insurance Company (10275 West Higgins Road, Suite 750, Rosemont, IL 60018 NAIC # 38970), part of the Markel Group, is a specialist insurer that has been offering property and casualty insurance since 1930.
								See their website at <a href="https://markel.com">markel.com</a>
							</p>
							<p class="text-white" style="font-size: 8px">
								* Rating as of September 15, 2021. A.M. Best ratings are under
								continuous review and subject to change, please refer to
								Markel’s website for the most current information. The rating
								represents the overall financial status of Markel Insurance
								Company, and is not a recommendation of the specific policy
								provisions, rates or practices of the issuing insurance company.
								Terms and conditions for rate and coverage may vary by state and
								application. Insurance and coverage are subject to availability
								and qualifications and may not be available in all states.
							</p>
						</div>
						<div class="col-md-5 offset-md-1">
							<img
								src="/images/woman-checking-phone_portrait-min-2.png"
								alt="Young woman holding tablet in office"
								class="img-fluid neg-margin-image"
							/>
							<p class="text-white mb-4">
								The Hanover Insurance Group, Inc. based in Worcester, MA is the holding company for a group of insurers that includes The Hanover Insurance Company (Worcester, MA), and its insurance company affiliates and subsidiaries. 
								The Hanover Group offers a wide range of property and casualty products and services to businesses, individuals, and families since 1852. 
								See their website at <a href="https://hanover.com">hanover.com</a>
							</p>
						</div>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import { isLoggedIn, } from "../Utils/auth.js";
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import RecoverComponent from "../components/RecoverComponent.vue";
import { getClientDomain, } from "../Utils/themer.js";

export default {
  name: "Index",
  components: {
    StandardNav,
    StandardFooter,
    RecoverComponent
  },
  props: {},
  data: function() {
    return {
        workType: '',
        recover: false,
        recover2: false
    };
  },
  mounted() {
      //no website for brokers
    if ('draftrs' !== getClientDomain()) {
        this.$router.push({name: 'dashboard'}).catch(() => {});
    }
    window.scrollTo(0,0);
    this.rotateSteps();
  },
  methods: {
    newQuote: function() {
        this.$router.push({name: 'quote', params: { workType: this.workType}});
    },
    loggedIn: function() {
    return isLoggedIn();
    },
    rotateSteps: function() {
        const stepNodes = document.querySelectorAll(".step-node");
        const steps = document.querySelectorAll(".step");
        const stepContents = document.querySelectorAll(".step-content");
        const stepsContainer = document.querySelector(".steps-container");
        const stepImages = document.querySelectorAll(".step-img");
        const allSteps = [stepNodes, steps, stepContents, stepImages];
        let currentStep = 0;
        
        if (stepsContainer)
            stepsContainer.style.height = `${
                stepsContainer.nextElementSibling.offsetHeight + "px"
            }`;

        const timerCallback = () => {
            allSteps.forEach((step) => step[currentStep].classList.remove("active"));

            setTimeout(() => {
                currentStep < stepNodes.length - 1 ? currentStep++ : (currentStep = 0);

                allSteps.forEach((step) => step[currentStep].classList.add("active"));
            }, [800]);
        };

        if (stepNodes.length && steps.length && stepContents.length) {
            setInterval(timerCallback, [4500]);
        }
    }
  },
};
</script>

<style lang="scss">

</style>
