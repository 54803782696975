<template>
  <body>
    <div id="wrapper">
        <link rel="stylesheet" href="../../js/swagger/swagger-ui.css" >
        <StandardNav/>
        <main id="main" role="main" class="">
        <div id="swagger-ui"></div>
        </main>
        <StandardFooter/>
    </div>
  </body>
</template>

<!-- Still needed for some reason even tho created below -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/swagger-ui/4.14.3/swagger-ui-bundle.js" integrity="sha512-aEiOdJ8F7gzgO4EmhNK3u8ShxmmVhZCAZQpyHgL6HP54OiTgHl+wyy3Qyssh7qsbOPcEpmCdZ4Utx/VkeAJWtQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/swagger-ui/4.14.3/swagger-ui-standalone-preset.js" integrity="sha512-GO9pqUSumr2uSp98BbJTPJRZWj0dp9tVbw14Tl4xgijH6sY6D7Hg4pOofV7GOAYQBQm73uONRAH4PHcFURGqBQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script>

  import StandardNav from "../components/StandardNav.vue";
  import StandardFooter from "../components/StandardFooter.vue";

  export default {       
    name: "OpenAPI",
    components: {
      StandardNav,
      StandardFooter
    },
    props: {},
    data: function() {
      return {
      };
    },
    mounted() {
      window.scrollTo(0,0);

      // recreate swagger openapi js
      let themejs = document.createElement('script')
        themejs.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/swagger-ui/4.14.3/swagger-ui-bundle.js');
        themejs.setAttribute('integrity', 'sha512-aEiOdJ8F7gzgO4EmhNK3u8ShxmmVhZCAZQpyHgL6HP54OiTgHl+wyy3Qyssh7qsbOPcEpmCdZ4Utx/VkeAJWtQ==');
        themejs.setAttribute('crossorigin', 'anonymous');
        themejs.setAttribute('referrerpolicy', 'no-referrer');
      document.head.appendChild(themejs)

      // recreate swagger openapi js
      let themejs2 = document.createElement('script')
        themejs2.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/swagger-ui/4.14.3/swagger-ui-standalone-preset.js');
        themejs2.setAttribute('integrity', 'sha512-GO9pqUSumr2uSp98BbJTPJRZWj0dp9tVbw14Tl4xgijH6sY6D7Hg4pOofV7GOAYQBQm73uONRAH4PHcFURGqBQ==');
        themejs2.setAttribute('crossorigin', 'anonymous')
        themejs2.setAttribute('referrerpolicy', 'no-referrer');
      document.head.appendChild(themejs2)

      window.onload = function() {
        const ui = SwaggerUIBundle({
          url: window.location.origin + "/spec/broker.yaml",
          dom_id: '#swagger-ui',
          presets: [
            SwaggerUIBundle.presets.apis,
            SwaggerUIStandalonePreset
          ]
        })

        window.ui = ui;
      }
    },
    methods: {
    },
  };
</script>

<style lang="scss">
</style>
