<template>
    <body>
        <div id="wrapper" class="bg-light">
            
            <div v-if="!purchased">
                <EmptyNav/>
                <main id="main" role="main">
                    <div class="row" style="height: 0px; z-index: 200; position: relative; top: 250px; left: 48%;" v-show="loadingSpinner">
                        <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-center mb-5">
                            <form ref="form5" id="form5">
                                <div v-show="purchased == false">
                                    <QuoteSummary :quote="quote" :prorate="prorate" :issueAction="context.action" v-if="purchased == false"/>
                                    <div v-if="context.action=='endorse' && Array.isArray(quote.changes) && quote.changes.length > 0">
                                        <h5 class="main-color mt-5">COVERAGE CHANGE DISCLAIMER</h5>
                                        <div class="fs-block-boxes">
                                            <div class="form-group mb-3">
                                                <label class="mb-3" >You are adding or modifying the following endorsements on your policy:</label>
                                                <div class="d-flex flex-wrap justify-content-left mb-3">
                                                    <div class="py-1 pl-4 w-100" v-for="(change, idx) in quote.changes" :key="idx">
                                                        <i class="main-color fas fa-circle fa-xs"></i>
                                                        {{ change }}
                                                    </div>
                                                    
                                                </div>
                                                <div class="mt-4 ml-3 d-flex">
                                                    <input id="consent-endorsement-changes" @click="consent('endorse')" value="true" v-model="quote.consentEndorsementChanges" class="pl-2 purp-check-input form-check-input" :class="quote.consentEndorsementChanges ? '' : 'is-blank'" type="checkbox" required> 
                                                    <label for="consent-endorsement-changes" class=" pl-3 pt-1 form-check-label" :class="quote.consentEndorsementChanges ? '' : 'is-invalid'">
                                                        <i class="far fa-hand-point-left fa-lg pr-1"></i> By checking this box you warrant that you are unaware of any facts, circumstances or incidents that could reasonably give rise to a claim under the policy.<br/> (Policyholders Warranty of Claims or Circumstances)
                                                    </label>  
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="text-block text-center">
                                        <h1 id="purch-txt" v-if="context.action=='renew'">Renew Policy</h1>
                                        <h1 id="purch-txt" v-else-if="context.action=='endorse'">Endorse Policy</h1>
                                        <h1 id="purch-txt" v-else>Purchase Policy</h1>
                                    </div>
                                    <div class="alert alert-danger border border-danger collapse" id="pay-error" >
                                        <i class="fas fa-exclamation-triangle"></i> Please complete the fields or questions that are highlighted in red.
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 ">
                                            <div class="address-pay info-form border border-dark bg-white rounded p-3">
                                                <h3 class="main-color">Insured Info</h3>
                                                <form>
                                                    <div class="form-row">
                                                    <div class="col-md-12 mb-3">
                                                        <input type="text" readonly class="form-control" :class="quote.insured ? 'is-valid' : 'is-blank'" v-model="quote.insured" required placeholder="Business Name">
                                                    </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="col-md-6 mb-3 mt-3">
                                                            <input @change="addressChanges()" type="text" class="form-control" :class="quote.phone ? 'is-valid' : 'is-blank'" v-model="quote.phone" placeholder="Phone Number" required>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="col-md-12 mb-3">
                                                            <input @change="addressChanges()" type="email" class="form-control" :class="quote.email ? 'is-valid' : 'is-blank'" v-model="quote.email" placeholder="Email" required>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="col-md-12 mb-3 mt-5">
                                                            <input @change="addressChanges()" type="text" class="form-control" :class="quote.address ? 'is-valid' : 'is-blank'" v-model="quote.address" placeholder="Business Address" required>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="col-md-12 mb-3">
                                                            <input @change="addressChanges()" type="text" class="form-control" :class="quote.city ? 'is-valid' : 'is-blank'" v-model="quote.city" placeholder="City" required>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="col-md-7 mb-3">
                                                                <select @change="addressChanges()" class="custom-select" :class="quote.state ? 'is-valid' : 'is-blank'" required v-model="quote.state">
                                                                    <option value="">Select a State</option>
                                                                    <option value="AL">Alabama</option>
                                                                    <option value="AK">Alaska</option>
                                                                    <option value="AS">American Samoa</option>
                                                                    <option value="AZ">Arizona</option>
                                                                    <option value="AR">Arkansas</option>
                                                                    <option value="CA">California</option>
                                                                    <option value="CO">Colorado</option>
                                                                    <option value="CT">Connecticut</option>
                                                                    <option value="DE">Delaware</option>
                                                                    <option value="DC">District Of Columbia</option>
                                                                    <option value="FM">Federated States Of Micronesia</option>
                                                                    <option value="FL">Florida</option>
                                                                    <option value="GA">Georgia</option>
                                                                    <option value="GU">Guam</option>
                                                                    <option value="HI">Hawaii</option>
                                                                    <option value="ID">Idaho</option>
                                                                    <option value="IL">Illinois</option>
                                                                    <option value="IN">Indiana</option>
                                                                    <option value="IA">Iowa</option>
                                                                    <option value="KS">Kansas</option>
                                                                    <option value="KY">Kentucky</option>
                                                                    <option value="LA">Louisiana</option>
                                                                    <option value="ME">Maine</option>
                                                                    <option value="MH">Marshall Islands</option>
                                                                    <option value="MD">Maryland</option>
                                                                    <option value="MA">Massachusetts</option>
                                                                    <option value="MI">Michigan</option>
                                                                    <option value="MN">Minnesota</option>
                                                                    <option value="MS">Mississippi</option>
                                                                    <option value="MO">Missouri</option>
                                                                    <option value="MT">Montana</option>
                                                                    <option value="NE">Nebraska</option>
                                                                    <option value="NV">Nevada</option>
                                                                    <option value="NH">New Hampshire</option>
                                                                    <option value="NJ">New Jersey</option>
                                                                    <option value="NM">New Mexico</option>
                                                                    <option value="NY">New York</option>
                                                                    <option value="NC">North Carolina</option>
                                                                    <option value="MP">Northern Mariana Islands</option>
                                                                    <option value="ND">North Dakota</option>
                                                                    <option value="OH">Ohio</option>
                                                                    <option value="OK">Oklahoma</option>
                                                                    <option value="OR">Oregon</option>
                                                                    <option value="PW">Palau</option>
                                                                    <option value="PA">Pennsylvania</option>
                                                                    <option value="PR">Puerto Rico</option>
                                                                    <option value="RI">Rhode Island</option>
                                                                    <option value="SC">South Carolina</option>
                                                                    <option value="SD">South Dakota</option>
                                                                    <option value="TN">Tennessee</option>
                                                                    <option value="TX">Texas</option>
                                                                    <option value="UT">Utah</option>
                                                                    <option value="VT">Vermont</option>
                                                                    <option value="VI">Virgin Islands</option>
                                                                    <option value="VA">Virginia</option>
                                                                    <option value="WA">Washington</option>
                                                                    <option value="WV">West Virginia</option>
                                                                    <option value="WI">Wisconsin</option>
                                                                    <option value="WY">Wyoming</option>
                                                                </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="col-md-5 mb-0">
                                                            <input type="text" class="form-control" :class="quote.zip && !validations.zip ? 'is-valid' : 'is-blank'" v-model="quote.zip" @change="validateZip" placeholder="Zip Code" required>
                                                        </div>
                                                        <div class="ml-2 invalid-feedback" :style="validations.zip ? 'display: block' : 'display: none'">
                                                            {{ validations.zip }}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div v-if="quoteId" class="address-pay border border-dark bg-white rounded p-3">
                                                <StripePayment v-if="Object.prototype.hasOwnProperty.call(quote, 'id')" :agencyBill="agencyBill" :quoteData="quote" :issueAction="this.context.action" @onPaymentComplete="onPaymentComplete" :token="token"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
                <StandardFooter/>
            </div>

            <div v-if="purchased">
                <EmptyNav/>
                <main id="main" role="main">
                    <div class="card-body">
                        <div class="d-flex justify-content-center mb-5">
                            <div class="d-flex card col-md-8 border-0">
                                <PurchaseSuccess :quote="quote" v-if="purchased"/>
                            </div>
                        </div>
                    </div>
                </main>
                <StandardFooter/>
            </div>

        </div>
    </body>
</template>

<script>
import Vue from "vue";
import EmptyNav from "../components/EmptyNav.vue";
const axios = require('axios');
import moment from 'moment';
import StripePayment from "../components/StripePayment.vue";
import PurchaseSuccess from "../components/PurchaseSuccess.vue";
import QuoteSummary from "../components/QuoteSummary.vue";
import StandardFooter from "../components/StandardFooter.vue";
import { isLoggedIn,loginUser } from "../Utils/auth.js";
import { getClientLogo, getClientDomain, getClientText, getBrokerConfigs, getStyle, isDevServer } from "../Utils/themer.js";

export default {
  name: "Payment",
  components: {
    EmptyNav,
    StripePayment,
    PurchaseSuccess,
    QuoteSummary,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
        quote: {},
        context: {},
        quoteId: null,
        issuing: false,
        token: null,
        purchased: false,
        prorate: null,
        loadingSpinner: false,
        validations: {zip: ''},
        snapshotId: null,
        agencyBill: false,
        stripeLink: null
    };
  },
  mounted() {
      this.token = this.$route.params.token;

      this.brokerConfigs = this.getBrokerConfigs();
        this.appetites = this.brokerConfigs.appetites;

        // check if has agencyBill
        if (Object.prototype.hasOwnProperty.call(this.brokerConfigs, 'agencyBill') && this.brokerConfigs.agencyBill) {
            this.agencyBill = true;
        }

      this.loadSnapshot();
  },
  methods: {
    isDevServer() {
        return isDevServer();
    },
    getStyle(val) {
        return getStyle(val);
    },
    getClientLogo(mob) {
        return getClientLogo(mob);
    },
    getClientText() {
        return getClientText();
    },
    getClientDomain() {
        return getClientDomain();
    },
    getBrokerConfigs() {
        return getBrokerConfigs();
    },
    loadSnapshot: async function() {
        let ret = false;

        this.loadingSpinner = true;
        await axios.get( "/api/payment-invite/" + this.token)
        .then((res) => {
            ret = res.data;
            if (ret && ret.snapshot.context.action != 'endorse') {
                this.loadingSpinner = false;
            }
        })
        .catch(() => { });

        if (ret) {
            this.snapshotId = ret.snapshot.snapshotId;
            this.$route.params.snapshotId = this.snapshotId;
            this.quote = ret.snapshot.data;
            this.quoteId = ret.snapshot.quoteId;
            this.context = ret.snapshot.context;
            this.token = ret.jwt;
            
            // if effective date has passed just use todays date
            if (this.isEffectiveDatePassed())
                this.quote.effective = moment().format('YYYY-MM-DD');

            //reset consents
            this.quote.consent = false;
            this.quote.consentCarrier = false;

            //login
            if (!isLoggedIn()) {
                loginUser(this.quote.owners[0], this.quote.email, this.token);
            }

            //prorate if needed
            let pr = null;
            if (this.context.action == 'endorse') {
                await axios.post('/api/payment', { quoteId: this.quote.id, premium: this.quote.premium, ratingId: this.quote.ratingId, action: 'endorse', effective: this.quote.endorseEffective }).then((res) => {
                    pr =  Math.round(res.data.amount / 100);
                });
                this.loadingSpinner = false;
            }
            if (null != pr) {
                this.prorate = pr;
            }
        }

        return ret;
    },
    saveQuote: async function() {
        await axios.post('/api/quote', this.quote).then((res) => {
            this.quote = res.data;
        });
    },
    addressChanges: async function() {

        let els;
        let formEl = document.getElementById('form5');
        let payError = document.getElementById('pay-error');

        if (formEl) {
            els = formEl.getElementsByClassName('is-blank');

            Array.from(els).forEach(element => element.classList.add('is-invalid'));
        }

        if (payError) {
            if (els && els.length) {
                this.formErrors = true;
            payError.classList.add('show')
            payError.classList.remove('collapse')
                return false;
            } else {
                payError.classList.remove('show')
                payError.classList.add('collapse')
            }
        }

        this.saveQuote();
    },
    consent: async function(id) {
        await axios.get('/api/consent/'+id).then((res) => {
            if (!this.quote.consents) {
                this.quote.consents = [];
            }
            let repl = false;
            for (let i in this.quote.consents) {
                if (id == this.quote.consents[i].id) {
                    this.quote.consents[i] = res.data;
                    repl = true;
                    break;
                }
            }
            if (false === repl) {
                this.quote.consents.push(res.data);
            }
        });
    },
    ofac: function() {
        return;
    },
    issuePolicy: async function(billing) {
        this.issuing = true;
        let params = { quoteId: this.quote.id, action: this.context.action };
        if ('endorse' == this.context.action) {
            params.quote = this.quote;
            this.prorate = this.quote;
        }
        if (billing) {
            params.billing = billing;
        }
        let opts = {};
        if (this.token) {
            opts.headers = { "authentication": "Bearer " + this.token};
        }
        await axios.post('/api/policy', params, opts).then(() => {
            this.purchased = true;
            window.scroll(0,0);
        });
        await axios.get('/api/quote/' + this.quote.id, opts).then((res) => {
            this.quote = res.data;
        });
        this.issuing = false;
    },
    validateZip: async function() {
        let zipd;
        if (5 > this.quote.zip.length) {
            zipd = false;
        } else {
            const state = this.quote.state;
            await axios.get('/api/zip/'+this.quote.zip).then((res) => {
                zipd = res.data;
                if (!zipd.zip || (zipd.zip == this.quote.zip && zipd.state != state)) {
                    zipd = false;
                }
            }).catch(() => {
                zipd = false;
            });
        }
        if (false === zipd) {
            Vue.set(this.validations, 'zip', 'Zipcode does not match with state ' + this.quote.state + '. Please enter the correct zipcode.');
        } else {
            Vue.set(this.validations, 'zip', '');
        }
    },
    isEffectiveDatePassed: function() {
        if (this.quote.status=='new' && moment(new Date()).isAfter(this.quote.effective))
            return true;

        return false;
    },
    },
};
</script>

<style lang="scss">

</style>
