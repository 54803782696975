<template>
  <div id="wrapper">
      
    </div>
</template>

<script>
import axios from 'axios';
import { loginUser, isLoggedIn, getUserData } from '../Utils/auth.js';
import { getClientDomain } from '../Utils/themer.js';

export default {
  name: 'StartQuote',
  data: function () {
    return {}
  },
  async mounted() {
    //decode because of azure's stupid (no period in urls)
    this.token = this.$route.params.token;
    if (this.loggedIn() && this.getRole() != 'ROLE_CONSUMER') {
      return this.$router.push('quote');
    } else {
      this.tokenRoute();
    }
  },
  methods: {
    tokenRoute: async function() {
      const client = getClientDomain();
      await axios.post("/api/consumer/token", { client: client }).then(res => {
        if (res.data.token) {
          loginUser(client, null, res.data.token);
          return this.$router.push('quote');
        }
      });
    },
    loggedIn: function() {
      return isLoggedIn();
    },
    getRole() {
      return getUserData().role;
    }
  }
}
</script>

<style lang="scss"></style>