<template>
  <body>
    <div id="wrapper" class="bg-light">
        <AgentNav/>
        <main id="main" role="main" class="px-3">
            <h3>Reassign Quote <router-link :to="{ name: 'quote', params: { id: quoteId }}">{{ quote.insured }}</router-link></h3>
            <div class="alert alert-primary" role="alert">
                Assign this quote to the user selected below. The user will be notified via email.
            </div>
            <select v-if="0 < users.length" v-model="selectedUser" class="form-control mt-3" @change="updateQuoteUser()">
                <template v-for="user, idx in users">
                    <option v-bind:key="idx">{{user.email}}</option>
                </template>
            </select>
        </main>
      <StandardFooter/>
    </div>
  </body>
</template>

<script>
import AgentNav from "../components/AgentNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import axios from 'axios';
import { getUserData } from "../Utils/auth.js";

export default {
  name: "Assign",
  components: {
    AgentNav,
    StandardFooter
  },
  data: function() {
    return {
        quoteId: null,
        selectedUser: null,
        users: [],
        quote: {}
    };
  },
  async mounted() {
      this.quoteId = this.$route.params.quoteId;
      let users = await this.getUserList();
      let q;
      await axios.get('/api/quote/' + this.quoteId).then((res) => {
        q = res.data;
      });
      this.quote = q;
      this.users = [];
      //don't allow draftrs quotes to be assigned to non draftrs users
      //unless draftrs user
      if ('draftrs' !== getUserData().owner && q.owners.includes('draftrs')) {
        for (let i in users) {
          if ('draftrs' == users[i].owner) {
            this.users.push(users[i]);
          }
        }
      } else {
        this.users = users;
      }

      this.selectedUser = this.quote.user;
  },
  methods: {
    updateQuoteUser: async function() {
        const params = { quoteId: this.quoteId, user: this.selectedUser };
        await axios.post('/api/assign', params).then(() => {
            this.$toast.open({ 
                message: 'Quote has been assigned to ' + this.selectedUser,
                type: 'success',
                dismissible: true,
                duration: 8000,
                position: 'top-right'
            });
        });
    },
    getUserList: async function() {
        let ret = [];
        await axios.get('/api/users').then((res) => { ret = res.data; });
        return ret;
    },
  },
};
</script>

<style lang="scss">
    main {
        min-height: 500px;
    }
</style>
