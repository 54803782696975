<template>
    <div class="card shadow card-radius mt-2 mb-4">
        <h3 class="card-header bg-transparent">
            Summary
            <span class="btn-group float-right" role="group">
                <button type="button" class="rounded-sm p-2 btn btn-sm btn-outline-dark" v-bind:class="{ active: '3d' === period }" @click.prevent="period='3d'">3d</button>
                <button type="button" class="p-2 btn btn-sm btn-outline-dark" v-bind:class="{ active: '1w' === period }" @click.prevent="period='1w'">1w</button>
                <button type="button" class="p-2 btn btn-sm btn-outline-dark" v-bind:class="{ active: '1m' === period }" @click.prevent="period='1m'">1m</button>
                <button type="button" class="p-2 btn btn-sm btn-outline-dark" v-bind:class="{ active: '3m' === period }" @click.prevent="period='3m'">3m</button>
                <button type="button" class="rounded-sm p-2 btn btn-sm btn-outline-dark" v-bind:class="{ active: '1y' === period }" @click.prevent="period='1y'">1y</button>
            </span>
        </h3>
        <div class="card-body" v-if="isDevServer()">
            <!-- <div class="text-center" style="font-size: 1.2em; height: 0px; position: relative; top: 80px; color: white;" v-if="noStats">
              Not enough quotes for {{ period }} time period
            </div> -->
            <b-container class="summary">
                <b-row class="text-center pb-1">
                    <b-col>
                        <b-card>
                            <b>Total Bound Premium</b>
                            <div class="my-2">
                                <i class="fas fa-chart-bar"></i>
                            </div>
                            <div class="my-2 main-color">
                                <b>{{ totalBoundPremium | currency }}</b>
                            </div>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card>
                            <b>Total <br/>Submissions</b>
                            <div class="my-2">
                                <i class="fas fa-chart-bar"></i>
                            </div>
                            <div class="my-2 main-color">
                                <b>{{ quoted }}</b>
                            </div>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card>
                            <b>Total Open<br/> Quotes</b>
                            <div class="my-2">
                                <i class="fas fa-chart-bar"></i>
                            </div>
                            <div class="my-2 main-color">
                                <b>{{ quoted - bound }}</b>
                            </div>
                        </b-card>
                    </b-col>
                    <b-col>
                        <b-card>
                            <b>Total Issued<br/> Policies</b>
                            <div class="my-2">
                                <i class="fas fa-chart-bar"></i>
                            </div>
                            <div class="my-2 main-color">
                                <b>{{ bound }}</b>
                            </div>
                        </b-card>
                    </b-col>
                    <!--              <b-col class=" mt-4">-->
                    <!--                <b>Bind<br/>Ratio</b>-->
                    <!--                <div class="my-2">-->
                    <!--                  <i class="fas fa-chart-bar"></i>-->
                    <!--                </div>-->
                    <!--                <div class="my-2 main-color">-->
                    <!--                  <b>{{ ratio }}%</b>-->
                    <!--                </div>-->
                    <!--              </div>-->
                </b-row>
            </b-container>
        </div>
        <div class="card-body" v-else>
            <div id="dash-stats" :class=" noStats ? 'no-stats' : ''">
                <div class="text-center" style="font-size: 1.2em; height: 0; position: relative; top: 80px; color: white;" v-if="noStats">Not enough quotes for {{ period }} time period</div>
                <D3LineChart :config="getHistogramConfig()" :datum="quotes" :height="'100'"></D3LineChart>
                <div class="container">
                    <div class="row text-center pb-1" v-if="!noStats">
                        <div class="col-sm"><b class="main-color">Quoted</b> {{ quoted }}</div>
                        <div class="col-sm"><b style="color: green">Issued</b> {{ bound }}</div>
                        <div class="col-sm"><b>Ratio</b> {{ ratio }}%</div>
                    </div>
                    <div class="row text-center pb-1" v-else>
                        <div class="col-sm"><b class="main-color">No Statistics</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {D3LineChart} from 'vue-d3-charts';
import axios from 'axios';
import {isDevServer} from "../Utils/themer.js";

export default {
    name: "DashboardStats",
    components: {
        D3LineChart
    },
    async mounted() {
        await this.getStats();
    },
    data: function () {
        return {
            quotes: [],
            allCharts: false,
            period: '3m',
            limit: {"1y": 200, "3m": 70, "1m": 30, "1w": 10, "3d": 5}, // limit for each period
            quoted: 0,
            bound: 0,
            total: 0,
            ratio: 0,
            noStats: false,
            statsData: {}
        };
    },
    watch: {
        period: async function () {
            await this.getStats();
        }
    },
    computed: {
        totalBoundPremium() {
            return Math.round(this.statsData.stats?.aggs?.totalPremium?.value / 10)
        }
    },
    methods: {
        isDevServer() {
            return isDevServer();
        },
        addData() {
            const start = Math.floor(Math.random() * 20000);
            const end = Math.floor(Math.random() * 20000);
            const name = 'new #' + this.count++;
            this.chart_data.push({start, end, name})
        },
        removeData() {
            if (!this.chart_data.length)
                return;
            const random_index = Math.floor(Math.random() * this.chart_data.length)
            this.chart_data.splice(random_index, 1);
        },
        getQuotes() {
            return this.quotes;
        },
        getHistogramConfig() {
            return {
                showTooltips: false,
                values: ['issued', 'total'],
                date: {
                    key: 'date',
                    inputFormat: '%Y-%m-%d',
                    outputFormat: '%Y-%m-%d'
                },
                color: {
                    scheme: ['green', '#330099']
                },
                axis: {
                    yTitle: 'Quotes',
                    xTitle: 'Date',
                    yFormat: ".0f",
                    xFormat: "%Y-%m-%d"
                },
                margin: {
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 40
                }
            };
        },
        async getStats() {

            await axios.post('/api/stats/quotes', {from: this.period}).then((res) => {
                this.statsData = res.data;
                let quoteData = res.data.dateHistogram;

                this.total = this.bound = this.ratio = 0;
                quoteData.forEach(quote => {
                    this.bound += quote.issued;
                    this.total += quote.total;
                });
                this.quoted = this.total - this.bound;
                this.ratio = Math.floor(this.bound / this.quoted * 100);

                if (this.total < this.limit[this.period]) {
                    this.staticStats();
                    this.noStats = true;
                } else {
                    this.quotes = quoteData;
                    this.noStats = false;
                }
            });
        },
        staticStats: function () {
            this.total = this.bound = this.ratio = 0;
            this.quotes = [{"date": "2021-10-26", "issued": 5, "total": 10}, {"date": "2021-10-27", "issued": 8, "total": 14}, {"date": "2021-10-29", "issued": 5, "total": 11}, {
                "date": "2021-11-04",
                "issued": 3,
                "total": 7
            }, {"date": "2021-11-08", "issued": 16, "total": 20}, {"date": "2021-11-12", "issued": 23, "total": 30}];
            this.quotes.forEach(quote => {
                this.bound += quote.issued;
                this.total += quote.total;
            });
            this.quoted = this.total - this.bound;
            this.ratio = Math.floor(this.bound / this.quoted * 100);
        }
    }
};
</script>

<style lang="sass">
.no-stats
    background-color: gray
    opacity: .85
</style>
