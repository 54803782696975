<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1 my-1">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							Press Releases
						</h1>
						<h5 class="text-white">
							Draftrs Announces That Its Professional Liability Product For Architects and Engineers is Now Available In 48 States
						</h5>
					</div>
				</div>
			</div>
		</header>
		<main class="container-fluid bg-grey">
            <div class="container py-5">
				<div class="col-md-10 offset-md-1">   
                    <p class="pb-3">
                        <b><center>Draftrs Announces That Its Professional Liability Product For Architects and Engineers is Now Available In 48 States</center></b>
                    </p>
                    <p class="pb-3">
                        <b>Dallas, Texas – February 16, 2023 –</b> Draftrs, an insurtech company focused on modernizing small business professional liability insurance, today announced that its professional liability insurance product for architects and engineers is now available in 48 states.     
                    </p>
                    <p class="pb-3">
                        “We are very excited to be available in 48 states,” said Audrey Lau, co-founder and CEO of Draftrs.  “We are working with our carrier and agent partners to continue to expand our product reach.” 
                    </p>
                    <p class="pb-3">
                        The company plans to launch additional small firm professional liability products later in 2023.   
                    </p>
                    <p class="pb-3">
                        <b>About Draftrs, Inc. (Draftrs)</b><br/>
                        Draftrs, Inc. (Draftrs) is a technology-enabled, managing general agent (MGA) that is streamlining, automating, and digitizing the insurance underwriting and distribution process for carrier and broker partners to create a more efficient way to deliver professional liability insurance to the small commercial market.  Draftrs provides instant, direct access and enables distribution partners to become digital agencies without having to make any costly tech upgrades or investments.  For more information, please visit https://draftrs.com.   
                    </p>
				</div>
                <div class="col-md-10 offset-md-1">
                    <a href="/Press">View more press releases</a>
                </div>
			</div>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import StandardNav from "../../components/StandardNav.vue";
import StandardFooter from "../../components/StandardFooter.vue";

export default {
  name: "PressReleases",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
    },
    watch: {
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
    },
};
</script>

<style lang="scss">

</style>
