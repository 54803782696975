<script setup>
import {getUserData} from "@/Utils/auth"
import {computed} from "vue"

let props = defineProps({
    quote: Object,
    issueAction: String
})
let endorsing = computed(() => {
    return props.issueAction === 'endorse' && props.quote.id
})
</script>

<template>
    <nav aria-label="breadcrumb" class=" d-md-block mb-0 " v-if="getUserData().role !== 'ROLE_CONSUMER'">
        <ol class="breadcrumb bg-transparent px-4 py-2 my-0">
            <li class="breadcrumb-item"><a href="/home">Home</a></li>
            <li class="breadcrumb-item" aria-current="page"><a href="/dashboard">Dashboard</a></li>
            <li class="breadcrumb-item active" aria-current="date" v-if="endorsing"><a :href="'/policy/' + quote.customerId">Policy</a></li>
            <li class="breadcrumb-item active" aria-current="date" v-if="endorsing"><span>Endorse: {{ quote.insured }}</span></li>
            <li class="breadcrumb-item active" aria-current="date" v-else>Quote<span v-if="quote.id">: {{ quote.id }}</span></li>
        </ol>
    </nav>
</template>

<style scoped lang="scss">

</style>