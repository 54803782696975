<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-6 offset-md-1 my-5">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							File a claim
						</h1>
						<h5 class="text-white mb-3">
							Please complete the form below to file notice of a claim.
						</h5>
						<h5 class="text-white mb-3">
							While your claim is being processed:
						</h5>
						<div class="d-flex">
							<i class="orange-check-icon mt-1 mr-3"></i>
							<p class="text-white">
								Don’t admit responsibility for the accident or injury.
							</p>
						</div>
						<div class="d-flex">
							<i class="orange-check-icon mt-1 mr-3"></i>
							<p class="text-white">
								Don’t discuss the accident or injury with anyone other than your
								insurance agent or authorized representative, the insurance carrier,
								or law enforcement officials.
							</p>
						</div>
						<div class="d-flex">
							<i class="orange-check-icon mt-1 mr-3"></i>
							<p class="text-white">
								Don’t give copies of documents to anyone other than the insurance carrier
								associates or authorized representatives.
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main>
			<!-- Form -->
			<section class="container-fluid bg-grey">
				<div class="container py-5">
					<div class="row">
						<div class="col-md-10 offset-md-1">
							<form action="">
								<div class="row">
									<div class="col-md-6 mb-4">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="policy-number"
												placeholder="Policy Number *"
												aria-label="Policy Number"
                                                v-model.lazy="claim.policyNumber"
											/>
										</div>
									</div>
									<div class="col-md-6 mb-4">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="name"
												placeholder="Name *"
												aria-label="Name of Person Submitting the Claim"
                                                v-model.lazy="claim.insured"
											/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 mb-4 position-relative">
										<i
                                            v-b-tooltip.hover
											class="tooltip-icon tooltip-input-position position-absolute pb-2"
											data-bs-toggle="tooltip"
											data-bs-placement="top"
											title="Named Insured/Business Entity"
										></i>
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="policyholder"
												placeholder="Policy Holder *"
												aria-label="Policy Holder"
                                                v-model="claim.applicant"
											/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 mb-4">
										<div class="form-group">
											<input
												type="email"
												class="form-control"
												id="email"
												placeholder="Email Address *"
												aria-label="Email Address"
                                                v-model="claim.email"
											/>
										</div>
									</div>
									<div class="col-md-6 mb-4">
										<div class="form-group">
											<input
												type="tel"
												class="form-control"
												id="phone"
												placeholder="Phone Number *"
												aria-label="Phone Number"
                                                v-model="claim.phone"
											/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 mb-4 position-relative">
										<div class="form-group">
                                            <i
                                                v-b-tooltip.hover
                                                class="tooltip-icon tooltip-input-position position-absolute pb-2"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Named Insured/Business Entity"
                                            ></i>
											<input
												type="text"
												class="form-control"
												id="claimant"
												placeholder="Claimant *"
												aria-label="Claimant"
                                                v-model="claim.claimant"
											/>
										</div>
									</div>
									<div class="col-md-6 mb-4">
										<div class="form-group">
											<input
												type="tel"
												class="form-control"
												id="claimant-phone"
												placeholder="Claimant Phone Number *"
												aria-label="Claimant Phone Number"
                                                v-model="claim.claimantPhone"
											/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6 mb-4">
										<div class="form-group">
											<b-form-datepicker
                                                today-button
                                                reset-button
                                                close-button
                                                no-close-on-select
                                                label-no-date-selected="When was the date of loss?"
                                                v-model.lazy="claim.lossDate"
                                                @input="get_data()"
                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                locale="en"
                                                size="md"
                                                placeholder="MM/DD/YYYY *"
                                            >
                                            </b-form-datepicker>
										</div>
									</div>
									<div class="col-md-6 mb-4">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="location"
												placeholder="Location of Loss *"
												aria-label="Location of Loss"
                                                v-model="claim.lossLocation"
											/>
										</div>
									</div>
                                    <div class="col-md-12 mb-4">
                                        <div class="form-group">
                                        <label for="message">Description of loss (what happened, parties involved etc)  *</label>
                                        <textarea class="form-control" id="message" rows="10" v-model="claim.lossDescription"></textarea>
                                    </div>
                                    </div>
								</div>
								<button :disabled="!formIsComplete" type="submit" @click.prevent="submitClaim()" class="btn btn-primary mb-4">
									Submit
								</button>
								<p>
									Questions on filing your claim? Email us at:
									<a href="mailto:claims@draftrs.com">claims@draftrs.com</a>
								</p>
							</form>
						</div>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import axios from 'axios';
import { isLoggedIn } from '../Utils/auth.js';

export default {
  name: "FileClaim",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
        workType: '',
        summary: {},
        policy: {},
        quote: {},
        claim: {},
        formIsComplete: false
    };
  },  
  watch: {
    'claim': function() {
        if (Object.keys(this.claim).length == 10) {
            this.formIsComplete = true;
        } else
            this.formIsComplete = false;
    }
},
  async mounted() {
    window.scrollTo(0,0);
    if (isLoggedIn()) {
        let summary = {}, policy = {}, quote = {};
        this.customerId = this.$route.params.customerId;
        await axios.get('/api/policy/' + this.customerId).then((res) => {
            summary = res.data;
            policy = summary.policies[summary.policies.length-1];
        });
        await axios.get('/api/quote/' + policy.quoteId).then((res) => {
            quote = res.data;
        });
        this.summary = summary;
        this.policy = policy;
        this.quote = quote;

        this.claim.policyNumber = policy.policyNumber;
        this.claim.insured = quote.insured;
        this.claim.phone = quote.phone;
        this.claim.email = quote.email;
        this.claim.applicant = quote.owner;
        this.claim.lossDate = new Date();
        this.claim.transactionId = summary.transactions[0].id;
        this.claim.customerId = this.customerId;
    }
  },
  methods: {
    submitClaim: async function() {
        let ret;
        await axios.post('/api/claim', this.claim).then((res) => {
            ret = res.data;

            this.$toast.open({ 
                message: 'Thanks for sending us a message! Someone should get back to you ASAP.',
                type: 'success',
                dismissible: true,
                duration: 100000,
                position: 'top-right'
            });
        });

        return ret;
    }
  },
};
</script>

<style lang="scss">

</style>
