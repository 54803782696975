import { render, staticRenderFns } from "./draftrs-launches-new-digital-professional-liability-insurance-platform.vue?vue&type=template&id=2b3bc01e"
import script from "./draftrs-launches-new-digital-professional-liability-insurance-platform.vue?vue&type=script&lang=js"
export * from "./draftrs-launches-new-digital-professional-liability-insurance-platform.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports