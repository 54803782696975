<template>
    <div>
        <StandardNav/>
        <main>
			<section class="container-fluid bg-grey">
				<div class="row">
					<div class="col-md-6 px-0 position-relative">
						<img
							src="/images/office-space-min.jpg"
							alt="Modern office"
							class="img-fluid"
						/>
						<div
							class="position-absolute text-white col-10 offset-2"
							style="top: 10%"
						>
							<h1 class="display-3 font-weight-bold mb-5">Contact Us</h1>
							<h3 class="font-weight-bold">Email</h3>
							<a href="mailto:support@draftrs.com" class="text-white"
								>support@draftrs.com</a>
							<h3 class="font-weight-bold mt-4">Phone</h3>
							<a href="tel:(214) 494-1422" class="text-white">(214) 494-1422 </a>
						</div>
					</div>
					<div class="col-md-5 my-5 p-5">
                        <FormulateForm
                            @submit="submitHandler"
                            #default="{ hasErrors }"
                        >
						
							<div class="mb-3 form-group">
								<FormulateInput 
                                    type="text" 
                                    name="First Name" 
                                    placeholder="First Name" 
                                    input-class="form-control" 
                                    validation="required" 
                                    id="first-name" 
                                    aria-label="First Name"
                                    v-model="contact.fname"
                                />
							</div>
							<div class="mb-3 form-group">
                                <FormulateInput 
                                    type="text" 
                                    name="Last Name" 
                                    placeholder="Last Name" 
                                    input-class="form-control" 
                                    validation="required" 
                                    id="last-name" 
                                    aria-label="Last Name"
                                    v-model="contact.lname" 
                                />

							</div>
							<div class="mb-3 form-group">
                                <FormulateInput 
                                    type="email" 
                                    placeholder="Email" 
                                    name="Email" 
                                    input-class="form-control" 
                                    validation="email" 
                                    id="email"
                                    aria-label="Email"
                                    v-model="contact.email"
                                />
							</div>
							<div class="mb-3 form-group">
                                <FormulateInput 
                                    type="text" 
                                    placeholder="Mobile Phone Number" 
                                    name="phone" 
                                    input-class="form-control" 
                                    validation="required" 
                                    id="email"
                                    aria-label="Email"
                                    v-model="contact.phone"
                                />
							</div>
							<div class="mb-3 form-group">
                                <FormulateInput 
                                    type="text" 
                                    placeholder="Company Name" 
                                    name="company" 
                                    input-class="form-control" 
                                    validation="required" 
                                    id="company"
                                    aria-label="Company"
                                    v-model="contact.company"
                                />
							</div>
							<div class="mb-5 form-group">
                                <FormulateInput 
                                    type="textarea" 
                                    validation="required|max:400,length|min:30,length" 
                                    input-class="form-control" 
                                    id="message" 
                                    v-model="message"
                                    placeholder="Message"
                                    rows="6"
                                    :help="`Please keep it under 400 characters. ${400 - message.length} left.`"
                                />
							</div>
							<FormulateInput
                                input-class="btn btn-primary px-4"
                                type="submit"
                                :disabled="hasErrors"
                            />
						</FormulateForm>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import axios from 'axios';
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
    name: "ContactUs",
    components: {
        StandardNav,
        StandardFooter
    },
    props: {},
    data: function() {
        return {
            contact: {contactus: true, fname: '', lname: '', email: '', phone: '', company: '', message: ''},
            message: '',
        };
    },
    watch: {
        message: function() {
            if (this.message.length > 400) {
                this.message = this.message.substr(0, 400);
            }

        }
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
        submitHandler: async function() {
            this.contact.message = this.message;
            await axios.post('/api/contact', this.contact).then((response) => {
                if (response.data.status) {
                    this.$toast.open({ 
                        message: 'Thanks for sending us a message! Someone should get back to you ASAP.',
                        type: 'success',
                        dismissible: true,
                        duration: 100000,
                        position: 'top-right'
                    });

                } else {
                    this.$toast.open({ 
                        message: 'Oops something happened! Please try contacting us at support@draftrs.com.',
                        type: 'warning',
                        dismissible: true,
                        duration: 100000,
                        position: 'top-right'
                    });
                }
                window.scroll(0,0);
            });
        },
        redirectToHome: function() {
        this.$router.push({name: 'home', params: {}});
    },
    },
};
</script>

<style lang="scss">

</style>
