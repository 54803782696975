import Vue, {ref} from 'vue'
import App from './App.vue'
import router from './router'

import moment from 'moment'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import axios from 'axios'
import VueFormulate from '@braid/vue-formulate'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import VueGtag from "vue-gtag"
//import * as Sentry from "@sentry/vue"
//import { Integrations } from "@sentry/tracing"
import {getClientDomain, getClientText, isDevServer } from './Utils/themer.js'
import {getUserData} from "./Utils/auth.js"
import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'
import trackerAxios from '@openreplay/tracker-axios'
import VueScrollTo from 'vue-scrollto'
import {encode} from 'html-entities'
import formLibrary from "@/Utils/formLibrary.js"
import Vuetify from "vuetify"

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuetify)

Vue.config.productionTip = false;
Vue.prototype.loading = ref(false);
Vue.config.host = window.location.hostname
//openreplay
if (!isDevServer()) {
    const tracker = new Tracker({
        projectKey: "cVkFgJRASAFVgMvGKJOg",
        __DISABLE_SECURE_MODE: Vue.config.host === "localhost"
    });
    tracker.start();
    tracker.use(trackerAssist());
    tracker.use(trackerAxios());
    Vue.config.openreplay = tracker;
    if (Vue.config.host !== "localhost") {
        Vue.use(VueGtag, {config: {id: "G-T0R8HSWVYN"}}, router);
    }
}

Vue.use(VueFormulate, {
    classes: {
        outer: 'form-group',
        inputHasErrors: 'is-invalid',
        help: 'form-text text-muted',
        errors: 'list-unstyled text-danger',
        groupRepeatableRemove: 'btn btn-danger btn-sm repeat-btn-remove'
    },
    library: formLibrary
});

Vue.use(VueFormWizard);
Vue.use(VueToast);

// global methods 
Vue.mixin({
    methods: {
        isEffectiveDatePassed: function () {

            if (!this.editingDisabled() && this.quote.status === 'new' && moment.utc(this.minDate).isAfter(this.quote.effective, 'day')) {
                if (!this.effectiveDatePassed && !this.hasEffectiveDateOverride())
                    return true;
            }

            return false;
        },
        hasEffectiveDateOverride: function () {
            // only show effective date override for new business
            return this.quote && this.quote.effectiveDateOverride && this.issueAction == 'issue';
        },
        isObserver: function () {
            const user = getUserData();
            return ['ROLE_OBSERVER'].includes(user.role);
        },
        isCarrierAdmin: function () {
            const user = getUserData();
            return ['ROLE_ADMIN'].includes(user.role) && ['draftrs'].includes(user.owner) && ['hanover', 'markel'].includes(user.company);
        },
        findMissingIndustryType: function () {
            const lob = this.hasObject(this.quote, 'lob') && this.quote.lob == 'mpl' ? 'mpl' : 'pl';
            // finds industryType on first finding in the brokerConfigs brokerConfigs[lob][workTypes]
            if (this.brokerConfigs[lob]) this.brokerConfigs[lob].forEach((i) => {
                i.workTypes.forEach((wt) => {
                    if (wt.value === this.quote.workType && !this.quote.industryType) this.quote.industryType = i.industry.value
                })
            });
        },
        getRole: function () {
            return getUserData().role;
        },
        formatDate: function (dt) {
            return moment.utc(dt).startOf('date').format('MM/DD/YYYY');
        },
        formatMoney: function (m) {
            return Number((Math.round(m * 100) / 100)).toFixed(2);
        },
        capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),

        hasObject: function (parent, child) {
            return Object.prototype.hasOwnProperty.call(parent, child);
        },

        intersects: function (arr1, arr2) {
            if (!Array.isArray(arr1) || !Array.isArray(arr2))
                return 0;

            return arr1.filter(val => arr2.includes(val)).length > 0;
        },

        displayLimit: function (limit, option = 'full') {
            let limitOptions = {
                options: [
                    {v: 250000, per: '250,000', agg: '250,000', full: '$250K/$250K', rawAgg: 250000},
                    {v: 500000, per: '500,000', agg: '500,000', full: '$500K/$500K', rawAgg: 500000},
                    {v: 1000000, per: '1,000,000', agg: '1,000,000', full: '$1M/$1M', rawAgg: 1000000},
                    {v: 2000000, per: '2,000,000', agg: '2,000,000', full: '$2M/$2M', rawAgg: 2000000},
                    {v: 51, per: '500,000', agg: '1,000,000', full: '$500K/$1M', rawAgg: 1000000},
                    {v: 12, per: '1,000,000', agg: '2,000,000', full: '$1M/$2M', rawAgg: 2000000}
                ]
            };
            let map = limitOptions.options.filter(o => o.v == limit)

            if (map[0])
                return map[0][option];
        },
        getQuoteBreakdownUrl: async function (ratingId) {
            let url = {};
            await axios.get('/api/audit/quote/' + ratingId).then((res) => {
                url = res.data;
            });

            window.open(url.url, '_blank');
        },
        /* QUOTEFLOW AND PAYMENT STUFF */
        downloadPolicyDoc: function () {
            axios({
                url: '/api/doc/' + this.quote.customerId + '/' + this.quote.policyNumber + '/policy',
                method: 'POST',
                responseType: 'blob' // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'policy.pdf');
                document.body.appendChild(link);
                link.click();
            });
        },
        clearRequestCallPhone: async function () {
            this.quote.phone = !Object.prototype.hasOwnProperty.call(this.quote, 'phone') || !this.quote.phone ? this.quote.requestCallPhone : this.quote.phone;
            this.quote.requestCallPhone = '';
            this.requestCallPhoneSpinner = true;
            await this.saveQuoteData().then(() => {
                this.requestCallPhoneSpinner = 'saved';
            })

        },
        previewPolicy: function () {
            let url = '/api/doc/1/1/preview/1';
            this.policyPreviewSpinner = true;
            axios({
                url: url,
                method: 'POST',
                responseType: 'blob', // important
                data: this.quote
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'policy-preview-' + this.quote.insured + '.pdf');
                document.body.appendChild(link);
                link.click();
                this.policyPreviewSpinner = false;
            });
        },
        calcTax: function () {
            let tax = 0;
            if (this.quote.rating && this.quote.rating.breakdowns) {
                let premium = 'endorse' == this.issueAction ? this.prorate : this.quote.premium;

                if (null !== premium) {
                    tax = this.quote.rating.breakdowns.taxFee;
                }
            }

            return tax;
        },
        displayTax: function () {
            if (!this.calcTax())
                return '';

            let tax = this.formatMoney(this.calcTax())
            if (this.quote.state == 'FL') {
                return this.floridaFIGA();
            } else if (this.quote.state == 'NJ') {
                let taxStr = 'Premium is inclusive of $' + tax + ' Property-Liability Insurance Guaranty Association Surcharge';
                taxStr = this.quote.lob == 'mpl' ? 'New Jersey Property - Liability Insurance Guaranty Association Surcharge: $' + tax : taxStr;
                return taxStr;

            } else if (this.quote.state == 'KY') {
                let stateSurcharge = this.quote.rating.breakdowns.taxFee;
                let kyLocalTax = this.quote.premium ? this.formatMoney(Number((this.quote.premium - stateSurcharge) / 100 * 1.8)) : 0;
                let kyTotalTax = stateSurcharge = this.formatMoney(Number(stateSurcharge).toFixed(2));
                let stateSurchargeAmount = (kyTotalTax - kyLocalTax).toFixed(2);

                let taxStr = 'Premium is inclusive of $' + stateSurchargeAmount + ' KY Local Government Premium Tax and<br/> $' + kyLocalTax + ' KY Public Service Premium Surcharge';
                taxStr = this.quote.lob == 'mpl' ? 'Premium is inclusive of $' + kyLocalTax + ' Public Service Surcharge and $' + stateSurchargeAmount + ' Local Government Tax' : taxStr;
                return taxStr;

            } else {
                return 'Inclusive of $' + tax + ' State Tax';
            }
        },
        floridaFIGA: function () {
            if (!Object.prototype.hasOwnProperty.call(this.quote, 'state') || !Object.prototype.hasOwnProperty.call(this.quote, 'effective'))
                return false;

            let premium = 'endorse' == this.issueAction ? this.prorate : this.quote.premium;
            let taxFee = 'endorse' == this.issueAction ? this.quote.rating.breakdowns.endorsedTaxFee : this.quote.rating.breakdowns.taxFee;

            if (0 >= premium)
                return;

            let flTax = '';

            // 2022 FIGA
            // let d1 = new Date(2022, 6, 1); // Date(year, month, day)
            // let d2 = new Date(2023, 12, 31); // months go from 0 to 11

            // 2023 FIGA
            let d3 = new Date(2023, 10, 1); // Date(year, month, day)
            let d4 = new Date(2024, 9, 30); // months go from 0 to 11

            let c = new Date(String(this.quote.effective).substring(0, 4), parseInt(String(this.quote.effective).substring(5, 7)) - 1, String(this.quote.effective).substring(8, 10));

            if (moment.utc(c).isBetween(moment.utc(d3), moment.utc(d4))) { // FIGA effective between 01/01/2024-09/30/2024
                let tax = this.formatMoney((premium - taxFee) * .01);
                if (this.quote.lob && this.quote.lob == 'mpl') {
                    flTax = `Premium is inclusive of 2023 FIGA Emergency Assesment Surcharge of $` + tax;
                } else {
                    flTax = `Premium is inclusive of $` + tax + ` 2023 FIGA Emergency Assessment Surcharge`;
                }
            } else {
                flTax = ` `; //`Premium is inclusive of $${Math.round(flTax)} FL Insurance Guarantee Assessment Surcharge`;
            }

            return flTax;
        },
        saveRequestCallPhone: async function () {
            this.requestCallPhoneSpinner = true;
            await this.saveQuoteData().then(() => {
                this.requestCallPhoneSpinner = 'saved';
            })

        },
        scrollTo: function (id, offset = 0) {
            VueScrollTo.scrollTo(id, 6000, {easing: 'ease', offset: offset, cancelable: true})
        },
        sendEmail: function () {
            if (this.user.role == 'ROLE_OBSERVER')
                return;

            const msgType = document.getElementById('msgType').value;

            if (msgType == 'payment') {
                this.sendPaymentLink(this.quotes[this.currentIdx])
            } else {
                this.inviteInsured(this.quotes[this.currentIdx]);
            }

        },
        inviteInsured: async function (quote, url) {
            if (this.user.role == 'ROLE_OBSERVER')
                return;

            this.loaded = false;
            this.sendingEmail = true;
            this.shareEmail = this.agencyBill ? quote.agentEmail : quote.email;

            let params = {email: quote.email, quoteId: quote.id}

            let msg = 'An email has been sent to <strong>' + this.shareEmail + '</strong> with instructions for accessing their policy';
            if (url) {
                params.url = url;
                msg = url == 'purchaseerp' ? 'An email has been sent to <strong>' + this.shareEmail + '</strong> for purchasing ERP' : msg;
            }

            if (this.agencyBill)
                params.agentEmail = quote.agentEmail;

            let message = document.getElementById('message') ? encode(document.getElementById('message').value.replace(/(?:\r\n|\r|\n)/g, '<br>')) : null;
            if (message)
                params['message'] = message;

            let subject = document.getElementById('subject') ? encode(document.getElementById('subject').value) : null;
            if (subject)
                params['subject'] = subject;

            await axios.post('/api/recover', params).then((res) => {
                if (res.data) {
                    this.loaded = true;
                    this.sendingEmail = false;
                    this.$toast.open({
                        message: msg,
                        type: 'success',
                        dismissible: true,
                        duration: 8000,
                        position: 'top-right'
                    });
                }
            });
        },
        sendPaymentLink: async function (quote, type = 'payment') {
            if (this.user.role == 'ROLE_OBSERVER')
                return;

            if (type == 'renewalnotice' && !this.isExpiring(quote) && quote.status != 'issued') {
                this.$toast.open({
                    message: 'Email not sent. This policy is either not issued or is not expiring.',
                    type: 'success', dismissible: true, duration: 8000, position: 'top-right'
                });
            }

            this.loaded = false;
            this.sendingEmail = true;
            type = this.agencyBill ? 'agencyBill' : type;

            let params = {"email": quote.email, "quoteId": quote.id, "context": {"type": type, "action": 'issue'}, "client": quote.owners[0]};

            let message = document.getElementById('message') ? encode(document.getElementById('message').value.replace(/(?:\r\n|\r|\n)/g, '<br>')) : null;
            if (message)
                params['message'] = message;

            let subject = document.getElementById('subject') ? encode(document.getElementById('subject').value) : null;
            if (subject)
                params['subject'] = subject;

            await axios.post('/api/payment-invite', params).then(() => {
                this.loaded = true;
                this.sendingEmail = false;
                this.$toast.open({
                    message: type == 'payment' ? 'Payment email sent to ' + quote.email : (type == 'agencyBill' ? 'E-Signature email sent to ' + quote.email : 'Renewal email sent to ' + quote.email),
                    type: 'success',
                    dismissible: true,
                    duration: 8000,
                    position: 'top-right'
                });
            });
        },
        onPaymentComplete: async function (result) {
            this.issuing = true;
            
            if (true === result.success && ['ach'].includes(result.payMethod) && this.hasObject(result.billing, 'link')) { // ach micro deposit with stripe link
                this.stripeLink = result.billing.link

                // add preBindACH data to quote so the stripe event webhook will have the billing data
                if (this.stripeLink) {
                    this.quote.preBindACH = {};
                    this.quote.preBindACH.billing = result.billing;
                    this.quote.preBindACH.action = this.issueAction;
                }

                this.quote.payMethod = result.payMethod;
                this.quote.purchased = true;
                if (result.nameOnBank)
                    this.quote.nameOnBank = result.nameOnBank;
        
                // save payment data history to prevent re-payment
                if (!this.hasObject(this.quote, 'payments'))
                    this.quote.payments = [];

                // push and save payment info
                this.quote.payments.push({date: moment.utc().toISOString(), action: this.issueAction, stripe: result});

                // save quote data
                await axios.post('/api/quote', this.quote).then(() => {
                    // this.showPurchaseSuccess = true;
                });

                // send preBindACH email to insured with stripe link
                console.log('send pre bind email');
                await axios.post('/api/send-email', {email: this.quote.email, quoteId: this.quote.id, client: this.quote.owners[0], hook: 'preBindACH'}).then((res) => {
                    console.log(res.data);
                });

                this.purchased = true;
                window.scroll(0, 0);

            } else if (true === result.success && ['agencyBill', 'ach', 'cc'].includes(result.payMethod)) { // else cc or instant ach
                this.quote.payMethod = result.payMethod;
                this.quote.purchased = true;
                if (result.nameOnBank)
                    this.quote.nameOnBank = result.nameOnBank;
        
                // save payment data history to prevent re-payment
                if (!this.hasObject(this.quote, 'payments'))
                    this.quote.payments = [];

                // push and save payment info
                this.quote.payments.push({date: moment.utc().toISOString(), action: this.issueAction, stripe: result});

                // save quote data
                await axios.post('/api/quote', this.quote).then(() => {
                        this.issuePolicy(result.billing);
                });

                this.purchased = true;
                window.scroll(0, 0);
            } else {
                this.issuing = false;
            }
        },
        issuePolicy: async function (billing) {
            this.issuing = true;
            let params = {quoteId: this.quote.id, action: this.issueAction};

            params.effective = this.issueAction == 'endorse' ? this.quote.endorseEffective : this.quote.effective;
            params.quote = ['endorse', 'renew'].includes(this.issueAction) ? this.quote : null;

            if (billing) {
                params.billing = billing;
            }
        
            await axios.post('/api/policy', params).then((res) => {
                this.quote = res.data.quote;
                // this.showPurchaseSuccess = true;
            });
            //await this.refreshQuoteData();
            this.issuing = false;
        },
        allowPayment: function () {
            if (!Object.prototype.hasOwnProperty.call(this.quote, 'owners'))
                return true;
        
            if (this.hasObject(this.quote, 'preBindACH'))
                return false;

            const clientsToBlockFirst = ['catalant'];
            if (clientsToBlockFirst.includes(this.quote.owners[0])) {
                if (Object.prototype.hasOwnProperty.call(this.quote, 'allowPayment') && this.quote.allowPayment === 'disable')
                    return false;
                else if (Object.prototype.hasOwnProperty.call(this.quote, 'allowPayment') && this.quote.allowPayment === 'enable')
                    return true;

            } else
                return true;
        },
    }
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment.utc(String(value)).format('MM/DD/YYYY')
    }
});


const money = function (value) {
    if (value) {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        return formatter.format(value)
    }
    return null
}

Vue.filter('formatMoney', function (value) {
    return money(value)
});

Vue.filter('currency', function (value) {
    return money(value)
});

// set browser tab favicons
const favicon = document.querySelector("[rel='shortcut icon']")
favicon.setAttribute('href', process.env.BASE_URL + getClientDomain() + '.ico')

// set browser tab title
if (document.title)
    document.title = getClientText().title;

axios.interceptors.request.use((config) => {
    Vue.prototype.loading.value = true
    return config
})
axios.interceptors.response.use((response) => {
        Vue.prototype.loading.value = false
        return response
    },
    (error) => {
        Vue.prototype.loading.value = false
        if (error.status === 419) router.go()
        throw error
    }
)