<template>
  <body>
    <div id="wrapper" class="insure-board">
      <AgentNav/>
      <main id="main" role="main">
            <div class="">
                <div class="row">
                    <div class="col-md-3">
                        <div class="side-bar d-none d-md-block">
                            <div class="s-log">
                                <h2 v-if="summary.insured">{{ summary.insured.insured }}</h2>
                            </div>
                            <div class="s-nav">
                                <ul>
                                    <li><a href="#" class="active"><span><img src="/images/over.png" alt=""></span>Overview</a></li>
                                    <li><a href="#" @click="downloadPolicyDoc()"><span><img src="/images/policy.png" alt=""></span> Documents</a></li>
                                    <li><a href="#"><span><img src="/images/doco.png" alt=""></span>Payments</a></li>
                                    <li><a href="#"><span><img src="/images/poliy-change.png" alt=""></span>Policy Changes</a></li>
                                    <li><a href="#"><span><img src="/images/ins-doc.png" alt=""></span>Insurance Certificates</a></li>
                                </ul>
                            </div>
                            <div class="bottm-text">
                                <h2>Have a Question?</h2>
                                <a href="tel:9209891469"><span><img src="/images/cell.png" alt=""></span> 920-989-1469</a>
                                <a href="mailto:support@dratftrs.com"><span><img src="/images/mail.png" alt=""></span> support@dratftrs.com</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <!-- CANCEL BODY -->
                        <div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card" style="border-radius: 10px">
                                        <h4 class="card-header p-4" style="background: none;" >
                                            <i class="fas fa-edit"></i> Cancel Policy
                                        </h4>
                                        <div class="card-body row">
                                            <div class="card-inner col-md-7 m-4 p-3">
                                                <div class="list-area">
                                                    <dl class="row" style="font-size: .95rem ">
                                                        <dd class="col-sm-4 col-6 ">Policy Number:</dd>
                                                        <dd class="col-sm-8 col-6">
                                                            <router-link :to="{ name: 'quote', params: { id: policy.quoteId} }">{{ policy.policyNumber }}</router-link>
                                                        </dd>
                                                    
                                                        <dd class="col-sm-4 col-6">Effective: </dd>
                                                        <dd class="col-sm-8 col-6">{{ formatDate(policy.effective) }} - {{ getExpDt(policy.effective, policy.term) }}</dd>
                                                    
                                                        <dd class="col-sm-4 col-6">Coverage:</dd>
                                                        <dd class="col-sm-8 col-6">Professional Liability</dd>
                                                    
                                                        <dd class="col-sm-4 col-6">Premium:</dd>
                                                        <dd class="col-sm-8 col-6">{{ policy.premium | currency }}</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div class="card-inner col-md-11 m-4 p-3">
                                                <h4 class="main-color mb-5">Cancellation</h4>
                                                
                                                <div class="form-group mb-5 ml-2">
                                                    <label>What date do you want the policy cancelled?</label>
                                                    <b-form-datepicker
                                                        label-no-date-selected="Cancel Date"
                                                        v-model.lazy="cancelDate"
                                                        style="height: 34px; border-color: purple"
                                                        locale="en"
                                                        value-as-date
                                                        size="md"
                                                        placeholder="MM/DD/YYYY"
                                                        class="col-md-5"
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <p class="ml-2">Cancelled policy premiums will be returned pro-rata with an 8% administration fee calculated as follows:</p>
                                                
                                                <div class="row justify-content-center ml-2" v-show="cancelDate">
                                                    <div class="col-md-7 list-area">
                                                        <dl class="row" style="font-size: .95rem ">
                                                            <dt class="col-8 pl-4">Total Policy Premium: </dt>
                                                            <dd class="col-4 pl-4">{{ policy.premium | currency }}</dd>
                                                        
                                                            <dt class="col-8 pl-4">Prorate Return Premium:</dt>
                                                            <dd class="col-4 pl-4">{{ prorate | currency }}</dd>
                                                        
                                                            <dt class="col-8 pl-4">8% Administration Fee:</dt>
                                                            <dd class="col-4 pl-4">{{ fee | currency }}</dd>

                                                            <dt class="col-8 mt-4 p-4 bg-white">Total Refund Premium:</dt>
                                                            <dt class="col-4 mt-4 p-4 bg-white">{{ refund | currency }}</dt>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <div class="text-center mt-5">
                                                    <button :disabled="cancelDate ? '' : 'disabled'" class="purple-btn mr-3">Cancel Policy</button>
                                                    <router-link :to="'/policy/'+customerId" >
                                                        <button class="btn btn-outline-secondary btn-lg">Exit to Policy</button>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
  </body>
</template>

<script>
import AgentNav from "../components/AgentNav.vue";
import axios from 'axios';
import moment from 'moment';

export default {
  name: "Documents",
  components: {
    AgentNav
  },
  data: function() {
    return {
        customerId: null,
        quoteId: null,
        summary: {},
        policy: {},
    };
  },
  watch: {
  },
  mounted() {
    this.customerId = this.$route.params.customerid;
    this.quoteId = this.$route.params.id;
    this.getCustomerData();
  },
  methods: {
    getCustomerData: async function() {
        let summary = {};
        await axios.get('/api/policy/' + this.customerId).then((res) => {
        summary = res.data;
        });
        this.summary = summary;
        this.policy = this.summary.policies[this.summary.policies.findIndex(p => p.quoteId == this.quoteId)]
    },
    formatDate: function(dt, fmt) {
        if (!fmt) {
            fmt = 'MM/DD/YYYY';
        }
        return moment(dt).format(fmt);
    },
    getExpDt: function(eff, term) {
        return moment(eff).add(term, 'months').format('MMMM Do YYYY');
    },
}
};
</script>

<style lang="scss">
</style>