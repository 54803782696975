<template>
    <div>
        <StandardNav/>
		<header class="section-fluid bg-purple">
			<div class="container">
				<div class="row">
					<div
						class="col-md-5 d-flex flex-column justify-content-center align-items-md-start py-5"
					>
						<h1 class=" text-white font-weight-bold">Technical Design Consultants Professional Liability E&O Insurance</h1>
						<p class="text-white">
							Professional liability (E&O) insurance protects you and your business from claims against you arising out of a range of technical design consulting services including: CAD/BIM Drafting, environmental, acoustical, audio visual and other technical construction design related consulting services.
						</p>
						<a href="/getaquote" class="mx-auto btn btn-secondary mt-3"
							>Get a quote</a
						>
					</div>
					<div class="col-md-6 offset-md-1">
						<div class="half-width-img">
							<img
								src="/images/discussing-blueprint.jpg"
								alt="Young male architect at desk with schematics"
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main>
			<section class="container pt-5">
				<div class="row pb-5">
					<div class="col-md-6">
						<h4 class="font-weight-bold mb-4">What Does The Policy Cover?<br/>Coverage Highlights and Benefits</h4>
						<ul class="fa-ul mb-4 ml-4">
							<li class="pb-2">
								<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> Provides and covers the cost of a legal defense if Consultant gets sued due to their professional work
							</li>
							<li class="pb-2">
								<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> Pays awarded damages the Consultant owes to a third party for bodily injury, property damage and financial loss
							</li>
							<li class="pb-2">
								<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> Offers the Consultant expense reimbursements to attend arbitrations, mediations, depositions, hearings or trials in connection with a claim
							</li>
							<li class="pb-2">
								<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> Reimburses the Consultant for amounts paid in connection with regulatory or administrative actions, and amounts incurred in defense of a professional license or disciplinary proceedings
							</li>
							<li class="pb-2">
								<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> Offers the Consultant assistance in responding to subpoenas and the ability to report pre-claims matter for pre-claims assistance
							</li>
						</ul>
						<h4 class="font-weight-bold mb-4">Technical Design Consultants Professional Liability Claims Examples</h4>
						<p class="mb-4">
							Vibrations from outside noise was affecting the production quality of a new sound studio. Owner seeks additional costs from the acoustical consultant to redesign and retrofit the space.
						</p>

						<p class="mb-4 font-italic text-80">
							These Claims Examples are for illustrative purposes only. Please remember that only the insurance policy governs the coverage terms, conditions and exclusions
						</p>
					</div>
					<div class="offset-1 col-md-5">

						<h4 class="font-weight-bold mb-4">How much does a Technical Consultant’s professional liability policy cost?</h4>
						<p class="mb-4">
							Our premium for a 12 month Technical Consultant’s professional liability policy with a $500,000 policy limit starts at $800.  Our average customer premium for Technical Consultant’s is $1,025.
						</p>
						<p class="mb-4">
							Technical Consultant’s with less than $1M in revenues get a quote and buy instantly here!
						</p>
						<p class="mb-4 text-center">
							<a href="/getaquote" class="mx-auto btn btn-secondary mt-3">
								Get a quote
							</a>
						</p>

					</div>
				</div>
			</section>

			<!-- About FAQ -->
			<section class="container pb-5">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1">
						<h2 class="font-weight-bold mb-4">Professional Liability FAQs</h2>
						<div class="accordion" id="about-faq">
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutOne"
											aria-expanded="true"
											aria-controls="aboutOne"
										>
											Do I need professional liability?
										</button>
									</h2>
								</div>
								<div id="aboutOne" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											Many clients will contractually require professionals to carry professional liability insurance that covers their work. Depending on the profession, some states may also require the professional to obtain professional liability insurance as part of licensure requirements. Many small firms end up purchasing coverage as a result of these contractual and regulatory requirements but with coverage being so affordable, it is prudent for professionals to purchase the coverage regardless. A professional claim against you can be costly and stressful but a professional liability policy can provide legal assistance and protect you and your business. The policy will take on the claim defense, cover legal costs and pay awarded damages for covered matters
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutTwo"
											aria-expanded="false"
											aria-controls="aboutTwo"
										>
											Claims made and retroactive coverage
										</button>
									</h2>
								</div>
								<div id="aboutTwo" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											Most professional liability policies are written on a "claims made" basis. An important part of the claims made policy is the retroactive date, which is the date that the professional services were actually performed. “Claims made” policies provide coverage for claims made during the policy period related to professional services that were performed on or after the retroactive date.
For example, you buy a professional policy for the first time in 2021 with a 2021 retroactive date. Later in the year a client sues you for work you performed in 2020. That claim would not be covered because the retroactive date only picks up work you performed from 2021 onward.
										</p>
										<ul class="fa-ul">
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> If you are purchasing professional liability for the first time, your retroactive date will match the inception date of the policy. When you renew your coverage you should always make sure you carry over this first retroactive date on your future policies.
											</li>
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> If you have purchased continuous professional liability coverage in the past, it is important that you maintain that first retroactive date. This ensures all of you past work, from that first retroactive date forward, will continue to be covered should you receive a claim later on.
											</li>
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> You should never give up the retroactive date in exchange for a lower premium. You pay the premium for the work you perform but most professional liability claims will come in after you complete the work and in some cases, years after the work is complete.
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutThree"
											aria-expanded="false"
											aria-controls="aboutThree"
										>
											How is professional liability different from general liability?
										</button>
									</h2>
								</div>
								<div id="aboutThree" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											A general liability policy covers third party bodily injury and property damage that occurs on your premises. General liability also includes “covered or completed operations” coverage to cover a firms business operations however, this often does not include professional services.
										</p>
										<p>
											In fact, many general liability policies will have a “professional services” exclusion under this coverage part. That means there would be no coverage under a general liability policy for claims arising out of a professional firms’ services. 
										</p>
										<p>
											By contrast, a professional liability policy is intended to cover third party claims due to errors or omissions in professional services. An unsatisfied client suing the professional for damages is the most common claimant under the policy. All professional liability policies provide coverage for financial loss claims and depending on the profession, some policies will also include coverage for bodily injury and property damage claims.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "FAQ",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>
