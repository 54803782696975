<template>
    <div>
        <StandardNav/>
		<header class="section-fluid bg-purple">
			<div class="container">
				<div class="row">
					<div
						class="col-md-5 d-flex flex-column justify-content-center align-items-md-start py-5"
					>
						<h1 class="display-3 text-white font-weight-bold">General Liability</h1>
						<p class="text-white">
							General liability covers third party bodily injury and property damage that occurs on your premises and/or arises out of your business operations. For professionals, your business operations are usually your professional services and professional services are explicitly excluded on most general liability policies. Insurers will typically expect that professionals will obtain business operations coverage under a separate professional liability policy.
An example of a general liability premises claim: Amazon driver delivers package at your office, trips on the stairs and breaks an arm. This type of “slip and fall” that occurs on your premises would typically be covered under a general liability and not a professional liability.

						</p>
						<a href="/getaquote" class="mx-auto btn btn-secondary mt-3"
							>Get a quote</a
						>
					</div>
					<div class="col-md-6 offset-md-1">
						<div class="half-width-img">
							<img
								src="/images/3-professionals-discussion.jpg"
								alt="Diverse group of young professionals working"
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main>
			<!-- About FAQ -->
			<section class="container py-5">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1">
						<h2 class="font-weight-bold mb-4">General Liability FAQs</h2>
						<div class="accordion" id="about-faq">
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutOne"
											aria-expanded="true"
											aria-controls="aboutOne"
										>
											Do professionals need general liability?
										</button>
									</h2>
								</div>
								<div id="aboutOne" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											Professionals could need a general liability policy to cover bodily injury and property damage to third party which does NOT arise out of their professional services.  Some reasons why a professional might consider a general liability policy:
										</p>
										<ul class="fa-ul">
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> You rent office space and the commercial lease agreements requires tenants to carry general liability.
											</li>
											<li class="pb-2">
												<span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> Fills in potential coverage gap for “slips and falls” that happen on your premises but do not arise out of your professional services.
											</li>
										</ul>
										<p>
											If you work out of a home office, you should check your homeowners policy for coverage first.  If properly declared, this may already covered under your homeowners policy and you would not need to purchase an additional general liability or business operations (BOP) policy.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutTwo"
											aria-expanded="false"
											aria-controls="aboutTwo"
										>
											Occurrence vs claims made coverage trigger
										</button>
									</h2>
								</div>
								<div id="aboutTwo" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											General liability policies can be written on an occurrence or claims made basis. The “claims made” general liability policy is similar to the “claims made” professional policy where coverage is provided for claims made during the policy period related to events that occurred on or after the retroactive date.
										</p>
										<p>
											An “occurrence” general liability policy would cover bodily injury and property damage that occurred during the policy coverage period, even if the claim is made after the policy expires.
										</p>
										<p>
											For example, an Amazon delivery driver tripped over some cables in your office and broke their arm last year. Under a “claims made” general liability policy, a claim today would be covered if the slip and fall occurred after the retroactive date of the policy. Under an “occurrence” general liability policy, a claim today would be covered only if the policy was in effect at the time the slip and fall.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutThree"
											aria-expanded="false"
											aria-controls="aboutThree"
										>
											How is professional liability different from general liability?
										</button>
									</h2>
								</div>
								<div id="aboutThree" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											A general liability policy covers third party bodily injury and property damage that occurs on your premises. General liability also includes “covered or completed operations” coverage to cover a firms business operations however, this often does not include professional services.
										</p>
										<p>
											In fact, many general liability policies will have a “professional services” exclusion under this coverage part. That means there would be no coverage under a general liability policy for claims arising out of a professional firms’ services. 
										</p>
										<p>
											By contrast, a professional liability policy is intended to cover third party claims due to errors or omissions in professional services. An unsatisfied client suing the professional for damages is the most common claimant under the policy. All professional liability policies provide coverage for financial loss claims and depending on the profession, some policies will also include coverage for bodily injury and property damage claims.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "FAQ",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>
