<template>
    <div>
        <StandardNav/>
		<header class="faq-header container-fluid d-flex">
			<div
				class="container d-flex flex-column justify-content-center align-items-start"
			>
				<h1 class="display-4 text-white font-weight-bold mt-5">
					Insurance Glossary Terms & Definitions
				</h1>
			</div>
		</header>
		<main>

			<!-- Frequent Terms & Definitions -->
			<section class="container py-5">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1">
						<h2 class="font-weight-bold mb-4">Frequently used terms and definitions</h2>
						<div class="accordion" id="termsfaq">
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#claims-faq"
											aria-expanded="true"
											aria-controls="claims-faq"
										>
											Claims Made and Reported 
										</button>
									</h2>
								</div>
								<div id="claims-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											Most professional liability policies are written on a "claims made and reported” basis. These policies provide coverage for claims made against the Insured during the policy period related to professional services that were performed on or after the policy retroactive date.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#contingent-faq"
											aria-expanded="false"
											aria-controls="contingent-faq"
										>
											Contingent coverage
										</button>
									</h2>
								</div>
								<div id="contingent-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											Contingent coverage under a professional liability policy usually refers to coverage for specific damages
											that must directly arise out of the performance of professional services. The intent is to delineate
											coverage from the general liability policy and damages that occur on your premise vs those that are
											caused by your professional services. Contingent bodily injury and property damage, and contingent
											pollution are most commonly found on professional liability policies.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#deductible-faq"
											aria-expanded="false"
											aria-controls="deductible-faq"
										>
											Deductible
										</button>
									</h2>
								</div>
								<div id="deductible-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											This is the amount you are responsible for paying before limits under the policy would apply.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#financial-faq"
											aria-expanded="false"
											aria-controls="financial-faq"
										>
											Financial loss
										</button>
									</h2>
								</div>
								<div id="financial-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											A type of third-party damage where no bodily injury or property damage has been sustained. This might
											include loss of income, loss of value, loss of use, loss of contract, loss of production etc. Pure financial
											loss is covered under a professional liability policy. A general liability policy does not cover pure financial
											loss however, it might offer some coverage for consequential losses that result from covered bodily injury or property damages
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#firstdollar-faq"
											aria-expanded="false"
											aria-controls="firstdollar-faq"
										>
											First dollar defense
										</button>
									</h2>
								</div>
								<div id="firstdollar-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											Coverage enhancement under which the deductible would not apply to defense costs. The deductible
											would only apply if there was a payment made under the policy to a third party for damages.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#policy-faq"
											aria-expanded="false"
											aria-controls="policy-faq"
										>
											Policy limits
										</button>
									</h2>
								</div>
								<div id="policy-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											This is the maximum amount of coverage payable under the policy. It is typically expressed as a per
											claim amount and a total aggregate amount for the policy year. For professional liability policies, defense costs are usually included under this limit.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#retroactivedate-faq"
											aria-expanded="false"
											aria-controls="retroactivedate-faq"
										>
											Retroactive date
										</button>
									</h2>
								</div>
								<div id="retroactivedate-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											Typically used with “claims made” policies, the retroactive date establishes how far back coverage would apply for the professional services performed on or after this retroactive date
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#subrogation-faq"
											aria-expanded="false"
											aria-controls="subrogation-faq"
										>
											Subrogation
										</button>
									</h2>
								</div>
								<div id="subrogation-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											When a carrier makes payment under the policy, the carrier typically retains rights to seek recovery from any other responsible third party. This recovery right is subrogation.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#supplemental-faq"
											aria-expanded="false"
											aria-controls="supplemental-faq"
										>
											Supplemental payments
										</button>
									</h2>
								</div>
								<div id="supplemental-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											Additional coverage under the policy where payments and limits are often provided in addition to the
											policy limits and not subject to the policy deductible. For professional liability policies, this coverage
											enhancement typically applies to expenses related to regulatory and disciplinary proceedings.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#vicarious-faq"
											aria-expanded="false"
											aria-controls="vicarious-faq"
										>
											Vicarious liability
										</button>
									</h2>
								</div>
								<div id="vicarious-faq" class="collapse" data-parent="#termsfaq">
									<div class="card-body">
										<p>
											Refers to liability and responsibility held for another persons’ actions. This most commonly applies to
											employers, clients and owners who are often responsible for the actions of their employees, contractors, consultants and subscontractors.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "FAQ",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>
