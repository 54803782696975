<template>
    <div>
        <StandardNav/>
		<header class="faq-header container-fluid d-flex">
			<div
				class="container d-flex flex-column justify-content-center align-items-start"
			>
				<h1 class="display-4 text-white font-weight-bold mt-5">
					Frequently asked questions
				</h1>
			</div>
		</header>
		<main>
			<!-- About FAQ -->
			<section class="container py-5">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1">
						<h2 class="font-weight-bold mb-4">About Draftrs</h2>
						<div class="accordion" id="about-faq">
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutOne"
											aria-expanded="true"
											aria-controls="aboutOne"
										>
											What does Draftrs do?
										</button>
									</h2>
								</div>
								<div id="aboutOne" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											Draftrs is an online insurance platform that makes it
											easier and faster for small business professional firms
											and their agents to instantly get a quote, buy coverage
											and manage their policies. Our customers are small
											business accountants, design professionals, lawyers and
											consultants with less than $1M in fee revenues including
											sole practitioners and freelancers with more than 8 years
											of practice experience, and startups and first time buyers
											with no prior coverage.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutTwo"
											aria-expanded="false"
											aria-controls="aboutTwo"
										>
											What insurance products does Draftrs offer?
										</button>
									</h2>
								</div>
								<div id="aboutTwo" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											We specialize in professional liability insurance for
											small business professional firms. We also have the
											ability to offer a general liability policy when purchased
											in conjunction with a professional policy.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#aboutThree"
											aria-expanded="false"
											aria-controls="aboutThree"
										>
											Is Draftrs an Insurance Agency or Insurance Carrier?
										</button>
									</h2>
								</div>
								<div id="aboutThree" class="collapse" data-parent="#about-faq">
									<div class="card-body">
										<p>
											Draftrs is licensed as an insurance agent and is a program
											administrator for Markel Insurance Company. All policies
											are issued by Draftrs and underwritten by Markel Insurance
											Company, AM Best "A" rated. Draftrs licenses can be viewed
											<a href="/Licenses">here</a>.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- Buying a policy FAQ -->
			<section class="fluid-container grey-accordion-section">
				<div class="container py-5">
					<div class="row py-5">
						<div class="col-md-10 offset-md-1">
							<h2 class="font-weight-bold mb-4">Buying a policy</h2>
							<div class="accordion" id="buying-faq">
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#buyingOne"
												aria-expanded="true"
												aria-controls="buyingOne"
											>
												Do I need an agent?
											</button>
										</h2>
									</div>
									<div
										id="buyingOne"
										class="collapse"
										data-parent="#buying-faq"
									>
										<div class="card-body">
											<p>
												Our policies are currently only available through select
												agents. We will be opening up later on so that small
												business professional firms will also have the option of
												getting a quote and purchasing through us directly.
											</p>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#buyingTwo"
												aria-expanded="false"
												aria-controls="buyingTwo"
											>
												How quickly can I get Insured?
											</button>
										</h2>
									</div>
									<div
										id="buyingTwo"
										class="collapse"
										data-parent="#buying-faq"
									>
										<div class="card-body">
											<p>
												You can get insured instantly! Once the policy is
												purchased you will have immediate access to your policy.
												You will also be able to setup an account and instantly
												issue an insurance certificate to clients.
											</p>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#buyingThree"
												aria-expanded="false"
												aria-controls="buyingThree"
											>
												Can I cancel the policy at anytime? (Returns, Refunds,
												Cancellations)
											</button>
										</h2>
									</div>
									<div
										id="buyingThree"
										class="collapse"
										data-parent="#buying-faq"
									>
										<div class="card-body">
											<p>
												Policies can be cancelled by the Insured at any time.
												Refunds are offered on a pro-rata basis for the
												remaining policy term and subject to a 20%
												non-refundable minimum earned premium. For MI and GA,
												refunds will be provided in accordance with state
												specific schedules. Once policies are cancelled you will
												no longer carry consecutive coverage and you will lose
												the retroactive date coverage.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- Managing your policy FAQ -->
			<section class="container py-5">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1">
						<h2 class="font-weight-bold mb-4">Managing your policy</h2>
						<div class="accordion" id="managing-faq">
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#managingOne"
											aria-expanded="true"
											aria-controls="managingOne"
										>
											How do I file a claim?
										</button>
									</h2>
								</div>
								<div
									id="managingOne"
									class="collapse"
									data-parent="#managing-faq"
								>
									<div class="card-body">
										<p>
											You can log into your account to file the claim or file a
											claim <a href="/fileclaim">here</a>.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header">
									<h2 class="mb-0">
										<button
											class="btn btn-link btn-block text-left collapsed"
											type="button"
											data-toggle="collapse"
											data-target="#managingTwo"
											aria-expanded="false"
											aria-controls="managingTwo"
										>
											How do I get an Insurance Certificate?
										</button>
									</h2>
								</div>
								<div
									id="managingTwo"
									class="collapse"
									data-parent="#managing-faq"
								>
									<div class="card-body">
										<p>
											You will need to log into your account to create and issue
											your certificates.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<!-- Related topics FAQ -->
			<section class="fluid-container grey-accordion-section">
				<div class="container py-5">
					<div class="row py-5">
						<div class="col-md-10 offset-md-1">
							<h2 class="font-weight-bold mb-4">Related Topics</h2>
							<div class="accordion" id="related-faq">
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#relatedOne"
												aria-expanded="true"
												aria-controls="relatedOne"
											>
												What is professional liability?
											</button>
										</h2>
									</div>
									<div
										id="relatedOne"
										class="collapse"
										data-parent="#related-faq"
									>
										<div class="card-body">
											<p>
												Professional liability, sometimes also referred to as
												errors & omissions (E&O) and malpractice insurance,
												covers a professional in the event a client or other
												third party sues for damages that arise out errors or
												omissions in the professional’s works and services.
											</p>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#relatedTwo"
												aria-expanded="false"
												aria-controls="relatedTwo"
											>
												Claims made with retroactive date
											</button>
										</h2>
									</div>
									<div
										id="relatedTwo"
										class="collapse"
										data-parent="#related-faq"
									>
										<div class="card-body">
											<p>
												Most professional liability policies are written on a
												"claims made" basis. An important part of the claims
												made policy is the retroactive date, which is the date
												that the professional services were actually performed.
												“Claims made” policies provide coverage for claims made
												during the policy period related to professional
												services that were performed on or after the retroactive
												date.
											</p>
											<p>
												For example, you buy a professional policy for the first
												time in 2021 with a 2021 retroactive date. Later in the
												year a client sues you for work you performed in 2020.
												That claim would not be covered because the retroactive
												date only picks up work you performed from 2021 onward.
											</p>
											<div class="row">
												<div
													class="col-md-10 offset-md-1 p-3 border-radius-30 my-3 bg-purple text-white"
												>
													<div class="d-flex">
														<i class="orange-check-icon mt-1 mr-3"></i>
														<p class="text-white">
															If you are purchasing professional liability for
															the first time, your retroactive date will match
															the inception date of the policy. When you renew
															your coverage you should always make sure you
															carry over this first retroactive date on your
															future policies.
														</p>
													</div>
													<div class="d-flex">
														<i class="orange-check-icon mt-1 mr-3"></i>
														<p class="text-white">
															If you have purchased continuous professional
															liability coverage in the past, it is important
															that you maintain that first retroactive date.
															This ensures all of you past work, from that first
															retroactive date forward, will continue to be
															covered should you receive a claim later on.
														</p>
													</div>
													<div class="d-flex">
														<i class="orange-check-icon mt-1 mr-3"></i>
														<p class="text-white">
															You should never give up the retroactive date in
															exchange for a lower premium. You pay the premium
															for the work you perform but most professional
															liability claims will come in after you complete
															the work and in some cases, years after the work
															is complete.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#relatedThree"
												aria-expanded="false"
												aria-controls="relatedThree"
											>
												What is general liability?
											</button>
										</h2>
									</div>
									<div
										id="relatedThree"
										class="collapse"
										data-parent="#related-faq"
									>
										<div class="card-body">
											<p>
												General liability covers third party bodily injury and
												property damage that occurs on your premises and/or
												arises out of your business operations. For
												professionals, your business operations are usually your
												professional services and professional services are
												explicitly excluded on most general liability policies.
												Insurers will typically expect that professionals will
												obtain business operations coverage under a separate
												professional liability policy.
											</p>
											<p>
												An example of a general liability premises claim: A
												client comes to your office, trips on the stairs and
												breaks an arm. This type of “slip and fall” that occurs
												on your premises would typically be covered under a
												general liability and not a professional liability.
											</p>
											<div class="row">
												<div
													class="col-md-10 offset-md-1 p-3 border-radius-30 my-3 bg-purple text-white"
												>
													<div class="d-flex">
														<i class="orange-check-icon mt-1 mr-3"></i>
														<p class="text-white">
															Draftrs offers a claims made general liability
															policy that goes along with the professional
															policy to cover your premises exposure. Some
															reasons why a professional might also consider a
															general liability policy:
														</p>
													</div>
													<div class="d-flex gap-2">
														<i class="orange-check-icon mt-1 mr-3"></i>
														<p class="text-white">
															Commercial lease agreements may require tenants to
															carry general liability
														</p>
													</div>
													<div class="d-flex gap-2">
														<i class="orange-check-icon mt-1 mr-3"></i>
														<p class="text-white">
															Potential coverage gap as “slips and falls” that
															happen on your premises and do not arise out of
															your professional services may not be covered
															under the professional liability policy
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#relatedFour"
												aria-expanded="true"
												aria-controls="relatedFour"
											>
												General liability insurance "claims made" or
												"occurrence" triggers
											</button>
										</h2>
									</div>
									<div
										id="relatedFour"
										class="collapse"
										data-parent="#related-faq"
									>
										<div class="card-body">
											<p>
												General liability policies can be written on an
												occurrence or claims made basis. The “claims made”
												general liability policy is similar to the “claims made”
												professional policy where coverage is provided for
												claims made during the policy period related to events
												that occurred on or after the retroactive date.
											</p>
											<p>
												An “occurrence” general liability policy would cover
												bodily injury and property damage that occurred during
												the policy coverage period, even if the claim is made
												after the policy expires.
											</p>
											<p>
												For example, a client tripped over some cables in your
												office and broke their arm last year. Under a “claims
												made” general liability policy, a claim today would be
												covered if the slip and fall occurred after the
												retroactive date of the policy. Under an “occurrence”
												general liability policy, a claim today would be covered
												only if the policy was in effect at the time the slip
												and fall.
											</p>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#relatedFive"
												aria-expanded="true"
												aria-controls="relatedFive"
											>
												How is professional liability different from general
												liability?
											</button>
										</h2>
									</div>
									<div
										id="relatedFive"
										class="collapse"
										data-parent="#related-faq"
									>
										<div class="card-body">
											<p>
												A general liability policy covers third party bodily
												injury and property damage that occurs on your premises.
												General liability also includes “covered or completed
												operations” coverage to cover a firms business
												operations however, this often does not include
												professional services. In fact, many general liability
												policies will have a “professional services” exclusion
												under this coverage part. That means there would be no
												coverage under a general liability policy for claims
												arising out of a professional firms’ services. By
												contrast, a professional liability policy is intended to
												cover third party claims due to errors or omissions in
												professional services. An unsatisfied client suing the
												professional for damages is the most common claimant
												under the policy. All professional liability policies
												provide coverage for financial loss claims and depending
												on the profession, some policies will also include
												coverage for bodily injury and property damage claims.
											</p>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#relatedSix"
												aria-expanded="true"
												aria-controls="relatedSix"
											>
												Do I need professional liability insurance?
											</button>
										</h2>
									</div>
									<div
										id="relatedSix"
										class="collapse"
										data-parent="#related-faq"
									>
										<div class="card-body">
											<p>
												Many clients will contractually require professionals to
												carry professional liability insurance that covers their
												work. Depending on the profession, some states may also
												require the professional to obtain professional
												liability insurance as part of licensure requirements.
												Many small firms end up purchasing coverage as a result
												of these contractual and regulatory requirements but
												with coverage being so affordable, it is prudent for
												professionals to purchase the coverage regardless. A
												professional claim against you can be costly and
												stressful but a professional liability policy can
												provide legal assistance and protect you and your
												business. The policy will take on the claim defense,
												cover legal costs and pay awarded damages for covered
												matters.
											</p>
										</div>
									</div>
								</div>
								<div class="card">
									<div class="card-header">
										<h2 class="mb-0">
											<button
												class="btn btn-link btn-block text-left collapsed"
												type="button"
												data-toggle="collapse"
												data-target="#relatedSeven"
												aria-expanded="true"
												aria-controls="relatedSeven"
											>
												Frequently Used Terms and Definitions
											</button>
										</h2>
									</div>
									<div
										id="relatedSeven"
										class="collapse"
										data-parent="#related-faq"
									>
										<div class="card-body">
											<p>
												Draftrs is licensed as an insurance agent and is a
												program administrator for Markel Insurance Company. All
												policies are issued by Draftrs and underwritten by
												Markel Insurance Company, AM Best "A" rated. Draftrs
												licenses can be viewed here.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
 
export default {       
  name: "FAQ",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
    };
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
  },
};
</script>

<style lang="scss">

</style>
