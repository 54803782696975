<template>
    <div id="wrapper" class="insure-board bg-light ">
        <AgentNav/>
        <nav aria-label="breadcrumb" class=" d-md-block mb-0">
            <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
                <li class="breadcrumb-item"><a href="/home">Home</a></li>
                <li v-if="getUserData().role !== 'ROLE_CONSUMER'" class="breadcrumb-item" aria-current="page"><a href="/dashboard">Dashboard</a></li>
                <li v-if="summary && summary.insured" class="breadcrumb-item"><a :href="'/policy/' + summary.customerId">Policy: <span v-if="summary.insured">{{ summary.insured.insured }}</span></a></li>
                <li v-if="policy" class="breadcrumb-item active" aria-current="page">Update Insured Details: <span v-if="policy">{{ policy.policyNumber }}</span></li>
            </ol>
        </nav>
        <main id="main" role="main">
            <div v-if="loading" class="spinner-container position-fixed">
                <b-spinner label="Loading..." class="h-100 w-100"></b-spinner>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <b-button @click="json = !json">Toggle Editor</b-button>
            </div>
            <div v-if="json" class="json">
                <JsonEditorVue v-model="quote" />
            </div>
            <div v-else class="row justify-content-center p-2">
                <div class="col-md-8 card p-3 mb-3 shadow card-radius">
                    <h2 class="main-color pl-3 mb-1"><i class="fas fa-user-cog"></i>Update Insured Details</h2>
                    <div class="card-body">
                        <div class="alert alert-danger border border-danger" v-show="formErrors">
                            <i class="fas fa-exclamation-triangle"></i>Please complete the fields that are highlighted in red.
                        </div>
                        <div id="business-info" class="address-pay info-form p-3">
                            <form id="insuredForm">
                                <fieldset>
                                    <div class="form-row">
                                        <div class="col-md-12 mb-3">
                                            <label>Business Name</label>
                                            <input type="text" class="form-control" :disabled="!isSuperAdmin" :class="quote.insured ? 'is-valid' : 'is-blank'" v-model="quote.insured" required>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-12 mb-3">
                                            <label>Effective Date</label>
                                            <b-form-datepicker
                                                    no-flip
                                                    today-button
                                                    reset-button
                                                    close-button
                                                    no-close-on-select
                                                    label-no-date-selected="When would you like coverage to start?"
                                                    v-model="quote.effective"
                                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                    locale="en"
                                                    size="lg"
                                                    :disabled="!isSuperAdmin"
                                                    placeholder="MM/DD/YYYY"
                                                    :class="quote.effective ? 'is-valid' : 'is-blank'"
                                                    class="col-md-4"
                                            />
                                        </div>
                                    </div>
                                    <div v-for="(label, field) in {'phone': 'Phone Number', 'email': 'Email', 'address':'Business Address', 'city': 'City' }" class="form-row" :key="field">
                                        <div class="col-md-4 mb-3 mt-3">
                                            <label>{{ label }}</label>
                                            <input type="text" class="form-control" :class="quote[field] ? 'is-valid' : 'is-blank'" v-model="quote[field]" required>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-8 mb-3">
                                            <StateSelect v-model="quote.state" @input="validateZip"/>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-4 mb-0">
                                            <label>Zip Code</label>
                                            <input type="text" class="form-control" :class="quote.zip && !validations.zip ? 'is-valid' : 'is-blank'" v-model="quote.zip" @input="validateZip" required>
                                        </div>
                                        <div class="ml-2 is-invalid" v-show="validations.zip">
                                            {{ validations.zip }}
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center" v-if="updated">
                <router-link :to="'/policy/'+customerId">
                    <button class="btn btn-primary btn-lg mt-2"><i class="fas fa-chevron-left"></i>Return to Policy</button>
                </router-link>
            </div>
            <div class="text-center" v-else>
                <button @click="update" class="btn btn-primary btn-lg mr-3 mt-2">
                    <i v-if="submittingForm" class="fas fa-spinner fa-pulse"></i>
                    Update
                </button>
                <router-link :to="'/policy/'+customerId">
                    <button class="btn btn-outline-dark btn-lg mt-2">Cancel</button>
                </router-link>
            </div>
        </main>
        <StandardFooter/>
    </div>
</template>

<script setup>
import Vue, {ref} from "vue"
import AgentNav from "../components/AgentNav.vue"
import StandardFooter from "../components/StandardFooter.vue"
import axios from 'axios'
import {getUserData} from "@/Utils/auth"
import StateSelect from "@/components/Form/StateSelect.vue"
import JsonEditorVue from 'json-editor-vue'
import {useRoute} from 'vue-router/composables'

const route = useRoute()
let json = ref(false)
let updated = ref(false)
let formErrors = ref(false)
let loading = ref(true)
let customerId = ref(route.params.customerid) // url order: /quoteId/customerId
let quoteId = ref(route.params.id)
let originalQuote = ref(null)
let quote = ref({})
let summary = ref({})
let policy = ref({})
let validations = ref({})
let submittingForm = ref(false)
let isSuperAdmin = ref(['ROLE_SUPER'].includes(getUserData().role))

let getCustomerData = async function () {
    let res = await axios.get('/api/policy/' + customerId.value)
    summary.value = {...res.data}
    policy.value = summary.value.policies?.[summary.value.policies.findIndex(p => p.quoteId === quoteId.value)]
    res = await axios.get('/api/quote/' + policy.value.quoteId)
    originalQuote.value = {...res.data}
    quote.value = {...res.data}
    loading.value = false
}

getCustomerData()

let validateZip = async function () {
    let zipd = true
    if (5 > quote.value.zip.length) {
        zipd = false
    } else {
        let res = await axios.get('/api/zip/' + quote.value.zip).catch(() => {
            zipd = false
        })
        zipd = res.data
        if (!zipd.zip || (zipd.zip === quote.value.zip || zipd.state !== quote.value.state)) {
            zipd = false
        }
    }
    Vue.set(validations.value, 'zip', false === zipd ? ('Zipcode does not match with state ' + quote.value.state + '. Please enter the correct zipcode.') : null)
}

let update = async function () {
    submittingForm.value = true
    // ADD FORM VALIDATIONS HERE
    if (!json.value) { // can't validate the raw json
        let els
        let formEl = document.getElementById('insuredForm')
        if (formEl) {
            els = formEl.getElementsByClassName('is-blank')
            Array.from(els).forEach(element => element.classList.add('is-invalid'))
        } else
            return false

        if (els.length) {
            formErrors.value = true
            window.scrollTo(0, 0)
            return false
        }
    }
    const updatedQuote = {}
    // console.log(originalQuote.value)
    // console.log(quote.value)
    if (JSON.stringify(originalQuote.value) !== JSON.stringify(quote.value)) {
        updatedQuote.value = {...originalQuote.value, ...quote.value}
    } else {
        submittingForm.value = false
        return
    }
    const params = {quoteId: quoteId.value, quote: updatedQuote.value, action: 'changeinfo'}
    await axios.post('/api/policy', params).catch(() => {
        submittingForm.value = false
        Vue.$toast.open({
            message: 'Oops! We could not update your information at this time. Please try again later.',
            type: 'warning',
            dismissible: true,
            duration: 8000,
            position: 'top-right'
        })
    })
    submittingForm.value = false
    updated.value = true
    formErrors.value = false
    Vue.$toast.open({
        message: 'Insured details have been updated.',
        type: 'success',
        dismissible: true,
        duration: 8000,
        position: 'top-right'
    })
}
</script>

<style lang="scss">
.spinner-container {
    height: 100px;
    width: 100px;
    z-index: 200;
    top: 40%;
    left: 47.5%;
}

#payment-form {
    border: 1px solid var(--main-color);
    padding: 10px
}
</style>