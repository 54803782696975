<template>
  <div>
    
  </div>



</template>

<script>
import {  loginUser } from '../Utils/auth.js';
import axios from 'axios';

export default {
  name: 'sso',
  data: function () {
    return {
      client: 'londonuw',
      email: null,
      token: null,
      code: null
    }
  },
  async mounted() {
      this.email = this.$route.params.email;
      this.token = this.$route.params.token;
      this.code = this.$route.query.code;
      //loginUser(this.client, this.email, this.token);
      //this.$router.push('/');
      if (!this.code) {
        return window.location = 'https://draftrs.auth.us-west-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=15ecikbfpprefu24oorstg5bpg&redirect_uri=https://londonuw.draftrs.com/sso';
      }

      await axios.post("/api/sso", { code: this.code }).then(res => {
                const jwt = res.data.token;
                const email = res.data.email;
                
                if (jwt) {
                    loginUser(this.client, email, jwt)
                    this.$router.push('/dashboard');
                    this.email = email;
                    this.token = jwt;
                } else {
                    console.log('LoginError1');
                }
            }).catch(() => {
              console.log('LoginError2');
            });
  },
  methods: {

  }
}
</script>

<style lang="scss">


</style>