<template>
    <div id="wrapper">
        <div class="d-none">PL</div>
        <BreadCrumb :quote="quote" :issueAction="issueAction"/>

        <main id="main" role="main" class="mb-5">
            <section class="">
                <div class="row h-100">
                    <div class=" pr-0 col-md-3">
                        <div class=" d-none d-md-block bg-light rounded m-2 h-100 shadow">
                            <div class="text-center" v-if="getRole() === 'ROLE_CONSUMER'">
                                <a v-if="clientDomain !== 'draftrs'" class="navbar-brand mr-0" href="/dashboard"><img :src="getClientLogo()" alt=""></a>
                                <div v-if="getClientText().phone.length > 0" class="pt-1" :class="getClientText().phone.length < 5 ? 'd-none': ''" style="font-size: 10pt">
                                    {{ getClientText().phone }}
                                </div>
                            </div>

                            <div class="fs-nav" v-if="purchased === false">
                                <ul>
                                    <li v-if="quote.status === 'issued'"><a href="javascript:close()" class="main-color"><i class="fas fa-chevron-left mr-2"></i>Back To My Policy</a></li>
                                    <li><a href="#" @click="sideBarChangeTab(0)" :class="{ active: 0 === currentIdx }"><span>1</span>Start</a></li>
                                    <li><a href="#" @click="sideBarChangeTab(1)" :class="{ active: 1 === currentIdx }"><span>2</span>Company</a></li>
                                    <li><a href="#" @click="sideBarChangeTab(2)" :class="{ active: 2 === currentIdx }"><span>3</span>Details</a></li>
                                    <li><a href="#" @click="sideBarChangeTab(3)" :class="{ active: 3 === currentIdx }"><span>4</span>Quote</a></li>
                                    <li v-if="!lockEdit && quote.premium"><a href="#" @click="sideBarChangeTab(4)"
                                                                             :class="{ active: 4 === currentIdx }"><span>5</span>{{ issueAction === 'renew' ? 'Renew Policy' : 'Purchase' }}</a></li>
                                </ul>
                            </div>
                            <div class="fs-nav" v-else>
                                <ul>
                                    <li v-if="issueAction === 'issued'"><a href="#" class="active">Quote Purchased</a></li>
                                    <li v-if="issueAction === 'endorse'"><a href="#" class="active">Policy Endorsed</a></li>
                                </ul>
                            </div>
                            <div v-if="['catalant', 'aibd', 'draftrs'].includes(clientDomain)">
                                <div class="mt-3 pt-5 pl-5 text-90">
                                    <h5>Have questions?</h5>
                                    <p>Ask one of our insurance advisors.</p>
                                    Email: <a href="mailto:submissions@draftrs.com">submissions@draftrs.com</a>
                                </div>
                                <div class="pt-1 pl-5 text-90">
                                    Call: (214) 494-1422
                                </div>
                            </div>
                            <div class="text-center text-muted" v-if="clientDomain === 'grange'">
                                <div class="mt-3 pt-5 text-90">
                                    <b>Agency Name</b><br/>
                                    {{ getStyle('agencyName') }}
                                </div>
                                <div class="pt-4 text-90">
                                    <b>Agency Code</b><br/>
                                    {{ getStyle('agencyCode') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 px-0" v-show="isLoading()">
                        <div class="text-center">
                            <h2 style="padding-top: 200px">🚀 loading...</h2>
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="align-middle spinner-border text-secondary" style="width: 5rem; height: 5rem;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <transition name="fade">
                        <div class="quote-form col-md-9 px-0" v-show="!isLoading()">
                            <div id="all-inputs" class="shadow rounded m-2 p-md-4 h-100" style="background-color: #FAF9FE">
                                <h2 id="purch-txt" class="text-center text-secondary" v-if="issueAction==='renew'">👋 Let's renew your policy!</h2>
                                <form-wizard ref="formWizard" title="" subtitle="" color="var(--main-color)" stepSize="xs" @on-change="changeTab">
                                    <div class="text-right " v-if="saving">
                                        <div style="height: 0; z-index: 200; left: 50%">
                                            Saving <i class="fas fa-circle-notch fa-spin"></i>
                                        </div>
                                    </div>
                                    <div class="alert alert-danger border border-danger" v-show="formErrors">
                                        <i class="fas fa-exclamation-triangle"></i> Please complete the fields or questions that are highlighted in red.
                                    </div>
                                    <!-- QUOTE PAGE 1 -->
                                    <tab-content>
                                        <form ref="form1" id="form1">
                                            <fieldset :disabled="editingDisabled() || lockEdit">

                                                <div class="rounded shadow-sm p-1 p-md-4  mx-sm-0 bg-white" style="min-height: 1000px">
                                                    <h2 v-if="issueAction !== 'renew'" class="main-color mb-4">👋 Let's get a quote!</h2>

                                                    <div class="form-group mb-5" v-if="hasObject(quote, 'industryType')">
                                                        <div v-if="!hasObject(quote, 'id')">
                                                            <label>Select Your Industry</label>
                                                            <b-dropdown id="industryType-section" dropdown no-flip :text="quote.industryType ? quote.industryType : ''"
                                                                        toggle-class=" rounded-sm text-left form-label text-dark bg-light py-1 pl-1" menu-class="w-100 rounded-sm bg-light"
                                                                        class="w-100 rounded-sm" :variant="quote.industryType !== null ? 'outline-light' : 'outline-danger'">
                                                                <template v-for="industryType in industryTypes">
                                                                    <!-- header only -->
                                                                    <template v-if="hasObject(industryType, 'header') && industryType.header === 'header-only'">
                                                                        <b-dropdown-item disabled class="font-weight-bold text-dark mt-1 mb-1" :key="industryType.id">
                                                                            {{ industryType.value }}
                                                                        </b-dropdown-item>
                                                                    </template>
                                                                    <!-- single industry as header -->
                                                                    <template v-else-if="hasObject(industryType, 'header') && industryType.header === 'header'">
                                                                        <b-dropdown-item href="#" @click="changeIndustry(industryType.key)" :key="industryType.id" class="font-weight-bold mt-1">
                                                                            {{ industryType.value }}
                                                                        </b-dropdown-item>
                                                                    </template>
                                                                    <!-- nested industries below a header -->
                                                                    <template v-else>
                                                                        <b-dropdown-item href="#" @click="changeIndustry(industryType.key)" :key="industryType.id" class="pl-2">
                                                                            - {{ industryType.value }}
                                                                        </b-dropdown-item>
                                                                    </template>
                                                                </template>
                                                            </b-dropdown>
                                                        </div>
                                                        <div v-else>
                                                            <label>{{ quote.id ? "Your Industry" : "Select Your Industry" }}</label>
                                                            <select class="form-control" :disabled="quote.id" @change="redirectIfNotPL(quote.industryType)" v-model="quote.industryType"
                                                                    :class="isNotNull('industryType') ? 'is-valid' : 'is-blank'" required>
                                                                <option value="null"></option>
                                                                <option class="font-weight-bolder" v-for="industryType in industryTypes" :key="industryType.key" :value="industryType.value">
                                                                    {{ industryType.key }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <b-modal id="appetite-modal" size="lg" ok-only title="Program Qualification Checklist">
                                                        <ul class="my-4 fa-ul">
                                                            <li class="pb-2" v-for="(app, i) in appetite" :key="i"><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> {{ app }}
                                                            </li>
                                                        </ul>
                                                    </b-modal>

                                                    <div class=" mb-5" v-if="quote.industryType">
                                                        <label>What type of work do you do?</label>
                                                        <select class="form-control" v-model="quote.workType" :class="quote.workType ? 'is-valid' : 'is-blank'" required>
                                                            <option value="">Select line of work</option>
                                                            <option v-for="workType in workTypes" :key="workType.key" :value="workType.value">{{ workType.key }}</option>
                                                        </select>
                                                        <div :class="checkWorkType() ? '' : 'd-none'" class="mt-2 alert alert-danger border border-danger">
                                                            <i class="fas fa-exclamation-triangle"></i> Unfortunately we do not insure structural or geotechnical engineers. Select another option.
                                                        </div>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label class="buss">
                                                            Name of Business
                                                            <i tabindex="0"
                                                               v-b-tooltip.hover
                                                               title="Name of the business entity to be Insured.
                                                    This will be Named Insured on the policy.
                                                    Additional Named Insureds can be added on the endorsements page."
                                                               class="far fa-question-circle main-color">
                                                            </i>
                                                        </label>
                                                        <input type="text" @change="ofac()" v-model="quote.insured" :class="isNotNull('insured') ? 'is-valid' : 'is-blank'" class="form-control">
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label>State</label>
                                                        <select :disabled="issueAction === 'endorse'" v-model="quote.state" :class="quote.state ? 'is-valid' : 'is-blank'" class="form-control"
                                                                required>
                                                            <option value="">Select a State</option>
                                                            <option v-for="(state, abbrev) in formStates" :key="abbrev" :value="state.abbrev">{{ state.name }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group mb-5" v-if="quote.state === 'KY'">
                                                        <label>Address</label>
                                                        <input v-model="quote.address" type="text" @change="validateZip" class="form-control" :class="isNotNull('address') ? 'is-valid' : 'is-blank'"
                                                               required>
                                                    </div>

                                                    <div class="form-group mb-5" v-if="quote.state === 'KY'">
                                                        <label>Zipcode</label>
                                                        <input type="text" class="form-control" :class="isNotNull('zip') && !validations.zip ? 'is-valid' : 'is-blank'" v-model="quote.zip"
                                                               @change="validateZip" placeholder="Zip Code" required>
                                                        <div class="invalid-feedback" :style="validations.zip ? 'display: block' : 'display: none'">
                                                            {{ validations.zip }}
                                                        </div>
                                                    </div>

                                                    <div class="form-group mb-5" v-if="quote.state === 'KY'">
                                                        <label>City</label>
                                                        <input v-model="quote.city" type="text" class="form-control" readonly>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label>
                                                            Insured Email Address
                                                            <i tabindex="0"
                                                               v-b-tooltip.hover
                                                               title="This will be the email we use to correspond with the Insured to send a copy of the quote, payment, documents, login access..."
                                                               class="far fa-question-circle main-color">
                                                            </i>
                                                        </label>
                                                        <input :disabled="issueAction === 'endorse'" v-model="quote.email" type="email" class="form-control"
                                                               :class="isNotNull('email') ? 'is-valid' : 'is-blank'" aria-describedby="emailHelp" required>
                                                        <!-- <span v-if="'ROLE_CONSUMER' === getRole()">
                                                    <input type="checkbox" class="ml-1" style="display: block; float: left; height: 24px;" value="true" v-model="quote.emailMeMyQuote">
                                                    <label class="ml-1 font-weight-lighter" style="font-size: .80em" >Email me a link to access my quote.</label>
                                                </span> -->
                                                    </div>
                                                </div>

                                            </fieldset>
                                        </form>
                                    </tab-content>

                                    <!-- QUOTE PAGE 2 -->
                                    <tab-content>
                                        <form ref="form2" id="form2">
                                            <fieldset :disabled="editingDisabled() || lockEdit">

                                                <div class="rounded shadow-sm p-1 p-md-4  mx-sm-0 bg-white">
                                                    <h2 class="main-color mb-4">Company Info</h2>

                                                    <div class="form-group mb-5">
                                                        <label>Name of Applicant/Contact Name</label>
                                                        <input type="text" v-model="quote.owner" :class="isNotNull('owner') ? 'is-valid' : 'is-blank'" class="form-control" required>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label>When would you like your coverage to start?</label>
                                                        <b-form-datepicker
                                                                no-flip
                                                                today-button
                                                                reset-button
                                                                close-button
                                                                :min="hasEffectiveDateOverride() ? '' : minDate"
                                                                no-close-on-select
                                                                label-no-date-selected="When would you like coverage to start?"
                                                                v-model="quote.effective"
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                style="height: 44px;"
                                                                locale="en"
                                                                size="lg"
                                                                :disabled="issueAction==='endorse' || issueAction==='renew' || (hasEffectiveDateOverride() && !['ROLE_SUPER'].includes(getRole()))"
                                                                placeholder="MM/DD/YYYY"
                                                                :class="isNotNull('effective') ? 'is-valid' : 'is-blank'"
                                                                class="col-md-6"

                                                        >
                                                        </b-form-datepicker>
                                                    </div>

                                                    <div class="form-group mb-5 bg-light p-3 border-danger border" v-if="getRole() === 'ROLE_SUPER'">
                                                        <input id="consent-project-types-excluded" v-model="quote.effectiveDateOverride" class="purp-check-input form-check-input"
                                                               type="checkbox" required>
                                                        <label for="consent-project-types-excluded" class="pl-1 pt-1 form-check-label">
                                                            <i class="far fa-hand-point-left fa-lg pr-1"></i> SUPER ADMIN: Allow post dating the effective date?
                                                        </label>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label>Date Business was Established</label>
                                                        <b-form-datepicker
                                                                no-flip
                                                                label-no-date-selected="Date business was established"
                                                                v-model="quote.established"
                                                                :max="minDate"
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                style="height: 44px;"
                                                                locale="en"
                                                                size="lg"
                                                                :disabled="issueAction==='endorse' || issueAction==='renew'"
                                                                placeholder="MM/DD/YYYY"
                                                                :class="isNotNull('established') ? 'is-valid' : 'is-blank'"
                                                                class="col-md-6"

                                                        >
                                                        </b-form-datepicker>

                                                    </div>
                                                </div>

                                                <!-- FIRM REVENUE AND EMPLOYEES -->
                                                <div class="rounded shadow-sm p-1 p-md-4  mx-sm-0 bg-white mt-5">
                                                    <h2 class="main-color mb-4" id="firm-revenue">Firm Revenue and Employees</h2>

                                                    <div class="form-group mb-5">
                                                        <label>Total annual firm revenues or professional fees
                                                            <i tabindex="2"
                                                               v-b-tooltip.hover
                                                               title="Provide revenues for the last 12 months or the last completed year.
                                                            If you are a startup with less than 12 months of operating experience, estimate your revenues for the next 12 month period. Total annual firm revenues = total professional fees and billings"
                                                               class="far fa-question-circle main-color">
                                                            </i>
                                                        </label>
                                                        <p>use last 12 months or estimate next 12 months if startup</p>
                                                        <h5 v-if="hasObject(quote, 'revenueOverride') && quote.revenueOverride" class="p-3 bg-light">${{ formatMoney(quote.revenue) }}</h5>
                                                        <div v-else class="input-group ml-0 mt-1">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                    :disabled="hasObject(quote, 'revenueOverride') && quote.revenueOverride"
                                                                    type="number"
                                                                    v-model.number="quote.revenue"
                                                                    class="form-control"
                                                                    :class="isNotNull('revenue') ? 'is-valid' : 'is-blank'"
                                                                    aria-label="Amount (to the nearest dollar)"
                                                            >
                                                        </div>
                                                    </div>

                                                    <div class="form-group mb-5 bg-light p-3 border-danger border" v-if="getRole() === 'ROLE_SUPER'">
                                                        <input id="consent-project-types-excluded" v-model="quote.revenueOverride" class="purp-check-input form-check-input"
                                                               type="checkbox" required>
                                                        <label for="consent-project-types-excluded" class="pl-1 pt-1 form-check-label">
                                                            <i class="far fa-hand-point-left fa-lg pr-1"></i> SUPER ADMIN: Override the $1M revenue Limit?
                                                        </label>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.controlOwnedByOther" label="Is your firm a subsidiary and/or controlled or owned by any other
                                                            firm or company?"/>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label>Total professional employees</label>
                                                        <p>include owners/principals</p>
                                                        <input
                                                                type="text"
                                                                v-model="quote.totalOwners"
                                                                @keypress="isNumber($event)"
                                                                class="form-control col-md-4"
                                                                :class="isNotNull('totalOwners') ? 'is-valid' : 'is-blank'"
                                                        >
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label style="margin-bottom: 25px">Average years of professional experience</label>
                                                        <input
                                                                type="text"
                                                                v-model="quote.experienceYears"
                                                                @keypress="isNumber($event)"
                                                                min="0"
                                                                class="form-control col-md-4"
                                                                :class="isNotNull('experienceYears') ? 'is-valid' : 'is-blank'"
                                                        >
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label>Total non-professional employees, clerical etc</label>
                                                        <input
                                                                type="text"
                                                                v-model="quote.totalNoProfEmployees"
                                                                @keypress="isNumber($event)"
                                                                class="form-control col-md-3"
                                                                :class="quote.totalNoProfEmployees > -1 ? 'is-valid' : 'is-blank'"
                                                        >
                                                    </div>

                                                    <div class="form-group mb-0" v-show="quote.totalNoProfEmployees !== null">
                                                        <label>Total of all employees: {{ Number(quote.totalNoProfEmployees) + Number(quote.totalOwners) }}</label>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </form>
                                    </tab-content>

                                    <!-- QUOTE PAGE 3 -->
                                    <tab-content>
                                        <form ref="form3" id="form3" class="needs-validation" novalidate>
                                            <fieldset :disabled="editingDisabled() || lockEdit">
                                                <!-- SERVICES PROVIDED -->
                                                <div class="rounded shadow-sm p-1 p-md-4  mx-sm-0 bg-white">
                                                    <h2 class="main-color mb-4">Services Provided</h2>

                                                    <div class="form-group mb-5">
                                                        <label>Add any additional service that:</label>
                                                        <div class="pb-2">
                                                            <ul class="fa-ul">
                                                                <li class="pb-2"><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> you are trained or licensed to provide</li>
                                                                <li class="pb-2"><span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> is self performed by you and not
                                                                    subcontracted
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div v-for="(addlServicesProvided, idx) in quote.addlServicesProvided" :key="idx">
                                                            <div class="form-inline py-2">
                                                                <select v-model="quote.addlServicesProvided[idx]" @change="endorseChanges()" class="form-control"
                                                                        :class="quote.addlServicesProvided && !quote.addlServicesProvided.filter(as => as == '').length ? 'is-valid' : 'is-blank'"
                                                                        required>
                                                                    <option value="">Select an Additional Service</option>
                                                                    <option v-for="workType in workTypes" :key="workType.key" :value="workType.value">{{ workType.key }}</option>
                                                                </select>
                                                                <button class="btn btn-light border border-danger m-2 btn-danger" v-b-tooltip.hover title="Delete this Additional Service"
                                                                        @click.prevent="removeAddlService(idx)"><i class="fas text-danger fa-trash-alt"></i></button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a class="btn btn-light border border-success" @click.prevent="newAddlService()"><i class="fas text-success fa-plus-circle"></i> Add Service</a>
                                                        </div>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.engineeringServices"
                                                                         label="Do you provide any of the following services?"
                                                                         :list="[['Environmental Remediation','Stamped or Engineered Foundation Design','Fire Safety &amp; Protection Services'],['Materials Testing & Inspection Services','Products Design or Engineering','Seismic Engineering']]"/>
                                                    </div>
                                                    <!-- CONTRACTOR RESPONSIBILITY -->
                                                    <div>
                                                        <div class="form-group mb-4">
                                                            <BooleanQuestion v-model="quote.contractorResponsibility" label="Are you a contractor, or do you ever take construction
                                                                responsibility?"/>
                                                        </div>

                                                        <div class="form-group mb-5" v-if="quote.contractorResponsibility">
                                                            <label class="alert alert-warning mb-2 text-left" :class="isNotNull('consentContractorExclusion') ? '' : 'is-blank'">
                                                                <i class="text-warning fas fa-exclamation-triangle"></i> Your construction work is not covered under the policy.
                                                            </label>

                                                            <div class="mt-3 ml-3">
                                                                <input id="consent-project-types-excluded" v-model="quote.consentContractorExclusion" class="purp-check-input form-check-input"
                                                                       type="checkbox" required>
                                                                <label for="consent-project-types-excluded" class="pl-3 pt-1 form-check-label"
                                                                       :class="isNotNull('consentContractorExclusion') ? '' : 'is-invalid'">
                                                                    <i class="far fa-hand-point-left fa-lg pr-1"></i> Please check to acknowledge and proceed.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- SELL STOCK RENDERINGS? -->
                                                    <div>
                                                        <div class="form-group pt-3 mb-4 mt-5">
                                                            <BooleanQuestion v-model="quote.sellStockRenderings" label="Do you sell stock renderings, drafts, plans and/or reference
                                                                designs?"/>
                                                        </div>
                                                        <div v-if="quote.sellStockRenderings" class="form-group mb-4">
                                                            <BooleanQuestion v-model="quote.disclaimerUnstamped" label="Do you include disclaimers that plans are unstamped and may
                                                                not be compliant to local codes and requirements?"/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- PROJECT TYPES -->
                                                <div v-if="quote.state !== 'WA'" class="rounded shadow-sm p-1  p-md-4  mx-sm-0 bg-white mt-5">
                                                    <h2 class="main-color mb-4" id="project-types">Project Types</h2>

                                                    <div class="form-group mb-5">
                                                        <label :class="!quote.projects || !quote.projects.length ? 'is-blank' : ''" class="text-left mb-3">Check the boxes for the project types you
                                                            work on. Select at least 1.</label>

                                                        <div class="fs-check-point">
                                                            <ul>
                                                                <li>
                                                                    <div class="form-group">
                                                                        <div class="form-check">
                                                                            <label for="resi-dos" class="form-check-label">
                                                                                <b>Residential</b>
                                                                                <br/>
                                                                                apartments, single family, condos, etc
                                                                            </label>
                                                                            <input v-model="quote.projects" value="Residential" @change="clearDependantsOf('projectTypeResidential'); endorseChanges();"
                                                                                   id="resi-dos" class="form-check-input" type="checkbox">
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="form-group">
                                                                        <div class="form-check">
                                                                            <label for="com-off" class="form-check-label">
                                                                                <b>Commercial</b>
                                                                                <br/>
                                                                                office, retail, warehouses, hotels, etc
                                                                            </label>
                                                                            <input v-model="quote.projects" value="Commercial" @change="clearDependantsOf('projectTypeCommercial'); endorseChanges();"
                                                                                   id="com-off" class="form-check-input" type="checkbox">
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="form-group">
                                                                        <div class="form-check">
                                                                            <label for="manu-proc" class="form-check-label">
                                                                                <b>Industrial &amp; Manufacturing</b><br/>
                                                                                including hospitals, assisted living, processing facilities etc
                                                                            </label>
                                                                            <input v-model="quote.projects" @change="endorseChanges();" value="Industrial Manufacturing" id="manu-proc"
                                                                                   class="form-check-input" type="checkbox">
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="form-group">
                                                                        <div class="form-check">
                                                                            <label for="pow-n-energy" class="form-check-label">
                                                                                <b>Power &amp; Energy/ Oil &amp; Gas</b><br/>
                                                                                upstream, midstream, downstream, power generation &amp; nonrenewables
                                                                            </label>
                                                                            <input v-model="quote.projects" value="Power and Energy and Utilities"
                                                                                   @change="clearDependantsOf('projectTypePowerEnergy'); endorseChanges();" id="pow-n-energy" class="form-check-input"
                                                                                   type="checkbox">
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="form-group">
                                                                        <div class="form-check">
                                                                            <label for="muni-pub" class="form-check-label">
                                                                                <b>Municipal/Public</b><br/>
                                                                                schools, parks, theaters, jails, government buildings, street and road, etc
                                                                            </label>
                                                                            <input v-model="quote.projects" @change="endorseChanges();" value="Institutional, Municipal and Recreation" id="muni-pub"
                                                                                   class="form-check-input" type="checkbox">
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="form-group">
                                                                        <div class="form-check">
                                                                            <label for="civil-inf" class="form-check-label">
                                                                                <b>Civil &amp; Infrastructure</b><br/>
                                                                                highways, mass transit, airports, water/waste treatment facilities
                                                                            </label>
                                                                            <input v-model="quote.projects" value="Heavy Civil and Infrastructure"
                                                                                   @change="clearDependantsOf('projectTypeCivilInfra'); endorseChanges();" id="civil-inf" class="form-check-input"
                                                                                   type="checkbox">
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="form-group mb-5" v-if="hasProject('Residential')">
                                                        <BooleanQuestion v-model="quote.homes3M" label="Do you work on custom homes with more than $3 million in hard construction
                                                            cost?"
                                                                         tooltip="Known hard construction costs.  Hard costs do not include land value, development costs, escalation, contigencies, profits etc"
                                                                         @change="clearDependantsOf('homes3M')"/>
                                                    </div>
                                                    <div class="form-group mb-5" v-if="hasProject('Residential')">
                                                        <BooleanQuestion v-model="quote.forSaleDevelopments"
                                                                         label="Do you work on any for sale residential developments?"
                                                                         sublabel="including: condos, townhomes, subdivisions, HOA"
                                                                         @input="clearDependantsOf('forSaleDevelopments')"/>
                                                    </div>
                                                    <div class="form-group mb-5" v-if="quote.forSaleDevelopments">
                                                        <BooleanQuestion v-model="quote.greaterThan25Units"
                                                                         label="Any developments greater than 25 units?"
                                                                         @input="clearDependantsOf('greaterThan25Units')"/>
                                                    </div>

                                                    <div class="form-group mb-5" v-if="hasProject('Commercial')">
                                                        <BooleanQuestion v-model="quote.bldg12Stories"
                                                                         label="Do you work on high rise buildings taller than 12 stories?"
                                                                         @input="clearDependantsOf('bldg12Stories')"/>
                                                    </div>

                                                    <div class="form-group mb-5" v-if="hasProject('Power and Energy and Utilities')">
                                                        <BooleanQuestion v-model="quote.minesQuarriesNukes"
                                                                         label="Do you work on Mines, Quarries, or Nuclear Projects in the Power &amp; Energy sectors?"
                                                                         @input="clearDependantsOf('minesQuarriesNukes')"/>
                                                    </div>

                                                    <div class="form-group mb-5" v-if="hasProject('Heavy Civil and Infrastructure')">
                                                        <BooleanQuestion v-model="quote.bridgesTunnelsDams"
                                                                         label="Do you work on Bridges, Blast or Bored Tunnels, Dams, Harbors, or Wharves in the Civil &amp; Infrastructure sector?"
                                                                         @input="clearDependantsOf('bridgesTunnelsDams')"/>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.notCoveredProjectTypes"
                                                                         label="Do you work on any of the following project types?"
                                                                         :list="[['Amusement/Water Park Rides','Aviation and/or Naval Craft','Offshore Projects'],['Professional Sports Stadiums','Prototype or First Technologies','Stand Alone Parking Structures']]"
                                                                         @input="clearDependantsOf('notCoveredProjectTypes')"/>
                                                    </div>

                                                    <div class="form-group mb-3"
                                                         v-if="quote.bridgesTunnelsDams || quote.notCoveredProjectTypes || quote.greaterThan25Units || quote.homes3M || quote.bldg12Stories || quote.minesQuarriesNukes">
                                                        <label class="alert alert-warning mb-2 text-left" :class="isNotNull('consentProjectTypesExcluded') ? '' : 'is-blank'">
                                                            <i class="text-warning fas fa-exclamation-triangle"></i> You have identified working on a project type that is excluded from our program.
                                                            This can result in a gap in coverage. <br/> <br/> This exclusion does not apply to land surveying, interior design, lanscape architecture
                                                            and/or technical consulting services. <br/> <span class="pl-4 pt-2"> </span>
                                                        </label>

                                                        <div style="font-size: .8em">
                                                            <div class="mb-1" v-show="quote.greaterThan25Units || quote.homes3M">
                                                                <h6 class="pt-2 pl-2">Residential:</h6>
                                                                <div class="d-flex flex-wrap justify-content-left">
                                                                    <div class="pl-4" style="width: 100%" v-show="quote.greaterThan25Units"><i class="main-color fas fa-circle fa-xs"></i>
                                                                        Condo/Townhomes/Subdivisions/HOA developments with more than 25 units
                                                                    </div>
                                                                    <div class="pl-4" style="width: 100%" v-show="quote.homes3M"><i class="main-color fas fa-circle fa-xs"></i> Custom Homes with
                                                                        more than $3M in hard construction costs
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="mb-1" v-show="quote.bldg12Stories">
                                                                <h6 class="pt-2 pl-2">Commercial:</h6>
                                                                <div class="d-flex flex-wrap justify-content-left">
                                                                    <div class="pl-4" style="width: 100%"><i class="main-color fas fa-circle fa-xs"></i> High Rise Buildings taller than 12 stories
                                                                        (exclusion does not apply to non-structural interior renovations projects)
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="mb-1" v-show="quote.minesQuarriesNukes">
                                                                <h6 class="pt-2 pl-2">Power &amp; Energy:</h6>
                                                                <div class="d-flex flex-wrap justify-content-left">
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Mines/Quarries</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Nuclear Energy Projects</div>
                                                                </div>
                                                            </div>

                                                            <div class="mb-1" v-show="quote.bridgesTunnelsDams">
                                                                <h6 class="pt-2 pl-2">Civil &amp; Infrastructure:</h6>
                                                                <div class="d-flex flex-wrap justify-content-left">
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Bridges</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Blast &amp; Bored Tunnels</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Dams, Harbours or Wharves</div>
                                                                </div>
                                                            </div>

                                                            <div class="mb-1" v-show="quote.notCoveredProjectTypes">
                                                                <h6 class="pt-2 pl-2">Other Project Types:</h6>
                                                                <div class="d-flex flex-wrap justify-content-left">
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Amusement/Water Park Rides</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Aviation &amp; Naval</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Offshore Projects</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Professional Sports Stadiums</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Prototype or First Technologies</div>
                                                                    <div class="pl-4" style="width: 250px"><i class="main-color fas fa-circle fa-xs"></i> Stand Alone Parking Structures</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="mt-4 ml-3">
                                                            <input id="consent-project-types-excluded" v-model="quote.consentProjectTypesExcluded" class="purp-check-input form-check-input"
                                                                   type="checkbox" required>
                                                            <label for="consent-project-types-excluded" class="pl-3 pt-1 form-check-label"
                                                                   :class="isNotNull('consentProjectTypesExcluded') ? '' : 'is-invalid'">
                                                                <i class="far fa-hand-point-left fa-lg pr-1"></i> Please check to acknowledge and proceed.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- WA SPECIFIC PROJECT TYPES -->
                                                <div v-else class="rounded shadow-sm p-1  p-md-4  mx-sm-0 bg-white mt-5">
                                                    <h2 class="main-color mb-4" id="project-types">Project Types</h2>

                                                    <div class="form-group mb-5">
                                                        <label :class="!quote.projects || !quote.projects.length ? 'is-blank' : ''" class="text-left mb-3">Check the boxes for the project types you
                                                            work on. Select at least 1.</label>
                                                        <div class="fs-check-point">
                                                            <ul>
                                                                <li>
                                                                    <div class="pb-3">
                                                                        <label class="form-check-label">
                                                                            <b class="main-color">Residential</b>
                                                                            <br/>
                                                                            apartments, single family, condos, etc
                                                                        </label>
                                                                    </div>
                                                                    <ul>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="apartments" @change="endorseChanges();" class="purp-check-input float-left"
                                                                                   type="checkbox"> Apartments
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="customhomesunder3m" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Custom Homes under $3M value
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="condosless25units" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Condos Less than 25 Units
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="customhomesover3m" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Custom Homes Over $3M
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="condosover25units" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Condos Over 25 Units
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <div class="pb-3">
                                                                        <label class="form-check-label">
                                                                            <b class="main-color">Commercial</b>
                                                                            <br/>
                                                                            office, retail, warehouses, hotels, etc
                                                                        </label>
                                                                    </div>
                                                                    <ul>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="conventioncenters" @change="endorseChanges();"
                                                                                   class="purp-check-input float-left"
                                                                                   type="checkbox"> Convention Centers
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="highriseover12stories" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> High Rise over 12 stories
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="hotels" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Hotels
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="offices" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Offices
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="retailshoppingcenters" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Retail &amp; Shopping Centers
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="theaters" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Theaters
                                                                        </li>
                                                                    </ul>
                                                                </li>

                                                                <li>
                                                                    <div class="pb-3">
                                                                        <label class="form-check-label">
                                                                            <b class="main-color">Municipal/Public</b><br/>
                                                                            schools, parks, theaters, jails, government buildings, street and road, etc
                                                                        </label>
                                                                    </div>
                                                                    <ul>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="governmentbldgs" @change="endorseChanges();" class="purp-check-input float-left"
                                                                                   type="checkbox"> Government Buildings
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="prisonsjail" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Prisons / Jail
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="publicparksgardens" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Public Parks &amp; Gardens
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="schoolsk12" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Schools K-12
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="streetsroads" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Streets &amp; Roads
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="universities" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Universities
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <div class="pb-3">
                                                                        <label class="form-check-label">
                                                                            <b class="main-color">Power &amp; Energy/ Oil &amp; Gas</b><br/>
                                                                            upstream, midstream, downstream, power generation &amp; nonrenewables
                                                                        </label>
                                                                    </div>
                                                                    <ul>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="powerutilityplants" @change="endorseChanges();"
                                                                                   class="purp-check-input float-left"
                                                                                   type="checkbox"> Power &amp; Utility Plants
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="petrochemicalfacilities" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Petrochemical Facilities &amp; Refineries
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="renewableenergyplants" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Renewable Energy Plants
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="minesquarries" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Mines Quarries
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="nuclear" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Nuclear
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <div class="pb-3">
                                                                        <label class="form-check-label">
                                                                            <b class="main-color">Civil &amp; Infrastructure</b><br/>
                                                                            highways, mass transit, airports, water/waste treatment facilities
                                                                        </label>
                                                                    </div>
                                                                    <ul>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="airports" @change="endorseChanges();" class="purp-check-input float-left"
                                                                                   type="checkbox"> Airports
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="highways" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Highways
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="masstransit" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Mass Transit
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="tunnelstrenches" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Tunnels - Cut Cover or Trench
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="bridges" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Bridges
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="damslevees" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Dams &amp; Levees
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="tunnelsblastbored" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Tunnels - Blast or Bored
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="wharvesharbors" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Wharves Harbors
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                                <li>
                                                                    <div class="pb-3">
                                                                        <label class="form-check-label">
                                                                            <b class="main-color">Industrial &amp; Manufacturing</b><br/>
                                                                            including hospitals, assisted living, processing facilities etc
                                                                        </label>
                                                                    </div>
                                                                    <ul>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="hospitals" @change="endorseChanges();" class="purp-check-input float-left"
                                                                                   type="checkbox"> Hospitals
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="mfgfacilities" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Manufacturing Facilities
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="sewagewatertreatment" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Sewage &amp; Water Treatment Facilities
                                                                        </li>
                                                                    </ul>
                                                                    <div class="pb-3">
                                                                        <label class="form-check-label">
                                                                            <b class="main-color">Other High Hazard Projects</b>
                                                                        </label>
                                                                    </div>
                                                                    <ul>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="amusementparkrides" @change="endorseChanges();"
                                                                                   class="purp-check-input float-left"
                                                                                   type="checkbox"> Amusement Parks/Rides
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="aviationnaval" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Aviation &amp; Naval
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="offshoreprojects" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Offshore Projects
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="prosportsstadiums" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Professional Sports Stadiums
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="prototypedesigns" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Prototype Designs
                                                                        </li>
                                                                        <li class="text-left" style="min-height: 70px">
                                                                            <input v-model="quote.projects" value="standaloneparking" @change="endorseChanges();"
                                                                                   class="purp-check-input form-check-input float-left" type="checkbox"> Stand Alone Parking Structures
                                                                        </li>
                                                                    </ul>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- FIRM HISTORY -->
                                                <div class="rounded shadow-sm p-1  p-md-4  mx-sm-0 bg-white mt-5">
                                                    <h2 class="main-color mb-4" id="firm-history">Firm History</h2>
                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.currentInsurance"
                                                                         label="Do you currently carry Professional Liability Insurance?"
                                                                         @input="clearDependantsOf('currentInsurance')"/>
                                                    </div>

                                                    <div class="form-group mb-5" v-if="quote.currentInsurance === 1">
                                                        <label>
                                                            What is the earliest retroactive date on that policy?
                                                            <i tabindex="0"
                                                               v-b-tooltip.hover
                                                               title="The policy provides coverage for your professional services that were performed on or after this date."
                                                               class="far fa-question-circle main-color">
                                                            </i>
                                                        </label>
                                                        <p class="mb-1 main-color">Leave blank if you don't know</p>
                                                        <b-form-datepicker
                                                                no-flip
                                                                label-no-date-selected="What is the earliest retroactive date on that policy?"
                                                                v-model="quote.retroDate"
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                style="height: 44px; border-color: purple"
                                                                locale="en"
                                                                size="lg"
                                                                placeholder="MM/DD/YYYY"
                                                                class="col-md-6"
                                                        >
                                                        </b-form-datepicker>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.claimsYears"
                                                                         label="Has the business, any predecessor business and/or any owned subsidiary Insured under the policy had any insurance claims in the last 5 years?"
                                                                         @input="clearDependantsOf('claimsYears')"/>
                                                    </div>

                                                    <div class="form-row" v-if="quote.claimsYears">
                                                        <div class="form-group mb-6 col-md-6">
                                                            <label>Total number of reported matters</label>
                                                            <div><small>(do not include claims that have closed without payment)</small></div>
                                                            <input type="number" v-model="quote.numberReportedMatters" :class="isNotNull('numberReportedMatters') ? '' : 'is-blank'"
                                                                   class="form-control col-md-4">
                                                        </div>
                                                        <div class="form-group mb-5 col-md-5">
                                                            <label>Total sum of claims paid</label>
                                                            <input type="number" v-model="quote.sumClaimsPaid" :class="isNotNull('sumClaimsPaid') ? '' : 'is-blank'"
                                                                   class="form-control col-md-6 mt-3">
                                                        </div>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.insuranceCancelled" label="Has the business, any predecessor business and/or any owned subsidiary Insured under the policy ever had insurance non-renewed or cancelled
                                                            (other than fan for non-payment)?"/>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.circumstanceClaim" label="
                                                            Are you aware of any facts, circumstances or incidents which may give rise to claim under the proposed policy?"/>
                                                    </div>
                                                    <div class="form-group mb-5">
                                                        <BooleanQuestion v-model="quote.felonyBk5Yrs"
                                                                         label="Has the business, any predecessor business and/or any owned subsidiary Insured under the policy had any of the following?"
                                                                         :list="
                                                        [['a felony conviction','a bankruptcy','a professional license suspended or  reviewed in the last 5 years']]"/>
                                                    </div>

                                                    <div class="form-group mb-5">
                                                        <label class="text-left">
                                                            Additional Application Notes
                                                        </label>
                                                        <div class="row pb-2">
                                                            <div class="col-md-12">
                                                                <textarea class="form-control" v-model="quote.notes"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </tab-content>

                                    <!-- QUOTE PAGE 4 -->
                                    <tab-content v-if="hasDeclines()">
                                        <h5 class="mb-4"><i class="far fa-frown fa-lg"></i> Unfortunately, we are unable to offer you a quote at this time for the following reason<span
                                                v-if="quote.declines.length > 1">s</span></h5>
                                        <ul class="fa-ul" v-for="(declines, idx) in quote.declines" :key="idx">
                                            <li class="pb-2" v-if="declines.includes('Draftrs is not currently available in your state')">
                                                <span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> {{ declines }}
                                                <div class="mt-4 ml-3">
                                                    <input id="notify-me-when-in-my-state" @click="saveQuoteData('notifyMeWhenInMyState')" v-model="quote.notifyMeWhenInMyState"
                                                           class="purp-check-input form-check-input" type="checkbox">
                                                    <label for="notify-me-when-in-my-state" class="pl-3 pt-1">
                                                        <i class="far fa-hand-point-left fa-lg pr-1"></i> Check here if you want to be notified by email when we open in your state ({{ quote.state }}).
                                                    </label>
                                                </div>

                                            </li>
                                            <li class="pb-2" v-else>
                                                <span class="fa-li"><i class="main-color fas fa-circle fa-xs"></i></span> {{ declines }}<br>
                                            </li>
                                        </ul>
                                        <div class="mt-4 pt-3 pb-4 ml-4">
                                            If you find the stated decline<span v-if="quote.declines.length > 1">s</span> in error you can go back and make changes.
                                        </div>

                                        <div class="mt-4 ml-4">
                                            If you'd like a copy of your application you can download your application here <i class="far fa-hand-point-right fa-lg ml-1"></i>
                                            <button :disabled="downloadSpinner" class="ml-2 btn btn-primary text-center" style="width: 140px" @click="downloadAppDoc()">
                                                <i v-if="downloadSpinner" class="fas fa-spinner fa-pulse"></i> Download
                                            </button>
                                        </div>
                                    </tab-content>
                                    <tab-content v-else>

                                        <form ref="form4" id="form4" class="needs-validation">
                                            <fieldset :disabled="editingDisabled()">
                                                <div class="" :class="{nostickypremium: scrollPosition < 240, stickypremium: scrollPosition > 240}">

                                                    <div class="row text-center">
                                                        <div class="col-md-6">
                                                            <div class="text-block">
                                                                <div class="border border-dark bg-white rounded p-3">
                                                                    <p><span class="text-muted">Named Insured:</span> <b>{{ quote.insured }}</b></p>
                                                                    <p class="mb-0" v-if="issueAction==='endorse'">
                                                                        <span class="text-muted">Endorsement Effective Date: </span>
                                                                        <b> {{ formatDate(quote.endorseEffective) }}</b>
                                                                    </p>
                                                                    <p class="mb-0" v-else>
                                                                        <span class="text-muted">Effective Date:</span>
                                                                        <b>{{ formatDate(quote.effective) }}</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6 text-center pt-2">
                                                            <div class="text-muted">
                                                                <span>{{ issueAction === 'endorse' ? 'Endorsement Premium' : 'Total Premium' }}</span>
                                                                <a v-if="getRole() === 'ROLE_SUPER'" @click="getQuoteBreakdownUrl(quote.ratingId)"><i class="fas fa-search ml-2"></i></a>
                                                                <a v-if="getRole() === 'ROLE_SUPER'" :href="'/admin/rating/'+ quote.id" target="_blank"><i class="fas fa-search ml-2"></i></a>
                                                            </div>
                                                            <div class="text-block d-flex justify-content-center">
                                                                <div class="spinner-border mt-2 mr-2" style="height: 2.8em; width: 2.8em;" v-show="ratingSpinner"></div>

                                                                <h2 v-if="issueAction==='endorse' && ratingSpinner === false && prorate > 0" class="mb-0">
                                                                    <div id="total-premium" class=""> {{ prorate | currency }}</div>
                                                                </h2>
                                                                <h2 v-else-if="['issue', 'renew'].includes(issueAction)" class="mb-0" v-show="!ratingSpinner">
                                                                    <div id="total-premium" class=""> {{ quote.premium | currency }}</div>
                                                                </h2>
                                                                <h2 class="mb-0" v-else>
                                                                    <div id="total-premium" class=""> {{ 0 | currency }}</div>
                                                                </h2>

                                                            </div>
                                                            <div class="text-muted" style="font-size: .8em" v-html="displayTax()"></div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <!-- Limits, Term, Deductible, Coverage Enhancements -->
                                                <div class="row" v-if="isEffectiveDatePassed()">
                                                    <div class="col-12 alert alert-danger border border-danger mt-3 mb-0">
                                                        <i class="fas fa-exclamation-triangle"></i> The effective date {{ formatDate(quote.effective) }} is older than todays date. Please select a new
                                                        date below.
                                                    </div>
                                                </div>

                                                <div class="fs-policy-area mt-4" v-if="isEffectiveDatePassed()">
                                                    <div class="mx-auto col-6">
                                                        <label>When would you like your coverage to start?</label>
                                                        <b-form-datepicker
                                                                no-flip
                                                                today-button
                                                                reset-button
                                                                close-button
                                                                :min="hasEffectiveDateOverride() ? '' : minDate"
                                                                no-close-on-select
                                                                label-no-date-selected="When would you like coverage to start?"
                                                                v-model="quote.effective"
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                style="height: 44px;"
                                                                locale="en"
                                                                size="lg"
                                                                :disabled="issueAction==='endorse' || issueAction==='renew' || (hasEffectiveDateOverride() && !['ROLE_SUPER'].includes(getRole()))"
                                                                placeholder="MM/DD/YYYY"
                                                                :class="isNotNull('effective') ? 'is-valid' : 'is-blank'"
                                                                class=""

                                                        >
                                                        </b-form-datepicker>
                                                    </div>
                                                </div>

                                                <div class="row" :class="{'pt-1': scrollPosition < 240, 'pt-5 mt-5': scrollPosition > 240}">
                                                    <div class="col-md-12">

                                                        <div class="mb-1 mt-5 float-right" v-if="issueAction==='endorse'">Some options may be restricted for endorsements.</div>
                                                        <h5 class="main-color mt-5">QUOTE OPTIONS</h5>
                                                        <div class="fs-policy-area" v-if="issueAction==='endorse'">
                                                            <p>Choose Endorsement Effective Date</p>
                                                            <b-form-datepicker
                                                                    no-flip
                                                                    :min="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole()) ? '' : minDate"
                                                                    today-button
                                                                    reset-button
                                                                    close-button
                                                                    label-no-date-selected="Endorsement Date?"
                                                                    v-model="quote.endorseEffective"
                                                                    @input="setQuoteVal('endorseEffective')"
                                                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                    locale="en"
                                                                    size="lg"
                                                                    placeholder="MM/DD/YYYY"
                                                                    :class="isNotNull('endorseEffective') ? 'is-valid' : 'is-blank'"
                                                                    style="width: 250px"
                                                                    class="mx-auto font-weight-bold"
                                                            >
                                                            </b-form-datepicker>
                                                        </div>

                                                        <div class="fs-policy-area pb-5">
                                                            <p>
                                                                Limits <span class="mr-2" v-if="issueAction==='endorse' && boundQuote">(Current: {{ displayLimit(boundQuote.limit, 'full') }})</span>
                                                                <span>
                                                                    <i tabindex="0"
                                                                       v-b-tooltip.hover
                                                                       title="This is the maximum amount of coverage you will have for each claim and in total under the policy.  Note that defense costs are included under this limit and are not in addition to the limit."
                                                                       class="far fa-question-circle main-color">
                                                                    </i>
                                                                </span>
                                                            </p>
                                                            <ul>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('limit', 500000); endorseChanges() }}"
                                                                       v-bind:class="isActive('limit', 500000)"
                                                                       href="#">$500K/$500K</a></li>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('limit', 1000000); endorseChanges() }}"
                                                                       v-bind:class="isActive('limit', 1000000)" href="#">$1M/$1M</a></li>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('limit', 2000000); endorseChanges() }}"
                                                                       v-bind:class="isActive('limit', 2000000)" href="#">$2M/$2M</a></li>
                                                            </ul>
                                                            <ul class="mt-3">
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('limit', 51); endorseChanges() }}" v-bind:class="isActive('limit', 51)"
                                                                       href="#">$500K/$1M</a></li>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('limit', 12); endorseChanges() }}" v-bind:class="isActive('limit', 12)"
                                                                       href="#">$1M/$2M</a></li>
                                                            </ul>
                                                            <div v-if="quote.state === 'VT'" class="text-muted" style="font-size: .8em">
                                                                Defense costs are not included under these limits. An additional $500K separate sublimit for Defense Costs will be automatically be
                                                                included by endorsement
                                                            </div>
                                                        </div>
                                                        <div class="fs-policy-area pb-5">
                                                            <p>
                                                                Policy Term <span class="mr-2" v-if="issueAction==='endorse'">(Current: {{
                                                                    boundQuote.policyTerm === 6 ? '6 Months' : '12 Months'
                                                                }})</span>
                                                            </p>
                                                            <ul>
                                                                <li v-if="!agencyBill && !brokerConfigs.no6MonthPolicyTerms"><a ref="policyTermDefault"
                                                                                                                                @click.prevent="() => { if (!ratingSpinner) {setQuoteVal('policyTerm', 6); endorseChanges() }}"
                                                                                                                                v-bind:class="isActive('policyTerm', 6)" href="#">6 Months</a></li>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('policyTerm', 12); endorseChanges() }}"
                                                                       v-bind:class="isActive('policyTerm', 12)" href="#">12 Months</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="fs-policy-area pb-5">
                                                            <p>
                                                                Deductible
                                                                <span class="mr-2" v-if="issueAction==='endorse'">(Current: {{
                                                                        boundQuote.deductible === 1000 ? '$1,000' : (boundQuote.deductible === 2500 ? '$2,500' : '$5,000')
                                                                    }})</span>
                                                                <span>
                                                                    <i tabindex="0"
                                                                       v-b-tooltip.hover
                                                                       title="This is the amount you will be responsible for paying under a claim before the limits under the policy would apply."
                                                                       class="far fa-question-circle main-color">
                                                                    </i>
                                                                </span>
                                                            </p>
                                                            <ul>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('deductible', 1000); endorseChanges() }}"
                                                                       v-bind:class="isActive('deductible', 1000)" href="#">$1,000</a></li>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('deductible', 2500); endorseChanges() }}"
                                                                       v-bind:class="isActive('deductible', 2500)" href="#">$2,500</a></li>
                                                                <li><a @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('deductible', 5000); endorseChanges() }}"
                                                                       v-bind:class="isActive('deductible', 5000)" href="#">$5,000</a></li>
                                                            </ul>
                                                        </div>
                                                        <h5 class="main-color mt-5">COVERAGE ENHANCEMENTS</h5>

                                                        <!-- LIMIT CHANGES DURING RENEWAL/ENDORSEMENT-->
                                                        <div class="fs-block-boxes" v-if="hasObject(quote, 'oldLimit')">
                                                            <div class="btn-area">
                                                                <a href="#" class="active">ADDED</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-book mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Change Limit of Liaibility Endorsement <br/>from {{ displayLimit(quote.oldLimit, 'full') }} to {{
                                                                        displayLimit(quote.limit, 'full')
                                                                    }}
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Coverage enhancement for limit change"
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                            </div>
                                                        </div>

                                                        <!-- PRIOR LIMIT CHANGES -->
                                                        <div class="fs-block-boxes" v-if="hasObject(quote, 'limitChanges')">
                                                            <div class="btn-area">

                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-book mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Prior Change Limit of Liaibility Endorsement
                                                                </h2>
                                                                <ul>
                                                                    <li v-for="change in quote.limitChanges" :key="change.effDt">
                                                                        from {{ displayLimit(change.oldLimit, 'full') }} to {{ displayLimit(change.newLimit, 'full') }} Date {{
                                                                            formatDate(change.effDt)
                                                                        }}
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>

                                                        <!-- Additional Named Insureds -->
                                                        <div class="fs-block-boxes">
                                                            <div class="btn-area">
                                                                <a href="#" @click.prevent="() => {if (!ratingSpinner) setQuoteVal('addlNamedInsureds', 0, 1)}"
                                                                   v-bind:class="Array.isArray(quote['addlNamedInsureds']) && quote['addlNamedInsureds'].length ? 'active' : ''">ADD +</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-men_larger mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Additional Named Insureds Liability
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Fully owned subsidiaries and predecessor companies can be added to the policy.  They become Insureds under the policy and will be covered for their professional work."
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                                <p v-if="Array.isArray(quote['addlNamedInsureds']) && !quote['addlNamedInsureds'].length"><strong>Not Included</strong></p>
                                                                <ul class="ml-4 fa-ul" v-for="(ai, idx) in quote.addlNamedInsureds" :key="idx">
                                                                    <li class="pb-2"><span class="fa-li"><i class="main-color fas fa-check"></i></span> <b>{{ ai.entityName }}</b>, {{ ai.entityType }},
                                                                        {{ ai.entityRevenues | currency }} <a href="#" class="ml-2 main-color"
                                                                                                              @click.prevent="() => {if (!ratingSpinner) setQuoteVal('addlNamedInsureds', 25, 1)}"><i
                                                                                class="far fa-edit mr-1"></i>Edit</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <!-- Additional Insureds Vicarious -->
                                                        <div class="fs-block-boxes">
                                                            <div class="btn-area">
                                                                <a href="#" @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('addlVicarious', 1, 1); endorseChanges() }}"
                                                                   v-bind:class="isActive('addlVicarious', 1)">ADD +</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-book mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Additional Insureds Vicarious Liability
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Clients and project owners can be added to the policy with this endorsement but will only be covered for their vicarious liability arising out of professional services of an Insured on the policy.  These additional insureds have no coverage under the policy for their own acts or professional services."
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                                <p v-if="isActive('addlVicarious', 1) && rates.aiv"><strong>{{ rates.aiv | currency }}</strong> / Term</p>
                                                                <p v-if="!isActive('addlVicarious', 1)"><strong>Not Included</strong></p>
                                                                <p v-else><strong>Included</strong></p>
                                                            </div>
                                                        </div>

                                                        <!-- First Dollar Defense -->
                                                        <div class="fs-block-boxes">
                                                            <div class="btn-area">
                                                                <a href="#" @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('addl1stDefense', 1, 1); endorseChanges() }}"
                                                                   v-bind:class="isActive('addl1stDefense', 1)">ADD +</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-dollar mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    First Dollar Defense
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Coverage enhancement under which the deductible would not apply to defense costs.  The deductible would only apply if there was a payment to a third party for damages."
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                                <p v-if="isActive('addl1stDefense', 1) && rates.firstDollar"><strong>{{ rates.firstDollar | currency }}</strong> / Term</p>
                                                                <p v-if="!isActive('addl1stDefense', 1)"><strong>Not Included</strong></p>
                                                                <p v-else><strong>Included</strong></p>
                                                            </div>
                                                        </div>

                                                        <!-- Personal Injury Enhancement -->
                                                        <div class="fs-block-boxes">
                                                            <div class="btn-area">
                                                                <a href="#" @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('addlPersonalInjury', 1, 1); endorseChanges() }}"
                                                                   v-bind:class="isActive('addlPersonalInjury', 1)">ADD +</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-book mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Personal Injury Enhancement
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Adds coverage for invasion of privacy, libel, slander, false arrest, malicious prosecution etc, that arises out of the Insureds professional services.  "
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                                <p v-if="isActive('addlPersonalInjury', 1) && rates.pi"><strong>{{ rates.pi | currency }}</strong> / Term</p>
                                                                <p v-if="!isActive('addlPersonalInjury', 1)"><strong>Not Included</strong></p>
                                                                <p v-else><strong>Included</strong></p>
                                                            </div>

                                                        </div>

                                                        <!-- Rectification -->
                                                        <div>
                                                            <div class="fs-block-boxes">
                                                                <div class="btn-area">
                                                                    <a href="#"
                                                                       @click.prevent="() => {if (!ratingSpinner) { clearDependantsOf('addlRectification'); setQuoteVal('addlRectification', 1, 1, 1); endorseChanges() }}"
                                                                       v-bind:class="isActive('addlRectification', 1)">ADD +</a>
                                                                </div>
                                                                <div class="img-area">
                                                                    <div class="sprite bg-book mx-auto"></div>
                                                                </div>
                                                                <div class="text-area">
                                                                    <h2>
                                                                        Rectification
                                                                        <span>
                                                                            <i tabindex="0"
                                                                               v-b-tooltip.hover
                                                                               title="Coverage enhancement under which the Insured can seek costs directly from the Insurer to rectify a design defect that would otherwise lead to a policy claim.
                                                                                Offered at $50K or $100K sublimits only."
                                                                               class="far fa-question-circle main-color">
                                                                            </i>
                                                                        </span>
                                                                    </h2>
                                                                    <p v-if="isActive('addlRectification', 1) && rates.rect"><strong>{{ rates.rect | currency }}</strong> / Term</p>
                                                                    <p v-if="!isActive('addlRectification', 1)"><strong>Not Included</strong></p>
                                                                    <p v-else><strong>Included</strong></p>
                                                                </div>
                                                                <div class="questions pt-3" v-if="quote.addlRectification">
                                                                    <div class="form-group mb-0 pl-0 col-md-6">
                                                                        <label class="mb-0">Please Select the sublimit you require</label>
                                                                        <select class="form-control text-center p-1" :disabled="lockEdit && boundQuote.rectificationSublimit"
                                                                                @change.prevent="setQuoteVal('rectificationSublimit'); endorseChanges()"
                                                                                :class="quote.rectificationSublimit ? 'is-valid' : 'is-blank'" v-model="quote.rectificationSublimit" required>
                                                                            <option value="">Select a Sublimit</option>
                                                                            <option value="50000">$50,000</option>
                                                                            <option value="100000">$100,000</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Notice of Cancellation -->
                                                        <div class="fs-block-boxes">
                                                            <div class="btn-area">
                                                                <a href="#"
                                                                   @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('addlNoticeOfCancel3rdParties', 1, 1); clearDependantsOf('addlNoticeOfCancel3rdParties'); endorseChanges() }}"
                                                                   v-bind:class="isActive('addlNoticeOfCancel3rdParties', 1)">ADD +</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-book mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Notice of Cancellation to Third Parties
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Add if and when required by a third party client, project owner and/or public entity where the carrier and/or policy issuer agrees to provide advanced notice to a scheduled third party in the event of policy cancellation."
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                                <p v-if="!isActive('addlNoticeOfCancel3rdParties', 1)"><strong>Not Included</strong></p>
                                                                <p v-else><strong>Included</strong></p>
                                                            </div>

                                                            <div class="questions" v-if="quote.addlNoticeOfCancel3rdParties">
                                                                <div class="form-group mb-5 mt-4">
                                                                    <label>Add any parties to the notification list 8 Max</label>
                                                                    <ol class="fa-ul" v-for="(an, idx) in quote.noticeOfCancelList" :key="idx">
                                                                        <li class="form-inline py-1 mb-4">
                                                                            <span class="fa-li align-top"><i class="fas fa-circle fa-xs main-color"></i></span>
                                                                            <input placeholder="Name" class="form-control mt-2 mr-2" @change="endorseChanges()"
                                                                                   :class="an.name ? 'is-valid' : 'is-blank'" v-model="an.name" type="text"><br/>

                                                                            <input placeholder="Address 1" class="form-control mt-2 mr-2" @change="endorseChanges()"
                                                                                   :class="an.address1 ? 'is-valid' : 'is-blank'" v-model="an.address1" type="text">
                                                                            <input placeholder="Address 2" class="form-control mt-2 mr-2" @change="endorseChanges()" v-model="an.address2"
                                                                                   type="text"><br/>

                                                                            <input placeholder="City" class="form-control mt-2 mr-2" @change="endorseChanges()"
                                                                                   :class="an.city ? 'is-valid' : 'is-blank'" v-model="an.city" type="text">
                                                                            <!-- <input placeholder="State" class="form-control mt-2 mr-2"  v-model="an.state" type="text"> -->
                                                                            <select style="max-width: 190px" @change="endorseChanges()" :class="an.state ? 'is-valid' : 'is-blank'"
                                                                                    class="custom-select mt-2 mr-2" required v-model="an.state">
                                                                                <option value="">Select a State</option>
                                                                                <option v-for="(state, abbrev) in formStates" :key="abbrev" :value="state.abbrev">{{ state.name }}</option>
                                                                            </select>
                                                                            <input placeholder="Zip" style="width: 180px" class="form-control mt-2 mr-2" @change="endorseChanges()"
                                                                                   :class="an.zip ? 'is-valid' : 'is-blank'" v-model="an.zip" type="number">
                                                                            <button class="btn btn-light border border-danger mt-2" title="Delete this notification recipient"
                                                                                    @click.prevent="removeFromNoticeOfCancelList(idx); endorseChanges()"><i class="fas text-danger fa-trash-alt"></i>
                                                                            </button>
                                                                        </li>
                                                                    </ol>
                                                                    <div>
                                                                        <a class="block my-2 btn btn-light border border-success" @click="addToNoticeOfCancelList()"><i
                                                                                class="fas text-success fa-plus-circle"></i> Add Notification Recipient</a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <!-- Extended Reporting Period -->
                                                        <div class="fs-block-boxes" v-if="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole()) && issueAction !== 'endorse'">
                                                            <div class="btn-area">
                                                                <a href="#"
                                                                   @click.prevent="clearDependantsOf('addlExtendedReportingPeriod'); setQuoteVal('addlExtendedReportingPeriod', 1, 1, 1); endorseChanges()"
                                                                   v-bind:class="isActive('addlExtendedReportingPeriod', 1)">ADD +</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-book mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Extended Reporting Period
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Option for the Insured to purchase a one, two or three year extended reporting period to report claims on the policy when a policy is cancelled.
                                                                            Typically purchased when a firm is acquired and/or is no longer in business.
                                                                            (Just making a note for us so we do not forget, this endorsement can only be taken selected up to 30 days after or in conjunction with a policy cancellation."
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                                <p v-if="isActive('addlExtendedReportingPeriod', 1) && rates.extendedReporting"><strong>{{
                                                                        rates.extendedReporting | currency
                                                                    }}</strong> / Term</p>
                                                                <p v-if="!isActive('addlExtendedReportingPeriod', 1)"><strong>Not Included</strong></p>
                                                                <p v-else><strong>Included</strong></p>
                                                            </div>

                                                            <div class="questions pt-3" v-if="quote.addlExtendedReportingPeriod">

                                                                <div class="form-group mb-0 pl-0 col-md-6">
                                                                    <label class="mb-0">How many months extended reporting do you require?</label>
                                                                    <select class="form-control p-1 text-center" :disabled="lockEdit && boundQuote.extendedReportingPeriodMonths"
                                                                            @change.prevent="setQuoteVal('extendedReportingPeriodMonths'); endorseChanges()"
                                                                            :class="quote.extendedReportingPeriodMonths ? 'is-valid' : 'is-blank'" v-model="quote.extendedReportingPeriodMonths"
                                                                            required>
                                                                        <option value="">Select a Timeframe</option>
                                                                        <option value="12">12 Months</option>
                                                                        <option value="24">24 Months</option>
                                                                        <option value="36">36 Months</option>
                                                                    </select>
                                                                </div>

                                                                <div class="form-group mt-4">
                                                                    <label>Coverage will start: {{ getExpDt() }}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Amend Professional Services -->
                                                        <div class="fs-block-boxes" v-if="['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole())">
                                                            <div class="btn-area">
                                                                <a href="#" @click.prevent="() => {if (!ratingSpinner) { setQuoteVal('amendServices', 1, 1); endorseChanges() }}"
                                                                   v-bind:class="isActive('amendServices', 1)">ADD +</a>
                                                            </div>
                                                            <div class="img-area">
                                                                <div class="sprite bg-book mx-auto"></div>
                                                            </div>
                                                            <div class="text-area">
                                                                <h2>
                                                                    Amend Professional Services
                                                                    <span>
                                                                        <i tabindex="0"
                                                                           v-b-tooltip.hover
                                                                           title="Amend Services."
                                                                           class="far fa-question-circle main-color">
                                                                        </i>
                                                                    </span>
                                                                </h2>
                                                                <p v-if="isActive('amendServices', 1)"><strong>Included</strong></p>
                                                                <p v-else><strong>Not Included</strong></p>
                                                            </div>

                                                            <div class="questions py-3" v-if="quote.amendServices">
                                                                <div class="form-group mb-0 pl-0 col-md-10">
                                                                    <label class="mb-0">List the additional professional service below</label>
                                                                    <input type="text" class="form-control" :class="isNotNull('amendServicesList') ? 'is-valid' : 'is-blank'"
                                                                           v-model="quote.amendServicesList"
                                                                           placeholder="" required>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="pt-4" v-if="!prorate && !['ROLE_USER', 'ROLE_CONSUMER'].includes(getRole()) && issueAction === 'endorse'">
                                                            <h5>Override $0 premium endorsement</h5>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" id="email-quote-radio" v-model="endorseOverride" value="true">
                                                                <label class="form-check-label" for="email-quote-radio">
                                                                    Check the box to proceed to payment page.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <!-- ADDITIONAL INSUREDS MODAL -->
                                            <b-modal
                                                    v-model="showAddlModal"
                                                    size="lg"
                                                    ref="addl-insured-modal"
                                                    title="Additional Named Insureds"
                                                    hide-header
                                                    hide-footer
                                                    no-close-on-backdrop
                                            >
                                                <h6 class="mb-0 align-bottom"><i class="main-color fas fa-user-shield mr-1"></i> Additional Named Insureds Liability</h6>
                                                <p class="my-2">
                                                    Complete for any fully owned subsidiary or predecessor entity that needs to be added for coverage as an Insured under the policy.
                                                </p>
                                                <div class="font-weight-lighter mb-2" style="font-size: .75em">We are unable to include joint venture companies, parent/holding companies and/or
                                                    minority interest affiliates/subsidiaries under the policy at this time.
                                                </div>

                                                <!-- REPETITIONS -->
                                                <div id="addlInsForm" class="py-4">
                                                    <!-- <b-button variant="light" size="md" class="float-right m-2 border-secondary" @click="showAddlModal=false; saveQuoteData('saveAddlInsuredModal')" >Cancel</b-button> -->
                                                    <button size="md" class="float-right btn btn-primary m-2" @click="saveQuoteData('saveAddlInsuredModal'); endorseChanges()"><span v-if="saving"
                                                                                                                                                                                     class="spinner-border spinner-border-sm mr-1 align-middle"></span>
                                                        Save &amp; Close
                                                    </button>

                                                    <button class="m-2 btn btn-light border border-success" @click="newAddlInsured()"><i class="fas text-success fa-plus-circle"></i> Add Insured
                                                    </button>

                                                    <div v-if="addlInsuredError" class="mt-2 p-2 alert-danger border border-danger">Please complete all fields or remove any incomplete additional
                                                        insureds.
                                                    </div>
                                                    <div v-for="(addlInsured, idx) in quote.addlNamedInsureds" :key="idx">
                                                        <fieldset :disabled="lockEdit">
                                                            <div class=" mt-2 p-3 rounded bg-light border border-secondary">

                                                                <button v-if="!isAddlInsuredLocked(idx) && addlInsured.unlocked" class="btn btn-light border border-danger float-right m-2"
                                                                        v-b-tooltip.hover
                                                                        title="Delete this Additional Insured" @click="removeAddlInsured(idx)">Remove <i class="fas text-danger fa-trash-alt"></i>
                                                                </button>


                                                                <div class="form-group mb-4">
                                                                    <label class="mb-0">Name of Additional Insured?</label>
                                                                    <input class="form-control p-1 col-md-6" :disabled="!addlInsured.unlocked" :class="addlInsured.entityName ? 'is-valid' : 'is-blank'"
                                                                           v-model="addlInsured.entityName" type="text">
                                                                    <input type="hidden" v-model="addlInsured.unlocked">
                                                                </div>

                                                                <div class="form-group mb-4">
                                                                    <label class="mb-0">Select the type of entity</label>
                                                                    <div class="choice-area mt-0">
                                                                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                                                            <label class="btn px-3"
                                                                                   :class="quote.addlNamedInsureds[idx].entityType === 'owned subsidiary' ? 'active' : (quote.addlNamedInsureds[idx].entityType ? '' : 'is-blank')">
                                                                                <input type="radio" :disabled="!addlInsured.unlocked" v-model="quote.addlNamedInsureds[idx].entityType"
                                                                                       name="options" value="owned subsidiary">
                                                                                owned subsidiary
                                                                            </label>
                                                                            <label class="btn px-3"
                                                                                   :class="quote.addlNamedInsureds[idx].entityType === 'predecessor firm' ? 'active' : (quote.addlNamedInsureds[idx].entityType ? '' : 'is-blank')">
                                                                                <input type="radio" :disabled="!addlInsured.unlocked" v-model="quote.addlNamedInsureds[idx].entityType" name="options"
                                                                                       value="predecessor firm">
                                                                                predecessor firm
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="form-group mb-4">
                                                                    <BooleanQuestion v-model="quote.addlNamedInsureds[idx].sameServiceAsEntity"
                                                                                     :label="'Does the Additional Insured provide the same services as' + quote.insured + '?'"
                                                                                     :options-disabed="!addlInsured.unlocked"
                                                                                     @input="clearDependantsOf('addlNamedInsureds', {index: idx, name: 'servicesProvided'})"/>
                                                                </div>

                                                                <div class="form-group mb-4" v-if="!quote.addlNamedInsureds[idx].sameServiceAsEntity">
                                                                    <label class="mb-0">Please select the additional service provided</label>
                                                                    <select class="form-control p-1 col-md-6" :disabled="!addlInsured.unlocked" v-model="addlInsured.servicesProvided"
                                                                            :class="addlInsured.servicesProvided ? 'is-valid' : 'is-blank'" required>
                                                                        <option value="">Select an Industry</option>
                                                                        <option v-for="workType in workTypes" :key="workType.key" :value="workType.value">{{ workType.key }}</option>
                                                                    </select>
                                                                </div>

                                                                <div class="form-group mb-3">
                                                                    <BooleanQuestion v-model="quote.addlNamedInsureds[idx].revenueInclInTotal"
                                                                                     label="Are the revenues already included in total firm revenues?"
                                                                                     :options-disabed="!addlInsured.unlocked"
                                                                                     @input="clearDependantsOf('addlNamedInsureds', {index: idx, name: 'entityRevenues'})"/>
                                                                </div>

                                                                <div class="form-group mb-3" v-if="!addlInsured.revenueInclInTotal">
                                                                    <label class="mb-0">Revenues attributed to the additional insured?</label>
                                                                    <input class="form-control p-1 col-md-4 "
                                                                           :class="!addlInsured.entityRevenues || getTotalRevenue() > 1000000 ? 'is-blank' : 'is-valid'"
                                                                           :disabled="!addlInsured.unlocked" v-model="addlInsured.entityRevenues" type="number">
                                                                    <div v-if="getTotalRevenue() > 1000000" class="alert alert-danger">
                                                                        The maximum total revenue allowed is $1M. With your additional insured revenue you are exceeding our allowed amount.
                                                                        You entered a primary revenue of {{ quote.revenue | currency }}.
                                                                        Your combined Primary + Sum of all additional insureds revenues must be less than $1M total.
                                                                    </div>
                                                                </div>

                                                                <div class="form-group mb-3" v-if="issueAction==='endorse' && !isAddlInsuredLocked(idx)">
                                                                    <BooleanQuestion v-model="quote.addlNamedInsureds[idx].insuranceClaims"
                                                                                     label="Has the Additional Insured had any insurance claims in the last 5 years?"
                                                                                     :options-disabed="!addlInsured.unlocked"
                                                                                     @input="clearDependantsOf('addlNamedInsureds', {index: idx, name: 'entityRevenues'})"/>
                                                                </div>

                                                                <div class="alert alert-danger border border-danger" v-if="addlInsured.insuranceClaims"><i class="fas fa-exclamation-triangle"></i> We
                                                                    cannot add {{ addlInsured.entityName }} to your policy due to their claims history. Please remove this entry.
                                                                </div>
                                                                <button v-if="!isAddlInsuredLocked(idx) && addlInsured.insuranceClaims" class="btn btn-light border border-danger m-2"
                                                                        title="Delete this Additional Insured" @click="removeAddlInsured(idx)"><i class="fas text-danger fa-trash-alt"></i> Remove
                                                                    {{ addlInsured.entityName }}
                                                                </button>


                                                            </div>
                                                            <b-button v-if="idx > 0 && quote.addlNamedInsureds.length === (idx+1)" size="md" class="float-right btn btn-primary mx-2 my-3"
                                                                      @click="saveQuoteData('saveAddlInsuredModal')"><span v-if="saving"
                                                                                                                           class="spinner-border spinner-border-sm mr-1 align-middle"></span> Save &amp;
                                                                Close
                                                            </b-button>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </b-modal>
                                        </form>
                                    </tab-content>

                                    <!-- QUOTE PAGE 5 -->
                                    <tab-content>
                                        <form ref="form5" id="form5">
                                            <div v-show="purchased === false">
                                                <QuoteSummary :quote="quote" :prorate="prorate" :issueAction="issueAction" v-if="purchased === false"/>

                                                <div class="row">
                                                    <div class="col-12 alert alert-danger border border-danger collapse" id="pay-error">
                                                        <i class="fas fa-exclamation-triangle"></i> Please complete the fields or questions that are highlighted in red.
                                                    </div>
                                                </div>
                                                <div class="text-center mb-4" v-if="issueAction === 'issue'">
                                                    <button class="btn btn-primary" @click.prevent="previewPolicy()" v-b-tooltip.hover title="Preview and Download Draft Policy PDF">
                                                        <i v-if="policyPreviewSpinner" class="fas fa-spinner fa-pulse"></i> Preview and Download Draft Policy
                                                    </button>
                                                </div>

                                                <!-- Insured Address Info -->
                                                <div class="row" v-if="!lockEdit">
                                                    <div class="col-md-12 mb-3">
                                                        <div class="info-form border border-dark bg-white rounded p-3">
                                                            <h4 class="main-color text-center">Insured Info</h4>
                                                            <div class="ml-2 pb-2 text-center text-80"> Please fill out all the details below.</div>
                                                            <form>
                                                                <fieldset :disabled="issueAction === 'endorse'">
                                                                    <div class="form-row ">
                                                                        <div class="col-md-8 pl-3">
                                                                            <h3>{{ quote.insured }}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-row mb-2">
                                                                        <div class="col-md-6">
                                                                            <input type="text" class="form-control" :class="isNotNull('phone') ? 'is-valid' : 'is-blank'" v-model="quote.phone"
                                                                                   placeholder="Phone Number" required>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <input type="email" class="form-control" :class="isNotNull('email') ? 'is-valid' : 'is-blank'" v-model="quote.email"
                                                                                   placeholder="Applicant Email" required>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-row mb-2">
                                                                        <div class="col-md-6">
                                                                            <input type="text" class="form-control" :class="isNotNull('address') ? 'is-valid' : 'is-blank'" v-model="quote.address"
                                                                                   placeholder="Business Address" required>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <input type="text" class="form-control" :class="isNotNull('zip') && !validations.zip ? 'is-valid' : 'is-blank'"
                                                                                   v-model="quote.zip"
                                                                                   @change="validateZip" placeholder="Zip Code" required>
                                                                            <div class="invalid-feedback" :style="validations.zip ? 'display: block' : 'display: none'">
                                                                                {{ validations.zip }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-row">
                                                                        <div class="col-md-6 mb-2">
                                                                            <input type="text" readonly class="form-control" :class="isNotNull('city') ? 'is-valid' : 'is-blank'" v-model="quote.city"
                                                                                   placeholder="City" required>
                                                                        </div>
                                                                        <div class="col-md-6 mb-2">
                                                                            <select class="custom-select" :class="quote.state ? 'is-valid' : 'is-blank'"
                                                                                    @change="setQuoteVal(quote.state, 'state')" required v-model="quote.state">
                                                                                <option value="">Select a State</option>
                                                                                <option value="AL">Alabama</option>
                                                                                <option value="AK">Alaska</option>
                                                                                <option value="AS">American Samoa</option>
                                                                                <option value="AZ">Arizona</option>
                                                                                <option value="AR">Arkansas</option>
                                                                                <option value="CA">California</option>
                                                                                <option value="CO">Colorado</option>
                                                                                <option value="CT">Connecticut</option>
                                                                                <option value="DE">Delaware</option>
                                                                                <option value="DC">District Of Columbia</option>
                                                                                <option value="FM">Federated States Of Micronesia</option>
                                                                                <option value="FL">Florida</option>
                                                                                <option value="GA">Georgia</option>
                                                                                <option value="GU">Guam</option>
                                                                                <option value="HI">Hawaii</option>
                                                                                <option value="ID">Idaho</option>
                                                                                <option value="IL">Illinois</option>
                                                                                <option value="IN">Indiana</option>
                                                                                <option value="IA">Iowa</option>
                                                                                <option value="KS">Kansas</option>
                                                                                <option value="KY">Kentucky</option>
                                                                                <option value="LA">Louisiana</option>
                                                                                <option value="ME">Maine</option>
                                                                                <option value="MH">Marshall Islands</option>
                                                                                <option value="MD">Maryland</option>
                                                                                <option value="MA">Massachusetts</option>
                                                                                <option value="MI">Michigan</option>
                                                                                <option value="MN">Minnesota</option>
                                                                                <option value="MS">Mississippi</option>
                                                                                <option value="MO">Missouri</option>
                                                                                <option value="MT">Montana</option>
                                                                                <option value="NE">Nebraska</option>
                                                                                <option value="NV">Nevada</option>
                                                                                <option value="NH">New Hampshire</option>
                                                                                <option value="NJ">New Jersey</option>
                                                                                <option value="NM">New Mexico</option>
                                                                                <option value="NY">New York</option>
                                                                                <option value="NC">North Carolina</option>
                                                                                <option value="MP">Northern Mariana Islands</option>
                                                                                <option value="ND">North Dakota</option>
                                                                                <option value="OH">Ohio</option>
                                                                                <option value="OK">Oklahoma</option>
                                                                                <option value="OR">Oregon</option>
                                                                                <option value="PW">Palau</option>
                                                                                <option value="PA">Pennsylvania</option>
                                                                                <option value="PR">Puerto Rico</option>
                                                                                <option value="RI">Rhode Island</option>
                                                                                <option value="SC">South Carolina</option>
                                                                                <option value="SD">South Dakota</option>
                                                                                <option value="TN">Tennessee</option>
                                                                                <option value="TX">Texas</option>
                                                                                <option value="UT">Utah</option>
                                                                                <option value="VT">Vermont</option>
                                                                                <option value="VI">Virgin Islands</option>
                                                                                <option value="VA">Virginia</option>
                                                                                <option value="WA">Washington</option>
                                                                                <option value="WV">West Virginia</option>
                                                                                <option value="WI">Wisconsin</option>
                                                                                <option value="WY">Wyoming</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                                <button v-if="issueAction === 'issue'" class="btn btn-sm btn-primary rounded-lg" @click.prevent="saveQuoteData('Insured Info');">Save
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Agent Info for Agency Bill -->
                                                <div class="row" v-if="!lockEdit && (agencyBill || hasRetailBroker)">
                                                    <div class="col-md-12 mt-3 mb-4">
                                                        <div class="info-form border border-dark bg-white rounded p-3">
                                                            <h4 class="main-color text-center">Retail Agent Info</h4>
                                                            <div class="ml-2 pb-2 text-center text-80"> Please fill out all the details below.</div>
                                                            <form>
                                                                <fieldset :disabled="issueAction === 'endorse'">
                                                                    <div class="form-row mb-2">
                                                                        <div class="col-md-6">
                                                                            <input type="text" class="form-control" :class="isNotNull('agentName') ? 'is-valid' : 'is-blank'" v-model="quote.agentName"
                                                                                   placeholder="Agent Name" required>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <input type="email" class="form-control" :class="isNotNull('agentEmail') ? 'is-valid' : 'is-blank'"
                                                                                   v-model="quote.agentEmail"
                                                                                   placeholder="Agent Email" required>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-row mb-2">
                                                                        <div class="col-md-6">
                                                                            <input type="text" class="form-control" :class="isNotNull('agentCompany') ? 'is-valid' : 'is-blank'"
                                                                                   v-model="quote.agentCompany"
                                                                                   placeholder="Agent Company" required>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <input type="email" class="form-control" v-model="quote.agentPhone" placeholder="Agent Phone">
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                                <button v-if="issueAction === 'issue'" class=" btn btn-sm btn-primary rounded-lg" @click.prevent="saveQuoteData('Broker Info');">Save
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Endorsement Coverage Changes -->
                                                <div v-if="issueAction==='endorse' && Array.isArray(quote.changes) && quote.changes.length > 0">
                                                    <div class="fs-block-boxes">
                                                        <h5 class="main-color">COVERAGE CHANGE DISCLAIMER</h5>
                                                        <div class="form-group mb-3">
                                                            <label class="mb-3">You are adding or modifying the following endorsements on your policy:</label>
                                                            <div class="d-flex flex-wrap justify-content-left mb-3">
                                                                <div class="py-1 pl-4 w-100" v-for="(change, idx) in quote.changes" :key="idx">
                                                                    <i class="main-color fas fa-circle fa-xs"></i>
                                                                    {{ change }}
                                                                </div>

                                                            </div>
                                                            <div class="mt-4 ml-3 d-flex">
                                                                <input id="consent-endorsement-changes" style="width: 100px" @click="consent('endorse')" value="true"
                                                                       v-model="quote.consentEndorsementChanges" class="pl-2 purp-check-input form-check-input"
                                                                       :class="isNotNull('consentEndorsementChanges') ? '' : 'is-blank'" type="checkbox" required>
                                                                <label for="consent-endorsement-changes" class=" pl-3 pt-1 form-check-label"
                                                                       :class="isNotNull('consentEndorsementChanges') ? '' : 'is-invalid'">
                                                                    <i class="far fa-hand-point-left fa-lg pr-1"></i> By checking this box you warrant that you are unaware of any facts, circumstances
                                                                    or incidents that could reasonably give rise to a claim under the policy. (Policyholders Warranty of Claims or Circumstances)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- agents fee override and email to insured -->
                                                <div class="row" v-if="!lockEdit">

                                                    <!-- Agents Fee Override -->
                                                    <div class="col-md-6 mb-3" v-if="!agencyBill && ['ROLE_SUPER', 'ROLE_ADMIN'].includes(getRole()) && hasBrokerOverride()">
                                                        <div class="border border-dark bg-white  rounded p-3">
                                                            <h4 class="main-color text-center pb-1">Agent Fee Override</h4>
                                                            <div class="w-100 text-center text-80 pt-2 text-muted">
                                                                Leave blank if using default agent fee. <br/>
                                                                Current default agent fee: ${{ quote.rating.breakdowns.defaultBrokerFee }}
                                                            </div>
                                                            <div class="input-group mb-3 justify-content-center">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text">$</div>
                                                                    <input type="number" v-model="quote.manualBrokerFee" class="form-control">

                                                                </div>
                                                                <button id="save-btn" class="ml-2 btn btn-primary text-center rounded-lg" type="button" @click="saveQuoteData('saveBrokerOverride')">
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Email Quote/Payment To Insured -->
                                                    <div class=" mb-3" :class="!agencyBill && hasBrokerOverride() ? 'col-md-6' : 'col-md-12'" v-if="'ROLE_CONSUMER' !== getRole()">
                                                        <div class="border border-dark bg-white rounded p-3">
                                                            <div class="info-form text-center">
                                                                <h4 v-if="agencyBill" class="main-color text-center">Email e-sign to insured</h4>
                                                                <h4 v-else-if="issueAction === 'issue'" class="main-color text-center">Email quote to insured</h4>
                                                                <h4 v-else-if="issueAction === 'endorse'" class="main-color text-center">Email endorsement to insured</h4>
                                                                <h4 v-else-if="issueAction === 'renew'" class="main-color text-center">Email renewal to insured</h4>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" id="email-quote-radio" name="sendQuoteMode" v-model="sendQuoteMode" value="quote">
                                                                    <label class="main-color text-120 form-check-label" for="email-quote-radio" v-b-tooltip.hover
                                                                           title="Insured will be able to review and edit every page in the quoting workflow. They will also be able to enter payment details and purchase the policy.">
                                                                        Send Quote Access
                                                                    </label>
                                                                </div>
                                                                <div class="form-check form-check-inline pl-2">
                                                                    <input class="form-check-input" type="radio" id="pay-link-radio" name="sendQuoteMode" v-model="sendQuoteMode"
                                                                           :value="agencyBill ? 'agencyBill' : 'payment'">
                                                                    <label class="main-color text-120 form-check-label" for="pay-link-radio" v-b-tooltip.hover
                                                                           :title="agencyBill ? 'Insured will not be able to edit the quote. They will access a single page that summarizes risk, coverage, and premium details and allows them to consent to the policy purchase.' : 'Insured will not be able to edit the quote. They will access a single page that summarizes risk, coverage, and premium details and allows them to purchase the policy.' ">
                                                                        {{ agencyBill ? "Send E-Sign Request" : "Send Payment Link" }}
                                                                    </label>
                                                                </div>
                                                                <div class="input-group mb-3 justify-content-center">
                                                                    <input type="email" :disabled="emailBtnSpinner" class="col-md-8 form-control" placeholder="Applicant Email"
                                                                           :class="isNotNull('email') ? 'is-valid' : 'is-blank'" v-model="quote.email"/>
                                                                    <button id="send-btn" class="ml-2 btn btn-primary text-center rounded-lg" type="button" @click="shareQuote()">
                                                                        <i v-show="emailBtnSpinner" class="fas fa-spinner fa-pulse mr-1"></i> Send
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="text-block text-center">
                                                    <h1 id="purch-txt" v-if="issueAction==='endorse'">Endorse Policy</h1>
                                                    <h1 id="purch-txt" v-else-if="issueAction==='renew'">Renew Your Policy</h1>
                                                    <h1 id="purch-txt" v-else>Purchase Policy</h1>
                                                </div>

                                                <div class="row" v-if="!lockEdit">
                                                    <div id="payment-section"></div>
                                                    <div class="col-md-12 mb-3" v-if="!prorate && issueAction === 'endorse'">
                                                        <div class="border border-dark bg-white rounded p-3">
                                                            <div v-if="Array.isArray(quote.changes) && quote.changes.length > 0 || endorseOverride" class="extra-margin text-center my-4">
                                                                <button id="stripe-pay-btn" @click.prevent="endorseOnly()" class="btn btn-primary btn-lg" v-if="!issuing">Complete Endorsement</button>
                                                                <button class="btn btn-primary btn-lg" type="button" disabled v-if="issuing">
                                                                    <span class="spinner-border spinner-border-md mr-2 " role="status" aria-hidden="true"></span>
                                                                    Processing
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="min-height: 300px" class="col-md-12 mb-3" v-else-if="prorate > 0 || issueAction !== 'endorse'">
                                                        <div class="border border-dark bg-white rounded p-3" v-if="allowPayment()">
                                                            <StripePayment ref="StripePayment" v-if="currentIdx == 4" :agencyBill="agencyBill" :quoteData="quote" :issueAction="issueAction"
                                                                           :editingDisabled="editingDisabled()" @ofac="ofac()" @onPaymentComplete="onPaymentComplete"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <PurchaseSuccess :issueAction="issueAction" :stripeLink="stripeLink" :quote="quote" v-if="purchased"/>
                                        </form>
                                    </tab-content>

                                    <!-- QUOTE FOOTER BUTTONS -->
                                    <template slot="footer" slot-scope="props">
                                        <div class="fixed-bottom " style="margin: auto; max-width: 1420px;" v-show="purchased === false">
                                            <div class="row px-3 pb-0">
                                                <div class="col-md-3 bg-white">
                                                </div>

                                                <div class="col-md-9 px-1 py-4 mb-0 bg-white">
                                                    <wizard-button :disabled="ratingSpinner" class="back main-color" v-if="props.activeTabIndex > 0 && !props.isLastStep"
                                                                   @click.native="beforeTabSwitch(props.activeTabIndex, props.activeTabIndex-1) ? props.prevTab() : {}">Back
                                                    </wizard-button>

                                                    <!-- only show on payment page -->
                                                    <wizard-button :disabled="ratingSpinner" class="back main-color" v-if="props.activeTabIndex > 0 && props.isLastStep"
                                                                   @click.native="beforeTabSwitch(props.activeTabIndex, props.activeTabIndex-1) ? props.prevTab() : {}">Back to Quote
                                                    </wizard-button>
                                                    <button v-if="!lockEdit && currentIdx === 4 && windowTop < 700" @click="scrollTo('#payment-section')"
                                                            class="float-right btn-lg btn btn-success px-5"
                                                            style="margin-right: 200px">Purchase
                                                    </button>

                                                    <wizard-button :disabled="ratingSpinner || isEffectiveDatePassed()"
                                                                   v-if="currentIdx === 3 && !editingDisabled() && issueAction !== 'endorse' && !hasDeclines()"
                                                                   @click.native="beforeTabSwitch(props.activeTabIndex, props.activeTabIndex+1) ? props.nextTab() : {}" class="btn  quote-btn"
                                                                   :style="props.fillButtonStyle">{{ issueAction === 'renew' ? 'Review & Renew Policy' : 'Review & Purchase Quote' }}
                                                    </wizard-button>
                                                    <wizard-button :disabled="ratingSpinner || isEffectiveDatePassed()"
                                                                   v-if="currentIdx === 3 && !editingDisabled() && issueAction === 'endorse' && !hasDeclines() && (endorseOverride || prorate > 0)"
                                                                   @click.native="beforeTabSwitch(props.activeTabIndex, props.activeTabIndex+1) ? props.nextTab() : {}" class="btn quote-btn"
                                                                   :style="props.fillButtonStyle">Endorse Policy
                                                    </wizard-button>
                                                    <wizard-button v-if="!props.isLastStep &&  currentIdx !== 3"
                                                                   @click.native="beforeTabSwitch(props.activeTabIndex,props.activeTabIndex+1) ? props.nextTab() : {}" class="btn quote-btn"
                                                                   :style="props.fillButtonStyle">Next
                                                    </wizard-button>

                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </form-wizard>
                            </div>
                        </div>
                    </transition>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from "vue";
import {getClientLogo, getClientDomain, getClientText, getBrokerConfigs, getStyle, isDevServer} from "../Utils/themer.js";
import moment from 'moment';
import StripePayment from "./StripePayment.vue";
import PurchaseSuccess from "./PurchaseSuccess.vue";
import QuoteSummary from "./QuoteSummary.vue";
import BooleanQuestion from "./BooleanQuestion.vue"
import {isLoggedIn, getUserData, loginUser} from "../Utils/auth.js";
import LogRocket from 'logrocket';
import {convertStringsToIntegers} from "@/Utils/quote"
import BreadCrumb from "@/components/BreadCrumb.vue"
import {getPremium} from "../Utils/quote"

export default {
    name: "QuoteFlow",
    props: {
        saveQuoteInProgress: {
            type: Boolean,
            default() {
                return true;
            }
        },
        issueAction: {
            type: String,
            default() {
                return 'issue';
            }
        }
    },
    components: {
        BreadCrumb,
        StripePayment,
        PurchaseSuccess,
        QuoteSummary,
        BooleanQuestion
    },
    data: function () {
        return {
            agent: 1,
            currentIdx: 0,
            quote: {
                lob: 'pl',
                limit: 500000,
                carrier: 'Markel Insurance Company',
                policyTerm: 12,
                deductible: 2500,
                workType: "",
                industryType: "",
                addlServicesProvided: [],
                addlNamedInsureds: [],
                projects: [],
                noticeOfCancelList: [],
                ofacData: {insured: '', pass: true},
                emailMeMyQuote: true,
                quoteEmailed: false
            },
            boundQuote: {},
            compareQuote: {}, // used to prevent unncessary saves
            purchased: false,
            firstLoad: false,
            issuing: false,
            scrollPosition: null,
            minDate: null,
            showAddlModal: false,
            addlInsuredError: false,
            saving: false,
            formErrors: false,
            ratingSpinner: false,
            rates: {},
            lockEdit: false,
            lockAll: false,
            prorate: 0,
            downloadSpinner: false,
            sendQuoteMode: "quote",
            emailBtnSpinner: false,
            ofacSpinner: false,
            validations: {zip: ''},
            coveredStates: [],
            kickoutStates: [],
            brokerConfigs: {industryTypes: []},
            formStates: [{"name": "Alabama", "abbrev": "AL"}, {"name": "Alaska", "abbrev": "AK"}, {"name": "American Samoa", "abbrev": "AS"}, {"name": "Arizona", "abbrev": "AZ"}, {
                "name": "Arkansas",
                "abbrev": "AR"
            }, {"name": "California", "abbrev": "CA"}, {"name": "Colorado", "abbrev": "CO"}, {"name": "Connecticut", "abbrev": "CT"}, {
                "name": "Delaware",
                "abbrev": "DE"
            }, {"name": "District Of Columbia", "abbrev": "DC"}, {"name": "Federated States Of Micronesia", "abbrev": "FM"}, {"name": "Florida", "abbrev": "FL"}, {
                "name": "Georgia",
                "abbrev": "GA"
            }, {"name": "Guam", "abbrev": "GU"}, {"name": "Hawaii", "abbrev": "HI"}, {"name": "Idaho", "abbrev": "ID"}, {"name": "Illinois", "abbrev": "IL"}, {
                "name": "Indiana",
                "abbrev": "IN"
            }, {"name": "Iowa", "abbrev": "IA"}, {"name": "Kansas", "abbrev": "KS"}, {"name": "Kentucky", "abbrev": "KY"}, {"name": "Louisiana", "abbrev": "LA"}, {
                "name": "Maine",
                "abbrev": "ME"
            }, {"name": "Marshall Islands", "abbrev": "MH"}, {"name": "Maryland", "abbrev": "MD"}, {"name": "Massachusetts", "abbrev": "MA"}, {
                "name": "Michigan",
                "abbrev": "MI"
            }, {"name": "Minnesota", "abbrev": "MN"}, {"name": "Mississippi", "abbrev": "MS"}, {"name": "Missouri", "abbrev": "MO"}, {"name": "Montana", "abbrev": "MT"}, {
                "name": "Nebraska",
                "abbrev": "NE"
            }, {"name": "Nevada", "abbrev": "NV"}, {"name": "New Hampshire", "abbrev": "NH"}, {"name": "New Jersey", "abbrev": "NJ"}, {"name": "New Mexico", "abbrev": "NM"}, {
                "name": "New York",
                "abbrev": "NY"
            }, {"name": "North Carolina", "abbrev": "NC"}, {"name": "North Dakota", "abbrev": "ND"}, {"name": "Northern Mariana Islands", "abbrev": "MP"}, {
                "name": "Ohio",
                "abbrev": "OH"
            }, {"name": "Oklahoma", "abbrev": "OK"}, {"name": "Oregon", "abbrev": "OR"}, {"name": "Palau", "abbrev": "PW"}, {"name": "Pennsylvania", "abbrev": "PA"}, {
                "name": "Puerto Rico",
                "abbrev": "PR"
            }, {"name": "Rhode Island", "abbrev": "RI"}, {"name": "South Carolina", "abbrev": "SC"}, {"name": "South Dakota", "abbrev": "SD"}, {"name": "Tennessee", "abbrev": "TN"}, {
                "name": "Texas",
                "abbrev": "TX"
            }, {"name": "Utah", "abbrev": "UT"}, {"name": "Vermont", "abbrev": "VT"}, {"name": "Virgin Islands", "abbrev": "VI"}, {"name": "Virginia", "abbrev": "VA"}, {
                "name": "Washington",
                "abbrev": "WA"
            }, {"name": "West Virginia", "abbrev": "WV"}, {"name": "Wisconsin", "abbrev": "WI"}, {"name": "Wyoming", "abbrev": "WY"}],
            industryTypes: [],
            workTypes: [],
            refundBilling: null,
            showPurchaseSuccess: false,
            endorseOverride: false,
            windowTop: 0,
            mplWorkTypes: null,
            appetites: null,
            appetite: null,
            snapshotId: null,
            agencyBill: false,
            lastKYZipEntered: null, // need this to prevent multiple hazardhub calls
            lastKYAddressEntered: null, // need this just in case KY zip is same but the address changes
            policyPreviewSpinner: false,
            hasRetailBroker: false,
            clientDomain: 'draftrs',
            stripeLink: null
        };
    },
    mounted() {

        // bootstrap-vue b-dropdown listener
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if (bvEvent.componentId === 'industryType-section')
                this.scrollTo('#industryType-section', -60)
        })

        this.snapshotId = this.$route.params.snapshotId;

        !isDevServer() && Vue.config.openreplay.setMetadata('quotePageNum', this.currentIdx);
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.minDate = new Date(today);
        window.addEventListener('scroll', this.updateScroll);
        this.getStates();

        this.refreshQuoteData(true);

        if (!this.$route.params.id) {
            if (this.clientDomain === 'catalant') // for catalant redirect to MPL as Business Consultant
                this.redirectIfNotPL('Business Consultants')
            if (this.clientDomain === 'nacm') // for nacm redirect to MPL as Legal Support Services
                this.redirectIfNotPL('Legal Support Services')
            else { // Temporarily for all other Brokers default PL to Architects and Engineers
                this.quote.industryType = '';
            }
        }

        if (this.$parent.industry) {
            this.quote.industryType = this.$parent.industry;
            this.redirectIfNotPL(this.quote.industryType);
        }

        if (this.issueAction === 'endorse') {
            this.lockEdit = !['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_AGENT'].includes(this.getRole());
        }

        // monitor scrolling y axis
        window.addEventListener("scroll", this.onScroll)
    },
    watch: {
        quote: function (old, new_) {
            if (new_.revenue && old.revenue !== new_.revenue) {
                Vue.set(this.quote, 'revenue', Math.abs(new_.revenue));
            }
            if (new_.industryType && old.industryType !== new_.industryType) {
                if (new_.industryType) {
                    this.loadWorkTypes(this.quote.industryType);
                } else { // if no industryType find it
                    this.findMissingIndustryType();
                }
            }
            if (new_.policyTerm && old.policyTerm !== new_.policyTerm) {
                Vue.set(this.quote, 'extendedReportingDate', this.getExpDt(1));
            }
            if (new_.addlExtendedReportingPeriod && old.addlExtendedReportingPeriod !== new_.addlExtendedReportingPeriod) {
                if (this.quote.addlExtendedReportingPeriod)
                    Vue.set(this.quote, 'extendedReportingDate', this.getExpDt(1));
            }
        },
        currentIdx: function () {
            window.scrollTo(0, 0);
        },
        purchased: function () {
            if (this.purchased === true) {
                // remove to progress bar
                const elem = document.querySelector('.wizard-nav');
                elem.style.display = 'none'
                const elem2 = document.querySelector('.wizard-progress-with-circle');
                elem2.style.display = 'none'
            }
        }
    },
    methods: {
        isNotNull: function (attr) {
            if (!Object.hasOwn(this.quote, attr) ||
                    null === this.quote[attr]) {

                return false
            }
            return this.quote[attr] !== 0
        },
        setBrokerConfigs: function () {
            this.brokerConfigs = this.getBrokerConfigs(this.quote.owners ? this.quote.owners[0] : null);
            this.appetites = this.brokerConfigs.appetites;

            // GH #681
            this.hasRetailBroker = this.brokerConfigs.hasRetailBrokers.includes(this.clientDomain);

            // check if has agencyBill
            if (Object.prototype.hasOwnProperty.call(this.brokerConfigs, 'agencyBill') && this.brokerConfigs.agencyBill) {
                this.agencyBill = true;
                this.sendQuoteMode = 'agencyBill';
            }

            // only allow mpl for these brokers
            let mplBrokers = this.brokerConfigs.mplOnlyBrokers;
            let plBrokers = this.brokerConfigs.plOnlyBrokers;

            if (mplBrokers.includes(this.clientDomain)) { // only mpl
                this.industryTypes = this.brokerConfigs.industryTypes.filter(it => it.product === 'mpl');
            } else if (plBrokers.includes(this.clientDomain)) { // only pl
                this.industryTypes = this.brokerConfigs.industryTypes.filter(it => it.product === 'pl');
            } else { // pl and mpl
                this.industryTypes = this.brokerConfigs.industryTypes;
            }
        },
        changeIndustry: function (industry) {
            Vue.set(this.quote, 'industryType', industry);
            Vue.set(this.quote, 'workType', '');
            this.loadWorkTypes(industry);
            this.redirectIfNotPL(this.quote.industryType);
        },
        getQuoteOwner: function () {
            if (!Object.prototype.hasOwnProperty.call(this.quote, 'owners'))
                return false;

            return this.quote.owners[0];
        },
        hasBrokerOverride() {
            return (['issue', 'renew'].includes(this.issueAction) && this.quote.rating && this.quote.rating.breakdowns.defaultBrokerFee);
        },
        loadWorkTypes(industryType) {
            if (industryType) {
                let product = '';
                this.brokerConfigs.industryTypes.forEach(it => {
                    if (industryType === it.value) product = it.product;
                });

                if (product)
                    this.brokerConfigs[product].forEach(product => industryType === product.industry.value ? this.workTypes = product.workTypes : '');

            } else { // else assume its PL for pre-existing quotes
                // this.quote.industryType = this.brokerConfigs.pl[0].industry.value;
                this.workTypes = this.brokerConfigs.pl[0]
            }
        },
        redirectIfNotPL(industryType) {
            if (industryType) {
                let product = '';
                this.brokerConfigs.industryTypes.forEach(it => {
                    if (industryType === it.value) product = it.product;
                });

                if (product === 'mpl') {
                    this.$parent.lob = 'mpl';
                    this.$parent.industry = industryType;
                }

                this.appetite = this.appetites[this.quote.industryType] ? this.appetites[this.quote.industryType].split(', ') : '';

                if (this.appetite) {
                    this.$bvModal.show('appetite-modal')
                }
            }
        },
        onScroll() {
            this.windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
        },
        loggedIn: function () {
            return isLoggedIn();
        },
        updateScroll: function () {
            this.scrollPosition = window.scrollY
        },
        // getForms: async function() {
        //     this.ratingSpinner = true;
        //     const q = this.quote;
        //     await axios.post('/api/forms', { quote: q }).then((res) => {
        //         this.forms = res.data;
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         this.ratingSpinner = false;
        //     });
        // },
        calcPremium: async function () {
            let rates = {};
            this.ratingSpinner = true;
            const q = this.quote;
            let result = await getPremium(q)
            if (result.error) {
                this.ratingSpinner = false
                return null
            }
            this.quote = result.quote
            this.rates = result.rates
            if (this.issueAction === 'endorse') {
                await axios.post('/api/payment', {
                    quoteId: this.quote.id,
                    premium: this.quote.premium,
                    ratingId: this.quote.ratingId,
                    action: 'endorse',
                    effective: this.quote.endorseEffective,
                    getPaymentIntent: false,
                    addlData: {rateSummary: rates.summary}
                }).then((res) => {
                    this.prorate = Math.round(res.data.amount / 100);

                    if (res.data.taxFee) { // added prorating tax for endorsements
                        this.quote.rating.breakdowns.endorsedTaxFee = res.data.taxFee;
                        this.rates.endorsedTaxFee = res.data.taxFee;
                    }

                    if (0 > this.prorate)
                        this.refundBilling = res.data;
                });
            }

            this.ratingSpinner = false;
            this.saveQuoteData('ratingCall');
            //openreplay event
            //Vue.config.openreplay.event('premium_change', { premium: this.quote.premium });

            return this.quote.premium;
        },
        getExpDt: function (asISOString) {
            let time = moment.utc(this.quote.effective).add(this.quote.policyTerm, 'months');
            return asISOString ? time.toISOString() : time.format('MM/DD/YYYY');
        },
        getRetroDt: function () {
            return this.quote.retroDate ? moment.utc(this.quote.retroDate).format('MM/DD/YYYY') : moment.utc(this.quote.effective).format('MM/DD/YYYY');
        },
        isDevServer() {
            return isDevServer();
        },
        getStyle(val) {
            return getStyle(val);
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientText() {
            return getClientText();
        },
        getClientDomain() {
            return getClientDomain();
        },
        getBrokerConfigs(b) {
            return getBrokerConfigs(b);
        },
        endorseOnly: async function () {
            this.issuing = true;
            if (0 === this.prorate || null === this.prorate) {
                this.quote.purchased = true;
                await this.saveQuoteData('endorseOnly');
                await this.issuePolicy({"amount": 0});
                this.purchased = true;
                window.scroll(0, 0);
            } else
                this.issuing = false;
        },
        endorseRefund: async function () {
            this.issuing = true;
            if (0 >= this.prorate && this.refundBilling) {
                this.quote.purchased = true;
                await this.saveQuoteData('endorseRefund');
                await this.issuePolicy(this.refundBilling);
                this.purchased = true;
                window.scroll(0, 0);
            } else
                this.issuing = false;
        },
        consent: async function (id) {
            await axios.get('/api/consent/' + id).then((res) => {
                if (!this.quote.consents) {
                    this.quote.consents = [];
                }
                let repl = false;
                for (let i in this.quote.consents) {
                    if (id === this.quote.consents[i].id) {
                        this.quote.consents[i] = res.data;
                        repl = true;
                        break;
                    }
                }
                if (false === repl) {
                    this.quote.consents.push(res.data);
                }
            });
        },
        loadSnapshot: async function () {
            if (!this.snapshotId) {
                return;
            }
            this.loadingSpinner = true;
            let result = await axios.get("/api/snapshot/" + this.snapshotId)
            let quote = result?.data?.snapshot?.data;
            this.loadingSpinner = false;
            if (quote) {
                this.quote = convertStringsToIntegers(quote)
            }
        },
        beforeTabSwitch: function (currPg, nextPg) {
            const idx = nextPg
            if (nextPg < currPg) { // if going backwards, don't validate form
                this.formErrors = false;
                return true;
            }

            // check if trying to jump a page from sidebar menu
            let formId = (nextPg - currPg > 1) ? 'all-inputs' : 'form' + (currPg + 1);

            this.formErrors = false;

            // ADD FORM VALIDATIONS HERE
            let els;
            let formEl = document.getElementById(formId);
            if (formEl) {
                els = formEl.getElementsByClassName('is-blank');

                // if trying to jump a page don't set inputs invalid, just prevent page jump
                if (els.length > 0 && formId === 'all-inputs')
                    return false;

                Array.from(els).forEach(element => element.classList.add('is-invalid'));
            } else
                return false;

            // now validation for dropdown industryType logic
            if (!this.quote.industryType) {
                Vue.set(this.quote, 'industryType', null);
                this.formErrors = true;
                return false;
            }

            if (els.length) {
                this.formErrors = true;
                window.scrollTo(0, 0);
                return false;
            }

            if (idx === 1 && this.checkDeclines())
                return true;

            if (idx === 3 && this.checkDeclines())
                return true;

            if (idx === 4) {
                //this.ofac(); // check ofac when going to payment page

                if (this.quote.zip)
                    this.validateZip()
            }

            if (idx === 3)
                this.calcPremium();

            return true;
        },
        isLoading: function () {
            return this.$route.params.id && !this.firstLoad;
        },
        refreshQuoteData: async function (firstLoad) {
            const quoteId = this.$route.params.id;
            let oldEffective = '';

            if (true === firstLoad && this.snapshotId) {
                await this.loadSnapshot();
            } else if (quoteId) {
                this.quote.id = quoteId;
                let quote = {};

                if (this.$route.params.policyId && this.$route.params.customerId) {
                    this.lockEdit = true;
                    let p = '/api/quote/' + quoteId + '/' + this.$route.params.customerId + '/' + this.$route.params.policyId;
                    let res = await axios.get(p)
                    quote = res.data;
                    this.lockAll = true;

                } else {
                    let res = await axios.get('/api/quote/' + quoteId)
                    quote = res.data;
                    oldEffective = res.data.effective;
                }
                this.quote = convertStringsToIntegers(quote)

                if (this.hasObject(this.quote, 'preBindACH')) {
                    this.lockEdit = true;
                }
            }

            // BEGIN POST LOADED QUOTE DATA ACTIONS SHOULD GO HERE
            if (firstLoad) {
                this.clientDomain = this.quote.owners ? this.quote.owners[0] : this.getClientDomain();
                this.setBrokerConfigs();
            }

            if (!this.quote.industryType) {
                this.findMissingIndustryType();
            }

            this.loadWorkTypes(this.quote.industryType);

            if (this.agencyBill) {
                this.quote.payMethod = 'agencyBill';
            }

            this.lastKYZipEntered = Object.prototype.hasOwnProperty.call(this.quote, 'zip') ? this.quote.zip : '';
            this.lastKYAddressEntered = Object.prototype.hasOwnProperty.call(this.quote, 'address') ? this.quote.address : '';

            // END POST LOADED QUOTE DATA ACTIONS

            LogRocket.identify(quoteId); // find session by quoteid
            //metadata
            !isDevServer() && Vue.config.openreplay.setMetadata('quoteId', quoteId);

            //reset consents and transaction specific setup
            if (this.issueAction === 'endorse') {
                let currentQuote = null;
                await axios.get('/api/quote/' + quoteId).then((res) => {
                    currentQuote = res.data;
                });
                this.boundQuote = JSON.parse(JSON.stringify(currentQuote));
                this.quote.changes = this.hasObject(this.quote, 'changes') ? this.quote.changes : [];
                this.boundQuote.addlInsCount = this.boundQuote.addlNamedInsureds.length;
                this.quote.endorseEffective = new Date(this.quote.effective).getTime() > new Date(this.minDate).getTime() ? this.quote.effective : this.minDate;
                this.quote.consentCarrier = false;
                this.quote.consent = false;
            }
            if (firstLoad && 'renew' === this.issueAction) {
                if (!this.snapshotId) {
                    this.quote.effective = moment(this.quote.effective).add(this.quote.policyTerm, 'M').toISOString();
                    this.quote.renewalExpiration = this.quote.expires; // quote.renewalExpiration just represents the last day you can renew the policy not including grace period
                    this.quote.expires = moment(this.quote.effective).add(this.quote.policyTerm, 'M').toISOString();
                } else {
                    this.quote.renewalExpiration = moment(this.quote.expires).subtract(this.quote.policyTerm, 'M').toISOString();
                }

                this.quote.expPolicyNumber = this.quote.policyNumber;
                this.quote.consentCarrier = false;
                this.quote.consent = false;
                this.quote.consentNoc = false;
                this.quote.currentInsurance = 1;
                this.quote.retroDate = !this.quote.retroDate ? oldEffective : this.quote.retroDate;
            }

            // if the current date is past the effective date
            // if (!this.editingDisabled() && this.quote.status==='new' && new Date(this.quote.effective).getTime() < this.minDate.getTime())
            //     this.quote.effective = this.minDate;

            // jump to quote page if has a premium (except for renewals)
            if (firstLoad && this.quote.premium && this.issueAction !== 'renew') {
                this.$refs.formWizard.changeTab(0, 3);
                this.calcPremium();
            }

            this.firstLoad = true;
            this.compareQuote = JSON.parse(JSON.stringify(this.quote));
        },
        saveQuoteData: async function (saveAction = null) {
            if (this.lockAll)
                return;
            this.ratingSpinner = true;
            const currentQuoteId = this.quote.id;
            const userData = getUserData();

            if (this.quote.limit === 500000 && ['SD', 'ND'].includes(this.quote.state)) {
                Vue.set(this.quote, 'limit', 1000000);
            }

            if (['VT'].includes(this.quote.state)) {
                Vue.set(this.quote, 'limit', 500000);
            }

            if (saveAction === 'saveAddlInsuredModal') {
                let formEl = document.getElementById('addlInsForm');
                let els = formEl.getElementsByClassName('is-blank');

                if (els.length)
                    Array.from(els).forEach(element => element.classList.add('is-invalid'));

                if (els.length) {
                    this.addlInsuredError = true;
                    this.ratingSpinner = false;
                    return false;
                } else {
                    this.addlInsuredError = false;
                    this.showAddlModal = false;
                }
            }

            // special case when need to save quote data on checked action but not expecting any further navigation to save the data.
            if (saveAction === 'notifyMeWhenInMyState') {
                this.quote.notifyMeWhenInMyState = this.quote.notifyMeWhenInMyState !== true;
            }

            // special conditions to save on renewal or endorse going from quote page to payment page
            if (['saveBrokerOverride', 'tabChangeFrom3To4', 'emailQuote'].includes(saveAction) && ['renew', 'endorse'].includes(this.issueAction)) {
                this.ratingSpinner = true;
            } else if (this.editingDisabled() || !this.saveQuoteInProgress || !this.quote.insured) {
                if (saveAction === 'saveAddlInsuredModal') {
                    await this.calcPremium();
                    this.showAddlModal = false;
                }
                this.ratingSpinner = false;
                return;
            }

            //init consumer values on first save
            if ('ROLE_CONSUMER' === userData.role && !this.quote.user) {
                this.quote.user = userData.agent;
                this.quote.alias = userData.alias;
            }

            if (JSON.stringify(this.compareQuote) !== JSON.stringify(this.quote)) { // check if any data has changed before saving
                this.saving = true;
                let quote = {...this.quote};
                if (['renew', 'endorse'].includes(this.issueAction)) {
                    const params = {
                        "quoteId": this.quote.id,
                        "snapshotId": this.snapshotId, // if a snapshot exists keep using it
                        "data": this.quote, "context": {
                            "action": this.issueAction
                        }, "client": this.quote.owners[0]
                    };

                    let res = await axios.post('/api/save-quote-snapshot', params)
                    this.snapshotId = res.data.result;
                    this.$route.params.snapshotId = this.snapshotId;
                    await this.$refs.StripePayment.paymentInit()
                } else {
                    let res = await axios.post('/api/quote', this.quote)
                    this.ratingSpinner = false;
                    quote = {...res.data};

                    if (saveAction === 'saveAddlInsuredModal') {
                        this.showAddlModal = false;
                        await this.calcPremium();
                    }
                    this.compareQuote = JSON.parse(JSON.stringify(quote));

                    if (saveAction === 'saveBrokerOverride')
                        if (this.agencyBill)
                            this.$refs.AgencyBillPayment.paymentInit(this.quote.manualBrokerFee)
                        else
                            this.$refs.StripePayment.paymentInit(this.quote.manualBrokerFee)
                }

                this.saving = false;
                // overwrite temp data on first save
                if (!this.quote.id) {
                    const oq = this.quote;
                    for (let i in oq) {
                        quote[i] = oq[i];
                    }
                    //update consumer token on first save
                    if ('ROLE_CONSUMER' === userData.role) { // this method adds values to the user token
                        await axios.post("/api/consumer/token", {client: userData.client, quoteId: this.quote.id, email: this.quote.email}).then(res => {
                            if (res.data.token) {
                                loginUser(userData.client, this.quote.email, res.data.token);
                            }
                        });
                    }
                }
                this.quote = convertStringsToIntegers(quote)
                if (currentQuoteId !== this.quote.id) {
                    this.changeId(this.quote.id);
                }

                //email consumer the quote only once if the box is checked and a premium exists.
                if (this.quote.quoteEmailed !== true && this.quote.email && this.quote.premium > 0 && 'ROLE_CONSUMER' === userData.role) {
                    this.quote.quoteEmailed = true;
                    this.sendQuoteMode = 'quote';
                    await this.shareQuote(false, true); // call shareQuote() but don't need to save, and no Toast
                }
            }
            this.saving = false;
            this.ratingSpinner = false;
        },
        changeId: function (newId) {
            this.$router.replace({name: "quote", params: {id: newId}});
        },
        validateZip: async function () {

            let zipd;
            if (5 > this.quote.zip.length) {
                zipd = false;
            } else {
                const state = this.quote.state;
                await axios.get('/api/zip/' + this.quote.zip).then((res) => {
                    zipd = res.data;
                    if (!zipd.zip || (zipd.zip === this.quote.zip && zipd.state !== state)) {
                        zipd = false;
                    }
                    zipd.city ? Vue.set(this.quote, 'city', zipd.city) : '';
                    this.quote.county = zipd.county ? zipd.county : '';
                    this.quote.cityData = zipd;

                    if ((this.lastKYAddressEntered !== this.quote.address || this.lastKYZipEntered !== this.quote.zip) && zipd && this.quote.state === 'KY') { // lookup hazardhub if its a valid zip and its KY (hazardhub costs $.50 per api call)
                        this.lastKYZipEntered = this.quote.zip;
                        this.lastKYAddressEntered = this.quote.address;
                        this.hazardHub();
                    }
                }).catch(() => {
                    zipd = false;
                });
            }
            if (false === zipd) {
                Vue.set(this.validations, 'zip', 'Zipcode does not match with state ' + this.quote.state + '. Please enter the correct zipcode.');
            } else {
                Vue.set(this.validations, 'zip', '');
            }
        },
        hazardHub: async function () {
            if (this.quote.state === 'KY' && this.quote.address && this.quote.city && this.quote.zip) {
                await axios.post('/api/hazardhub', {params: {'address': this.quote.address, 'city': this.quote.city, 'state': this.quote.state, 'zip': this.quote.zip}}).then(async res => {

                    if (res.data.tax && res.data.authority) {
                        this.quote.kyHazard = res.data.tax;
                        this.quote.kyHazardAuthority = res.data.authority;
                    } else {
                        Vue.set(this.quote, 'address', '');
                        Vue.set(this.validations, 'zip', 'Zipcode or address does not match with state ' + this.quote.state + '. Please enter the correct zipcode or address.');
                    }

                });

            }
        },
        isActive: function (field, val) {

            if (field === 'limit' && val === 500000 && ['SD', 'ND'].includes(this.quote.state)) {
                return 'disabled';
            }

            // State is CA,  Interior Design, Construction Manager and Landscape Architect Github #663
            if (['Interior Design', 'Construction Manager', 'Landscape Architect'].includes(this.quote.workType) && ['CA'].includes(this.quote.state)) {
                // If revenues are under $125K then maximum limit offered should be $1M/$2M
                if (field === 'limit' && [12].includes(val) && this.quote.revenue < 125000) {
                    return 'disabled';
                }

                // If revenues are under $125K then maximum deductible offered should be $1000 - remove the 2500 and 5000 options.
                if (field === 'deductible' && [2500, 5000].includes(val) && this.quote.revenue < 125000) {
                    return 'disabled';
                }
            }

            // limit 51 === 500k/1M and 12 === 1M/2M
            if (field === 'limit' && [1000000, 2000000, 51, 12].includes(val) && ['VT'].includes(this.quote.state)) {
                return 'disabled';
            }

            // limit 51 === 500k/1M and 12 === 1M/2M
            if (field === 'limit' && [2000000, 12].includes(val) && (this.hasWorkType('Construction Manager')
                    || this.hasWorkType('Civil Engineer')
                    || (this.hasProject('Power and Energy and Utilities') && this.quote.projects?.length === 1))) {
                return 'disabled';
            }

            if ('new' === this.issueAction) {
                if (field === 'deductible' && val === 1000) {
                    if (this.quote.limit !== 500000)
                        return 'disabled';
                }
            }

            // if endorsement lock deductible and hide other options
            if ('endorse' === this.issueAction && field === 'deductible' && val !== this.quote.deductible && this.getRole() !== 'ROLE_SUPER')
                return 'disabled d-none';

            return this.quote[field] === val ? 'active' : '';
        },
        setQuoteVal: function (field, val, nullable = 0, dontRate = 0) {

            if (this.lockEdit)
                return;

            if ('renew' !== this.issueAction && (('cancelled' === this.quote.status || 'issued' === this.quote.status) && 'endorse' !== this.issueAction)) {
                return;
            }

            if (field === 'limit' && this.issueAction === 'endorse') {
                let _val = this.displayLimit(val, 'rawAgg');
                let _bql = this.displayLimit(this.boundQuote.limit, 'rawAgg');
                if (_bql > _val)
                    return;
            }

            if (field === 'policyTerm') {
                if (this.boundQuote && this.boundQuote.policyTerm > val)
                    return;
                else
                    this.quote.expires = moment(this.quote.effective).add(val, 'M').toISOString();
            }

            if (field === 'deductible' && this.boundQuote.deductible < val)
                return;

            if (field === 'addlVicarious' && this.boundQuote.addlVicarious)
                return;

            if (field === 'addl1stDefense' && this.boundQuote.addl1stDefense)
                return;

            if (field === 'addlPersonalInjury' && this.boundQuote.addlPersonalInjury)
                return;

            if (field === 'addlRectification' && this.boundQuote.addlRectification)
                return;

            if (field === 'addlNoticeOfCancel3rdParties' && this.boundQuote.addlNoticeOfCancel3rdParties)
                return;

            if (field === 'addlExtendedReportingPeriod' && this.boundQuote.addlExtendedReportingPeriod)
                return;

            if (field === 'rectificationSublimit' && this.boundQuote.rectificationSublimit) {
                Vue.set(this.quote, 'rectificationSublimit', this.boundQuote.rectificationSublimit);
                return;
            }

            if (field === 'extendedReportingPeriodMonths' && this.boundQuote.extendedReportingPeriodMonths) {
                Vue.set(this.quote, 'extendedReportingPeriodMonths', this.boundQuote.extendedReportingPeriodMonths);
                Vue.set(this.quote, 'extendedReportingDate', this.boundQuote.extendedReportingDate);
                return;
            }

            if (field === 'limit' && val === 500000 && ['SD', 'ND'].includes(this.quote.state)) {
                return;
            }

            // State is CA,  Interior Design, Construction Manager and Landscape Architect Github #663
            if (['Interior Design', 'Construction Manager', 'Landscape Architect'].includes(this.quote.workType) && ['CA'].includes(this.quote.state)) {
                // If revenues are under $125K then maximum limit offered should be $1M/$2M
                if (field === 'limit' && [12].includes(val) && this.quote.revenue < 125000) {
                    return;
                }

                // If revenues are under $125K then maximum deductible offered should be $1000 - remove the 2500 and 5000 options.
                if (field === 'deductible' && [2500, 5000].includes(val) && this.quote.revenue < 125000) {
                    return;
                }
            }

            // limit 51 === 500k/1M and 12 === 1M/2M
            if (field === 'limit' && [1000000, 2000000, 51, 12].includes(val) && ['VT'].includes(this.quote.state)) {
                return;
            }

            // handle limit changes for endorsement and renewals
            if (field === 'limit' && ['renew', 'endorse'].includes(this.issueAction) && val !== this.quote.limit) {

                if (!this.hasObject(this.quote, 'oldLimit')) {
                    this.quote.oldLimit = this.quote.limit;
                }

                // prevent lowering limits for endorsement only in PL
                const limitOrder = [200000, 500000, 51, 1000000, 12, 2000000];
                if (limitOrder.indexOf(val) < limitOrder.indexOf(this.quote.oldLimit) && ['endorse'].includes(this.issueAction)) {
                    return;
                } else if (this.quote.oldLimit === val)
                    delete this.quote['oldLimit'];
            }

            // limit 51 === 500k/1M and 12 === 1M/2M
            if (field === 'limit' && [2000000, 12].includes(val) && (this.hasWorkType('Construction Manager')
                    || this.hasWorkType('Civil Engineer')
                    || (this.hasProject('Power and Energy and Utilities') && this.quote.projects?.length === 1))) {
                return;
            } else if (field === 'addlNamedInsureds') { // addl insureds modal
                if (Array.isArray(this.quote['addlNamedInsureds']) && this.quote['addlNamedInsureds'].length === 0)
                    this.newAddlInsured()

                this.showAddlModal = true;
                return;
            } else if (field === 'addlRectification' && this.quote['addlRectification']) {
                Vue.set(this.quote, 'addlRectification', 0);
                dontRate = 0;
            } else if (field === 'addlExtendedReportingPeriod' && this.quote['addlExtendedReportingPeriod']) {
                Vue.set(this.quote, 'addlExtendedReportingPeriod', 0);
                dontRate = 0;
            } else if (this.quote[field] !== null && this.quote[field] === val && nullable) { // button toggle
                Vue.set(this.quote, field, null);
            } else if (val !== undefined) {
                Vue.set(this.quote, field, val);
            }

            if (!dontRate) {
                this.calcPremium();
            }

            // this is needed for Vue reactivity issues with objects
            // this.$forceUpdate(); // not needed if using Vue.set()
        },
        updateRadioButton: function (field) {

            // handle any custom logic
            if (field === 'forSaleDevelopments') {
                this.quote.greaterThan25Units = null;
            }
        },
        changeTab: async function (prevIndex, nextIndex) {
            this.currentIdx = nextIndex;
            //track page change
            !isDevServer() && Vue.config.openreplay.setMetadata('quotePageNum', this.currentIdx);

            this.saveQuoteData('tabChangeFrom' + prevIndex + 'To' + nextIndex);

        },
        editingDisabled: function () {
            if (this.issueAction === 'renew')
                return false;

            return (('cancelled' === this.quote.status || 'issued' === this.quote.status) && this.saveQuoteInProgress);
        },
        ofac: async function () {
            if (this.quote.ofacData && this.quote.ofacData.pass) // only ofac if didn't pass before
                return;

            this.ofacSpinner = true;
            this.ofacTogglePay();

            if (!this.quote.insured) {
                this.ofacSpinner = false;
                return;
            }

            if (!Object.prototype.hasOwnProperty.call(this.quote, 'ofacData')) {
                this.quote.ofacData = {insured: '', pass: true};
            }

            if (Object.prototype.hasOwnProperty.call(this.quote, 'ofacData') && this.quote.ofacData.insured !== this.quote.insured) {
                await axios.post('/api/ofac', {insured: this.quote.insured}).then((res) => {
                    this.quote.ofacData = res.data.result;
                    this.ofacSpinner = false;
                });
            } else {
                this.ofacSpinner = false;
            }

            if (!this.quote.ofacData.pass) {
                if (this.getRole() === 'ROLE_CONSUMER')
                    this.sideBarChangeTab(3);
            }

            this.ofacTogglePay();

        },
        ofacTogglePay: function () {
            let payError = document.getElementById('pay-error');
            //let ofacError = document.getElementById('ofac-error');
            let payBtn = document.getElementById('stripe-pay-btn');
            let sendBtn = document.getElementById('send-btn');

            if (this.ofacSpinner === true) {
                if (0 < this.prorate)
                    payBtn.setAttribute('disabled', true);
                if (sendBtn)
                    sendBtn.setAttribute('disabled', true);
                return;
            }

            if (Object.prototype.hasOwnProperty.call(this.quote, 'ofacData') && this.quote.ofacData.insured === this.quote.insured && !this.quote.ofacData.pass) {
                //ofacError.classList.add('show');
                payError.classList.remove('show');

            } else if (Object.prototype.hasOwnProperty.call(this.quote, 'ofacData') && this.quote.ofacData.insured === this.quote.insured && this.quote.ofacData.pass) {
                //ofacError.classList.remove('show');
                if (0 < this.prorate)
                    payBtn.removeAttribute('disabled');

                if (sendBtn)
                    sendBtn.removeAttribute('disabled');
            }
        },
        sideBarChangeTab: function (newTab) {
            if (this.beforeTabSwitch(this.currentIdx, newTab) || newTab < this.currentIdx) {
                this.$refs.formWizard.changeTab(this.currentIdx, newTab);
            }
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        formatStr: function (str) {
            return str ? str.toLocaleString() : '';
        },
        getRole: function () {
            return getUserData().role;
        },
        shareQuote: async function (saveAction = 'emailQuote', suppressToast = false) {

            this.saveQuoteData(); // just in case they change the users email when sharing the quote

            if (Object.prototype.hasOwnProperty.call(this.quote, 'ofacData') && this.quote.ofacData.insured === this.quote.insured && !this.quote.ofacData.pass) {
                this.ofacTogglePay();
                return false;
            } else if (Object.prototype.hasOwnProperty.call(this.quote, 'ofacData') && this.quote.ofacData.insured !== this.quote.insured) {
                await this.ofac();

                if (!this.quote.ofacData.pass) {
                    this.ofacTogglePay();
                    return false;
                }
            }

            // if need to save any form data before sending
            if (saveAction) {
                this.quote.quoteEmailed = true;
                await this.saveQuoteData(saveAction);
            }

            this.emailBtnSpinner = true;
            if ('quote' === this.sendQuoteMode) {
                await this.sendPaymentLink();
                this.emailBtnSpinner = false;
                if (!suppressToast) {
                    this.$toast.open({
                        message: 'An email has been sent to <strong>' + this.quote.email + '</strong> with instructions for accessing their quote',
                        type: 'success',
                        dismissible: true,
                        duration: 8000,
                        position: 'top-right'
                    });
                }
            } else if ('agencyBill' === this.sendQuoteMode) {
                await this.sendPaymentLink();
                this.emailBtnSpinner = false;
                if (!suppressToast) {
                    this.$toast.open({
                        message: 'Consent and e-signature request email sent to ' + this.quote.email,
                        type: 'success',
                        dismissible: true,
                        duration: 8000,
                        position: 'top-right'
                    });
                }
            } else {
                await this.sendPaymentLink();
                this.emailBtnSpinner = false;
                this.$toast.open({
                    message: 'Payment email sent to ' + this.quote.email,
                    type: 'success',
                    dismissible: true,
                    duration: 8000,
                    position: 'top-right'
                });
            }
        },
        sendPaymentLink: async function () {
            this.emailBtnSpinner = true;

            const params = {
                "email": this.quote.email,
                "quoteId": this.quote.id,
                "data": this.quote,
                "context": {
                    "type": this.sendQuoteMode,
                    "action": this.issueAction
                },
                "client": this.quote.owners[0],
                "details": {
                    "premium": this.quote.premium,
                    "limit": this.displayLimit(this.quote.limit, 'full'),
                    "deductible": this.quote.deductible,
                    "policyTerm": this.quote.policyTerm
                }
            };
            await axios.post('/api/payment-invite', params);
            this.emailBtnSpinner = false;
        },
        newAddlInsured: function () {
            if (this.editingDisabled())
                return;

            this.quote.addlNamedInsureds.unshift({
                entityName: '',
                entityType: null,
                sameServiceAsEntity: null,
                servicesProvided: null,
                entityRevenues: null,
                revenueInclInTotal: null,
                insuranceClaims: null,
                unlocked: true
            });
        },
        removeAddlInsured: function (index) {
            if (this.editingDisabled())
                return;

            if (this.issueAction === 'endorse' && !this.lockEdit) {
                let cnt = this.quote.addlNamedInsureds.length;
                let exist = this.boundQuote.addlInsCount;
                if ((index + 1) <= Math.abs(cnt - exist)) // only delete the new ones
                    this.quote.addlNamedInsureds.splice(index, 1);
            } else
                this.quote.addlNamedInsureds.splice(index, 1);
        },
        cancelAddlInsured: function (index) {
            if (this.editingDisabled())
                return;

            if (this.issueAction === 'endorse' && !this.lockEdit) {
                let cnt = this.quote.addlNamedInsureds.length;
                let exist = this.boundQuote.addlInsCount;
                if ((index + 1) <= Math.abs(cnt - exist)) // only delete the new ones
                    this.quote.addlNamedInsureds.splice(index, 1);
            } else
                this.quote.addlNamedInsureds.splice(index, 1);
        },
        isAddlInsuredLocked: function (index) {
            if (this.issueAction === 'endorse' && !this.lockEdit) {
                let cnt = this.quote.addlNamedInsureds.length;
                let exist = this.boundQuote.addlInsCount;
                return (index + 1) > Math.abs(cnt - exist);
            }

            return false;

        },
        addToNoticeOfCancelList: function () {
            if (this.editingDisabled())
                return;

            if (Array.isArray(this.quote.noticeOfCancelList) && this.quote.noticeOfCancelList.length < 8) {
                this.quote.noticeOfCancelList.push({name: '', address1: '', address2: '', city: '', state: '', zip: ''});
                Vue.set(this.quote, 'noticeOfCancelList', this.quote.noticeOfCancelList);
            }

        },
        removeFromNoticeOfCancelList: function (index) {
            if (this.editingDisabled())
                return;

            this.quote.noticeOfCancelList.splice(index, 1);
            Vue.set(this.quote, 'noticeOfCancelList', this.quote.noticeOfCancelList);

        },

        newAddlService: function () {
            if (this.editingDisabled())
                return;

            if (Array.isArray(this.quote.addlServicesProvided))
                this.quote.addlServicesProvided.push('')

        },
        removeAddlService: function (index) {
            if (this.editingDisabled())
                return;

            this.quote.addlServicesProvided.splice(index, 1);
        },
        clearDependantsOf: function (field, sub) {
            if (this.editingDisabled())
                return;

            switch (field) {
                case 'projectTypeCommercial' :
                    Vue.set(this.quote, 'bldg12Stories', null);
                    break;
                case 'projectTypeResidential' :
                    Vue.set(this.quote, 'forSaleDevelopments', null);
                    Vue.set(this.quote, 'homes3M', null);
                    break;
                case 'forSaleDevelopments' :
                    Vue.set(this.quote, 'greaterThan25Units', null);
                    break;
                case 'projectTypePowerEnergy' :
                    Vue.set(this.quote, 'minesQuarriesNukes', null);
                    break;
                case 'projectTypeCivilInfra' :
                    Vue.set(this.quote, 'bridgesTunnelsDams', null);
                    break;
                case 'currentInsurance' :
                    Vue.set(this.quote, 'retroDate', null);
                    break;
                case 'claimsYears' :
                    Vue.set(this.quote, 'numberReportedMatters', null);
                    Vue.set(this.quote, 'sumClaimsPaid', null);
                    break;

                case 'contractorResponsibility' :
                    Vue.set(this.quote, 'consentContractorExclusion', null);
                    break;

                case 'sellStockRenderings' :
                    Vue.set(this.quote, 'disclaimerUnstamped', null);
                    break;

                    // these all clear the checkbox if any of them change
                case 'notCoveredProjectTypes' :
                    Vue.set(this.quote, 'consentProjectTypesExcluded', null);
                    break;
                case 'greaterThan25Units' :
                    Vue.set(this.quote, 'consentProjectTypesExcluded', null);
                    break;
                case 'homes3M' :
                    Vue.set(this.quote, 'consentProjectTypesExcluded', null);
                    break;
                case 'bldg12Stories' :
                    Vue.set(this.quote, 'consentProjectTypesExcluded', null);
                    break;
                case 'minesQuarriesNukes' :
                    Vue.set(this.quote, 'consentProjectTypesExcluded', null);
                    break;
                case 'bridgesTunnelsDams' :
                    Vue.set(this.quote, 'consentProjectTypesExcluded', null);
                    break;

                    //pg 4
                case 'addlRectification' :
                    if (!this.lockEdit)
                        Vue.set(this.quote, 'rectificationSublimit', '');
                    break;
                case 'addlExtendedReportingPeriod' :
                    if (!this.lockEdit) {
                        delete this.quote['extendedReportingPeriodMonths'];
                        delete this.quote['extendedReportingDate'];

                        // Vue.set(this.quote, 'extendedReportingPeriodMonths', null);
                        // Vue.set(this.quote, 'extendedReportingDate', null);
                    }
                    break;
                case 'addlNoticeOfCancel3rdParties' :
                    Vue.set(this.quote, 'noticeOfCancelList', []);
                    break;
                case 'addlNamedInsureds' :
                    if (sub)
                        this.quote.addlNamedInsureds[sub.index][sub.name] = null;
                    break;
            }
        },
        endorseChanges: function () {

            if (this.issueAction !== 'endorse' || !Array.isArray(this.quote.changes))
                return;

            let countBefore = this.quote.changes.length;

            if (this.quote.limit !== this.boundQuote.limit) {
                if (this.quote.changes.indexOf('Change limit endorsement') < 0)
                    this.quote.changes.push('Change limit endorsement');
            } else if (this.quote.changes.indexOf('Change limit endorsement') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Change limit endorsement'), 1);

            if (this.quote.limit !== this.boundQuote.limit && this.quote.limit === 2000000 && (this.hasProject('Power and Energy and Utilities') && this.quote.projects?.length > 1)) {
                if (this.quote.changes.indexOf('Project Sublimit Endorsement - Power Energy/Oil and Gas') < 0)
                    this.quote.changes.push('Project Sublimit Endorsement - Power Energy/Oil and Gas');
            } else if (this.quote.changes.indexOf('Project Sublimit Endorsement - Power Energy/Oil and Gas') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Project Sublimit Endorsement - Power Energy/Oil and Gas'), 1);

            if (this.quote.policyTerm !== this.boundQuote.policyTerm) {
                if (this.quote.changes.indexOf('Change policy term') < 0)
                    this.quote.changes.push('Change policy term');
            } else if (this.quote.changes.indexOf('Change policy term') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Change policy term'), 1);

            if (this.quote.deductible !== this.boundQuote.deductible) {
                if (this.quote.changes.indexOf('Change deductible endorsement') < 0)
                    this.quote.changes.push('Change deductible endorsement');
            } else if (this.quote.changes.indexOf('Change deductible endorsement') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Change deductible endorsement'), 1);

            if (JSON.stringify(this.quote.addlNamedInsureds) !== JSON.stringify(this.boundQuote.addlNamedInsureds)) {
                if (this.quote.changes.indexOf('Change Additional Named Insureds') < 0)
                    this.quote.changes.push('Change Additional Named Insureds');
            } else if (this.quote.changes.indexOf('Change Additional Named Insureds') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Change Additional Named Insureds'), 1);

            if (this.quote.addlVicarious !== this.boundQuote.addlVicarious) {
                if (this.quote.changes.indexOf('Additional Insureds Vicarous Liability') < 0)
                    this.quote.changes.push('Additional Insureds Vicarous Liability');
            } else if (this.quote.changes.indexOf('Additional Insureds Vicarous Liability') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Additional Insureds Vicarous Liability'), 1);

            if (this.quote.addl1stDefense !== this.boundQuote.addl1stDefense || this.quote.firstDollarDeductible !== this.boundQuote.firstDollarDeductible) {
                if (this.quote.changes.indexOf('Change First Dollar Defense') < 0)
                    this.quote.changes.push('Change First Dollar Defense');
            } else if (this.quote.changes.indexOf('Change First Dollar Defense') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Change First Dollar Defense'), 1);

            if (this.quote.addlPersonalInjury !== this.boundQuote.addlPersonalInjury) {
                if (this.quote.changes.indexOf('Personal Injury Enhancement') < 0)
                    this.quote.changes.push('Personal Injury Enhancement');
            } else if (this.quote.changes.indexOf('Personal Injury Enhancement') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Personal Injury Enhancement'), 1);

            if (this.quote.addlRectification !== this.boundQuote.addlRectification || this.quote.rectificationSublimit !== this.boundQuote.rectificationSublimit) {
                if (this.quote.changes.indexOf('Rectification') < 0)
                    this.quote.changes.push('Rectification');
            } else if (this.quote.changes.indexOf('Rectification') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Rectification'), 1);

            if (this.quote.addlRectification === 0 && !Object.prototype.hasOwnProperty.call(this.boundQuote, 'addlRectification')) {
                this.quote.changes.splice(this.quote.changes.indexOf('Rectification'), 1);
            }


            if (JSON.stringify(this.quote.noticeOfCancelList) !== JSON.stringify(this.boundQuote.noticeOfCancelList)) {
                if (this.quote.changes.indexOf('Notice of Cancellation to Third Parties') < 0)
                    this.quote.changes.push('Notice of Cancellation to Third Parties');
            } else if (this.quote.changes.indexOf('Notice of Cancellation to Third Parties') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Notice of Cancellation to Third Parties'), 1);

            if (this.quote.addlExtendedReportingPeriod !== this.boundQuote.addlExtendedReportingPeriod || this.quote.extendedReportingPeriodMonths !== this.boundQuote.extendedReportingPeriodMonths) {
                if (this.quote.changes.indexOf('Extended Reporting Period') < 0)
                    this.quote.changes.push('Extended Reporting Period');
            } else if (this.quote.changes.indexOf('Extended Reporting Period') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Extended Reporting Period'), 1);

            if (JSON.stringify(this.quote.addlServicesProvided) !== JSON.stringify(this.boundQuote.addlServicesProvided)) {
                if (this.quote.changes.indexOf('Disciplines') < 0)
                    this.quote.changes.push('Disciplines');
            } else if (this.quote.changes.indexOf('Disciplines') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Disciplines'), 1);

            if (JSON.stringify(this.quote.projects) !== JSON.stringify(this.boundQuote.projects)) {
                if (this.quote.changes.indexOf('Project Types') < 0)
                    this.quote.changes.push('Project Types');
            } else if (this.quote.changes.indexOf('Project Types') > -1)
                this.quote.changes.splice(this.quote.changes.indexOf('Project Types'), 1);

            let countAfter = this.quote.changes.length;
            if (countBefore !== countAfter || !countAfter)
                this.quote.consentEndorsementChanges = null;

        },
        hasWorkType: function (workType) {
            if (this.quote.workType === workType) {
                return true;
            } else if (Array.isArray(this.quote.addlServicesProvided) && this.quote.addlServicesProvided.includes(workType))
                return true;

            return false;
        },
        hasProject: function (projectName) {
            return Array.isArray(this.quote.projects) && this.quote.projects.includes(projectName);
        },
        getTotalRevenue: function () {

            let addlRevenue = 0;
            this.quote.addlNamedInsureds.forEach(function (addl) {
                if (addl.revenueInclInTotal === 0)
                    addlRevenue += Number(addl.entityRevenues);
            });

            return Number(this.quote.revenue) + addlRevenue;
        },
        checkDeclines: function () {
            let declines = [];
            let kickoutProjects = {
                kp: [
                    {key: 'customhomesover3m', value: 'custom homes over $3M'},
                    {key: 'condosover25units', value: 'condos over 25 units'},
                    {key: 'highriseover12stories', value: 'high rises over 12 stories'},
                    {key: 'nuclear', value: 'nuclear'},
                    {key: 'minesquarries', value: 'mines and quarries'},
                    {key: 'tunnelsblastbored', value: 'tunnels blast or bored'},
                    {key: 'wharvesharbors', value: 'wharves or harbors'},
                    {key: 'amusementparkrides', value: 'amusement park rides'},
                    {key: 'aviationnaval', value: 'aviation naval'},
                    {key: 'offshoreprojects', value: 'offshore projects'},
                    {key: 'prosportsstadiums', value: 'pro sports stadiums'},
                    {key: 'prototypedesigns', value: 'prototype designs'},
                    {key: 'standaloneparking', value: 'stand alone parking'}
                ]
            };

            let kickoutServices = ['Structural Engineer', 'Geotechnical Engineer', 'Process Engineer', 'Products Design/Engineer', 'Environmental Remediation', 'Control System Integration', 'Inspection Services'];

            // Washington specific kickout projects
            if (this.quote.state === 'WA') {
                let declineProjects = '';
                kickoutProjects.kp.forEach(kp => {
                    if (this.quote.projects.indexOf(kp.key) > -1) declineProjects = declineProjects + ', ' + kp.value;
                })

                if (declineProjects)
                    declines.push('You reported working on project(s): ' + declineProjects.replace(/(^,)|(,$)/g, "") + '.');
            }

            if (this.kickoutStates.includes(this.quote.state))
                declines.push('Draftrs is not currently available in your state, but we will be soon, pending licensing and regulatory approvals.');

            // if (!coveredStates.includes(this.quote.state))
            //     declines.push('Draftrs is not currently available in your state, but we will be soon, pending licensing and regulatory approvals.');

            if (Object.prototype.hasOwnProperty.call(this.quote, 'ofacData') && this.quote.ofacData.insured === this.quote.insured && !this.quote.ofacData.pass)
                declines.push('A name used in the application was found on a government sanctions list.');

            // special condition to allow SUPER_ADMIN to bypass $1M revenue limit declineOverride the $1M revenue Limit? (Super Admin Function)
            if (this.hasObject(this.quote, 'revenueOverride') && this.quote.revenueOverride === true)
                ; // do nothing
            else if (Number(this.getTotalRevenue()) > 1000000 && this.showAddlModal === false)
                declines.push('You reported annual revenues that exceed $1M.  We are currently only able to consider firms with up to $1M in fees.');

            if (this.quote.controlOwnedByOther)
                declines.push('You reported that you are a subsidary of another firm.  We are unable to consider stand alone policies for subsidiaries.  We can include subsidiaries under a parent company program.  Please apply as the parent company if total organization revenues are below $1M in annual fees.');

            if (Number(this.quote.totalNoProfEmployees) + Number(this.quote.totalOwners) > 10)
                declines.push('You reported more than 10 employees.  We are currently only able to consider firms with up to 10 employees.');

            let declineWorkAndServices = ['Structural Engineer', 'Geotechnical Engineer'];
            let workDeclined = false;
            if (declineWorkAndServices.includes(this.quote.workType)) {
                declines.push('An agent will contact you for some additional information to complete your quote');
                workDeclined = true;
            } else {
                declineWorkAndServices.forEach(svc => {
                    if (this.quote.addlServicesProvided.includes(svc)) {
                        declines.push('You reported providing services in Geotechnical or Structural Services. We are currently unable to consider firms working in this area.');
                        workDeclined = true;
                    }
                });
            }

            if (!workDeclined) {
                if (kickoutServices.includes(this.quote.workType)) {
                    declines.push('You reported providing services in one of the following areas: process engineering, products design/engineering, environmental remediation, control system integration, inspections services. We are currently unable to consider firms working in the above areas.');
                } else {
                    kickoutServices.forEach(svc => {
                        if (this.quote.addlServicesProvided.includes(svc))
                            declines.push('You reported providing services in one of the following areas: Process Engineering, Products Design/Engineering, Environmental Remediation, Control System Integration, Inspections Services. We are currently unable to consider firms working in the above areas.');
                    });
                }
            }

            if (this.quote.claimsYears && (this.quote.numberReportedMatters > 2 || this.quote.sumClaimsPaid > 15000))
                declines.push('You reported claims history with more than 2 reported matters and/or $15K total incurred payments.');

            if (this.quote.insuranceCancelled)
                declines.push('You reported a prior insurance cancellation or non-renewal that was not due to non-payment of premium.');

            if (this.quote.felonyBk5Yrs)
                declines.push('You reported a bankruptcy and or felony of the business, predecessor business and/or principal in the last 5 years.');

            if (this.quote.circumstanceClaim)
                declines.push('You reported a known circumstance which could give rise to a claim under the policy.');

            if (this.quote.experienceYears < 8)
                declines.push('You reported less than 8 years of professional experience.');

            if (this.quote.engineeringServices)
                declines.push('You reported working on these types of projects: Environmental Remediation, Materials Testing & Inspection Services, Products Design or Engineering, Seismic Engineering, Fire Safety & Proection Services, Foundation Design.');

            if (this.quote.disclaimerUnstamped === 0)
                declines.push('You reported that you do not include disclaimers that plans are unstamped and may not be compliant to local codes and requirements.');

            this.quote.declines = declines;
            this.quote.status = declines.length ? 'declined' : this.quote.customerId ? 'issued' : 'new';

            return declines.length !== 0;
        },
        hasDeclines: function () {
            return Array.isArray(this.quote.declines) && this.quote.declines.length;
        },
        isLob: function (lob) {
            return lob === this.quote.lob;
        },
        downloadAppDoc: function () {
            let url = '/api/application/application/' + this.quote.id;
            this.downloadSpinner = true;
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob' // important
            }).then((response) => {
                this.downloadSpinner = false;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'draftrs-application-' + this.quote.id + '.pdf');
                document.body.appendChild(link);
                link.click();
            });
        },
        getStates: async function () {
            await axios.get('/api/getstates/pl').then((res) => {
                const states = res.data;
                if (states) {
                    for (var key in states) {
                        if (Object.prototype.hasOwnProperty.call(states, key) && states[key]) {
                            this.coveredStates.push(key);
                        } else {
                            this.kickoutStates.push(key)
                        }
                    }
                }

                this.formStates = this.formStates.filter(state => this.coveredStates.includes(state.abbrev)); // filter only coveredStates to show on form

                if (this.brokerConfigs && this.brokerConfigs.states !== null && this.brokerConfigs.states)
                    this.formStates = this.formStates.filter(state => this.brokerConfigs.states.includes(state.abbrev));// filter broker only covered states if it exists
            });
        },
        checkWorkType: function () {
            return this.clientDomain !== 'shingleit' && ['Structural Engineer', 'Geotechnical Engineer'].includes(this.quote.workType);
        }
    }

};
</script>

<style lang="scss">
.wizard-icon {
    color: var(--main-color);
}

.wizard-icon-circle {
    border: 1px solid var(--main-color) !important;
}

.quote-two {
    min-height: 500px;
}

#total-premium {
    font-size: 50px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.nostickypremium {
    max-width: 960px;
    position: inherit;
}

.stickypremium {
    width: 69%;
    max-width: 970px;
    position: fixed;
    top: 0;
    z-index: 100;
    background: #faf9fe;
    //padding-top: 80px;
    //padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .stickypremium {
        width: 96%;
    }
}

h1#purch-txt {
    color: var(--main-color);
    margin-bottom: 30px;
}

.fs-policy-area ul li .active {
    background-color: var(--main-secondary);
}

.fs-block-boxes .btn-area a.active {
    background-color: var(--main-secondary);
}
</style>