<template>
    <div>
        <StandardNav/>
        <header class="container-fluid bg-purple">
			<div class="container">
				<div class="row py-5">
					<div class="col-md-10 offset-md-1 my-1">
						<h1 class="display-4 text-white font-weight-bold mb-4">
							Press Releases
						</h1>
						<h5 class="text-white">
							Draftrs Launches New Digital Professional Liability Insurance Platform
						</h5>
					</div>
				</div>
			</div>
		</header>
		<main class="container-fluid bg-grey">
            <div class="container py-5" >
				<div class="col-md-10 offset-md-1">
                    <p class="pb-3">
                        <b><center>Draftrs Launches New Digital Professional Liability Insurance Platform</center></b>
                    </p>
                    <p class="pb-3">
                        <b><center>Tech-enabled MGA provides small firm professionals and agents professional liability insurance with automated underwriting and instant policy management</center></b>
                    </p>
                    <p class="pb-3">
                        <b>Dallas, Texas – April 26, 2022 –</b> Draftrs, an insurtech company focused on modernizing small business 
                        professional liability insurance, today announced the launch of a new digital insurance platform that 
                        enables instant underwriting and policy management for small firm professionals and agents.    
                    </p>
                    <p class="pb-3">
                        Draftrs is launching with a new professional liability insurance product for architects and engineers that 
                        was developed in collaboration with Markel Insurtech Underwriters and plans to introduce additional 
                        professional liability product lines in the future.   
                    </p>
                    <p class="pb-3">
                        “There is significant opportunity in the small commercial space, but existing underwriting and 
                        distribution models are too manual, high touch, and inefficient,” said Audrey Lau, co-founder and CEO of 
                        Draftrs. “This has resulted in limited product choices, poor customer experiences, and a large uninsured 
                        market. Through tech-enabled automation, Draftrs offers a better product and a better experience for 
                        small firm professionals and their agents that is focused entirely on what they need.”  
                    </p>
                    <p class="pb-3">
                        The Draftrs platform streamlines, automates and digitizes the underwriting and distribution process for 
                        the company’s insurance carrier and broker partners to more efficiently deliver professional liability 
                        products to the small commercial market.   
                    </p>
                    <p class="pb-3">
                        For brokers, Draftrs provides a custom-branded user interface that delivers the online experience 
                        customers in the small commercial segment require. Brokers can also use the platform to get quotes, 
                        send payment requests, issue policy documents and track client activity.   
                    </p>
                    <p class="pb-3">
                        “With full API and integration capabilities, Draftrs is the only professional liability market that can 
                        empower brokers digitally from day one with custom tech and digital access solutions,” said Curtis 
                        Corliss, co-founder and CTO of Draftrs. “From integrations into an agency’s digital quote marketing 
                        system to full custom-branded integration of the Draftrs digital customer and policy management 
                        interface, we partner with brokers to provide custom tech and integration solutions that will help them 
                        grow and better service their professional clients.”  
                    </p>
                    <p class="pb-3">
                        Draftrs was co-founded in 2021 by Lau, an insurance industry veteran with more than 15 years of 
                        experience in professional liability underwriting and distribution, and Corliss, the former co-founder and 
                        CTO of insurtech solutions provider ClarionDoor. Company advisors include Bill Vit, former CEO of Aon 
                        Affinity, the MGA and programs division of Aon Brokers, and Jeremy Hitzig, former CEO of Distinguished 
                        Programs.  
                    </p>
                    <p class="pb-3">
                        Already in use by brokers nationwide for architect and engineer clients, the Draftrs platform provides 
                        better access to the most important insurance purchase for small professional firms, professional 
                        liability protection.
                    </p>
                    <p class="pb-3">
                        <b>About Draftrs, Inc. (Draftrs)</b><br/>
                        Draftrs, Inc. (Draftrs) is a technology-enabled, managing general agent (MGA) that is streamlining, automating, and digitizing 
                        the insurance underwriting and distribution process for carrier and broker partners to create a more efficient way to deliver 
                        professional liability insurance to the small commercial market. Draftrs provides instant, direct access and enables distribution 
                        partners to become a digital agency without having to make any costly tech upgrades or investments.  For more information 
                        please visit <a href="https://draftrs.com" >https://draftrs.com</a>.
                    </p>
				</div>
                <div class="col-md-10 offset-md-1">
                    <a href="/Press">View more press releases</a>
                </div>
			</div>
		</main>
        <StandardFooter/>
    </div>
</template>

<script>
import StandardNav from "../../components/StandardNav.vue";
import StandardFooter from "../../components/StandardFooter.vue";
export default {
  name: "PressReleases",
  components: {
    StandardNav,
    StandardFooter
  },
    props: {},
    data: function() {
    },
    watch: {
    },
    mounted() {
        window.scrollTo(0,0);
    },
    methods: {
    },
};
</script>

<style lang="scss">
</style>
