<template>
    <div v-if="quote">
        <vue-html2pdf
            id="vue-html2pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :htmlToPdfOptions="{
                filename: `quoteletter.pdf`,
            }"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="letter"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                            <div class="card-body">
                                <div>
                                    <a href="/home"><img :src="this.getClientLogo()" :height="this.getClientDomain() == 'draftrs' ? '33px' : false" alt=""></a>
                                </div>
                                <div class="d-flex justify-content-center mb-5">
                                    <QuoteSummary :quote="quote" :prorate="prorate" :issueAction="context.action" :header="''" />
                                </div>
                            </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>

const axios = require('axios');
import VueHtml2pdf from 'vue-html2pdf'
import QuoteSummary from "../components/QuoteSummary.vue";
import { loginUser, isLoggedIn, getUserData } from '../Utils/auth.js';
import { getClientDomain, getClientLogo, } from "../Utils/themer.js";

export default {
    name: "Payment",
    components: {
        QuoteSummary,
        VueHtml2pdf
    },
    props: {},
    data: function() {
        return {
            quote: {},
            context: {},
            quoteId: null,
            token: null,
            prorate: null,
            loadingSpinner: false
        };
    },
    mounted() {
        //decode because of azure's stupid (no period in urls)
        this.token = this.$route.params.token;

        if (!this.loggedIn()) {
            this.tokenRoute();
        } else {
            this.quoteData();
        }

    },
    methods: {
        quoteData: async function() {
            const quoteId = this.$route.params.id;

            if (quoteId)
                this.quote.id = quoteId ;

            if(!Object.prototype.hasOwnProperty.call(this.quote, 'limit')) {
                await axios.get('/api/quote/' + this.quote.id).then((res) => {
                    this.quote = res.data;

                    if (!this.quote.lob || this.quote.lob !== 'mpl' || this.quote.lob == 'pl')
                        this.quote.showQL = 'pl';
                    else if (this.quote.lob == 'mpl' )
                        this.quote.showQL = 'mpl';

                    this.generateReport();
                });
            } else {
                if (!this.quote.lob || this.quote.lob !== 'mpl' || this.quote.lob == 'pl')
                    this.quote.showQL = 'pl';
                else if (this.quote.lob == 'mpl' )
                    this.quote.showQL = 'mpl';

                this.generateReport();
            }

        },
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        getClientLogo(mob) {
            return getClientLogo(mob);
        },
        getClientDomain() {
            return getClientDomain();
        },
        tokenRoute: async function() {
            const client = getClientDomain();
            await axios.post("/api/consumer/token", { client: client }).then(res => {
                if (res.data.token) {
                loginUser(client, null, res.data.token);
                    this.quoteData();
                }
            });
        },
        loggedIn: function() {
            return isLoggedIn();
        },
        getRole() {
            return getUserData().role;
        }
    },
};
</script>

<style lang="scss">

</style>
