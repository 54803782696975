<template>
    <div id="wrapper">
        <StandardNav/>
        <main id="main" role="main">
            <section class="land-area m-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="uper-texts">
                                <h2>Technical Consultants Professional <br>Liability Insurance</h2>
                                <p>copy</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="imgg-box">
                                <img src="/images/men-r.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="block">
                                <h2>Claims Scenarios</h2>
                                <p>copy</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="block">
                                <h2>Coverage Highlights</h2>
                                <ul>
                                    <li>Defense costs, policy provides defense if you get sued</li>
                                    <li>Covers claims for bodily injury, property damage and financial loss</li>
                                    <li>Additional expense reimbursements for regulatory and administrative actions and professional licensing and disciplinary hearings</li>
                                    <li>Pre-claims reporting and subpeona assistance</li>
                                </ul>
                            </div>
                            <div class="btn-box">
                                <a :href="loggedIn() ? '/Quote' : '/ContactUs'" class="btn btn-get">Get a Quote Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>
import { isLoggedIn, } from "../Utils/auth.js";
import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "WeInsureTechnicalConsultants",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {};
  },
  mounted() {
      window.scrollTo(0,0);
  },
  methods: {
    loggedIn: function() {
      return isLoggedIn();
    },
  },
};
</script>

<style lang="scss">

</style>
