<template>
    <div>
        <StandardNav/>
        <header
			class="hero container-fluid d-flex flex-column justify-content-between text-center pt-5 bg-purple"
		>
			<div>
				<h1 class="display-4 font-weight-bold text-white col-md-10 offset-md-1">
					The only professional liability solution built for today's
					<span class="text-primary"> small professional firms </span>
				</h1>
				<h5 class="text-white my-4 col-md-6 offset-md-3">
					High-quality professional liability products with a platform that
					automates application, distribution, onboarding, and account
					management. It's a fresh experience for your small business customers
					and entire broker team.
				</h5>
				<a href="/getaquote" class="btn btn-secondary mx-1"
					>Get a quote</a
				>
			</div>
			<div class="col-md-8 offset-md-2">
				<img
					src="/images/hands-holding-tablet_popups.png"
					alt="Two hands holding a tablet with instant quote dashboard. Popups showing savings"
					class="img-fluid"
				/>
			</div>
        </header>
        <main>
          <!-- Image Grid -->
          <section class="container-lg py-5">
            <div class="row">
              <div
                class="col-md-5 d-flex flex-column justify-content-center align-items-center align-items-md-baseline text-center text-md-start mb-5 mb-md-0"
              >
                <h2 class="display-4 font-weight-bold">
                  <span class="text-primary"> Trusted by brokers </span>
                  to quote and manage their small business book
                </h2>
                <p class="my-4 text-black text-text-muted">
                  Quote A rated professional liability policies instantly and
                  automate application, distribution, onboarding, and account
                  management. It's a seamless experience for your customers and
                  entire broker team.
                </p>
              </div>
              <div class="col-md-6 offset-md-1">
                <img
                  src="/images/image-grid-3-min.png"
                  alt="Group of images with young professionals working and two people looking at designs"
                  class="img-fluid"
                />
              </div>
            </div>
          </section>
          <!-- How it works -->
          <section class="container-lg py-5">
            <h2 class="display-4 text-center font-weight-bold my-md-5">
              How it works
            </h2>
            <div class="row">
              <div class="col-md-6 position-relative mt-md-5 p-md-5">
                <div class="progress-node mt-5">
                  <div class="child-node"></div>
                </div>
                <h3 class="font-weight-bold text-md-end text-center mt-5">
                  Get instant quote options
                </h3>
              </div>
              <div class="col-md-5 p-5 progress-section">
                <p>
                  Streamlined digital application that can be completed by an Agent
                  or their Client in 5 minutes or less
                </p>
                <img
                  src="/images/quote-app-min.png"
                  alt="App screen showing instant quote"
                  class="img-fluid mt-5 shadow border-radius-30"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 position-relative mt-md-5 p-md-5">
                <div class="progress-node">
                  <div class="child-node"></div>
                </div>
                <h3 class="font-weight-bold text-md-end text-center">
                  Direct Credit Card Payment
                </h3>
              </div>
              <div class="col-md-5 p-5 progress-section">
                <p>
                  Email credit card payment link and electronic application
                  signatures directly to a client to bind coverage
                </p>
                <img
                  src="/images/email-quote-payment-icon.png"
                  alt="Icon showing email quote and payment to insured"
                  class="img-fluid mt-5 shadow border-radius-30"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 position-relative mt-md-5 p-md-5">
                <div class="progress-node">
                  <div class="child-node"></div>
                </div>
                <h3 class="font-weight-bold text-md-end text-center">
                  Download Policy
                </h3>
              </div>
              <div class="col-md-5 p-5 progress-section position-relative">
                <p>
                  Track quotes and manage accounts through the agent dashboard.
                  Directly cancel a policy, renew a policy and make mid term changes
                  with our self service account dashboards
                </p>
                <p class="text-black text-muted" style="font-size: 0.75rem">
                  No more waiting for an underwriter to get back to you with quotes,
                  policy documents and endorsement requests.
                </p>
                <img
                  src="/images/download-icon-min.png"
                  alt="Button to quickly download policy"
                  class="img-fluid mt-5 shadow border-radius-30"
                />
                <div class="progress-node-end">
                  <div class="child-node"></div>
                </div>
              </div>
            </div>
          </section>
          <!-- Carousel Screens -->
          <section class="container-fluid bg-purple">
            <div class="container-lg py-5">
              <h2 class="display-4 text-center text-white font-weight-bold my-5">
                Go from Quote to Bind
                <span class="text-primary">in minutes,</span><br />
                from anywhere
              </h2>
              <div class="row">
                <div class="col-md-8 offset-md-2">
                  <!-- Carousel -->
                  <div
                    id="carouselExampleIndicators"
                    class="carousel slide mb-5"
                    data-ride="carousel"
                  >
                    <ol class="carousel-indicators">
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="0"
                        class="active"
                      ></li>
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="1"
                      ></li>
                      <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="2"
                      ></li>
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img
                          src="/images/get-quote-full.png"
                          class="d-block w-100"
                          alt="Get online quote easy and instantly on web page"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          src="/images/account-screenshot-min.png"
                          class="d-block w-100"
                          alt="Web page of dashbaord to manage account"
                        />
                      </div>
                      <div class="carousel-item">
                        <img
                          src="/images/review-policy-full.png"
                          class="d-block w-100"
                          alt="Web page to manage account and view documents"
                        />
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      data-target="#carouselExampleIndicators"
                      data-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      data-target="#carouselExampleIndicators"
                      data-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Next</span>
                    </button>
                  </div>
                  <div class="row justify-content-center">
                    <p class="text-white text-center mb-5 pt-5">
                      Got a new small business prospect, but you're not in the
                      office or maybe in a meeting with a larger account? Set them
                      up for self-service in minutes. Send them to your broker
                      growth portal, they'll enter a few details, get a quote, pay
                      online, and they'll get onboarded almost instantly.
                    </p>
                    <div class="mb-5">
                      <a
                        href="/agentscontactus"
                        class="btn btn-outline-info mx-1 mb-3"
                        >Learn more</a
                      >
                      <a href="/getaquote" class="btn btn-secondary mx-1 mb-3"
                        >Get a quote</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- Features -->
          <section class="container-fluid bg-grey py-5">
            <div class="container">
              <div class="row my-5">
                <div class="col-md-5 offset-md-1">
                  <h2 class="display-4 font-weight-bold">
                    Reliable.<br />
                    Secure.
                  </h2>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 offset-md-1">
                  <img
                    class="my-4"
                    src="/images/icons/medal-icon.png"
                    alt="Medal icon"
                  />
                  <h3 class="font-weight-bold">Always-on 24/7 access</h3>
                  <p class="text-black text-text-muted mb-5">
                    You can bind your quotes. Any day any hour. Weekends or nights.
                    We've got you covered.
                  </p>
                </div>
                <div class="col-md-4 offset-md-2">
                  <img
                    class="my-4"
                    src="/images/icons/shield-icon.png"
                    alt="Shield icon"
                  />
                  <h3 class="font-weight-bold">Your data is safe with us</h3>
                  <p class="text-black text-text-muted mb-5">
                    Keep complete control and retention of your customer data.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <!-- Trusted Partners -->
          <section class="container py-5">
            <div class="row justify-content-center">
              <div class="text-center col-md-8 my-5">
                <h2 class="display-4 text-black font-weight-bold my-5">
                  Partnered with
                  <span class="text-primary"> Leading National Agencies</span>
                </h2>
                <p class="text-black text-muted">
                  Draftrs is small business professional liability insurance
                  platform that makes it quick and easy for agents to get a quote,
                  bind coverage, and service policies.
                </p>
                <div class="row justify-content-between my-5" style="gap: 40px">
                  <div class="col-md-3">
                    <a target="_blank" href="https://acecbit.org/pli-quote/">
                      <img
                        src="/images/acec-logo.png"
                        alt="ACEC logo"
                        class="img-fluid"
                      />
                    </a>
                  </div>
                  <div class="col-md-3">
                    <a target="_blank" href="https://www.risk-strategies.com/">
                      <img
                        src="/images/risk-strategy-logo.png"
                        alt="Risk Strategies logo"
                        class="img-fluid"
                      />
                    </a>
                  </div>
                  <div class="col-md-3">
                    <a target="_blank" href="https://greyling.com/">
                      <img
                        src="/images/greyling-logo.png"
                        alt="Greyling logo"
                        class="img-fluid"
                      />
                    </a>
                  </div>
                </div>
                <a href="/agentscontactus" class="btn btn-primary"
                  >Partner with us</a
                >
              </div>
            </div>
          </section>

          <!-- Reviews -->
          <section class="container-fluid py-5 bg-grey">
            <div class="container py-5">
              <div class="row pb-5">
                <div class="col-md-8 offset-md-2">
                  <h1 class="display-3 text-black text-center font-weight-bold">
                    Loved by architects, engineers, design professionals, and their
                    brokers
                  </h1>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-md-6 pe-md-5">
                  <h4 class="font-weight-bold my-5">What our agents are saying</h4>
                  <div class="d-flex mb-5">
                    <div class="mr-3 flex-shrink-0">
                      <h4 class="font-weight-bold">
                        <span class="display-6 font-weight-bold">5.0</span>/ 5
                      </h4>
                    </div>
                    <div>
                      <img
                        src="/images/icons/stars-5-icon.png"
                        alt="5 gold stars"
                      />
                      <h5 class="font-weight-bold mt-3">
                        Great market option for our clients
                      </h5>
                      <p class="text-black text-muted">
                        Process was remarkably easy and fast. The direct
                        billing is great.Saves us so much time and so many of 
                        our clients are always asking to pay with credit card.
                        
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="mr-3 flex-shrink-0">
                      <h4 class="font-weight-bold">
                        <span class="display-6 font-weight-bold">4.9</span>/ 5
                      </h4>
                    </div>
                    <div>
                      <img
                        src="/images/icons/stars-5-icon.png"
                        alt="5 gold stars"
                      />
                      <h5 class="font-weight-bold mt-3">Draftrs will be our go to for small firs</h5>
                      <p class="text-black text-muted">
                        We like the self service, direct bill and direct client access.
                        Clients appreciate the faster turnaround times.  With the time saved
                        from paperwork we can focus more on building client relationships and
                        helping them with risk management.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pe-md-5">
                  <h4 class="font-weight-bold my-5">
                    What our insureds are saying
                  </h4>
                  <div class="d-flex mb-5">
                    <div class="mr-3 flex-shrink-0">
                      <h4 class="font-weight-bold">
                        <span class="display-6 font-weight-bold">5.0</span>/ 5
                      </h4>
                    </div>
                    <div>
                      <img
                        src="/images/icons/stars-5-icon.png"
                        alt="5 gold stars"
                      />
                      <h5 class="font-weight-bold mt-3">
                        Piece of cake!
                      </h5>
                      <p class="text-black text-muted">
                        Overall it was very easy to use and very easy to complete the
                        purchase. My agent was so helpful.  I would definitely
                        recommend Draftrs to other engineers who are looking for 
                        professional liability insurance.
                        
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="mr-3 flex-shrink-0">
                      <h4 class="font-weight-bold">
                        <span class="display-6 font-weight-bold">5.0</span>/ 5
                      </h4>
                    </div>
                    <div>
                      <img
                        src="/images/icons/stars-5-icon.png"
                        alt="5 gold stars"
                      />
                      <h5 class="font-weight-bold mt-3">Awesome experience!</h5>
                      <p class="text-black text-muted">
                        The process was simple and straightforward compared to the
                        experience with my previous provider which was horrible!
                        My agent was nice and knowledgeable and I am happy she
                        recommended Draftrs.
                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- Final CTA -->
          <section class="container-fluid bg-purple">
            <div class="container">
              <div class="row">
                <div
                  class="col-md-6 d-flex flex-column justify-content-center align-items-start py-4"
                >
                  <h2 class="display-4 text-white font-weight-bold mb-3">
                    Draftrs makes growing and managing a book of small business
                    clients effortless.
                    <span class="text-primary">Ready to get started?</span>
                  </h2>
                  <a href="/agentscontactus" class="btn btn-outline-info"
                    >Get started</a
                  >
                </div>
                <div class="col-md-5 offset-md-1">
                  <img
                    src="/images/woman-checking-phone_portrait-min-2.png"
                    alt="Young woman holding tablet in office"
                    class="img-fluid neg-margin-image"
                  />
                </div>
              </div>
            </div>
          </section>
        </main>
        <StandardFooter/>
    </div>
</template>

<script>

import StandardNav from "../components/StandardNav.vue";
import StandardFooter from "../components/StandardFooter.vue";

export default {
  name: "AgentsPartners",
  components: {
    StandardNav,
    StandardFooter
  },
  props: {},
  data: function() {
    return {
        workType: ''
    };
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    newQuote: function() {
        this.$router.push({name: 'quote', params: { workType: this.workType}});
    }
  },
};
</script>

<style lang="scss">

</style>
