<template>
  <body>
    <div id="wrapper" class="bg-light">
        <AgentNav/>
        <main id="main" role="main" class="px-3">
            <div class="alert alert-primary mt-3" role="alert">
                Quote Access sent successfully to {{ quote.email }}.
            </div>

            <div class="text-center mt-3">
              <a href="/dashboard" class="shadow btn btn-primary px-2 my-1">Go to Dashboard</a>
            </div>
        </main>
      <StandardFooter/>
    </div>
  </body>
</template>

<script>
import AgentNav from "../components/AgentNav.vue";
import StandardFooter from "../components/StandardFooter.vue";
import axios from 'axios';

export default {
  name: "SendAccess",
  components: {
    AgentNav,
    StandardFooter
  },
  data: function() {
    return {
        quoteId: null,
        quote: {}
    };
  },
  async mounted() {
      this.quoteId = this.$route.params.quoteId;
      let q;
      await axios.get('/api/quote/' + this.quoteId).then((res) => {
        q = res.data;
      });
      this.quote = q;

      //send access email to insured
      await axios.post('/api/recover', { email: q.email, quoteId: q.id}).then((res) => {
            if (res.data) {
                this.$toast.open({ 
                    message: 'An email has been sent to <strong>'+ q.email +'</strong> with instructions for accessing their policy',
                    type: 'success',
                    dismissible: true,
                    duration: 8000,
                    position: 'top-right'
                });
            }
        });
  },
  methods: {
    
  },
};
</script>

<style lang="scss">
    main {
        min-height: 500px;
    }
</style>
