
import './broker_configs.js';
import { broker_configs } from './broker_configs.js';
import { isLoggedIn, getUserData } from './auth.js';

export function getClientDomain() {
    const hn = window.location.hostname;
    if (hn.includes('ngrok.io')) {
        return 'draftrs';
    }
    const urlPart = hn.split('.')[0];
    let client = 'draftrs';
    if ('localhost' == urlPart || 'dev' == urlPart || 'www' == urlPart) {
        // Now you don't need to change this var if you login as a domain user like adam.hsieh+rpsins@draftrs.com we get the owner in this case rpsins and set the client
        client = 'draftrs'; 
    } else if ('draftrs.com' != hn) {
        client = urlPart;
    }

    // if user is logging in on draftrs.com domain but are from another domain set the client so can pull correct theme and also configs from broker_configs.json
    if (isLoggedIn() ) 
        client = getUserData().owner ? getUserData().owner : getUserData().client;

    // 👇 comment this out to test broker theme on localhost
    // return 'draftrs'; // 👈 Change here for local dev different clients. Don't forget to switch back to draftrs for www on prod
    return client;
}

export function isDevServer() {
    const hn = window.location.hostname;
    const urlPart = hn.split('.')[0];
    return ('localhost' == urlPart || 'dev' == urlPart) ? true : false;
}

export function getClientLogo(client) {
    client = client ? client : getClientDomain();
    client = ("dev" == client) ? 'draftrs' : client;
    return "/images/" + client + ".png";
}

//return all styles that exist
export function getStyles() {
    return Object.keys(confs[getClientDomain()]);
}

export function getStyle(type) {
    const client = getClientDomain();
    return (!confs[client] || !confs[client][type]) ? null : confs[client][type];
}

export function getClientStyle(client, type) {
    return (!confs[client] || !confs[client][type]) ? null : confs[client][type];
}

export function getClientText(brokerClient = '') {
    const client = brokerClient ? brokerClient : getClientDomain();
    const meta =
    {
        "dev":                      {"casual": 'Draftrs', "title": "Draftrs Professional Liability Insurance", "phone": "" },
        "draftrs":                  {"casual": 'Draftrs', "title": "Draftrs Professional Liability Insurance", "phone": "" }, // phone (214) 494-1422
        "acec":                     {"casual": 'ACEC', "title": "ACEC BIT PLI - Draftrs", "phone": "(833) 223-2248" },
        "greyling":                 {"casual": 'Greyling', "title": "Greyling Small Firm PLI - Draftrs", "phone": "(770) 552-4225" },
        "nfp":                      {"casual": 'NFP', "title": "NFP - Draftrs", "phone": "" },
        "riskprous":                {"casual": 'Risk Pro US', "title": "Risk Pro - Professional Liability Insurance", "phone": "" },
        "riskstrategies":           {"casual": 'Risk Strategies', "title": "Risk Strategies - Professional Liability Insurance", "phone": "" },
        "lipmaninsurance":          {"casual": 'Lipman Insurance', "title": "Draftrs Professional Liability Insurance", "phone": "" },
        "vonzella":                 {"casual": 'Vonzella', "title": "Draftrs Professional Liability Insurance", "phone": "" },
        "stevebrooksagency":        {"casual": 'Steve Brooks Agency', "title": "Draftrs Professional Liability Insurance", "phone": "" },
        "insurancetrak":            {"casual": 'InsuranceTrak', "title": "InsuranceTrak Professional Liability Insurance", "phone": "" },
        "promark":                  {"casual": 'Promark', "title": "Promark Professional Liability Insurance", "phone": "" },
        "promarkins":               {"casual": 'Promark', "title": "Promark Professional Liability Insurance", "phone": "" },
        "driscollinsured":          {"casual": 'Driscoll Insured', "title": "Driscoll & Associates Insurance Services Inc.", "phone": ""},
        "socius":                   {"casual": 'Socius', "title": "Socius Insurance", "phone": ""},
        "dkbgroup":                 {"casual": 'DKB Group', "title": "DKB Group LLC", "phone": ""},
        "ironridgeus":              {"casual": 'Iron Ridge US', "title": "Iron Ridge Insurance", "phone": ""},
        "zippydi":                  {"casual": 'ZippyDi', "title": "Zippy Digital Insurance", "phone": ""},
        "mightyoaksf":              {"casual": 'Mighty Oak SF', "title": "Mighty Oak Insurance Services", "phone": ""},
        "riskdesk":                 {"casual": 'Risk Desk', "title": "RiskDesk Insurance", "phone": ""},
        "maloney":                  {"casual": 'Maloney', "title": "Professional Liability Pro", "phone": ""},
        "catalant":                 {"casual": 'Catalant', "title": "Catalant", "phone": "" },
        "professionalliabilitypro": {"casual": 'Professional Liability Pro', "title": "Professional Liability Pro", "phone": "(503) 653-8287"},
        "grange":                   {"casual": 'Grange', "title": "Grange Insurance", "phone": ""},
        "accessone80":              {"casual": 'AccessOne80', "title": "One80 Intermediaries Inc.", "phone": ""},
        "whins":                    {"casual": 'Whins', "title": "Whins Insurance", "phone": "(818) 233-0833"},
        "macario":                  {"casual": 'Macario', "title": "Macario Insurance", "phone": "(858) 764-0583"},
        "rpsins":                   {"casual": 'RPS', "title": "Risk Placement Services", "phone": ""},
        "aibd":                     {"casual": 'AIBD', "title": "American Institute of Building Design", "phone": ""},
        "sierrainsurance":          {"casual": 'Sierra Insurance', "title": "Sierra Insurance", "phone": ""},
        "nwinsurance":              {"casual": 'Northwest Insurance Services', "title": "Northwest Insurance Services", "phone": ""},
        "semsee":                   {"casual": 'Semsee Corp', "title": "Semsee Corp", "phone": ""},
        "founderspro":              {"casual": 'Founders Professional', "title": "Founders Professional", "phone": ""},
        "aimdar":                   {"casual": 'Aimdar Insurance', "title": "Aimdar Insurance", "phone": ""},
        "nacm":                     {"casual": 'NACM Insurance', "title": "NACM Insurance", "phone": ""},
        "hub":                      {"casual": 'HUB Insurance', "title": "HUB Insurance", "phone": ""},
        "hardcover":                {"casual": 'Hardcover Insurance', "title": "Hardcover Insurance", "phone": ""},
        "limit":                    {"casual": 'Limit Insurance', "title": "Limit Insurance", "phone": "(816) 518-9517"},
        "mcnamarains":              {"casual": 'McNamara Insurance', "title": "McNamara Insurance", "phone": "(617) 964-0200"},
        "bbrown":                   {"casual": 'Brown & Brown Insurance', "title": "Brown & Brown Insurance", "phone": "(630) 245-4608"},
        "grantprofessionals":       {"casual": 'Grant Professionals', "title": "Grant Professionals Association", "phone": ""},
        "gallagher-affinity":       {"casual": 'Gallagher Affinity', "title": "Gallagher Affinity", "phone": ""},
        "smithbrothers":            {"casual": 'Smith Brothers', "title": "Smith Brothers Insurance", "phone": ""},
        "londonuw":                 {"casual": 'London Underwriters', "title": "London Underwriters", "phone": ""},
        "cherub":                   {"casual": 'Cherub Underwriters', "title": "Cherub Underwriters", "phone": ""},
        "ieca":                     {"casual": 'IECA', "title": "Independent Educational Consultants Association", "phone": ""},
        "oatc":                     {"casual": 'OATC', "title": "Oregon Association of Consultants", "phone": ""},
        "riemer":                   {"casual": 'Riemer Insurance Group', "title": "Riemer Insrance Group", "phone": ""}
    }
    return meta[client] ? meta[client] : meta.draftrs;
}

export function getBrokerConfigs(client = '') {
    // if there is a client definition in the json use it. Otherwise just use the "default" definition thats defined in the json file ./broker_configs.json
    //return Object.prototype.hasOwnProperty.call(broker_configs, getClientDomain()) ? broker_configs[getClientDomain()] : broker_configs['default'];

    let configs = broker_configs['default'];
    client = client ? client : getClientDomain();

    if (Object.prototype.hasOwnProperty.call(broker_configs, client)) {
        let broker = client;

        // keep all default configs except those that are specified for the broker
        for (const prop in configs) {
            if (Object.prototype.hasOwnProperty.call(broker_configs[broker], prop))
                configs[prop] = broker_configs[broker][prop];
        }
    }
    configs.broker = client;
    return configs;

}

const confs =  require("../../public/css/client_styles.json")
