<template>
<body>
    <div id="wrapper">
      <AgentNav/>

      <nav aria-label="breadcrumb" class=" d-md-block mb-0">
          <ol class="breadcrumb bg-transparent px-4 py-1 mb-0">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item" aria-current="Agent Dashboard"><a href="/dashboard" >Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="Renewals">Renewals</li>
          </ol>
      </nav>
      
      <div class="card">
        <a class="btn btn-primary" @click="updateViewType('expiring')">Expiring</a>
        <a class="btn btn-primary" @click="updateViewType('state')">State</a>
        <a class="btn btn-primary" @click="updateViewType('notice')">Notice</a>

        <pre>{{ viewResult }}</pre>
      </div>
  </div>
</body>
</template>

<script>
import AgentNav from "../components/AgentNav.vue";
import axios from 'axios';

export default {
  name: 'renewals',
  props: {
  },
  components: {
    AgentNav
  },
  data: function () {
    return {
      viewType: 'expiring',
      viewResult: {}
    };
  },
  async mounted() {
    //this.viewResult = await this.getView(this.viewType);
  },
  methods: {
    updateViewType: async function(type) {
      this.viewResult = await this.getView(type);
    },
    getView: async function(viewType) {
      let ret;
      await axios.post('/api/renewal/' + viewType, {})
        .then((res) => { ret = res.data; })
        .catch((e) => { console.log(e); ret = false; });

      return ret;
    }
  }
}
</script>

<style lang="scss">

</style>