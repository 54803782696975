import BooleanQuestion from "@/components/BooleanQuestion.vue"
import StateSelect from "@/components/Form/StateSelect.vue"
import ManualPremium from "@/components/Form/ManualPremium.vue"
import GetPremium from "@/components/Form/GetPremium.vue"
import Importer from "../components/Form/Importer.vue"

export default {
    question: {
        component: BooleanQuestion
    },
    state: {
        component: StateSelect
    },
    manualPremium: {
        component: ManualPremium
    },
    premium: {
        component: GetPremium
    },
    importer: {
        component: Importer,
        slotProps: {
            component: ['repetition']
        }
    }
}